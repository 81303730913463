import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';

const LaborProfileStoreDialog = ({
  storesDialogVisible,
  setStoresDialogVisible,
  selectedStoresValues,
}) => {
  return (
    <Dialog
      header="Stores Values"
      visible={storesDialogVisible}
      onHide={() => setStoresDialogVisible(false)}
      className="w-11 lg:w-4"
      draggable={false}
    >
      <ListBox
        listStyle={{ maxHeight: '250px' }}
        value={selectedStoresValues}
        options={selectedStoresValues?.map(store => ({
          store_name: `${store?.store_number || store?.number || ''} - ${store?.store_name || store?.name || ''}`,
          store_id: store?.store_id,
        }))}
        optionLabel="store_name"
        className="w-full"
      />
    </Dialog>
  );
};
export default LaborProfileStoreDialog;
