import React from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import {
  Add as AddIcon,
  CloudDownload as CloudDownloadIcon,
} from '@material-ui/icons';
import { Image } from 'primereact';
import offlineIcon from '../../../assets/images/offline.svg';
import onlineIcon from '../../../assets/images/offline-green.svg';
import expandOffIcon from '../../../assets/images/expand-icon-inactive.svg';
import { useStyles } from './Docs.styles.js';

export default function RelatedMeasurementPhotosCard({
  docDetails,
  handleOfflineAvailability,
  relatedOfflineDocumentsMode = false,
  offlineDocuments,
  installerVisible,
  selectedJob,
  handlePreview,
}) {
  let documentName = '';
  try {
    if (docDetails) {
      if (docDetails.documetTypeId == 2)
        documentName = docDetails?.templateName;
      else documentName = docDetails?.documentName;
    }
  } catch (error) {}

  const classes = useStyles();

  const cardHTML = !docDetails ? null : (
    <Grid item>
      <Card classes={{ root: classes.photoCards }}>
        <CardActionArea
          onMouseEnter={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseLeave={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CardContent classes={{ root: classes.docDetails }}>
            <Image
              alt={docDetails?.documentName}
              src={docDetails?.documentUrl}
              imageStyle={{ verticalAlign: 'middle', margin: '0 8px 0 0' }}
              imageClassName={`${classes.photo} ${classes.avatarImg}`}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );

  return docDetails ? (
    <>
      <div className="grid px-2 w-14rem h-12rem grid-nogutter">
        <Card className="grid grid-nogutter border-gray-200 surface-overlay border-1">
          <div className={`col-2 col-md-2 col-lg-2`}>
            <>
              <div className="h-2rem p-2">
                <Tooltip title="Expand">
                  <img
                    src={expandOffIcon}
                    className="cursor-pointer"
                    alt="Technician"
                    width={12}
                    height={12}
                    onClick={() => handlePreview(docDetails?.documentUrl)}
                  />
                </Tooltip>
              </div>
              {(relatedOfflineDocumentsMode
                ? selectedJob?.project_type == 'Measurement' && installerVisible
                : installerVisible) && (
                <>
                  <Tooltip
                    title={
                      relatedOfflineDocumentsMode &&
                      offlineDocuments?.filter(
                        doc => doc.isRelatedProjectDoucmentOffine
                      ).length >= 2
                        ? 'Only 2 photos could be saved offline'
                        : 'Viewable in Offline'
                    }
                  >
                    <>
                      {offlineDocuments?.filter(
                        doc =>
                          doc.documentId === docDetails?.documentId &&
                          doc.isRelatedProjectDoucmentOffine
                      ).length > 0 ? (
                        <div className="h-2rem p-2">
                          <img
                            src={onlineIcon}
                            className="cursor-pointer"
                            alt="Reviewed"
                            width={14}
                            height={14}
                            onClick={event => {
                              handleOfflineAvailability(
                                docDetails?.documentId,
                                relatedOfflineDocumentsMode
                              );
                            }}
                            disabled={
                              relatedOfflineDocumentsMode &&
                              offlineDocuments?.filter(
                                doc => doc.isRelatedProjectDoucmentOffine
                              ).length >= 2
                            }
                          />
                        </div>
                      ) : (
                        <div className="h-2rem p-2">
                          <img
                            src={offlineIcon}
                            className="cursor-pointer"
                            alt="Reviewed"
                            width={14}
                            height={14}
                            onClick={event => {
                              handleOfflineAvailability(
                                docDetails?.documentId,
                                relatedOfflineDocumentsMode
                              );
                            }}
                            disabled={
                              relatedOfflineDocumentsMode &&
                              offlineDocuments?.filter(
                                doc => doc.isRelatedProjectDoucmentOffine
                              ).length >= 2
                            }
                          />
                        </div>
                      )}
                    </>
                  </Tooltip>
                </>
              )}
            </>
          </div>
          <div className={`col-10 col-md-10 col-lg-10`}>{cardHTML}</div>
        </Card>
      </div>
    </>
  ) : (
    <Grid>
      <Card classes={{ root: classes.add }}>
        <CardContent classes={{ root: classes.addIcon }}>
          <AddIcon fontSize="large" className={classes.plusIcon} />
        </CardContent>
      </Card>
    </Grid>
  );
}
