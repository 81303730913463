// **** React Imports ****
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';

import ApiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import SkeletonLoader from '../../shared/Loader/skeleton';
import TableLoader from '../../shared/Loader/TableLoader';

import './styles.css';
import {
  momentTz,
  convert24HourTimeTo12,
  isValidTimeFormat,
  onlyLettersAndNumbers,
} from '../../../../src/utils/Helpers';

const AuditNew = ({ formik }) => {
  // Set the default selected option based on modal_name
  const defaultSelectedModalName = 'Default';
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
  });
  const [count, setCount] = useState();
  const [searchQuery, setSearchQuery] = useState({
    limit: 10,
    offset: 0,
    searchString: '',
    sortKey: 'created_at',
    sortDirection: 'DESC',
  });
  const [auditValues, setAuditValues] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [dropDownArray, setDropDownArray] = useState([]);
  const [reportValue, setReportValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSortedValues, setIsSortedValues] = useState('');

  const toast = useRef(null);

  const toCapitalizedCase = str => {
    let prefix = '';
    if (
      str === 'projectInstaller__date_scheduled_start' ||
      str === 'projectInstaller__date_scheduled_end'
    ) {
      prefix = 'Technician ';
    }
    if (
      str === 'project__date_scheduled_start' ||
      str === 'project__date_scheduled_end'
    ) {
      prefix = 'Project ';
    }

    return (
      prefix +
      str
        .split('__')[1]
        .replace(/_/g, ' ')
        .replace(/\b\w/g, firstChar => firstChar.toUpperCase())
    );
  };

  let retry = 0;

  const handleOnChange = async (event, retryCall = 0) => {
    setIsLoading(true);
    setAuditValues([]);
    try {
      retry++;
      const response = await ApiService.get(
        `${URL_CONSTANTS.AUDIT_LOG.module.getRowData}${event}`
      );
      const responseAuditLog = await ApiService.postAuditLog(
        `/audit-log/${formik?.values?.project_id}/report`,
        { log_fields: response?.data?.log_fields }
      );

      const auditMockRes = responseAuditLog;

      const changeLog = auditMockRes?.changeLog;

      if (changeLog && changeLog?.length > 0) {
        const dynamicHeaders = Object.keys(changeLog[0]);
        let filteredHeaders = dynamicHeaders?.filter(header =>
          response?.data?.log_fields.some(field => field.field === header)
        );

        const sortedArray = filteredHeaders.slice().sort((a, b) => {
          const indexOfA = response?.data?.log_fields
            ?.map(obj => obj.field)
            ?.indexOf(a);
          const indexOfB = response?.data?.log_fields
            ?.map(obj => obj.field)
            ?.indexOf(b);
          return indexOfA - indexOfB;
        });
        const sortedValues = sortedArray?.filter(header =>
          header?.includes('created_at')
        )[0];
        setIsSortedValues(sortedValues);
        setIsLoading(false);
        setHeaders(sortedArray);
        let result = [auditMockRes.initialData, ...changeLog];
        result = result.map(obj => {
          for (const key in obj) {
            if (isValidTimeFormat(obj[key]?.value)) {
              obj[key] = {
                ...obj[key],
                value: convert24HourTimeTo12(obj[key]?.value),
              };
            }
            if (obj[key]?.isDateField) {
              obj[key] = {
                ...obj[key],
                value: momentTz(obj[key]?.value).format('MM-DD-YYYY hh:mm A'),
                dateValue: obj[key]?.value,
              };
            }
            if (key?.includes('modified_by')) {
              obj[key] = {
                ...obj[key],
                value: onlyLettersAndNumbers(obj[key]?.value)
                  ? 'System'
                  : obj[key]?.value,
              };
            }
            if (obj[key]?.value?.includes('_')) {
              obj[key] = {
                ...obj[key],
                value: obj[key]?.value?.replace(/_/g, ' '),
              };
            }
          }
          return obj;
        });
        setAuditValues(result);
      } else {
        if (retry < 3 && !auditMockRes?.initialData) {
          let r;
          if (!retryCall) {
            r = await ApiService.post(
              `/projects/audit/${formik?.values?.project_id}`,
              {}
            );
            retryCall = r;
          }
          setTimeout(() => {
            handleOnChange(event, retryCall);
          }, 2000);
        } else {
          setHeaders([]);
          setAuditValues([]);
          setIsSortedValues('');
          setIsLoading(false);
        }
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error?.message,
        life: 3000,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCount(auditValues?.length);
  }, [auditValues]);

  useEffect(async () => {
    try {
      const response = await ApiService.get(
        `${URL_CONSTANTS.AUDIT_LOG.module.list}`
      );
      const updatedArray = response?.rows.map(item => ({
        audit_log_modal_id: item?.audit_log_modal_id,
        modal_name: item?.modal_name,
      }));
      const defaultSelectedOption = updatedArray?.find(
        option => option?.modal_name === defaultSelectedModalName
      );

      setReportValue(defaultSelectedOption?.audit_log_modal_id || '');
      handleOnChange(defaultSelectedOption?.audit_log_modal_id);
      setDropDownArray(updatedArray);
    } catch (e) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: e?.message,
        life: 3000,
      });
    }
  }, []);

  const renderHeader = () => {
    return (
      <div className="grid">
        <div className="col-12 lg:col-6 flex align-items-center">
          <h3 className="text-xl">Audit Report</h3>
        </div>
        <div className="col-12 md:col-6 pb-0 justify-content-end flex">
          <PFDropdown
            options={dropDownArray}
            placeholder="Select Report"
            optionLabel="modal_name"
            optionValue="audit_log_modal_id"
            name="reportName"
            filter={true}
            value={reportValue || ''}
            onChange={(n, e) => {
              setReportValue(e);
              handleOnChange(e);
            }}
            className="w-full md:w-14rem p-inputtext-sm"
            disabled={disabled}
          />
        </div>
      </div>
    );
  };

  const handleOnPage = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      limit: event.rows,
      offset: event.first,
    }));
    setlazyState(event);
  };

  const getCellClassName = (rowData, field) => {
    let highlightClassName = 'highlight';
    highlightClassName =
      rowData?.action && rowData?.action?.value.toUpperCase() === 'DELETE'
        ? 'highlightDelete'
        : highlightClassName;
    return rowData[field].isUpdated && rowData[field]?.value
      ? highlightClassName
      : '';
  };

  return (
    <>
      <Toast ref={toast}></Toast>
      <Message
        text="Audit logs post February 27 2024, can be seen on this tab."
        className="border-none"
      />
      <div className="grid mt-3">
        {isLoading ? (
          <>
            <TableLoader columnCount={7} noOfRow={15} mTopBottom={0} />
          </>
        ) : (
          <DataTable
            value={auditValues}
            dataKey="auditLog_id"
            showGridlines={false}
            size="small"
            stripedRows={true}
            lazy={false}
            paginator={false}
            header={renderHeader}
            rows={10}
            rowsPerPageOptions={[10, 20, 50, 100]}
            columnResizeMode="expand"
            resizableColumns
            className="w-full"
            scrollable
            sortOrder={1}
            sortField={`${isSortedValues}.dateValue`}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            pt={{
              wrapper: {
                className: 'max-h-30rem',
              },
            }}
          >
            {headers.map((col, i) => (
              <Column
                frozen={i === 0 ? true : false}
                sortable={col?.includes('created_at') ? true : false}
                key={col}
                field={`${col}.value`}
                header={toCapitalizedCase(col)}
                body={rowData => (
                  <span className={`${getCellClassName(rowData, col)}`}>
                    {rowData[col].value}
                  </span>
                )}
                sortField={`${col}.dateValue`}
              />
            ))}
          </DataTable>
        )}
      </div>
    </>
  );
};

export default AuditNew;
