import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ApiService from '../../../services/api.service';
const initialState = {
  projectStatusType: [],
  status: false,
  error: null,
};
export const fetchProjectStatusType = createAsyncThunk(
  'master/fetchProjectStatusType',
  async (_, { getState, rejectWithValue }) => {
    const { projectStatusType } = getState().projectStatusType;
    if (projectStatusType && projectStatusType.length > 0) {
      return rejectWithValue('Project status type already fetched');
    } else {
      const response = await ApiService.get(
        '/system/status?statusType=Project'
      );
      return response;
    }
  }
);
const projectStatusTypeSlice = createSlice({
  name: 'projectStatusType',
  initialState,
  reducers: {
    clearProjectStatusType: state => {
      state.projectStatusType = [];
      state.status = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProjectStatusType.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchProjectStatusType.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.projectStatusType = action?.payload || [];
      })
      .addCase(fetchProjectStatusType.rejected, (state, action) => {
        if (action.payload === 'Project status type already fetched') {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.error = action.error.message;
        }
      });
  },
});
export const { clearProjectStatusType } = projectStatusTypeSlice.actions;

export const projectStatusTypeReducer = projectStatusTypeSlice.reducer;
