import React, { useState, useRef, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

import PFAutoComplete from './PFAutoComplete.jsx';
import PFButton from './PFButton.js';

export const PFDynamicDropdown = ({
  listCallback = () => {},
  field,
  storeCallback,
  defaultValue,
  selectedData,
  label,
  disabled: suggestionInput,
  buttonVisibility = true,
  className = '',
}) => {
  const dropdownRef = useRef(null);
  const [value, setValue] = useState('');
  const [items, setItems] = useState([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    listCallback(null, setItems);
  }, []);

  const debouncedChangeHandler = useCallback(
    debounce(value => {
      value && listCallback(value, setItems);
    }, 500),
    []
  );

  const handleCreate = () => {
    if (value) {
      storeCallback(value, setValue, setItems);
    }
  };

  useEffect(() => {
    setDisabled(true);
    if (value && typeof value === 'string') {
      const res = items?.find(
        res => res?.company_name?.toLowerCase() === value?.trim().toLowerCase()
      );
      res ? setDisabled(true) : setDisabled(false);
    }
    selectedData(null);
    if (value && typeof value === 'object') {
      selectedData(value);
    }
  }, [value, items]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  return (
    <div className={`p-inputgroup ${className}`}>
      <span className={`p-float-label ${className}`}>
        <PFAutoComplete
          inputClassName={`${buttonVisibility ? `border-right-none border-noround-right p-inputtext-sm height-40  ${className}` : ''}`}
          ref={dropdownRef}
          value={value}
          suggestions={items}
          completeMethod={e => debouncedChangeHandler(e)}
          onChange={e => {
            setValue(e.value);
          }}
          onFocus={e => {
            if (dropdownRef.current) {
              dropdownRef.current.show();
            }
          }}
          field={field}
          loading={false}
          disabled={suggestionInput || false}
        />
        {buttonVisibility && (
          <PFButton
            icon="pi pi-plus"
            className={`p-button-primary height-40 border-round-right-md ${className}`}
            severity="primary"
            type="button"
            disabled={disabled}
            onClick={handleCreate}
          />
        )}
        <label htmlFor="PFDynamicDropdown">{label || ''}</label>
      </span>
    </div>
  );
};
