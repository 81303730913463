import { MESSAGES } from '../helpers/helpers';

export const ProjectCardRowTemplate = ({ label, value }) => {
  return (
    <>
      <div className={'col-fixed p-1 w-12rem'}>
        <div className="flex w-full border-bottom-dashed">
          <div className="col-fixed p-0 schedule-job-heading text-left w-4rem">
            {label}
          </div>
          <div className="col-fixed p-0 pl-1 schedule-job-subheading word-break text-left w-8rem white-space-normal">
            {value != 'null' ? value : MESSAGES.NOT_AVAILABLE}
          </div>
        </div>
      </div>
    </>
  );
};
