import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';

export const packageReceivedGetService = async project_id => {
  try {
    const res = await axios.get(
      `${URL_CONSTANTS?.API?.BASE_URL}/shipping/project-shipping/get-shipping/${project_id}`
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'shipping.service',
      'packageReceivedGetService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const packageReceivedPostService = async (project_id, body = {}) => {
  try {
    const res = await axios.post(
      `${URL_CONSTANTS?.API?.BASE_URL}/shipping/project-shipping/create-shipping/${project_id}`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'shipping.service',
      'packageReceivedPostService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const packageReceivedPatchService = async (
  project_id,
  project_shipping_id,
  body = {}
) => {
  try {
    const res = await axios.patch(
      `${URL_CONSTANTS?.API?.BASE_URL}/shipping/project-shipping/update-shipping/${project_id}/${project_shipping_id}`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'shipping.service',
      'packageReceivedPatchService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const getAllShipmentService = async (
  project_id,
  project_shipping_id,
  rows,
  page,
  sortBy,
  orderBy
) => {
  try {
    let queryParams = {};
    queryParams.limit = rows;
    queryParams.offset = page * rows;

    if (project_id && project_shipping_id) {
      const res = await axios.get(
        `${URL_CONSTANTS?.API?.BASE_URL}/shipping/project-shipment/get-all-shipment/${project_id}/${project_shipping_id}`,
        { params: queryParams }
      );
      return res?.data || [];
    }
  } catch (e) {
    console.error(
      'shipping.service',
      'getAllShipmentService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const shippingCompanyListService = async search => {
  try {
    const res = await axios.get(
      `${URL_CONSTANTS?.API?.BASE_URL}/shipping/shipping-company/get-list${search ? `?search=${search}` : ''}`
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'shipping.service',
      'shippingCompanyListService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const shippingCompanyCreateService = async body => {
  try {
    const res = await axios.post(
      `${URL_CONSTANTS?.API?.BASE_URL}/shipping/shipping-company/create-shipping-company`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'shipping.service',
      'shippingCompanyCreateService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const getAllTechnicianListService = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS?.API?.BASE_URL}/auth/user/report/get-installers`
    );
    if (response?.status == 200) {
      return response?.data || [];
    }
  } catch (e) {
    console.error(
      'shipping.service',
      'getAllTechnicianListService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const createShipmentService = async (
  project_id,
  project_shipping_id,
  payload
) => {
  try {
    if (project_id) {
      const response = await axios.post(
        `${URL_CONSTANTS?.API?.BASE_URL}/shipping/project-shipment/create-shipment/${project_id}`,
        payload
      );
      if (response?.status) {
        return response?.data || null;
      }
    }
  } catch (e) {
    console.error(
      'shipping.service',
      'createShipmentService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const updateShipmentService = async (
  project_id,
  project_shipping_id,
  project_shipment_id,
  payload
) => {
  try {
    if ((project_id && project_shipping_id, project_shipment_id)) {
      const response = await axios.patch(
        `${URL_CONSTANTS?.API?.BASE_URL}/shipping/project-shipment/update-shipment/${project_id}/${project_shipping_id}/${project_shipment_id}`,
        payload
      );
      if (response?.status) {
        return response?.data || null;
      }
    }
  } catch (e) {
    console.error(
      'shipping.service',
      'updateShipmentService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const deleteShipmentService = async (
  project_id,
  project_shipping_id,
  project_shipment_id
) => {
  try {
    if ((project_id && project_shipping_id, project_shipment_id)) {
      const response = await axios.delete(
        `${URL_CONSTANTS?.API?.BASE_URL}/shipping/project-shipment/delete-shipment/${project_id}/${project_shipping_id}/${project_shipment_id}`
      );
      if (response?.status) {
        return response?.data || null;
      }
    }
  } catch (e) {
    console.error(
      'shipping.service',
      'deleteShipmentService',
      e && e.message ? e.message : e
    );
    return {};
  }
};
