// **** React Imports ****
import React, { useEffect, useState } from 'react';

// **** External Utilities ****
import { Grid, TextField, InputLabel, FormHelperText } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// **** Custom Components ****
import GenericTable from '../../shared/GenericTable/GenericTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import {
  createNewDocument,
  editDocument,
  deleteDocument,
  getPreSignedUrl,
} from './UserManagement.service';

// **** Styles *****
import { useStyles } from './UserManagement.styles';

const documentsColumns = [
  {
    id: 'file',
    value: 'File',
  },
  {
    id: 'description',
    value: 'Description',
  },
  {
    id: 'notes',
    value: 'Notes',
  },
];

const Documents = ({ documents, userId, setReloadDocumentsList }) => {
  const classes = useStyles();
  const docInputRef = React.useRef();
  const { setAlert } = useAlerts();
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [formAction, setFormAction] = React.useState('add');
  const [preSignedUrl, setPreSignedUrl] = useState();
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Add Document',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });

  let documentsData = [];
  // React.useEffect(() => {
  //   getRelations(setRelationsList);
  // }, []);
  const handleSignedUrl = async (event, value) => {
    event.preventDefault();
    const docUrl = value;
    const preUrl = await getPreSignedUrl(setLoading, setPreSignedUrl, docUrl);
    window.open(preUrl, '_blank');
  };
  // **** Data Modification to Display *****
  if (documents?.length) {
    documentsData = documents?.map((document, index) => {
      return {
        document_id: { value: index },
        file: {
          data: document.file,
          value: (
            <a
              href={'javascript:void(0)'}
              target="_blank"
              rel="noreferrer"
              download={document?.file?.name}
              className={classes.docName}
              onClick={(event, value) =>
                handleSignedUrl(
                  event,
                  (value = document?.file?.url?.split('?')[0])
                )
              }
            >
              {document?.file?.name || ''}
            </a>
          ),
        },
        // document_name: { value: document.document_name },
        description: { value: document.description },
        notes: { value: document.notes },
      };
    });
  }

  //View Emergency Contact
  const viewEditDocument = (action, index) => {
    setFormAction(action);
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title: (action === 'view' && 'View Document') || 'Edit Document',
    }));
    setRowIndex(index);
    setFormAction(action);
    setIsOpen(true);
  };
  const confirmDeleteDocument = index => {
    setRowIndex(index);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'Are you sure you want to delete document?',
      header: 'Delete Document',
    });
  };
  const onConfirmDialog = () => {
    const remainingDocumentData = documentsData
      .filter((val, index) => {
        return rowIndex !== val.document_id?.value;
      })
      .map(val => {
        return {
          file: val.file.data,
          description: val.description.value,
          notes: val.notes.value,
        };
      });

    deleteDocument(
      userId,
      remainingDocumentData,
      setLoading,
      setAlert,
      setReloadDocumentsList,
      setConfirmDialog
    );
  };

  //Emergency Contact ValidationSchema
  const documentValidationSchema = Yup.object().shape({
    file: Yup.mixed().required('Required'),
    description: Yup.string().trim().required('Required'),
  });
  // **** Formik Form Values ****
  const documentFormik = useFormik({
    initialValues: {
      file:
        (formAction !== 'add' && documentsData[rowIndex]?.file?.data) || null,
      //file: (formAction === 'add' || formAction === 'edit') && null,
      //document_name: (formAction !== 'add' && documentsData[rowIndex]?.document_name.value) || null,
      description:
        (formAction !== 'add' && documentsData[rowIndex]?.description.value) ||
        '',
      notes:
        (formAction !== 'add' && documentsData[rowIndex]?.notes.value) || null,
    },
    onSubmit: values => {
      if (formAction === 'add') {
        createNewDocument(
          userId,
          documents,
          values,
          setLoading,
          setIsOpen,
          setReloadDocumentsList,
          setAlert
        );
      } else {
        editDocument(
          userId,
          rowIndex,
          documentsData,
          documentFormik.values,
          setLoading,
          setIsOpen,
          setReloadDocumentsList,
          setAlert
        );
      }
    },
    validationSchema: documentValidationSchema,
    enableReinitialize: true,
  });
  const getDocumentViewEditForm = () => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item>
          <InputLabel
            shrink
            id="fielupload-label"
            className={classes.label}
            required={formAction !== 'view'}
          >
            File Upload
          </InputLabel>
          {formAction !== 'add' && (
            <a
              href={documentFormik.values?.file?.url + '#download'}
              target="_blank"
              rel="noreferrer"
              download={documentFormik.values?.file?.name}
              className={classes.docName}
            >
              {documentFormik.values.file?.name}
            </a>
          )}
          {formAction !== 'view' && (
            <input
              type="file"
              accept=".pdf, .doc, .docx, .xls, .xlsx, .csv"
              id="file"
              name="file"
              label="fielupload-label"
              onBlur={documentFormik?.handleBlur}
              onClick={event => {
                event.target.value = null;
              }}
              ref={docInputRef}
              onChange={(event, value) => {
                if (event.target.files && event.target.files[0]) {
                  if (
                    ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv'].includes(
                      event.currentTarget.files[0].name.split('.').pop()
                    )
                  ) {
                    documentFormik.setFieldValue(
                      'file',
                      event?.currentTarget?.files[0]
                    );
                  } else {
                    documentFormik.setErrors({
                      file: 'Only .pdf, .doc, .docx, .xls, .xlsx and .csv files are allowed',
                    });
                    docInputRef.current.value = '';
                  }
                }
              }}
              className={classes.inputfileupload}
            />
          )}
          {documentFormik.errors.file && (
            <FormHelperText error>{documentFormik.errors.file}</FormHelperText>
          )}
        </Grid>
        <Grid item>
          <TextField
            id="description"
            label="Description"
            name="description"
            onChange={documentFormik.handleChange}
            onBlur={documentFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view',
            }}
            disabled={formAction === 'view'}
            required={formAction !== 'view'}
            value={documentFormik?.values?.description}
            InputLabelProps={{ shrink: true }}
            error={
              documentFormik.touched.description &&
              documentFormik.errors.description
            }
            helperText={
              documentFormik.touched.description &&
              documentFormik.errors.description
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="notes"
            label="Notes"
            multiline
            onChange={documentFormik.handleChange}
            onBlur={documentFormik.handleBlur}
            InputProps={{
              readOnly: formAction === 'view' ? true : false,
            }}
            value={documentFormik?.values?.notes}
            InputLabelProps={{ shrink: true }}
            error={documentFormik.touched.notes && documentFormik.errors.notes}
            helperText={
              documentFormik.touched.notes && documentFormik.errors.notes
            }
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {(loading && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <PFTableLoader />
          </Grid>
        </Grid>
      )) || (
        <Grid container spacing={2} direction="column">
          <Grid item classes={{ root: classes.tableWrapper }}>
            <GenericTable
              title="Documents"
              columnData={documentsColumns}
              rowData={documentsData}
              showActions={{ view: true, edit: true, delete: true }}
              headerLinks={[
                {
                  label: 'Add Item',
                  handler: () => {
                    setFormAction('add');
                    documentFormik.resetForm();
                    setDialogSettings(prevState => ({
                      ...prevState,
                      showButton2: true,
                      title: 'Add Document',
                    }));
                    setIsOpen(true);
                  },
                },
              ]}
              handleView={index => viewEditDocument('view', index)}
              handleEdit={index => viewEditDocument('edit', index)}
              handleDelete={index => confirmDeleteDocument(index)}
            />
          </Grid>
          <GenericDialog
            fullwidth
            isOpen={isOpen}
            handleClose={() => {
              setIsOpen(false);
              documentFormik?.handleReset();
            }}
            handleSave={documentFormik.handleSubmit}
            dialogSettings={dialogSettings}
            disabledButton2={!documentFormik?.dirty || !documentFormik?.isValid}
            disabledButton1={false}
          >
            <form>{getDocumentViewEditForm()}</form>
          </GenericDialog>
          {/* Confirmation dialog for delete */}
          <GenericConfirmationDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            onConfirmDialog={onConfirmDialog}
          />
        </Grid>
      )}
    </>
  );
};

export default Documents;
