import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

import PageHeader from '../../../shared/PageHeader/PageHeader';
import PFButton from '../../../shared/PFPrime/PFButton';
// eslint-disable-next-line import/no-unresolved
import PFPickList from '../../../shared/PFPrime/PFPickList';

import {
  getLaborItemsList,
  getMerchandiseItemsList,
  getTargetList,
  groupItems,
} from './group-items.service';

const searchQuery = {
  limit: 100,
  offset: 0,
  is_parent: 0,
  is_child: 0,
};
const AddEditGroupItems = () => {
  const location = useLocation();
  const { type, item_number, item_desc, item_id, is_child } =
    location.state || {};
  if (!type || !item_id) {
    return null;
  }
  const [originalSource, setOriginalSource] = useState([]);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);

  const toast = useRef(null);
  const initialTargetRef = useRef([]);
  const history = useHistory();
  const isLabor = type === 'labor';
  const typeText = type.charAt(0).toUpperCase() + type.slice(1);
  const accessBreadcrumb = [
    {
      textWithoutLink: 'Project Management',
    },
    {
      link: '/project/items',
      text: 'Items',
    },
    {
      textWithoutLink: `${typeText} Items Configuration`,
    },
  ];

  const filteredItems = items =>
    items?.filter(
      item =>
        item?.item_number !== item_number &&
        !target.some(targetItem => targetItem?.item_id === item?.item_id)
    );

  const callItemList = async () => {
    let response;
    if (isLabor) {
      response = await getLaborItemsList(searchQuery);
    } else {
      response = await getMerchandiseItemsList(searchQuery);
    }
    try {
      const filteredList = filteredItems(response?.items);
      setOriginalSource(filteredList);
      setSource(filteredList);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    callItemList();
  }, []);

  const targetResponse = async () => {
    const response = await getTargetList(item_id);
    setTarget(response.children);
  };

  useEffect(() => {
    if (is_child) {
      targetResponse();
    }
  }, [is_child, item_id]);

  useEffect(() => {
    if (initialTargetRef?.current?.length === 0) {
      initialTargetRef.current = target;
    }
  }, [target]);

  const onSourceSearch = async searchText => {
    if (searchText) {
      if (searchText.length > 2) {
        let response;
        if (isLabor) {
          response = await getLaborItemsList(searchQuery, searchText);
        } else {
          response = await getMerchandiseItemsList(searchQuery, searchText);
        }
        setSource(filteredItems(response?.items));
      } else {
        setSource(filteredItems(source));
      }
    } else {
      setSource(filteredItems(originalSource));
    }
  };
  const debounceFilterUserName = useCallback(debounce(onSourceSearch, 500), [
    source,
    originalSource,
    target,
  ]);

  const onTargetSearch = searchText => {
    if (!searchText) {
      setTarget(initialTargetRef?.current);
    } else {
      const filteredTarget = initialTargetRef?.current?.filter(
        item =>
          item?.item_desc?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          item?.item_number?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
      setTarget(filteredTarget);
    }
  };

  const handleSave = async () => {
    try {
      const parentId = item_id;
      const payload = {
        child_ids: target.map(item => item.item_id),
      };
      const response = await groupItems(parentId, payload);
      if (response) {
        toast.current.show({
          severity: 'success',
          summary: 'Item Linked Successfully.',
        });
        history.replace({
          state: {
            ...location.state,
            is_child: true,
          },
        });
      }
      true;
      setDialogVisible(false);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'An Error Occurred While Linking The Items.',
      });
    }
  };

  return (
    <div className="w-full">
      <PageHeader
        pageTitle={`Group ${typeText} Items`}
        breadCrumbArray={accessBreadcrumb}
        className="pl-2"
      />
      <Card className="mt-4" pt={{ body: { className: 'pt-0' } }}>
        <Toast ref={toast}></Toast>
        <>
          <div className="col-12 flex flex-wrap align-items-center py-0">
            <div className="col-12 md:col-8 lg:col-8 md:ml-8 lg:ml-8 md:mr-2 lg:mr-2 P-0 ">
              <h2 className="my-0">{`${item_number} - ${item_desc} `}</h2>
            </div>
            <div className="col-12 md:col-1 lg:col-1 md:ml-4 lg:ml-4">
              <PFButton
                className="w-full"
                label="Back"
                outlined
                onClick={() => {
                  history.push(`/project/items`);
                }}
              ></PFButton>
            </div>
            <div className="col-12 md:col-1 lg:col-1 ">
              <PFButton
                className="w-full"
                label="Save"
                onClick={() => {
                  setDialogVisible(true);
                }}
                disabled={!target.length}
              ></PFButton>
            </div>
          </div>
          <>
            <div className="col-12">
              <PFPickList
                source={source}
                target={target}
                onChange={event => {
                  setSource(event?.source);
                  setOriginalSource(event?.source);
                  setTarget(event?.target);
                }}
                sourceHeader="Source"
                targetHeader="Target"
                responsive={true}
                sourceStyle={{ height: '23rem' }}
                targetStyle={{ height: '23rem' }}
                filter
                filterBy="item_number"
                itemTemplate={item => (
                  <span>
                    {item?.item_number} - {item?.item_desc}
                  </span>
                )}
                onSourceFilterChange={e => debounceFilterUserName(e?.value)}
                onTargetFilterChange={e => onTargetSearch(e?.value)}
                sourceFilterPlaceholder="Search"
                targetFilterPlaceholder="Search"
              />
            </div>
          </>
          <Dialog
            header="Confirm"
            visible={dialogVisible}
            onHide={() => {
              setDialogVisible(false);
            }}
            className="w-4"
          >
            <p>
              {isLabor
                ? 'Going forward for all new Projects, labor profile configured on parent item would only be considered for calculations. Would you like to continue?'
                : 'Going forward for all new Projects, auto adjustments would be done on parent items only. Would you like to continue ?'}
            </p>

            <div className="col-12 md:col-12 px-0 text-right">
              <PFButton
                label="Cancel"
                primary
                outlined
                className="w-12 md:w-2 lg:w-2 ml-2"
                onClick={() => {
                  setDialogVisible(false);
                }}
              />

              <PFButton
                label="Save"
                className="w-12 md:w-2 lg:w-2 ml-2"
                onClick={handleSave}
              />
            </div>
          </Dialog>
        </>
      </Card>
    </div>
  );
};

export default AddEditGroupItems;
