import { URL_CONSTANTS } from '../../../constants/urlConstants';
import apiService from '../../../services/api.service';

export const allStore = async searchValue => {
  try {
    // const storesRequest = await ApiService.get(`/stores?searchQuery=${params}`);
    const storesRequest = await apiService.get(
      `/stores?searchString=${searchValue}`
    );
    return storesRequest;
  } catch (err) {
    console.error(err);
  }
};

export const getCustomerList = async searchValue => {
  try {
    let endpoint = '/customers?offset=0&limit=10';

    if (searchValue) {
      endpoint += `&searchString=${searchValue}`;
    }
    const response = await apiService.get(endpoint);
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getWorkroomOptions = async value => {
  try {
    const response = await apiService.get(
      `/master/get-Workroom/list?search=${value}`
    );
    if (response && response?.data) {
      return response?.data;
    }
    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDefaultFilterValuesById = async value => {
  try {
    const response = await apiService.get(
      apiService.formatUrl(
        URL_CONSTANTS.GLOBAL_FILTERS.getDefaultFiltersById,
        value
      )
    );
    if (response && response?.data) {
      return response?.data;
    }
    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
