import axios from 'axios';

import { getLocalTimeZone, isCrmEnabled } from '../../../../utils/Helpers';
import { CUBEJS_BASE_URL } from '../../../../config/variables';
import { CONFIG } from '../../../../constants';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getDataSource = async () => {
  try {
    const excludeCubesList = [
      'ProjectTaskNotes',
      'TaskNotes',
      'ProjectTasks',
      'OpportunitySource',
      'OpportunityStatus',
      'QuoteStatus',
      'ItemTypes',
      'QuotesLineItem',
      'QuotesNote',
      'ChargebackReasons',
      'ChargebackStatus',
      'CreditUser',
      'DebitUser',
      'CreatedBy',
      'UpdatedBy',
      'ChargebackPaymentCategories',
      'CrewTypes',
      'RetainageTypes',
      'RetainageCategories',
      'RetainagePaymentCategories',
    ];
    if (!isCrmEnabled()) {
      excludeCubesList.push(
        'Opportunity',
        'OpportunitySource',
        'OpportunityStatus',
        'Quotes'
      );
    }
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/widgets/cubes`
    );
    return (
      response?.data?.filter(
        record => !excludeCubesList.includes(record.name)
      ) || []
    );
  } catch (error) {
    console.log(error);
  }
};

export const getQueryResult = async (query, widget_id) => {
  try {
    if (query?.dimensions?.[0] || query?.measures?.length) {
      if (query.dimensions) {
        query.dimensions = query?.dimensions?.filter(
          dimension => dimension != null && dimension !== ''
        );
      }
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${CUBEJS_BASE_URL}/cubejs-api/v1/load/`,
        headers: {
          client_id: localStorage.getItem('client_id'),
          Authorization: localStorage.getItem('cubejsAuthorizationToken'),
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          query: query,
        }),
      };
      const response = await axios.request(config);
      return response?.data || [];
    }
    return {};
  } catch (error) {
    console.log(error);
  }
};

export const createWidget = async payload => {
  const response = await axios.post(
    `${URL_CONSTANTS.API.BASE_URL}/dashboard/widget-templates`,
    payload
  );
  return response;
};

export const editWidget = async (payload, id) => {
  const response = await axios.patch(
    `${URL_CONSTANTS.API.BASE_URL}/dashboard/widget-templates/${id}`,
    payload
  );
  return response;
};

export const getDataByCube = async (cubeName, dimensionName) => {
  if (
    ['Opportunity'].includes(cubeName) &&
    ['source_id'].includes(dimensionName)
  ) {
    const response = await axios.get(
      `${URL_CONSTANTS.CRM.baseUrl}/api/master-data/cube?cube=${cubeName}&dimension=${dimensionName}`
    );
    return response.data;
  } else {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/cubes/master?cube=${cubeName}&dimension=${dimensionName}`
    );
    return response?.data;
  }
};

export const getFilterDetails = async () => {
  const response = await axios.get(
    `${URL_CONSTANTS.API.BASE_URL}/dashboard/widgets/filter-type`
  );
  return response?.data;
};

export const getWidgetDataById = async id => {
  const response = await axios.get(
    `${URL_CONSTANTS.API.BASE_URL}/dashboard/widget-templates/${id}`
  );
  return response?.data;
};

export const getProjectDetails = async projectTaskId => {
  let apiUrl = `${CONFIG.API_BASE_URL}/projects/${projectTaskId}`;
  try {
    const response = await axios.get(apiUrl);
    if (response) {
      return response?.data;
    }
  } catch (error) {
    return null;
  }
};

export const getDashboardList = async (searchQuery, setDashboardListData) => {
  try {
    const newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');
    const response = await axios.get(
      `${CONFIG.API_BASE_URL}/dashboard/manage/list/available-dashboards?${newQueryString}`
    );
    setDashboardListData(response.data?.items);
  } catch (error) {
    console.log(error);
  }
};

export const storeDashboardAnalysis = payload => {
  // ApiService.post('/dashboard/dashboard-analysis', payload);
};

export const fetchWarehouseList = async () => {
  try {
    const response = await ApiService.get(`/system/master-warehouses/list`);
    return response;
  } catch (error) {
    console.error('Error Occured while Fetching Warehouse List: ', error);
    return [];
  }
};

export const fetchBinList = async warehouseId => {
  try {
    const response = await ApiService.get(
      `/system/inventory/${warehouseId}/bin-list`
    );
    return response;
  } catch (error) {
    console.error('Error Occured while Fetching Bin List:', error);
    return [];
  }
};

export const updateReceiveInventory = async (projectId, ProductId, payload) => {
  try {
    const response = await ApiService.put(
      `/projects/${projectId}/v1/project-item/${ProductId}/update`,
      payload
    );
    return response;
  } catch (error) {
    console.error(error);
    return {};
  }
};
