import React, { useState, useEffect, createContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { AddEditViewReceiving } from './components/AddEditViewReceiving';
import { Receiving } from './components/Receiving';
import { ReceiptTable } from './components/ReceiptTable';

export const ReceivingContext = createContext();

export const ReceivingContainer = () => {
  const toast = useRef(null);
  const { projectId } = useParams();
  const [receivingContextValue, setReceivingContextValue] = useState({
    show: false,
    hide: () => {
      setReceivingContextValue(preValue => ({
        ...preValue,
        show: !preValue?.show,
      }));
    },
    visibleMode: {
      visible: 'add',
      data: {},
      disabled: false,
    },
    updateContextValue: data => {
      setReceivingContextValue(preValue => ({
        ...preValue,
        ...data,
      }));
    },
    toast: toast,
  });

  useEffect(() => {
    projectId &&
      setReceivingContextValue(preValue => ({
        ...preValue,
        project_id: projectId,
      }));
  }, [projectId]);
  return (
    <>
      <ReceivingContext.Provider
        value={{ receivingContextValue, setReceivingContextValue }}
      >
        <Toast ref={toast} />
        <Receiving />
        <ReceiptTable />
        {receivingContextValue?.show ? <AddEditViewReceiving /> : null}
      </ReceivingContext.Provider>
    </>
  );
};
