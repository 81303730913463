const permissions = {
  home: {
    menu: 'HOME_M',
    attachDashboard: 'ATTACH_DASHBOARD',
    detachDashboard: 'DETACH_DASHBOARD',
    applyFilter: 'DASHBOARD_FILTER_MODIFY',
    clearFilter: 'DASHBOARD_FILTER_CLEAR',
  },
  project: {
    menu: 'PROJECT_M',
    viewProject: 'VIEW_PROJECT',
    editProject: 'EDIT_PROJECT',
    addProject: 'ADD_PROJECT',
    ctaViewProject: 'CTA_VIEW_PROJECT',
  },
  projectFields: {
    viewProjectFields: 'ADD_PROJECT_FIELDS_VIEW',
  },
  scheduling: {
    menu: 'SCHEDULING_M',
    reportOnHold: 'SCHEDULING_ON_HOLD',
    reportRTS: 'SCHEDULING_RTS',
    reportSchToday: 'SCHEDULING_SCH_TODAY',
    reportWFP: 'SCHEDULING_WFP',
    reportUTC: 'SCHEDULING_UTC',
    reportSchTomorrow: 'SCHEDULING_SCH_TOMORROW',
  },
  clients: {
    menu: 'CLIENTS_M',
    addClient: 'ADD_CLIENT',
    editClient: 'EDIT_CLIENT',
  },
  items: {
    menu: 'ITEMS_M',
    addLaborItem: 'ADD_LABOR_ITEM',
    editLaborItem: 'EDIT_LABOR_ITEM',
    addMerchandiseItem: 'ADD_MERCHANDISE_ITEM',
    editMerchandiseItem: 'EDIT_MERCHANDISE_ITEM',
    viewCostField: 'VIEW_COST_FIELD',
  },
  viewEditProject: {
    actionOnWay: 'PROJECT_ON_WAY',
    actionLeftMessage: 'PROJECT_LEFT_MESSAGE',
    actionOnHold: 'PROJECT_ON_HOLD',
    actionCompleted: 'PROJECT_COMPLETED',
    actionNeedReview: 'PROJECT_NEED_REVIEW',
    actionCancelled: 'PROJECT_CANCELLED',
    actionRequalify: 'PROJECT_REQUALIFY',
    actionScheduler: 'SCHEDULER_M',
    actionEditScheduler: 'EDIT_SCHEDULER_M',
    actionContractPending: 'PROJECT_CONTRACT_PENDING',
    actionContractReviewed: 'PROJECT_CONTRACT_REVIEW',
    actionReadyToSchedule: 'READY_TO_SCHEDULE_VIEW',
    actionButtonScheduler: 'SCHEDULER_VIEW',
    actionBustedJob: 'BUSTED_JOB_VIEW',
    actionDeclineJob: 'DECLINE_JOB',
    actionAcceptJob: 'ACCEPT_JOB',
    actionRepairOrder: 'REPAIR_ORDER',
    actionSendToFloorsoft: 'SEND_TO_FLOORSOFT_VIEW',
    actionPullLineItems: 'PULL_LINE_ITEM',
    actionCopyProject: 'COPY_PROJECT',

    actionCarpetStagedView: 'CARPET_STAGED_VIEW',
    actionPadStagedView: 'PAD_STAGED_VIEW',
    actionFollowUpAmCallView: 'FOLLOW_UP_AM_CALL_VIEW',
    actionFollowUpPmCallView: 'FOLLOW_UP_PM_CALL_VIEW',
    actionWelcomeLeftMessageView: 'WELCOME_LEFT_MESSAGE_VIEW',
    actionWelocomeCallLCompleteView: 'WELCOME_CALL_COMPLETE_VIEW',
    actionDispatchedView: 'DISPATCHED_VIEW',
    actionEtaConfirmedView: 'ETA_CONFIRMED_VIEW',
    actionsInProgressView: 'IN_PROGRESS_VIEW',
    actionsConfirmCompleteView: 'CONFIRM_COMPLETE_VIEW',
    actionQaCompleteView: 'QA_COMPLETE_VIEW',
    actionReundReviewedView: 'REFUND_REVIEWED_VIEW',
    actionSurveyResolvedView: 'SURVEY_RESOLVED_VIEW',
    tabProcessTabView: 'PROCESS_TAB_VIEW',
    actionProjectColorCodeAdd: 'PROJECT_COLOR_CODE_ADD',
    actionAcknowledgeChangeView: 'PROJECT_ACKNOWLEDGE_CHANGE_VIEW',
    actionAcknowledgeCancelView: 'PROJECT_ACKNOWLEDGE_CANCEL_VIEW',
    actionSyncStatus: 'SYNC_STATUS',
    tabScheduleInfoView: 'SCHEDULE_INFO_VIEW',
    addInstaller: 'ADD_INSTALLER',
    viewInstaller: 'VIEW_INSTALLER',
    editInstaller: 'MODIFY_INSTALLER',
    deleteInstaller: 'DELETE_INSTALLER',
    tabSiteInspectionView: 'PROJECT_SITE_INSPECTION',
    tabSiteInspectionAdd: 'ADD_SITE_INSPECTION',
    tabSiteInspectionEdit: 'EDIT_SITE_INSPECTION',
    tabSiteInspectionDelete: 'DELETE_SITE_INSPECTION',
    tabHomeDepotPoView: 'HOME_DEPOT_PO_VIEW',
    tabHomeDepotLeadView: 'HOME_DEPOT_LEAD_VIEW',

    tabExtraColumnView: 'PROJECT_EXTRA_COLUMN',

    viewAdditionalFeeTab: 'PR_INFO_VIEW',

    tabAdditionalFeeView: 'PROJECT_ADDITIONAL_FEE',
    tabAdditionalFeeAdd: 'ADD_ADDITIONAL_FEE',
    tabAdditionalFeeEdit: 'EDIT_ADDITIONAL_FEE',
    tabAdditionalFeeDelete: 'DELETE_ADDITIONAL_FEE',

    tabAdditionalFeeExpensesView: 'VIEW_EXPENSES',
    tabAdditionalFeeExpensesAdd: 'ADD_EXPENSES',
    tabAdditionalFeeExpensesEdit: 'MODIFY_EXPENSES',
    tabAdditionalFeeExpensesDelete: 'DELETE_EXPENSES',

    paymentRequestMenu: 'PAYMENT_REQUEST',
    tabProjectItems: 'PROJECT_ITEMS',
    viewLaborCostFields: 'LABOR_COST_FIELDS',
    viewAllCostFields: 'ALL_COST_FIELDS',
    tabProjectItemsLaborView: 'VIEW_PROJECT_LABOR',
    tabProjectItemsLaborAdd: 'ADD_PROJECT_LABOR',
    tabProjectItemsLaborModify: 'EDIT_PROJECT_LABOR',
    tabProjectItemsLaborDelete: 'DELETE_PROJECT_LABOR',
    tabProjectItemsMerchandiseView: 'VIEW_PROJECT_MERCHANDISE',
    tabProjectItemsMerchandiseAdd: 'ADD_PROJECT_MERCHANDISE',
    tabProjectItemsMerchandiseModify: 'EDIT_PROJECT_MERCHANDISE',
    tabProjectItemsMerchandiseDelete: 'DELETE_PROJECT_MERCHANDISE',

    tabNotes: 'PROJECT_NOTES',
    tabNotesAdd: 'ADD_NOTES',
    tabNotesView: 'VIEW_NOTES',
    deleteRetailerNotes: 'DELETE_RETAILER_NOTES',
    deleteInstallerNotes: 'DELETE_INSTALLER_NOTES',
    deleteInternalNotes: 'DELETE_INTERNAL_NOTES',
    deleteScheduledNotes: 'DELETE_SCHEDULED_NOTES',

    tabRelatedPOs: 'PROJECT_RELATED_PO',
    tabDocsView: 'PROJECT_DOCS',
    tabDocs: 'VIEW_DOCS',
    tabDocsAddProjectDocs: 'ADD_PROJECT_DOCS',
    tabDocsDownloadProjectDocs: 'DOWNLOAD_PROJECT_DOCS',
    tabDocsViewRelated: 'VIEW_RELATED_PROJ_DOCS',
    tabDocsDownloadRelated: 'DOWNLOAD_RELATED_PROJ_DOCS',
    tabPhotosView: 'PROJECT_PHOTOS',
    tabPhotosAdd: 'ADD_PROJECT_PHOTOS',
    tabPhotosDownload: 'DOWNLOAD_PHOTOS',
    relatedMeasurementPhotos: 'RELATED_MEASUREMENT_PHOTOS',
    tabEPALead: 'PROJECT_EPA_LEAD',

    tabPermitting: 'PROJECT_PERMITTING',
    tabPermittingAdd: 'ADD_PERMIT',
    tabPermittingDelete: 'DELETE_PERMIT',
    tabPermittingModify: 'EDIT_PERMITTING',

    tabBilling: 'PROJECT_BILLING',
    tabBillingAdd: 'ADD_PAYMENT',
    tabBillingDelete: 'PROJECT_BILLING_DELETE',
    tabBillingModify: 'PROJECT_BILLING_EDIT',

    tabTimeManagement: 'PROJECT_TIME_MGMT',
    tabActivities: 'PROJECT_ACTIVITIES',
    tabSMS: 'PROJECT_SMS',
    tabProjectSupportView: 'PROJECT_SUPPORT',
    tabProjectSupportAdd: 'ADD_SUPPORT',
    tabProjectSupportEdit: 'EDIT_SUPPORT',
    tabProjectSupportDelete: 'DELETE_SUPPORT',
    tabIssuesView: 'PROJECT_ISSUES',
    tabIssuesAdd: 'ADD_ISSUE',
    tabIssuesEdit: 'EDIT_ISSUE',
    tabIssuesDelete: 'DELETE_ISSUE',
    tabAudit: 'PROJECT_AUDIT',
    tabEmail: 'VIEW_EMAIL',
    tabEmailAdd: 'ADD_EMAIL',
  },
  projectItems: {
    laborCostFields: 'LABOR_COST_FIELDS',
    allCostFields: 'ALL_COST_FIELDS',
    addAllCostFields: 'ADD_ALL_COST_FIELDS',
    editAllCostFields: 'EDIT_ALL_COST_FIELDS',
    viewAllMerchandiseCost: 'VIEW_ALL_MERCHANDISE_COST',
    addAllMerchandiseCost: 'ADD_ALL_MERCHANDISE_COST',
    editAllMerchandiseCost: 'EDIT_ALL_MERCHANDISE_COST',
    viewProjectLevelCostField: 'VIEW_PROJECT_LEVEL_COST_FIELDS',
    editLaborProfileItems: 'EDIT_LABOR_PROFILE_ITEMS',
    technicianVisibility: 'TECHNICIAN_VISIBILITY',
    financeApproval: 'LABOR_ITEM_FINANCE_APPROVAL',
    paidApproval: 'LABOR_ITEM_PAID_APPROVAL',
    technicianFinanceApproval: 'LABOR_ITEM_TECHNICIAN_FINANCE_APPROVAL',
    technicianPaidApproval: 'LABOR_ITEM_TECHNICIAN_PAID_APPROVAL',
    viewProductItemStatus: 'VIEW_PRODUCT_ITEM_STATUS',
    addProductItemStatus: 'ADD_PRODUCT_ITEM_STATUS',
    editProductItemStatus: 'EDIT_PRODUCT_ITEM_STATUS',
    userAssignedLaborItems: 'USER_ASSIGNED_LABOR_ITEMS',
  },
  admin: {
    menu: 'ADMIN_M',
  },
  laborProfiles: {
    menu: 'LABOR_PROFILES_M',
    laborProfileAdd: 'ADD_LABOR_PROFILE',
    laborProfileEdit: 'EDIT_LABOR_PROFILE',
    laborProfileDelete: 'DELETE_LABOR_PROFILE',
    laborProfileCopy: 'COPY_LABOR_PROFILE',
  },
  userManagement: {
    menu: 'USER_MGMT_M',
    userManagementAdd: 'ADD_USER',
    userManagementEdit: 'EDIT_USER',
    userManagementManageRolesView: 'MANAGE_ROLE',
    userManagementManageRolesAdd: 'ADD_ROLE',
    userManagementManageRolesEdit: 'EDIT_ROLE',
    userManagementManageRolesCopy: 'COPY_ROLE',
    userManagementManageRolesDelete: 'DELETE_ROLE',
    userManagementViewInstaller: 'VIEW_GLOBAL_INSTALLER',
    userManagementModifyInstaller: 'MODIFY_GLOBAL_INSTALLER',
  },
  stores: {
    menu: 'STORE_M',
    storesAdd: 'ADD_STORE',
    storesEdit: 'EDIT_STORE',
  },
  dashboardManagement: {
    menu: 'DASHBOARD_MGMT_M',
    createDashboard: 'CREATE_DASHBOARD',
    editDashboard: 'EDIT_DASHBOARD',
    deleteDashboard: 'DELETE_DASHBOARD',
    copyDashboard: 'COPY_DASHBOARD',
    applyFilter: 'DASHBOARD_FILTER_MODIFY',
    clearFilter: 'DASHBOARD_FILTER_CLEAR',
  },
  widgetManagement: {
    menu: 'DASHBOARD_WIDGET_TEMPLATE',
    createWidget: 'DASHBOARD_WIDGET_TEMPLATE_ADD',
    editWidget: 'DASHBOARD_WIDGET_TEMPLATE_EDIT',
    deleteWidget: 'DASHBOARD_WIDGET_TEMPLATE_DELETE',
  },
  documentCenter: {
    menu: 'DOC_CENTER_M',
    showToCustomer: 'VIEW_DOC_SHOW_CUSTOMER',
    addDocTemplate: 'ADD_TEMPLATE',
    editDocTemplate: 'EDIT_TEMPLATE',
    copyDocTemplate: 'COPY_TEMPLATE',
    requireSignature: 'VIEW_REQUIRE_SIGNATURE_ON_CX_PORTAL',
    autoCreateDoc: 'VIEW_AUTO_CREATE_PROJECT_DOCUMENT',
    reviewDoc: 'NEEDS_REVIEW_DOCS',
    sendToSourceDoc: 'SEND_TO_SOURCE_DOCS',
    completeDoc: 'COMPLETE_DOCS',
    reviewPhoto: 'NEEDS_REVIEW_PHOTOS',
    sendToSourcePhoto: 'SEND_TO_SOURCE_PHOTOS',
    completePhoto: 'COMPLETE_PHOTOS',
    deleteDoc: 'DELETE_PROJECT_DOCS',
    deletePhoto: 'DELETE_PROJECT_PHOTOS',
    viewDocCategory: 'VIEW_DOCUMENT_CATEGORY',
    addDocCategory: 'ADD_DOCUMENT_CATEGORY',
    editDocCategory: 'EDIT_DOCUMENT_CATEGORY',
    viewPhotoCategory: 'VIEW_PHOTO_CATEGORY',
    addPhotoCategory: 'ADD_PHOTOS_CATEGORY',
    editPhotoCategory: 'EDIT_PHOTOS_CATEGORY',
    viewDocumentReview: 'VIEW_DOCUMENT_REVIEW',
    viewPhotoReview: 'VIEW_PHOTO_REVIEW',
  },
  ruleEngineLog: {
    menu: 'RULE_ENGINE_LOGS_VIEW',
  },
  personnel: {
    menu: 'PERSONNEL_M',
  },
  availabilityUpdates: {
    menu: 'AVAILABILITY_UPDATE_M',
    addAvailabilityUpdates: 'ADD_AVAILABILITY',
    editAvailabilityUpdates: 'EDIT_AVAILABILITY',
    availabilityUpdatesApproval: 'AVAILABILITY_UPDATE_APPROVAL',
    viewAvailabilityUpdateCancel: 'VIEW_AVAILABILITY_UPDATE_CANCEL',
    viewAvailabilityUpdateWithdraw: 'VIEW_AVAILABILITY_UPDATE_WITHDRAW',
  },
  globalSearch: {
    menu: 'GLOBAL_SEARCH',
    redirectHandler: 'GLOBAL_SEARCH_REDIRECT_NEW_TAB',
  },
  pullDownMenu: {
    view: 'VIEW_PULL_DOWN_MENU',
  },
  sms: {
    viewSMS: 'VIEW_SMS',
  },
  mobilePermissions: {
    retailerNoteVisibility: 'SEND_RETAILER_NOTE_TO_SOURCE',
  },
  payroll: {
    menu: 'VIEW_PAYROLL',
    review: 'VIEW_REVIEW',
    approve: 'VIEW_APPROVE',
    exclude: 'VIEW_EXCLUDE',
  },
  questionnaire: {
    view: 'QUESTIONNAIRE_VIEW',
    edit: 'QUESTIONNAIRE_EDIT',
    projectViewAnswer: 'PROJECT_QUESTIONNAIRE_ANSWER_VIEW',
    projectDeleteAnswer: 'PROJECT_QUESTIONNAIRE_ANSWER_DELETE',
  },
  addGlobalInstaller: 'ADD_GLOBAL_INSTALLER',
  autoScheduling: {
    hideBanner: 'HIDE_ALERT_BANNER',
  },
  chargeback: {
    add: 'ADD_APPROVE_CHARGEBACK',
    edit: 'EDIT_APPROVE_CHARGEBACK',
  },
};

export default permissions;
