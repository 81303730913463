import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';
import {
  getAllInstallersList,
  getProjectStatusList,
  updateInstallerSchedule,
} from '../services/calendar';

const Loader = () => (
  <div className="w-full h-full flex flex-column justify-content-center align-items-center">
    <Skeleton height="3rem" className="mb-2 w-6"></Skeleton>
    <Skeleton height="3rem" className="mb-2 w-6"></Skeleton>
    <Skeleton height="3rem" className="mb-2 w-6"></Skeleton>
    <Skeleton height="3rem" className="mb-6 w-6"></Skeleton>
  </div>
);

const ScheduleDropDialog = ({ calenderRefreshHandler }) => {
  const dispatch = useDispatch();
  const { isDropDialogOpen, dropInfo, dropDialogData } = useSelector(
    state => state.newScheduler
  );
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [projectStatusList, setProjectStatusList] = useState([]);
  const [projectInstallerList, setProjectInstallerList] = useState([]);

  const handleFormValidate = values => {
    let error = {};
    if (values?.start && values?.end && values?.end < values?.start) {
      error.end = 'End Time must be greater than Start Time.';
    }
    return error;
  };

  const scheduleDropValidationSchema = Yup.object().shape({
    installer: Yup.object()
      .shape({
        installerUserId: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        id: Yup.number().required('Required'),
      })
      .required('Select a technician'),
    start: Yup.string().required('Required'),
    end: Yup.string().required('Required'),
    status: Yup.object()
      .shape({
        status_id: Yup.number().required('Required'),
        status: Yup.string().required('Required'),
      })
      .required('Status is required'),
  });

  const formik = useFormik({
    initialValues: {
      installer: null,
      start: null,
      end: null,
      status: null,
    },
    validationSchema: scheduleDropValidationSchema,
    validate: handleFormValidate,
    onSubmit: async values => {
      try {
        setIsLoading(true);
        let updateObj = {
          installerId: values?.installer?.id,
          projectId: dropDialogData?.project_id,
          projectStartDateTime: values?.start?.toISOString(),
          projectEndDateTime: values?.end?.toISOString(),
          old_installerId: null,
          old_startDateTime: null,
          old_endDateTime: null,
          status_id: values?.status?.status_id,
        };
        const response = await updateInstallerSchedule(updateObj);
        if (response?.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Confirmed',
            detail: 'Schedule successful',
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        dispatch(
          setNewSchedulerComponent({
            isDropDialogOpen: false,
          })
        );
        calenderRefreshHandler();
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isDropDialogOpen) {
      setIsLoading(true);
      Promise.all([getAllInstallersList(), getProjectStatusList()])
        .then(([installerList, statusList]) => {
          if (Array.isArray(installerList)) {
            setProjectInstallerList(
              installerList.map(item => {
                return {
                  name: `${item?.first_name} ${item?.last_name}`,
                  id: item?.installer_id,
                  installerUserId: item?.user_id,
                };
              })
            );
          }
          if (Array.isArray(statusList)) {
            setProjectStatusList(statusList);
            formik.setValues({
              start: new Date(dropInfo?.start),
              end: new Date(dropInfo?.end),
              status: statusList?.find(
                status => status?.status === 'Scheduled'
              ),
            });
          }
        })
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }
  }, [dropInfo]);

  const handleDialogOnHide = () => {
    dispatch(setNewSchedulerComponent({ isDropDialogOpen: false }));
  };

  const viewScheduleReport = () => {
    window.open(`/project/view/${dropDialogData?.project_id}`, '_blank');
  };

  return (
    <>
      <Dialog
        header="Schedule Job"
        visible={isDropDialogOpen}
        className="w-11  xl:w-7"
        onHide={handleDialogOnHide}
        draggable={false}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-column gap-2">
            <div className="flex flex-row">
              <label htmlFor="installer" className="w-3 align-self-center">
                Select Technician
              </label>
              <Dropdown
                id="installer"
                value={formik.values.installer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={projectInstallerList}
                optionLabel="name"
                className="w-6"
              />
            </div>
            <div className="flex flex-row">
              <label htmlFor="start" className="w-3 align-self-center">
                Start
              </label>
              <Calendar
                id="start"
                className="w-6"
                value={formik.values.start}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                showTime
                hourFormat="12"
              />
            </div>

            <div className="flex flex-column">
              <div className="flex flex-row">
                <label htmlFor="end" className="w-3 align-self-center">
                  End
                </label>
                <Calendar
                  id="end"
                  className="w-6"
                  value={formik.values.end}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  showTime
                  hourFormat="12"
                />
              </div>
              {formik.errors?.end && (
                <div className="mt-1 text-red-600">{formik.errors?.end}</div>
              )}
            </div>
            <div className="flex flex-row">
              <label htmlFor="status" className="w-3 align-self-center">
                Status
              </label>
              <Dropdown
                id="status"
                value={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={projectStatusList}
                optionLabel="status"
                className="w-6"
              />
            </div>
            <div className="flex flex-row">
              <span className="w-3">Store #</span>
              <span className="w-6">{dropDialogData?.store_number}</span>
            </div>
            <div className="flex flex-row">
              <span className="w-3">Client Name</span>
              <span className="w-6">{dropDialogData?.client_name}</span>
            </div>
            <div className="flex flex-row">
              <span className="w-3">Category</span>
              <span className="w-6">{dropDialogData?.category}</span>
            </div>
            <div className="flex flex-row">
              <span className="w-3">Address</span>
              <span className="w-6">
                {dropDialogData?.installation_address}
              </span>
            </div>
            <div className="flex flex-row">
              <span className="w-3">Type</span>
              <span className="w-6">{dropDialogData?.project_type}</span>
            </div>
            <div className="flex flex-row">
              <span className="w-3">Source Status</span>
              <span className="w-6">{dropDialogData?.IMS_status}</span>
            </div>
            <div className="flex flex-row">
              <span className="w-3">Project No</span>
              <span className="w-6">{dropDialogData?.project_number}</span>
            </div>
            <div className="flex flex-row">
              <span className="w-3">Mobile Number</span>
              <span className="w-6">{dropDialogData?.home_phone}</span>
            </div>
            <div className="flex flex-row">
              <span className="w-3">Alternate Number</span>
              <span className="w-6">{dropDialogData?.mobile_phone}</span>
            </div>
            <div className="flex flex-row mt-6 gap-3 justify-content-end">
              <Button
                severity="primary"
                size="small"
                label="GoTo Job"
                onClick={viewScheduleReport}
              />
              <Button
                label="Save"
                onClick={formik.handleSubmit}
                disabled={!formik.dirty || !formik.isValid}
                size="small"
                severity="primary"
              />
              <Button
                size="small"
                severity="primary"
                label="Cancel"
                onClick={handleDialogOnHide}
              />
            </div>
          </div>
        )}
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default ScheduleDropDialog;
