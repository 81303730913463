import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ApiService from '../../../services/api.service';
const initialState = {
  userTypes: [],
  status: false,
  error: null,
};
export const fetchUserTypes = createAsyncThunk(
  'master/fetchUserTypes',
  async (_, { getState, rejectWithValue }) => {
    const { userTypes } = getState().userTypes;
    if (userTypes && userTypes.length > 0) {
      return rejectWithValue('User types already fetched');
    } else {
      const response = await ApiService.get('/system/user-types');
      return response;
    }
  }
);
const userTypesSlice = createSlice({
  name: 'userTypes',
  initialState,
  reducers: {
    clearUserTypes: state => {
      state.userTypes = [];
      state.status = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUserTypes.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchUserTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userTypes = action?.payload || [];
      })
      .addCase(fetchUserTypes.rejected, (state, action) => {
        if (action.payload === 'User types already fetched') {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.error = action.error.message;
        }
      });
  },
});

export const { clearUserTypes } = userTypesSlice.actions;

export const userTypesReducer = userTypesSlice.reducer;
