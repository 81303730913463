import React from 'react';
import { Message } from 'primereact/message';

import { PROJECT_REPORT } from '../../../constants';
import { MESSAGES } from '../helpers/helpers';

const getMessageText = (view, isScheduler) => {
  if (view === PROJECT_REPORT.MAP || view === PROJECT_REPORT.SPLIT) {
    return MESSAGES.SELECT_MAP_FILTER;
  }

  if (view === PROJECT_REPORT.FULL_CALENDAR) {
    return MESSAGES.SELECT_CALENDAR_FILTER;
  }

  if (isScheduler) {
    return MESSAGES.SELECT_RTS_ROW;
  }

  return MESSAGES.SELECT_TECHNICIAN_FILTER;
};

const AlertInformation = ({ view, isScheduler }) => {
  return (
    <Message
      severity="info"
      text={getMessageText(view, isScheduler)}
      className="border-none"
      pt={{
        root: {
          className: 'w-full flex justify-content-start',
        },
        text: {
          className: 'text-sm',
        },
      }}
    />
  );
};

export default AlertInformation;
