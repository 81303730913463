import React, { useEffect, useContext } from 'react';
import moment from 'moment';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

import usePFDataTable from '../../../shared/PFPrime/PFDatatable.jsx';
import {
  deleteShipmentService,
  getAllShipmentService,
} from '../services/shipping.service.js';
import PFButton from '../../../shared/PFPrime/PFButton.js';
import { ShippingContext } from '../ShippingContainer.jsx';

export const ShippingTable = () => {
  const { shippingContextValue, setShippingContextValue } =
    useContext(ShippingContext);

  const {
    layout: TableLayout,
    columns,
    totalRecords,
    data: tableData,
    rows,
    page,
    sortBy,
    orderBy,
  } = usePFDataTable();

  const getAllShipment = async () => {
    const res = await getAllShipmentService(
      shippingContextValue?.project_id,
      shippingContextValue?.project_shipping_id,
      rows,
      page,
      sortBy,
      orderBy
    );
    if (res?.status) {
      tableData(res?.data?.rows || []);
      totalRecords(res?.data?.count || 0);
      setShippingContextValue(preValue => ({
        ...preValue,
        received_package: res?.data?.received_package,
        expected_package: res?.data?.expected_package,
      }));
    }
  };

  const deleteShipment = async (
    project_id,
    project_shipping_id,
    project_shipment_id
  ) => {
    const res = await deleteShipmentService(
      project_id,
      project_shipping_id,
      project_shipment_id
    );
    if (res?.status) {
      setShippingContextValue(preValue => ({
        ...preValue,
        listReload: true,
      }));
      shippingContextValue?.toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Shipment deleted successfully',
      });
    }
  };

  const deleteConfirmation = (
    event,
    project_id,
    project_shipping_id,
    project_shipment_id
  ) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this?',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => {
        deleteShipment(project_id, project_shipping_id, project_shipment_id);
      },
      reject: () => false,
    });
  };

  useEffect(() => {
    columns([
      {
        header: '',
        body: data => {
          const {
            project_shipment_id,
            project_shipping_id,
            client_id,
            project_id,
            created_by,
            modified_by,
            created_at,
            modified_at,
            shippingCompany,
            receiptLocation,
            shippingTechnician,
            ...rest
          } = data;
          return (
            <div className="flex">
              <PFButton
                icon="pi pi-eye"
                outlined
                text
                className="text-white border-none"
                severity="primary"
                aria-label="View"
                onClick={() => {
                  shippingContextValue?.setVisibleMode(
                    {
                      visible: 'view',
                      data: rest,
                    },
                    {
                      project_shipment_id: project_shipment_id,
                      shippingCompany: shippingCompany,
                    }
                  );
                  shippingContextValue?.hide();
                }}
              />
              <PFButton
                icon="pi pi-pencil"
                outlined
                text
                className="text-white border-none"
                severity="primary"
                aria-label="Edit"
                onClick={() => {
                  shippingContextValue?.setVisibleMode(
                    {
                      visible: 'edit',
                      data: rest,
                    },
                    {
                      project_shipment_id: project_shipment_id,
                      shippingCompany: shippingCompany,
                    }
                  );
                  shippingContextValue?.hide();
                }}
              />
              <PFButton
                icon="pi pi-trash"
                outlined
                text
                className="text-white border-none"
                severity="primary"
                aria-label="Edit"
                onClick={e =>
                  deleteConfirmation(
                    e,
                    data?.project_id,
                    data?.project_shipping_id,
                    data?.project_shipment_id
                  )
                }
              />
            </div>
          );
        },
      },
      {
        field: 'shippingCompany.company_name',
        header: 'Shipping Company',
      },
      {
        field: 'tracking',
        header: 'Tracking',
      },
      {
        field: 'eta',
        header: 'ETA Date',
        body: data => (
          <div className="w-10rem">
            {data?.eta && moment(data?.eta, 'YYYY-MM-DD', true).isValid()
              ? moment(data?.eta).format('MM-DD-YYYY')
              : null}
          </div>
        ),
      },
      {
        field: 'follow_up_date',
        header: 'Follow Up Date',
        body: data => (
          <div className="w-10rem">
            {data?.follow_up_date &&
            moment(data?.follow_up_date, 'YYYY-MM-DD', true).isValid()
              ? moment(data?.follow_up_date).format('MM-DD-YYYY')
              : null}
          </div>
        ),
      },
      {
        field: 'received_date',
        header: 'Date Received',
        body: data => (
          <div className="w-10rem">
            {data?.received_date &&
            moment(data?.received_date, 'YYYY-MM-DD', true).isValid()
              ? moment(data?.received_date).format('MM-DD-YYYY')
              : null}
          </div>
        ),
      },
      {
        field: 'received_package',
        header: 'No. Of Packages Received',
      },
      {
        field: 'expected_package',
        header: 'No. Of Packages Expected',
      },
      {
        field: 'receiptLocation.name',
        header: 'Receipt Location',
      },
      {
        field: 'shippingTechnician.first_name',
        header: 'Shipping Technician',
        body: data =>
          `${data?.shippingTechnician?.first_name || ''} ${data?.shippingTechnician?.last_name || ''}`,
      },
      {
        field: 'received_by',
        header: 'Received By',
      },
      {
        field: 'shipment_note',
        header: 'Note',
      },
    ]);
  }, []);

  useEffect(() => {
    shippingContextValue?.project_id &&
      shippingContextValue?.project_shipping_id &&
      getAllShipment();
  }, [
    shippingContextValue?.project_id,
    shippingContextValue?.project_shipping_id,
    rows,
    page,
    sortBy,
    orderBy,
  ]);

  useEffect(() => {
    if (shippingContextValue?.listReload) {
      getAllShipment().then(() => {
        setShippingContextValue(preValue => ({
          ...preValue,
          deleteFlag: true,
          listReload: false,
        }));
      });
    }
  }, [shippingContextValue?.listReload]);

  return (
    <div className="grid">
      <div className="col-12">
        <ConfirmPopup />
        <TableLayout />
      </div>
    </div>
  );
};
