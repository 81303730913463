import { ITEM_TYPES } from '../../../../constants';
import ApiService from '../../../../services/api.service';

export const getLaborItemsList = async (searchQuery, searchText = '') => {
  try {
    const params = { ...searchQuery };
    if (searchText) {
      params.query = searchText;
    }
    const response = await ApiService.getWithDiffBaseUrl(
      `/system/item-types/${ITEM_TYPES.labor}/items`,
      params
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const getMerchandiseItemsList = async (
  merchandiseSearchQuery,
  searchText = ''
) => {
  try {
    const params = { ...merchandiseSearchQuery };
    if (searchText) {
      params.query = searchText;
    }
    const response = await ApiService.getWithDiffBaseUrl(
      `/system/item-types/${ITEM_TYPES.product}/items`,
      params
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const groupItems = async (parentId, payload) => {
  try {
    const response = await ApiService.patch(
      `/system/item/${parentId}/link`,
      payload
    );
    return response;
  } catch (error) {
    return null;
  }
};

export const unlinkGroupItems = async parentId => {
  try {
    const response = await ApiService.delete(`/system/item/${parentId}/unlink`);
    return response;
  } catch (error) {
    return null;
  }
};

export const getTargetList = async parentItemId => {
  try {
    const response = await ApiService.get(`/system/items/${parentItemId}`);
    return response;
  } catch (error) {
    return null;
  }
};
