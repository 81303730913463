import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';

import PFTableLoader from '../../../shared/Loader/PFTableLoader';
import PFDialog from '../../../shared/PFPrime/PFDialog';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import {
  createAdjustment,
  updateAdjustment,
  getInventoryById,
} from '../../../ProjectManagement/Inventory/services/adjustment.services';
import {
  fetchBinList,
  fetchWarehouseList,
  updateReceiveInventory,
} from '../../Widget/service/widget.service';

const ViewEditReceiveInventory = ({
  setVisible,
  visible,
  selectedAdjustmentId,
  setInventoryLoading,
  toast,
  refreshDashboardList,
  inventoryLoading,
}) => {
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [binOptions, setBinOptions] = useState([]);
  const [rowData, setRowData] = useState([]);

  const formik = useFormik({
    initialValues: {
      project_number: '',
      quantity_to_adjust: '',
      warehouse: '',
      bin: '',
      adjustment_note: '',
    },
    onSubmit: async values => {
      const createPayload = {
        productId: rowData.productId,
        date: new Date(rowData.date),
        adjustmentType: 'Add',
        warehouseId: values?.warehouse?.warehouse_object_id,
        quantityToAdjust: rowData.quantityToAdjust,
        unit: rowData.unit,
        noteText: values?.adjustment_note,
        binId: values?.bin?.bin_object_id,
      };
      const updatePayload = {
        _id: rowData._id,
        productId: rowData.productId,
        date: new Date(rowData.date),
        adjustmentType: 'Allocated',
        warehouseId: values?.warehouse?.warehouse_object_id,
        quantityToAdjust: rowData.quantityToAdjust,
        unit: rowData.unit,
        noteText: values?.adjustment_note,
        convertedQuantityToAdjust: rowData.convertedQuantityToAdjust,
        purchasePricePerUnit: rowData.purchasePricePerUnit,
        salesPricePerUnit: rowData.salesPricePerUnit,
        projectId: rowData.projectId,
        projectNumber: values.projectNumber,
        binId: values?.bin?.bin_object_id,
        binsCollection: rowData?.binsCollection,
      };
      const inventoryPayload = {
        master_warehouse_id: values?.warehouse?.master_warehouse_id,
        inventory_bin_id: values?.bin?.inventory_bin_id,
      };
      try {
        await createAdjustment(createPayload);
        const dispatchResponse = await updateAdjustment(updatePayload);
        const updateInventory = await updateReceiveInventory(
          rowData?.projectId,
          rowData?.product?.productId,
          inventoryPayload
        );
        if (dispatchResponse && updateInventory) {
          toast.current.show({
            severity: 'success',
            summary: 'Success!',
            detail: 'Adjustment Updated successfully',
            life: 3000,
          });
          setTimeout(refreshDashboardList, 2000);
          setVisible(false);
        }
      } catch (error) {
        toast.current.show({
          severity: 'danger',
          summary: 'Error!',
          detail: 'Something went wrong',
          life: 3000,
        });
      }
    },
  });

  const wareHouseList = async () => {
    try {
      const response = await fetchWarehouseList();
      setWarehouseOptions(response);
    } catch (error) {
      console.error(error);
    }
  };
  const binListing = async id => {
    try {
      const binResponse = await fetchBinList(id);
      setBinOptions(binResponse);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    wareHouseList();
  }, []);

  useEffect(() => {
    if (
      formik?.values?.warehouse &&
      formik?.values?.warehouse?.master_warehouse_id
    ) {
      binListing(formik?.values?.warehouse?.master_warehouse_id);
    }
  }, [formik?.values?.warehouse]);

  const handleInventoryOnOrder = async id => {
    try {
      const response = await getInventoryById(id);
      setRowData(response);
      formik?.setFieldValue('project_number', response?.projectNumber);
      formik?.setFieldValue('quantity_to_adjust', response?.quantityToAdjust);
      if (response?.warehouseId) {
        const selectedWarehouse = warehouseOptions?.find(
          item => item?.warehouse_object_id === response?.warehouseId
        );
        formik?.setFieldValue('warehouse', selectedWarehouse);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setInventoryLoading(false);
    }
  };

  useEffect(() => {
    if (formik?.values?.warehouse) {
      const selectedBin = binOptions?.find(
        item => item?.bin_object_id === rowData?.binId
      );
      formik?.setFieldValue('bin', selectedBin);
    }
  }, [formik?.values?.warehouse, binOptions]);

  useEffect(() => {
    if (warehouseOptions?.length > 0 && selectedAdjustmentId) {
      handleInventoryOnOrder(selectedAdjustmentId);
    }
  }, [selectedAdjustmentId, warehouseOptions?.length > 0]);

  return (
    <div>
      <PFDialog
        show={visible}
        hide={() => setVisible(false)}
        header="Receive Inventory"
        className="w-7"
        footer={
          <div>
            <PFButton
              label="Cancel"
              text
              outlined
              onClick={() => setVisible(false)}
              disabled={formik.isSubmitting}
            />
            <PFButton
              label="Save"
              onClick={formik.handleSubmit}
              disabled={
                formik.isSubmitting ||
                !formik?.values?.warehouse ||
                !formik?.values?.bin
              }
            />
          </div>
        }
        BodyComponent={
          inventoryLoading ? (
            <>
              <PFTableLoader />
            </>
          ) : (
            <div className="w-full">
              <div className="grid my-2">
                <div className="col-12 md:col-6 lg:col-6 ">
                  <span className="p-float-label w-full">
                    <PFInputText
                      value={formik?.values?.project_number || ''}
                      disabled
                      className="w-full"
                    />
                    <label className="text-xs">Project No.</label>
                  </span>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                  <span className="p-float-label w-full">
                    <PFInputText
                      value={formik?.values?.quantity_to_adjust || ''}
                      disabled
                      className="w-full"
                    />
                    <label className="text-xs">Quantity</label>
                  </span>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                  <span className="p-float-label w-full">
                    <PFDropdown
                      optionLabel="name"
                      placeholder="Select Warehouse"
                      options={warehouseOptions}
                      name="warehouse"
                      value={formik.values.warehouse}
                      onChange={(name, value) => {
                        formik.setFieldValue('warehouse', value);
                      }}
                    />
                    <label className="text-xs">
                      Warehouse <span className="text-red-500">*</span>
                    </label>
                  </span>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                  <span className="p-float-label w-full">
                    <PFDropdown
                      optionLabel="name"
                      placeholder="Select Bin"
                      options={binOptions}
                      name="bin"
                      value={formik.values?.bin}
                      onChange={(name, value) => {
                        formik.setFieldValue('bin', value);
                      }}
                      disabled={!formik?.values?.warehouse}
                    />
                    <label className="text-xs">
                      Bin<span className="text-red-500">*</span>
                    </label>
                  </span>
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                  <span className="p-float-label w-full">
                    <PFInputText
                      value={formik?.values?.adjustment_note || ''}
                      onChange={event => {
                        formik.setFieldValue(
                          'adjustment_note',
                          event?.target?.value
                        );
                      }}
                      className="w-full"
                    />
                    <label className="text-xs">Note</label>
                  </span>
                </div>
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

export default ViewEditReceiveInventory;
