import CryptoJS from 'crypto-js';

import { REACT_APP_CRYPTO_SECRET_KEY } from '../constants/envConstants';
const secretKey = REACT_APP_CRYPTO_SECRET_KEY;
export const encryptPassword = password => {
  return CryptoJS.AES.encrypt(password, secretKey).toString();
};

export const encryptEmail = emailContent => {
  return CryptoJS.AES.encrypt(emailContent, secretKey).toString();
};

export const decryptEmail = encryptedContent => {
  const bytes = CryptoJS.AES.decrypt(encryptedContent, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const encryptOtp = otp => {
  const secretKey = Config.bcrypt.cryptoKey;
  const encryptedOtp = CryptoJS.AES.encrypt(otp, secretKey).toString();
  return encryptedOtp;
};
