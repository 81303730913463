import {
  DEFAULT_COLORS_BY_PROJECT_TYPE,
  TEXT_COLOR_BLACK,
} from '../../../constants';
import { EVENT_COLORS } from '../constants/constants';

export const processFilters = value => {
  let selectedFilterObj = {};
  value?.filters?.forEach(item => {
    const filterValueArray = item?.filter_value
      ?.split(',')
      ?.map(value => parseInt(value));
    switch (item?.filterType?.type_name) {
      case 'store':
        selectedFilterObj.storeNumber = filterValueArray;
        break;
      case 'projectCategory':
        selectedFilterObj.projectCategoryId = filterValueArray;
        break;
      case 'projectType':
        selectedFilterObj.projectTypeId = filterValueArray;
        break;
      case 'status':
        selectedFilterObj.statusId = filterValueArray;
        break;
      case 'sourceStatus':
        selectedFilterObj.imsStatus = filterValueArray;
        break;
      case 'district':
        selectedFilterObj.districtId = [...item?.filter_value?.split(',')];
        break;
      case 'workroom':
        selectedFilterObj.workroomId = filterValueArray;
        break;
      case 'customer':
        selectedFilterObj.customerId = filterValueArray;
        break;
      default:
        break;
    }
  });

  return selectedFilterObj;
};
export const checkOtherFieldsHaveValues = (values, fields) => {
  return fields?.some(field => (values[field]?.length ?? 0) > 0);
};

export const checkScheduledDaysLength = eventInfo => {
  const scheduleDate = new Date(eventInfo?.schedule_date);
  const scheduleEndDate = new Date(eventInfo?.schedule_end_date);

  if (isNaN(scheduleDate) || isNaN(scheduleEndDate)) {
    return 2;
  } else {
    const timeDifference = scheduleEndDate - scheduleDate;
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
    return dayDifference;
  }
};

export const checkScheduledHoursLength = eventInfo => {
  const scheduleDate = new Date(eventInfo?.schedule_date);
  const scheduleEndDate = new Date(eventInfo?.schedule_end_date);

  if (isNaN(scheduleDate) || isNaN(scheduleEndDate)) {
    return 2;
  } else {
    const timeDifference = scheduleEndDate - scheduleDate;

    const millisecondsInOneDay = 1000 * 60 * 60 * 24;
    const millisecondsInOneHour = 1000 * 60 * 60;

    const hours = Math.floor(
      (timeDifference % millisecondsInOneDay) / millisecondsInOneHour
    );
    return hours;
  }
};
export const MESSAGES = {
  NOT_AVAILABLE: 'Not available',
  SELECT_RTS_ROW:
    'Please double click on required job to display filtered eligible technicians.',
  SELECT_TECHNICIAN_FILTER:
    'Please select the required filters to display the technicians and their assigned jobs.',
  SELECT_MAP_FILTER:
    'Please select the required filters to display the pins for Assigned, Unassigned over the map and for seeing the routes technician filter is mandatory (at max 10).',
  SELECT_CALENDAR_FILTER:
    'Please select the required filters to display the technicians.',
};

export let eventColors = [
  { bgColor: '#FFECEA', textColor: '#FF7F00' },
  { bgColor: '#DCFFDC', textColor: '#009500' },
  { bgColor: '#F3EDFF', textColor: '#4D00FF' },
  { bgColor: '#FFFAE5', textColor: '#FF2700' },
  { bgColor: '#ADD8E6', textColor: '#333333' },
];

export const EVENT_TYPES = {
  BLOCK: 'blockedTime',
  TIME_OFF: 'timeoff',
};

export const getShorthandPeriod = period => {
  switch (period) {
    case 'day':
      return 'D';
    case 'week':
      return 'W';
    case 'month':
      return 'M';
    default:
      return 'D';
  }
};

export const mapProjectTypeToColor = (
  colorsByProjectType,
  projectType,
  projectBusted,
  color_code = ''
) => {
  if (projectBusted === 'Busted') {
    return {
      bgColor: DEFAULT_COLORS_BY_PROJECT_TYPE?.Busted,
      textColor: TEXT_COLOR_BLACK,
    };
  } else {
    if (color_code) {
      return {
        bgColor: color_code,

        textColor: TEXT_COLOR_BLACK,
      };
    }
    if (colorsByProjectType && projectType) {
      return {
        bgColor: colorsByProjectType,
        textColor: TEXT_COLOR_BLACK,
      };
    }

    return EVENT_COLORS[1]; // default
  }
};
