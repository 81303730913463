import React, { useState, useEffect } from 'react';
import {
  Grid,
  CircularProgress,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Paper,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@material-ui/icons';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { parseISO } from 'date-fns';

import {
  FiltersOperators,
  FiltersOperatorsForDate,
  NumberFiltersOperators,
  timeFilterOptions,
} from '../../../../constants';
import { getDataByCube } from '../service/widget.service';

import { useStyles } from './FilterSection.styles';

const CONDITIONS = [
  { key: 'and', value: 'All' },
  { key: 'or', value: 'Any' },
];

const GetConditionRow = ({
  dimensionList,
  action,
  innerRecord,
  widgetFormik,
  filterConditions,
  index,
  record,
  filterFor,
  isCopyUse,
}) => {
  const [selectedColumnType, setSelectedColumnType] = useState('string');
  const [dataList, setDataList] = useState([]);
  const [valueType, setValueType] = useState('string');

  const getData = async params => {
    const response = await getDataByCube(params?.[0], params?.[1]);
    setDataList(response.map(item => ({ key: item.value, value: item.label })));
  };

  const initiateData = async () => {
    const selectedDimension = dimensionList.find(
      item => item.name == innerRecord.tableField
    );

    setValueType(selectedDimension?.type);
    await getData(selectedDimension?.name?.split('.'));
    setSelectedColumnType(selectedDimension?.type);
  };

  useEffect(() => {
    if (action !== 'create') {
      initiateData();
    }
  }, []);

  useEffect(() => {
    initiateData();
  }, [isCopyUse]);

  useEffect(() => {
    if (selectedColumnType === 'time' && !innerRecord.dateFilterOperatorType) {
      innerRecord.dateFilterOperatorType = 'equals';
    }
  }, [selectedColumnType]);

  return (
    <Grid
      container
      item
      style={{ gap: 15 }}
      direction="row"
      alignItems="center"
    >
      <Grid item xs={12} md={5} lg={3}>
        <Autocomplete
          id="chartType"
          key={innerRecord.tableField}
          fullWidth
          groupBy={option => option.firstLetter}
          options={dimensionList}
          getOptionLabel={option => option && option.value}
          renderInput={params => (
            <TextField {...params} label="Field" required />
          )}
          disabled={action === 'view' ? true : false}
          value={dimensionList.find(
            item => item.name == innerRecord.tableField
          )}
          onChange={(event, value) => {
            setValueType(value?.type || '');
            if (value?.name) {
              getData(value.name.split('.'));
            }
            setSelectedColumnType(value?.type || '');
            innerRecord.tableField = value?.name || '';
            delete innerRecord.operatorType;
            delete innerRecord.isTimeFilter;
            innerRecord.operator = '';
            innerRecord.value = '';
            widgetFormik.setFieldValue(filterFor, filterConditions);
          }}
        />
      </Grid>
      {selectedColumnType == 'time' ? (
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={FiltersOperatorsForDate}
            getOptionLabel={option => option?.value}
            disabled={action === 'view'}
            value={
              FiltersOperatorsForDate?.find(
                a => a.key == innerRecord.dateFilterOperatorType
              ) || {}
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Select Operator"
                required
              />
            )}
            onChange={(event, value) => {
              innerRecord.dateFilterOperatorType = value?.key || '';
              widgetFormik.setFieldValue(filterFor, filterConditions);
            }}
          />
        </Grid>
      ) : null}
      <Grid item xs={12} md={5} lg={3}>
        <Autocomplete
          key={innerRecord.operator}
          options={(() => {
            if (selectedColumnType == 'boolean') return [FiltersOperators[0]];
            else if (selectedColumnType == 'string') {
              return FiltersOperators.filter(
                operator => operator.type != 'time'
              );
            } else if (selectedColumnType == 'number') {
              return NumberFiltersOperators;
            } else if (selectedColumnType == 'time') {
              return timeFilterOptions;
            }
            return FiltersOperators;
          })()}
          getOptionLabel={option =>
            option?.value === 'null' ? 'Empty' : option?.value
          }
          disabled={action === 'view'}
          value={
            FiltersOperators?.find(a => a.key == innerRecord.operator) ||
            NumberFiltersOperators?.find(a => a.key == innerRecord.operator) ||
            timeFilterOptions?.find(a => a.key == innerRecord.operator)
          }
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label="Select Operators"
              required
            />
          )}
          onChange={(event, value) => {
            innerRecord.operator = value?.key || '';
            if (value?.custom) {
              innerRecord.operatorType = value.custom;
            } else {
              innerRecord.operatorType = '';
            }
            if (selectedColumnType == 'time') {
              innerRecord.isTimeFilter = true;
            } else {
              innerRecord.isTimeFilter = false;
            }
            widgetFormik.setFieldValue(filterFor, filterConditions);
          }}
        />
      </Grid>

      {dataList.length ? (
        <Grid item xs={12} md={3}>
          <Autocomplete
            key={innerRecord.value}
            options={dataList}
            getOptionLabel={option => option?.value}
            disabled={action === 'view'}
            value={dataList?.find(a => a.key == innerRecord.value)}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Value"
                required
              />
            )}
            onChange={(event, value) => {
              innerRecord.value = value?.key || '';
              widgetFormik.setFieldValue(filterFor, filterConditions);
            }}
          />
        </Grid>
      ) : selectedColumnType == 'time' ? (
        innerRecord.operatorType == 'daysField' ? (
          <Grid item xs={12} md={3}>
            <TextField
              type="number"
              label={'Days'}
              disabled={
                !innerRecord.operatorType ||
                !innerRecord.operatorType == 'daysField'
              }
              onChange={event => {
                innerRecord.value = event.target.value;
                widgetFormik.setFieldValue(filterFor, filterConditions);
              }}
              value={innerRecord.value}
              InputLabelProps={{ shrink: true }}
              required={true}
            />
          </Grid>
        ) : innerRecord.operatorType == 'datePicker' ? (
          <>
            <Grid item xs={12} md={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk
                  // id="date"
                  // name="date"
                  variant="inline"
                  label="Choose Date"
                  format="yyyy-MM-dd"
                  inputProps={{ autoComplete: 'off' }}
                  invalidDateMessage="Invalid Date"
                  value={
                    innerRecord.value &&
                    moment(innerRecord.value, 'YYYY-MM-DD', true)?.isValid()
                      ? moment(innerRecord.value)
                      : null
                  }
                  onChange={(date, value) => {
                    innerRecord.value = moment(date).format('YYYY-MM-DD');
                    widgetFormik.setFieldValue(filterFor, filterConditions);
                  }}
                  disabled={action === 'view'}
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </>
        ) : null
      ) : (
        !['set', 'notSet'].includes(innerRecord.operator) && (
          <Grid item xs={10} md={3}>
            <TextField
              label="Value"
              disabled={action === 'view'}
              onChange={event => {
                innerRecord.value = event.target.value;
                widgetFormik.setFieldValue(filterFor, filterConditions);
              }}
              value={innerRecord.value}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
        )
      )}

      {action !== 'view' && (
        <Grid item xs={1}>
          {index === 0 ? (
            <AddCircleOutlineIcon
              color="primary"
              fontSize="large"
              onClick={() => {
                record.fields.push({ tableField: '', operator: '', value: '' });
                widgetFormik.setFieldValue(filterFor, filterConditions);
              }}
            />
          ) : (
            index === record?.fields?.length - 1 && (
              <RemoveCircleOutlineIcon
                color="secondary"
                fontSize="large"
                onClick={() => {
                  record.fields.splice(index, 1);
                  widgetFormik.setFieldValue(filterFor, filterConditions);
                }}
              />
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};

const FilterSection = ({
  widgetFormik,
  filterConditions,
  action,
  dimensionList,
  filterFor,
  isCopyUse,
}) => {
  const classes = useStyles();
  if (!filterConditions) return null;
  return (
    <>
      <Grid container item direction="row" spacing={1} alignItems="center">
        <Grid item xs={3} lg={1}>
          <FormControl variant="outlined">
            <Select
              value={filterConditions.condition}
              onChange={event => {
                filterConditions.condition = event.target.value;
                widgetFormik.setFieldValue(filterFor, filterConditions);
              }}
              disabled={action === 'view'}
            >
              {CONDITIONS.map(record => (
                <MenuItem value={record.key}>{record.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>of these conditions are true</Grid>
        {filterConditions.filters.map((record, conditionIndex) => {
          return (
            <Grid container item direction="row" alignItems="center">
              <Grid item xs={11}>
                <Paper elevation={0} className={classes.paper}>
                  <Grid
                    container
                    item
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    {record && record?.fields?.length > 1 ? (
                      <>
                        <Grid item xs={3} lg={1}>
                          <FormControl variant="outlined">
                            <Select
                              value={record.condition}
                              onChange={event => {
                                record.condition = event.target.value;
                                widgetFormik.setFieldValue(
                                  filterFor,
                                  filterConditions
                                );
                              }}
                              disabled={action === 'view'}
                              required={true}
                            >
                              {CONDITIONS.map(record => (
                                <MenuItem value={record.key}>
                                  {record.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item>of these conditions are true</Grid>
                      </>
                    ) : null}
                    {record.fields.map((innerRecord, index) => {
                      return (
                        <GetConditionRow
                          dimensionList={dimensionList}
                          action={action}
                          innerRecord={innerRecord}
                          widgetFormik={widgetFormik}
                          filterConditions={filterConditions}
                          index={index}
                          record={record}
                          filterFor={filterFor}
                          isCopyUse={isCopyUse}
                        />
                      );
                    })}
                  </Grid>
                </Paper>
              </Grid>
              {action !== 'view' && (
                <Grid item xs={1}>
                  <Grid container item xs={1} alignItems="center">
                    {conditionIndex === 0 ? (
                      <AddCircleOutlineIcon
                        color="primary"
                        fontSize="large"
                        className="ml-2"
                        onClick={() => {
                          filterConditions.filters.push({
                            condition: 'and',
                            fields: [
                              { tableField: '', operator: '', value: '' },
                            ],
                          });
                          widgetFormik.setFieldValue(
                            filterFor,
                            filterConditions
                          );
                        }}
                      />
                    ) : (
                      conditionIndex ===
                        filterConditions.filters.length - 1 && (
                        <RemoveCircleOutlineIcon
                          color="secondary"
                          fontSize="large"
                          onClick={() => {
                            filterConditions.filters.splice(conditionIndex, 1);
                            widgetFormik.setFieldValue(
                              filterFor,
                              filterConditions
                            );
                          }}
                        />
                      )
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default FilterSection;
