import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import React, { useEffect, useRef, useState } from 'react';

import PFColorPicker from '../../../shared/PFPrime/PFColorPicker';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import apiService from '../../../../services/api.service';
import { formatDateTime } from '../../../../utils/Helpers';
import PFTableLoader from '../../../shared/Loader/PFTableLoader';
import PFButton from '../../../shared/PFPrime/PFButton';

import LinkedColorTypes from './LinkedColorTypes';
import AddColorDialog from './AddProjectColorCode';
import EditColorDialog from './EditProjectColorCode';

const ProjectColorCode = () => {
  const [visible, setVisible] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [deleteColor, setDeleteColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editColor, setEditColor] = useState('');
  const [colorList, setColorList] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  const [count, setCount] = React.useState();
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 5,
    page: 1,
  });

  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });
  const [globalSearch, setGlobalSearch] = useState('');

  const dt = useRef(null);
  const toast = useRef(null);
  const [projectType, setProjectType] = useState([]);

  const colorCol = [
    {
      field: '',
      header: 'Actions',
      body: (rowData, option) => (
        <div className="flex w-full">
          <PFButton
            icon="pi pi-pencil"
            outlined
            text
            className="text-white border-none px-0"
            severity="primary"
            aria-label="Edit"
            onClick={e => {
              setVisible(true);
              setEditColor(rowData);
            }}
          />
          <PFButton
            icon="pi pi-trash"
            outlined
            text
            className="text-white border-none px-0"
            severity="primary"
            aria-label="Edit"
            onClick={e => {
              setSelectedColor(rowData?.color_config_id);
              setDeleteColor(true);
            }}
          />
        </div>
      ),
      style: { minWidth: '60px', maxWidth: '60px' },
    },
    {
      field: 'color_code',
      header: 'Color',
      body: (rowData, option) => (
        <div className="flex w-4rem">
          <PFColorPicker disabled={true} value={rowData?.color_code} />
        </div>
      ),
      style: { minWidth: '60px', maxWidth: '60px' },
    },
    {
      field: 'color_code',
      header: 'Color Code',
      body: rowData => (
        <div className="flex w-4rem">
          {rowData?.color_code ? rowData?.color_code : ''}
        </div>
      ),
      style: { minWidth: '80px', maxWidth: '80px' },
    },

    {
      field: 'color_name',
      header: 'Color Name',
      body: data => (data?.color_name ? data?.color_name : ''),
      style: { minWidth: '80px' },
    },
    {
      field: 'created_at',
      header: 'Created At',
      body: data => (data?.created_at ? formatDateTime(data?.created_at) : ''),
      style: { minWidth: '60px', maxWidth: '100px' },
    },
    {
      field: 'created_by',
      header: 'Created By',
      body: data =>
        data?.create_user && data?.create_user?.first_name
          ? `${data?.create_user?.first_name}  ${data?.create_user?.last_name}`
          : '',
      style: { minWidth: '60px' },
    },
    {
      field: 'modified_at',
      header: 'Modified At',
      body: data =>
        data?.modified_at ? formatDateTime(data?.modified_at) : '',
      style: { minWidth: '60px', maxWidth: '100px' },
    },
    {
      field: 'modified_by',
      header: 'Modified By',
      body: data =>
        data?.modify_user && data?.modify_user?.first_name
          ? `${data?.modify_user?.first_name} ${data?.modify_user?.last_name}`
          : '',
      style: { minWidth: '60px' },
    },
  ];
  const getColorList = async searchQuery => {
    try {
      setLoading(true);
      let newQueryString;
      newQueryString = Object.entries(searchQuery)
        .filter(([prop, value]) => Boolean(value) || value === 0)
        .map(([key, value], index) => `${key}=${value}`)
        .join('&');
      const response = await apiService.get(
        `/admin-config/color-config/getAll?${newQueryString}`
      );
      if (response && response?.data) {
        setColorList(response?.data?.rows);
        setCount(response?.data?.count);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const deleteColorCode = async value => {
    try {
      const response = await apiService.delete(
        `/admin-config/color-config/${value}`
      );

      if (response && response?.status) {
        if (response?.status === 409) {
          toast.current?.show({
            severity: 'error',
            summary: `${response?.message}`,
          });
        } else {
          setReloadList(true);

          toast.current?.show({
            severity: 'success',
            summary: `${response?.message}`,
          });
        }
      }
      setSelectedColor(null);
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: `${error?.response?.data?.message || 'Something went wrong'}`,
      });
    } finally {
      setReloadList(false);
      setSelectedColor(null);
    }
  };
  const getProjectType = async () => {
    try {
      const typeRequest = await apiService.get(
        `/admin-config/project-type-colors`
      );
      setProjectType(typeRequest);
    } catch (error) {
      console.error('getProjectType', error);
    } finally {
      setReloadList(false);
      setSelectedColor(null);
    }
  };

  useEffect(() => {
    getColorList();
    getProjectType();
  }, [reloadList]);

  useEffect(() => {
    getColorList(searchQuery);
  }, [searchQuery, reloadList]);

  const handleDelete = () => {
    if (selectedColor) {
      deleteColorCode(selectedColor);
    }
  };
  const handleOnPage = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      limit: event.rows,
      offset: event.first,
    }));
    setLazyState(event);
  };

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.error('handleSearch', err);
    }
    intervalVal = setTimeout(() => {
      setSearchQuery({ limit: 10, offset: 0, search: searchString });
      setLazyState({
        first: 0,
        rows: 10,
        page: 1,
      });
    }, 400);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <PFInputText
            id="search"
            value={globalSearch}
            placeholder="Keyword Search"
            className="p-inputtext-sm w-12"
            onChange={e => {
              setGlobalSearch(e?.target?.value);
              handleSearch(e?.target?.value);
            }}
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();
  let dataTableParams = {
    ref: dt,
    searchQuery: searchQuery,
    header: header,
  };

  return (
    <>
      <div className="w-full">
        <h2>Color Code</h2>
        <Card>
          <div className="grid">
            <Toast ref={toast} />
            <div className="grid col-12">
              <div className="col-2 col-offset-10">
                <span className="p-float-label">
                  <PFButton
                    id="shade"
                    className="w-12"
                    label="Add Color Code"
                    onClick={() => {
                      setVisibleAdd(true);
                    }}
                  />
                </span>
              </div>
            </div>

            <div className="w-full mt-3">
              {loading ? (
                <PFTableLoader />
              ) : (
                <PFDataTable
                  header={header}
                  dataTableParams={dataTableParams}
                  columns={colorCol}
                  data={colorList}
                  lazy
                  paginator
                  page={lazyState.page}
                  first={lazyState.first}
                  rows={lazyState.rows}
                  onPage={handleOnPage}
                  totalRecords={count}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  scrollable
                  scrollHeight="400px"
                />
              )}
            </div>
          </div>
          {visible && (
            <EditColorDialog
              visible={visible}
              setVisible={setVisible}
              editColor={editColor}
              setLoading={setLoading}
              setReloadList={setReloadList}
              toast={toast}
            />
          )}
          {visibleAdd && (
            <AddColorDialog
              visible={visibleAdd}
              setVisible={setVisibleAdd}
              setLoading={setLoading}
              setReloadList={setReloadList}
              toast={toast}
            />
          )}
          {deleteColor && selectedColor && (
            <ConfirmDialog
              group="declarative"
              visible={deleteColor}
              closable={false}
              draggable={false}
              message="Do you want to delete this color ?"
              header="Confirmation"
              icon="pi pi-exclamation-triangle"
              accept={() => {
                handleDelete();
              }}
              reject={() => {
                setDeleteColor(false);
                setSelectedColor(null);
              }}
            />
          )}
        </Card>

        <div className="w-full mt-3">
          <LinkedColorTypes toast={toast} colorCodeTableColorList={colorList} />
        </div>
      </div>
    </>
  );
};
export default ProjectColorCode;
