import React, { useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

export const TruncatedText = ({ text, maxLength = 20 }) => {
  const op = useRef(null);

  return text.length > maxLength ? (
    <>
      <span
        className="truncated-text cursor-pointer hover:text-blue-500"
        onClick={e => op.current.toggle(e)}
      >
        {text.substring(0, maxLength) + '...'}
      </span>

      <OverlayPanel className="surface-50" ref={op} showCloseIcon dismissable>
        <div
          style={{
            textAlign: 'left',
            overflow: 'auto',
            maxHeight: '15rem',
            maxWidth: '20rem',
          }}
        >
          <p>{text}</p>
        </div>
      </OverlayPanel>
    </>
  ) : (
    text
  );
};
