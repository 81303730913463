import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ApiService from '../../../services/api.service';
const initialState = {
  projectStores: [],
  status: false,
  error: null,
};
export const fetchProjectStores = createAsyncThunk(
  'master/fetchProjectStores',
  async (_, { getState, rejectWithValue }) => {
    const { projectStores } = getState().projectStores;
    if (projectStores && projectStores.length > 0) {
      return rejectWithValue('Stores already fetched');
    } else {
      const response = await ApiService.get(
        '/stores?searchString=&offset=0&limit=10'
      );
      return response;
    }
  }
);
const projectStoresSlice = createSlice({
  name: 'projectStores',
  initialState,
  reducers: {
    clearStore: state => {
      state.projectStores = [];
      state.status = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProjectStores.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchProjectStores.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.projectStores = action?.payload || [];
      })
      .addCase(fetchProjectStores.rejected, (state, action) => {
        if (action.payload === 'Stores already fetched') {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.error = action.error.message;
        }
      });
  },
});

export const { clearStore } = projectStoresSlice.actions;

export const projectStoresReducer = projectStoresSlice.reducer;
