// **** React Imports ****
import React, { useEffect } from 'react';

// **** Utilities ****
import {
  Grid,
  TextField,
  Box,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Tooltip,
  Select,
  MenuItem,
} from '@material-ui/core';
import { CheckSharp as CheckIcon } from '@material-ui/icons/';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete } from '@material-ui/lab';
import { Dialog } from 'primereact';
import { parseISO, subDays } from 'date-fns';
import { debounce } from 'lodash';
import isBefore from 'date-fns/isBefore';

// **** Services *****
import moment from 'moment';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useSelector } from 'react-redux';

import { getStatusOptions } from '../ProjectInfo/ProjectDetails.service';
// **** Styles *****
import MapContainer from '../../shared/MapContainer';
import { formatDate, momentTz } from '../../../utils/Helpers';
import {
  CONFIG,
  MAPLINK_BASE,
  PROJECT_TIER_DROPDOWN,
  SOURCE_SYSTEMS,
  libraries,
} from '../../../constants';
import { getQueryResult } from '../../Admin/Widget/service/widget.service';
import { useAlerts } from '../../shared/Alerts/alertsService.js';
import PFCheckbox from '../../../components/shared/PFPrime/PFCheckbox';

import AddCustomAddress from './AddCustomAddress';
import Installer from './Installer';
import { useStyles } from './ScheduleInfo.styles.js';
import {
  getRescheduleReasonOptions,
  getPickUpLocation,
  getStateOptions,
  confirmAppointment,
  getScheduleReasonOptions,
  addScheduleReasonOptions,
  getSchedulePendingReasonOptions,
  addSchedulePendingReasonOptions,
  getVendorList,
} from './ScheduleInfo.service';

const PICKUP_REQUIRED_OPTIONS = {
  Yes: 1,
  No: 0,
};
const ScheduleInfo = ({
  historyProp,
  setReloadFormProp,
  setLoadingProp,
  setAlertProp,
  formik,
  action,
  installerList,
  setBackdropLoading,
  projectId,
  projectStatus,
  setReloadLaborItems,
  isSalesCommissionOpted,
  commissionedTechncianLineItem,
  ddConfirm,
  setMsProjectInstaller,
  distance,
  projectInstallationAddress,
  loadingProp,
  ...rest
}) => {
  const classes = useStyles();
  const { setAlert } = useAlerts();
  const [rescheduleReasonOptions, setRescheduleReasonOptions] = React.useState(
    []
  );

  const [confirmationStatusOptions, setConfirmationStatusOptions] =
    React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [reloadList, setReloadList] = React.useState(false);

  const [pickUpLocation, setPickUpLocation] = React.useState([]);
  const [stateOptions, setStateOptions] = React.useState([]);
  const [pickupLocationAddressList, setPickupLocationAddressList] =
    React.useState([]);
  const [pickupLocationWarehouseList, setPickupLocationWarehouseList] =
    React.useState([]);
  const [refreshAddressDetails, setRefreshAddressDetails] =
    React.useState(false);
  const [pageLoad, setPageLoad] = React.useState(false);
  const [showAddAddressPopUp, setShowAddAddressPopUp] = React.useState(false);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: CONFIG.GOOGLE_MAP_KEY,
    libraries,
  });
  const [scheduleReasonOptions, setScheduleReasonOptions] = React.useState([]);
  const [newscheduleReasonOptions, setNewScheduleReasonOptions] =
    React.useState([]);

  const [schedulePendingReasonOptions, setSchedulePendingReasonOptions] =
    React.useState([]);
  const [newSchedulePendingReason, setNewSchedulePendingReason] =
    React.useState([]);
  const [vendorList, setVendorList] = React.useState([]);
  const [technicianList, setTechnicianList] = React.useState([]);

  const { technicians } = useSelector(state => state.technicians);

  useEffect(() => {
    if (Array.isArray(technicians) && technicians?.length)
      setTechnicianList(technicians);
  }, [technicians]);

  const getWareHouseDetailsById = async wareHouseId => {
    const query = {
      limit: 10,
      offset: 0,
      dimensions: ['Warehouse.master_warehouse_id', 'Warehouse.name'],
      filters: [
        {
          member: 'Warehouse.master_warehouse_id',
          values: [wareHouseId.toString()],
          operator: 'equals',
        },
        {
          member: 'Warehouse.client_id',
          values: [localStorage.getItem('client_id')],
          operator: 'equals',
        },
      ],
    };
    const wareHouseResponse = await getQueryResult(query);

    setPickupLocationWarehouseList(
      wareHouseResponse?.data?.map(item => {
        return {
          pickupLocationId: item['Warehouse.master_warehouse_id'],
          pickupLocationTitle: `${item['Warehouse.name'] || ''}`,
        };
      })
    );

    setRefreshAddressDetails(!refreshAddressDetails);
  };
  const getWareHouseDetails = async (searchString = '') => {
    const query = {
      limit: 10,
      offset: 0,
      dimensions: ['Warehouse.master_warehouse_id', 'Warehouse.name'],
      filters: [
        {
          member: 'Warehouse.name',
          values: [searchString],
          operator: 'startsWith',
        },
        {
          member: 'Warehouse.client_id',
          values: [localStorage.getItem('client_id')],
          operator: 'equals',
        },
      ],
    };
    const wareHouseResponse = await getQueryResult(query);

    setPickupLocationWarehouseList(
      wareHouseResponse.data.map(item => {
        return {
          pickupLocationId: item['Warehouse.master_warehouse_id'],
          pickupLocationTitle: `${item['Warehouse.name'] || ''}`,
        };
      })
    );
  };
  const getAddressDetailsById = async (addressId, setValue = false) => {
    if (setValue) {
      formik?.setFieldValue('pick_up_location_address_id', addressId || null);
    }
    const query = {
      limit: 10,
      offset: 0,
      dimensions: [
        'Address.address_id',
        'Address.name',
        'Address.address1',
        'Address.address2',
        'Address.city',
        'Address.state',
        'Address.zipcode',
      ],
      filters: [
        {
          member: 'Address.address_id',
          values: [addressId.toString()],
          operator: 'equals',
        },
        {
          member: 'Address.client_id',
          values: [localStorage.getItem('client_id')],
          operator: 'equals',
        },
      ],
    };
    const addressResponse = await getQueryResult(query);
    setPickupLocationAddressList(
      addressResponse.data.map(item => {
        const title = item['Address.address1']
          ? item['Address.name']
          : `${item['Address.address1'] || ''} ${
              item['Address.address2'] || ''
            } ${item['Address.city'] || ''} ${item['Address.state'] || ''} ${
              item['Address.zipcode'] || ''
            }`;
        return {
          pickupLocationId: item['Address.address_id'],
          pickupLocationTitle: title,
        };
      })
    );
    setRefreshAddressDetails(!refreshAddressDetails);
  };
  const getAddressDetails = async (searchString = '') => {
    const query = {
      limit: 10,
      offset: 0,
      dimensions: [
        'Address.address_id',
        'Address.name',
        'Address.address1',
        'Address.address2',
        'Address.city',
        'Address.state',
        'Address.zipcode',
      ],
      filters: [
        {
          or: [
            {
              member: 'Address.name',
              values: [searchString],
              operator: 'startsWith',
            },
            {
              member: 'Address.address1',
              values: [searchString],
              operator: 'startsWith',
            },
            {
              member: 'Address.address2',
              values: [searchString],
              operator: 'startsWith',
            },
            {
              member: 'Address.city',
              values: [searchString],
              operator: 'startsWith',
            },
            {
              member: 'Address.zipcode',
              values: [searchString],
              operator: 'startsWith',
            },
          ],
        },
        {
          member: 'Address.occupant_type',
          values: ['Custom'],
          operator: 'equals',
        },
        {
          member: 'Address.client_id',
          values: [localStorage.getItem('client_id')],
          operator: 'equals',
        },
      ],
    };
    const addressResponse = await getQueryResult(query);
    setPickupLocationAddressList(
      addressResponse?.data?.map(item => {
        const title = item['Address.address1']
          ? item['Address.name']
          : `${item['Address.address1'] || ''} ${
              item['Address.address2'] || ''
            } ${item['Address.city'] || ''} ${item['Address.state'] || ''} ${
              item['Address.zipcode'] || ''
            }`;
        return {
          pickupLocationId: item['Address.address_id'],
          pickupLocationTitle: title,
        };
      })
    );
  };

  React.useEffect(() => {
    getStatusOptions('Confirmation', setLoading, setConfirmationStatusOptions);
    getPickUpLocation(setPickUpLocation);
    getStateOptions(setStateOptions);
    if (action == 'add') {
      getWareHouseDetails();
      getAddressDetails();
    }
  }, []);

  React.useEffect(() => {
    setLoading(loadingProp);
  }, [loadingProp]);

  React.useEffect(() => {
    if (!pageLoad) {
      if (action == 'edit' || action == 'view') {
        if (formik?.values?.pick_up_location_id == 4) {
          getAddressDetailsById(formik?.values?.pick_up_location_address_id);
          setPageLoad(true);
        } else if (formik?.values?.pick_up_location_id == 1) {
          getWareHouseDetailsById(formik?.values?.pick_up_location_address_id);
          setPageLoad(true);
        }
      }
    }
  }, [formik?.values?.pick_up_location_id]);

  React.useEffect(() => {
    if (formik?.values?.source_system?.source_system_id === 2) {
      getRescheduleReasonOptions(
        'Reschedule%20Reason',
        2,
        setRescheduleReasonOptions,
        setBackdropLoading
      );
    } else {
      if (
        formik?.values?.project_type?.project_type &&
        formik?.values?.source_system?.source_system_id &&
        !rescheduleReasonOptions.length
      )
        getRescheduleReasonOptions(
          formik?.values?.project_type?.project_type,
          formik?.values?.source_system?.source_system_id,
          setRescheduleReasonOptions,
          setBackdropLoading
        );
    }

    setReloadList(false);
  }, [
    formik?.values?.project_id,
    formik?.values?.project_type?.project_type,
    formik?.values?.source_system?.source_system_id,
    reloadList,
  ]);

  React.useEffect(() => {
    if (formik?.values?.project_type?.project_type) {
      getScheduleReasonOptions(
        setScheduleReasonOptions,
        formik?.values?.project_type?.project_type_id,
        ''
      );
    }
  }, [formik?.values?.project_type?.project_type, reloadList]);
  const vendorData = async setVendorList => {
    try {
      const response = await getVendorList();
      if (response) {
        setVendorList(response);
      }
    } catch (error) {
      console.error(error);
    }
  };
  React.useEffect(() => {
    getSchedulePendingReasonOptions(setSchedulePendingReasonOptions, '');
    vendorData(setVendorList);
  }, []);
  const onDisabled = (searchResult, value, key) => {
    let res = true;
    if (searchResult?.length > 0 && value && typeof value === 'string') {
      if (value.trim() != '') {
        const length = searchResult?.filter(
          item => item[key]?.toLowerCase() === value?.toLowerCase()
        ).length;
        if (length === 0) {
          res = false;
        }
      }
    }
    if (searchResult?.length === 0) {
      res = false;
    }
    if (!value) {
      res = true;
    }
    return res;
  };

  const handleScheduleReasonChange = event => {
    setNewScheduleReasonOptions(event?.target?.value);
    if (event?.target?.value) {
      const debouncedGetScheduleReasonOptions = debounce(() => {
        getScheduleReasonOptions(
          setScheduleReasonOptions,
          formik?.values?.project_type?.project_type_id,
          event?.target?.value
        );
      }, 300);

      debouncedGetScheduleReasonOptions();
    }
  };
  const handleScheduleReason = async () => {
    const data = {
      scheduling_reason: newscheduleReasonOptions,
      project_type_id: formik?.values?.project_type?.project_type_id,
    };

    const response = await addScheduleReasonOptions(data);

    if (response?.data?.status) {
      setAlert('success', response?.data?.message);
      getScheduleReasonOptions(
        setScheduleReasonOptions,
        formik?.values?.project_type?.project_type_id,
        response?.data?.data?.label
      );
      formik.setFieldValue('scheduling_reason', response?.data?.data);
    }
  };
  const handleSchedulePendingReasonChange = event => {
    setNewSchedulePendingReason(event?.target?.value);
    if (event?.target?.value) {
      const debouncedGetSchedulePendingOptions = debounce(() => {
        getSchedulePendingReasonOptions(
          setSchedulePendingReasonOptions,
          event?.target?.value
        );
      }, 300);

      debouncedGetSchedulePendingOptions();
    }
  };
  const handleSchedulePendingReason = async () => {
    const data = {
      scheduling_reason: newSchedulePendingReason,
    };
    const response = await addSchedulePendingReasonOptions(data);
    if (response?.data?.status) {
      setAlert('success', response?.data?.message);
      getSchedulePendingReasonOptions(
        setSchedulePendingReasonOptions,
        response?.data?.data?.label
      );
      formik.setFieldValue('schedule_pending_reason', response?.data?.data);
    }
  };
  const { address1, city, zipcode, latitude, longitude } =
    formik?.values?.installation_address || {};
  const addressParts = [
    address1,
    city,
    projectInstallationAddress?.state,
    zipcode,
  ];
  const formattedAddress = addressParts?.filter(part => part).join(', ');
  let mapLink = MAPLINK_BASE;
  if (latitude && longitude) {
    mapLink += `?q=${latitude},${longitude}`;
  }

  const handleSpecialTool = event => {
    formik.setFieldValue('special_tool', event.target.checked);
  };

  return (
    <>
      <Grid
        container
        spacing={4}
        direction="column"
        className="mui-custom-form"
      >
        {action !== 'add' && !loading ? (
          <Grid item xs={12} lg={12} md={12} sm={12} className="min-h-367">
            <Installer
              historyProp={historyProp}
              setReloadFormProp={setReloadFormProp}
              setLoadingProp={setLoadingProp}
              setAlertProp={setAlertProp}
              formik={formik}
              action={action}
              projectId={projectId}
              projectStatus={projectStatus}
              setReloadLaborItems={setReloadLaborItems}
              isSalesCommissionOpted={isSalesCommissionOpted}
              commissionedTechncianLineItem={commissionedTechncianLineItem}
              setMsProjectInstaller={setMsProjectInstaller}
              installerOptions={technicianList}
              setProjectInstallers={rest?.setProjectInstallers || null}
              refereshInstallerList={rest?.refereshInstallerList}
              setRefereshInstallerList={rest?.setRefereshInstallerList}
            />
          </Grid>
        ) : (
          action !== 'add' &&
          loading && (
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className="min-h-367"
            >
              <CircularProgress />
            </Grid>
          )
        )}
        <Grid item>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <Box className={classes.boxColumn} m={1}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  id="date_scheduled_start"
                  name="date_scheduled_start"
                  label="Scheduled Date and Time"
                  format="MM-dd-yyyy hh:mm a"
                  value={
                    formik?.values?.date_scheduled_start
                      ? momentTz(formik?.values?.date_scheduled_start).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : null
                  }
                  onChange={(date, value) => {
                    formik.setFieldValue('date_scheduled_start', date);
                    formik.setFieldTouched('date_scheduled_start', true);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    style: { display: action === 'view' && 'none' },
                  }}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  onBlur={formik.handleBlur}
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box className={classes.boxColumn} m={1}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  id="date_scheduled_end"
                  label="Project End Date and Time"
                  format="MM-dd-yyyy hh:mm a"
                  onBlur={formik?.handleBlur}
                  value={
                    formik?.values?.date_scheduled_end
                      ? momentTz(formik?.values?.date_scheduled_end).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : null
                  }
                  onChange={(date, value) => {
                    formik.setFieldValue('date_scheduled_end', date);
                    formik.setFieldTouched('date_scheduled_end', true);
                  }}
                  InputLabelProps={{ shrink: true }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    style: { display: action === 'view' && 'none' },
                  }}
                  disabled
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box className={classes.boxColumn} m={1}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiKeyboardDatePicker
                  label="RTS Follow Up Date"
                  format="MM-dd-yyyy"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',

                    style: { display: action === 'view' && 'none' },
                  }}
                  onChange={(date, value) => {
                    const dateFormated = `${moment(date).format(
                      'YYYY-MM-DD'
                    )}T00:00:00.000Z`;
                    formik.setFieldValue('rts_follow_up_date', dateFormated);
                  }}
                  InputLabelProps={{ shrink: true }}
                  disabled={(action === 'view' && true) || false}
                  value={
                    formik?.values?.rts_follow_up_date &&
                    parseISO(formik?.values?.rts_follow_up_date?.slice(0, 10))
                  }
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box className={classes.boxColumn} m={1}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiKeyboardDatePicker
                  autoOk
                  id="completion_date"
                  label="Completed Date"
                  InputLabelProps={{ shrink: true }}
                  format="MM-dd-yyyy"
                  minDate={
                    formik?.values?.date_scheduled_start &&
                    momentTz(formik?.values?.date_scheduled_start).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    style: { display: action === 'view' && 'none' },
                  }}
                  onChange={(date, value) => {
                    const dateFormated = `${moment(date).format(
                      'YYYY-MM-DD'
                    )}T00:00:00.000Z`;
                    formik.setFieldValue('completion_date', dateFormated);
                  }}
                  disabled={(action === 'view' && true) || false}
                  value={
                    formik?.values?.completion_date &&
                    parseISO(formik?.values?.completion_date?.slice(0, 10))
                  }
                  fullWidth
                  error={
                    formik?.touched.completion_date &&
                    formik.errors.completion_date
                  }
                  helperText={
                    formik?.touched.completion_date &&
                    formik?.errors?.completion_date
                  }
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" mt={2}>
            <Box className={classes.boxColumn} m={1}>
              <Autocomplete
                id="confirmation_status"
                disableListWrap
                options={confirmationStatusOptions || []}
                getOptionLabel={option => option && option.status}
                onChange={(event, value) => {
                  if (value) {
                    formik.setFieldValue('confirmation_status', value);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Confirmation Status"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                classes={{ input: classes.statusInput }}
                disabled={action === 'view' ? true : false}
                value={
                  (action === 'add' &&
                    confirmationStatusOptions?.find(
                      status => status?.status === 'Not Confirmed'
                    )) ||
                  formik?.values?.confirmation_status ||
                  ''
                }
              />
            </Box>

            <Box
              display="flex"
              m={1}
              className={`${classes.boxColumn} mui-custom-autocomplete`}
            >
              <Box width="84%">
                <Autocomplete
                  options={scheduleReasonOptions || []}
                  getOptionLabel={option => option && option.label}
                  onChange={(event, value) => {
                    formik.setFieldValue('scheduling_reason', value);
                  }}
                  className={classes.noDropdownIcon}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Scheduling Reason"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onInputChange={handleScheduleReasonChange}
                  value={
                    (scheduleReasonOptions &&
                      formik.values &&
                      scheduleReasonOptions.find(
                        option =>
                          option.label === formik?.values?.scheduling_reason
                      )) ||
                    ''
                  }
                  disabled={
                    action === 'view' ||
                    (!formik?.values?.project_type?.project_type && true) ||
                    false
                  }
                  clearOnBlur={false}
                  closeIcon={false}
                  fullWidth={true}
                />
              </Box>
              <Box width="16%">
                <Tooltip title="Add Scheduling Reason" placement="top">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon fontSize="inherit" />}
                    className={`${classes.iconBtn} border-round-sm`}
                    style={{
                      padding: '0',
                      border: 'none',
                      maxHeight: '46px',
                      minHeight: '46px',
                      minWidth: '100%',
                      maxWidth: '100%',
                    }}
                    disabled={
                      onDisabled(
                        scheduleReasonOptions,
                        newscheduleReasonOptions,
                        'label'
                      ) ||
                      action === 'view' ||
                      !formik?.values?.project_type?.project_type_id
                    }
                    onClick={handleScheduleReason}
                  />
                </Tooltip>
              </Box>
            </Box>

            <Box className={classes.boxColumn} m={1}>
              <Autocomplete
                options={rescheduleReasonOptions || []}
                getOptionLabel={option => option && option.reason_desc}
                onChange={(event, value) =>
                  formik.setFieldValue('reschedule_reason', value)
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Reschedule Reason"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disabled={
                  action === 'view' ||
                  ((!formik?.values?.project_type?.project_type,
                  !formik?.values?.source_system?.source_system_id) &&
                    true) ||
                  false
                }
                value={formik?.values?.reschedule_reason || ''}
              />
            </Box>

            <Box
              display="flex"
              m={1}
              className={`${classes.boxColumn} mui-custom-autocomplete`}
            >
              <Box width="84%">
                <Autocomplete
                  options={schedulePendingReasonOptions || []}
                  getOptionLabel={option => option && option.label}
                  onChange={(event, value) => {
                    formik.setFieldValue('schedule_pending_reason', value);
                  }}
                  className={classes.noDropdownIcon}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Schedule Pending Reason"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onInputChange={handleSchedulePendingReasonChange}
                  value={
                    (schedulePendingReasonOptions &&
                      formik.values &&
                      schedulePendingReasonOptions.find(
                        option =>
                          option.label ===
                          formik.values?.schedule_pending_reason
                      )) ||
                    ''
                  }
                  disabled={action === 'view'}
                  clearOnBlur={false}
                  closeIcon={false}
                  fullWidth={true}
                />
              </Box>
              <Box width="16%">
                <Tooltip title=" Add Schedule Pending Reason" placement="top">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CheckIcon fontSize="inherit" />}
                    className={`${classes.iconBtn} border-round-sm`}
                    style={{
                      padding: '0',
                      border: 'none',
                      maxHeight: '46px',
                      minHeight: '46px',
                      minWidth: '100%',
                      maxWidth: '100%',
                    }}
                    disabled={
                      onDisabled(
                        schedulePendingReasonOptions,
                        newSchedulePendingReason,
                        'label'
                      ) || action === 'view'
                    }
                    onClick={handleSchedulePendingReason}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" mt={2}>
            <Box className={classes.boxColumn} m={1}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiKeyboardDatePicker
                  autoOk
                  id="pick_up_date"
                  name="pick_up_date"
                  label="Pick up Date"
                  InputLabelProps={{ shrink: true }}
                  format="MM-dd-yyyy"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    style: { display: action === 'view' && 'none' },
                  }}
                  onChange={(date, value) => {
                    const dateFormated = `${moment(date).format(
                      'YYYY-MM-DD'
                    )}T00:00:00.000Z`;
                    formik.setFieldValue('pick_up_date', dateFormated);
                  }}
                  disabled={(action === 'view' && true) || false}
                  value={
                    (formik?.values?.pick_up_date &&
                      formik?.values?.pick_up_date?.split('T')?.length &&
                      parseISO(formik?.values?.pick_up_date?.slice(0, 10))) ||
                    null
                  }
                  fullWidth
                  disablePast
                  error={
                    formik?.touched.pick_up_date && formik.errors.pick_up_date
                  }
                  helperText={
                    formik?.touched.pick_up_date && formik?.errors?.pick_up_date
                  }
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box className={classes.boxColumn} mx={1} my={0.5}>
              <label
                style={{
                  color: '#777e98',
                  fontSize: '0.7rem',
                  fontWeight: 400,
                }}
              >
                Pickup Required
              </label>
              <Select
                key={`${formik?.values?.pickup_required}`}
                label="Pickup Required"
                value={formik?.values?.pickup_required}
                onChange={event => {
                  const updatedObject = { ...formik.values };
                  updatedObject.pickup_required = event.target?.value;
                  if (event?.target?.value == PICKUP_REQUIRED_OPTIONS.No) {
                    updatedObject.pick_up_location_id = null;
                    updatedObject.pick_up_location_address_id = null;
                  }
                  formik?.setValues(updatedObject);
                }}
                disabled={action === 'view'}
                onBlur={formik?.handleBlur}
              >
                {Object.keys(PICKUP_REQUIRED_OPTIONS).map(key => (
                  <MenuItem value={PICKUP_REQUIRED_OPTIONS[key]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className={classes.boxColumn} mx={1} my={0.5}>
              <TextField
                id="distance"
                label="Mileage"
                disabled={true}
                value={`${distance || '0.00'} Miles`}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <div className="flex align-items-center align-items-end mb-3 ml-2">
              <PFCheckbox
                checked={formik?.values?.special_tool}
                onChange={handleSpecialTool}
                inputId="specialTool"
                disabled={action === 'view'}
              />
              <label className="ml-3" htmlFor="specialTool">
                Special Tools
              </label>
            </div>
            {formik?.values?.pickup_required == PICKUP_REQUIRED_OPTIONS.Yes && (
              <>
                <Box className={classes.boxColumn} m={1}>
                  <Autocomplete
                    name="pick_up_location_id"
                    key={pickUpLocation}
                    options={pickUpLocation || []}
                    onChange={(event, value) => {
                      const updatedObject = { ...formik.values };
                      if (value?.location_id) {
                        updatedObject.pick_up_location_id = value.location_id;
                        if (value.location_id == 4) {
                          getAddressDetails();
                        } else if (value.location_id == 1) {
                          getWareHouseDetails();
                        }
                        if ([2, 3].includes(value.location_id)) {
                          updatedObject.pick_up_location_address_id = null;
                        }
                      } else {
                        updatedObject.pick_up_location_id = null;
                        updatedObject.pick_up_location_address_id = null;
                      }
                      formik?.setValues(updatedObject);
                    }}
                    getOptionLabel={option => option && option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Pick Up Location"
                        InputLabelProps={{ shrink: true }}
                        required={action === 'edit' || action === 'add'}
                      />
                    )}
                    disabled={(action === 'view' && true) || false}
                    value={pickUpLocation.find(
                      location =>
                        location.location_id ===
                        formik?.values?.pick_up_location_id
                    )}
                  />
                </Box>

                {formik?.values?.pick_up_location_id == 4 && (
                  <Box className={classes.boxColumn} m={1}>
                    <Autocomplete
                      name="pick_up_location_address_id"
                      key={refreshAddressDetails}
                      options={[
                        {
                          pickupLocationId: 0,
                          pickupLocationTitle: 'Create new address',
                        },
                        ...(pickupLocationAddressList || []),
                      ]}
                      onChange={(event, value) => {
                        if (value?.pickupLocationId == 0) {
                          setShowAddAddressPopUp(true);
                        } else {
                          formik?.setFieldValue(
                            'pick_up_location_address_id',
                            value?.pickupLocationId || null
                          );
                        }
                      }}
                      getOptionLabel={option =>
                        option && option.pickupLocationTitle
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Select Address"
                          required={action === 'edit' || action === 'add'}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      onInputChange={event => {
                        event?.currentTarget?.value &&
                          getAddressDetails(event.currentTarget.value);
                      }}
                      value={pickupLocationAddressList.find(
                        record =>
                          record.pickupLocationId ==
                          formik?.values?.pick_up_location_address_id
                      )}
                      disabled={(action === 'view' && true) || false}
                    />
                  </Box>
                )}
              </>
            )}

            {formik?.values?.pick_up_location_id == 1 && (
              <Box className={classes.boxColumn} m={1}>
                <Autocomplete
                  name="pick_up_location_address_id"
                  options={pickupLocationWarehouseList || []}
                  key={refreshAddressDetails}
                  onChange={(event, value) =>
                    formik?.setFieldValue(
                      'pick_up_location_address_id',
                      value.pickupLocationId
                    )
                  }
                  getOptionLabel={option =>
                    option && option.pickupLocationTitle
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Warehouse"
                      required={action === 'edit' || action === 'add'}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onInputChange={event =>
                    event?.currentTarget?.value &&
                    getWareHouseDetails(event.currentTarget.value)
                  }
                  value={pickupLocationWarehouseList.find(
                    record =>
                      record.pickupLocationId ==
                      formik?.values?.pick_up_location_address_id
                  )}
                  disabled={(action === 'view' && true) || false}
                />
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" mt={2}>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                id="floorsoft_transaction_id"
                label="FS Transaction ID"
                onChange={event => {
                  const value = event.target.value;
                  formik.setFieldValue('project_floorsoft', {
                    ...formik?.values?.project_floorsoft,
                    floorsoft_transaction_id: value,
                  });
                  formik.handleChange;
                }}
                disabled={action === 'view'}
                value={
                  formik?.values?.project_floorsoft?.floorsoft_transaction_id ||
                  ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                id="floorsoft_project_id"
                label="FS Project ID"
                onChange={event => {
                  const value = event.target.value;
                  formik.setFieldValue('project_floorsoft', {
                    ...formik?.values?.project_floorsoft,
                    floorsoft_project_id: value,
                  });
                  formik.handleChange;
                }}
                disabled
                value={
                  formik?.values?.project_floorsoft?.floorsoft_project_id || ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                id="floorsoft_error_message"
                label="FS Error Message"
                onChange={event => {
                  const value = event.target.value;
                  formik.setFieldValue('project_floorsoft', {
                    ...formik?.values?.project_floorsoft,
                    floorsoft_error_message: value,
                  });
                  formik.handleChange;
                }}
                disabled={action === 'view'}
                value={
                  formik?.values?.project_floorsoft?.floorsoft_error_message ||
                  ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                id="floorsoft_completed"
                label="FS Completed"
                onChange={event => {
                  const value = event.target.value;
                  formik.setFieldValue('project_floorsoft', {
                    ...formik?.values?.project_floorsoft,
                    floorsoft_completed: value,
                  });
                  formik.handleChange;
                }}
                disabled
                value={
                  (formik?.values?.project_floorsoft?.floorsoft_completed &&
                  formik?.values?.project_floorsoft?.floorsoft_completed === 1
                    ? 'Yes'
                    : 'No') || ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>{' '}
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" mt={2}>
            <Box className={classes.boxColumn} m={1}>
              <Autocomplete
                name="tier"
                options={PROJECT_TIER_DROPDOWN}
                onBlur={formik?.handleBlur}
                onChange={(event, value) => {
                  formik?.setFieldValue('tier', value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Tier"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={formik?.values?.tier || ''}
                disabled={action === 'view'}
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                id="est_job_duration"
                label="Job Duration (In Mins)"
                type="number"
                inputProps={{
                  min: '1',
                  max: '999',
                }}
                onChange={formik.handleChange}
                disabled={action === 'view'}
                value={formik?.values?.est_job_duration || ''}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                id="products"
                label="Products"
                type="number"
                onChange={(event, value) => {
                  formik.setFieldValue('products', event.target?.value);
                }}
                value={formik?.values?.products || ''}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: '1',
                  max: '9999',
                }}
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                id="installer_reference"
                label="Technician Reference"
                onChange={formik?.handleChange}
                disabled={action === 'view'}
                value={formik?.values?.installer_reference || ''}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" flexGrow="wrap" mt={2}>
            <Box className={`${classes.boxColumn}`} m={1}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  id="scheduled_date_811"
                  label="811 Schedule Date"
                  format="MM-dd-yyyy hh:mm a"
                  onBlur={formik?.handleBlur}
                  value={formik?.values?.scheduled_date_811}
                  onChange={(date, value) => {
                    formik.setFieldValue('scheduled_date_811', date);
                  }}
                  InputLabelProps={{ shrink: true }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    style: { display: action === 'view' && 'none' },
                  }}
                  disabled={action === 'view'}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <label
                style={{
                  color: '#777e98',
                  fontSize: '0.7rem',
                  fontWeight: 400,
                }}
              >
                Move-up on Schedule
              </label>
              <Select
                key={`${formik?.values?.move_up_on_schedule}`}
                label="Move-up on Schedule"
                value={formik?.values?.move_up_on_schedule}
                onChange={(event, value) => {
                  formik.setFieldValue(
                    'move_up_on_schedule',
                    event?.target?.value
                  );
                }}
                disabled={action === 'view'}
              >
                {Object.keys(PICKUP_REQUIRED_OPTIONS).map(key => (
                  <MenuItem value={PICKUP_REQUIRED_OPTIONS[key]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                id="document_downloaded"
                label="Document Downloaded"
                onChange={event => {
                  const value = event.target.value;
                  formik.setFieldValue('project_floorsoft', {
                    ...formik?.values?.project_floorsoft,
                    document_downloaded: value,
                  });
                  formik.handleChange;
                }}
                disabled
                value={
                  (formik?.values?.project_floorsoft?.document_downloaded &&
                  formik?.values?.project_floorsoft?.document_downloaded === 1
                    ? 'Yes'
                    : 'No') || ''
                }
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiKeyboardDatePicker
                  name="eta_date"
                  label="ETA Date"
                  format="MM-dd-yyyy"
                  value={
                    (formik?.values?.eta_date &&
                      formik?.values?.eta_date?.split('T')?.length &&
                      parseISO(formik?.values?.eta_date?.slice(0, 10))) ||
                    null
                  }
                  onChange={(date, value) => {
                    if (date) {
                      formik?.setFieldValue(
                        'eta_date',
                        `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                      );
                    } else {
                      formik?.setFieldValue('eta_date', null);
                    }
                  }}
                  shouldDisableDate={date => {
                    return isBefore(date, subDays(new Date(), 1));
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    style: { display: action === 'view' && 'none' },
                  }}
                  disabled={action === 'view'}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" flexGrow="wrap" mt={2}>
            {rest?.bustedDetails && (
              <>
                {rest?.bustedDetails?.busted_user && (
                  <Box className={classes.boxColumn} m={1}>
                    <TextField
                      id="busted_technician"
                      label="Busted Technician"
                      disabled
                      value={`${
                        rest?.bustedDetails?.busted_user?.first_name || ''
                      } ${rest?.bustedDetails?.busted_user?.last_name || ''}`}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                )}
                {rest?.bustedDetails?.busted_reasons && (
                  <Box className={classes.boxColumn} m={1}>
                    <TextField
                      id="busted_reasons"
                      label="Busted Reason"
                      disabled
                      value={`${
                        rest?.bustedDetails?.busted_reasons?.label || ''
                      }`}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" mt={2}>
            {action !== 'add' &&
              formik?.values?.source_system?.source_system_id ===
                SOURCE_SYSTEMS.LOWES &&
              formik?.values?.project_type?.project_type === 'Installation' && (
                <>
                  <Box className={classes.boxColumn} m={1}>
                    <FormControlLabel
                      value="top"
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.checkboxRoot,
                          }}
                          onChange={(event, value) => {
                            if (value) {
                              formik.setFieldValue('dd_confirm', 1);
                            } else {
                              formik.setFieldValue('dd_confirm', 0);
                            }
                          }}
                          checked={formik?.values?.dd_confirm}
                        />
                      }
                      disabled={action === 'view' || ddConfirm}
                      label="DD Confirm"
                      labelPlacement="top"
                      classes={{
                        root: classes.checkboxFormRoot,
                        labelPlacementTop: classes.checkboxLabelPlacement,
                        label: classes.checkboxLabel,
                      }}
                    />
                  </Box>

                  <Box
                    className={`${classes.boxColumn} 
                `}
                    m={1}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <MuiKeyboardDatePicker
                        id="ddconfirm_date"
                        label="DD Confirm Followup"
                        format="MM-dd-yyyy"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                          style: { display: action === 'view' && 'none' },
                        }}
                        autoOk
                        disablePast={action !== 'view'}
                        onChange={(date, value) => {
                          if (date != 'Invalid Date') {
                            const dateFormated = date
                              ? `${moment(date).format(
                                  'YYYY-MM-DD'
                                )}T00:00:00.000Z`
                              : null;
                            formik.setFieldValue(
                              'dd_confirm_follow_up',
                              dateFormated
                            );
                          }
                        }}
                        InputLabelProps={{ shrink: true }}
                        disabled={action === 'view' || ddConfirm}
                        value={
                          formik?.values?.dd_confirm_follow_up &&
                          formik?.values?.dd_confirm_follow_up?.split('T')
                            ?.length &&
                          parseISO(
                            formik?.values?.dd_confirm_follow_up?.slice(
                              0,
                              10
                            ) || null
                          )
                        }
                        fullWidth
                        error={
                          formik?.touched.dd_confirm_follow_up &&
                          formik.errors.dd_confirm_follow_up
                        }
                        helperText={
                          formik?.touched.dd_confirm_follow_up &&
                          formik?.errors?.dd_confirm_follow_up
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </>
              )}
            {formik?.values?.source_system?.source_system_id === 1 &&
              formik?.values?.current_activity === 'Confirm Appointment' &&
              formik?.values?.project_activity?.activity_status === 'Open' && (
                <Box className={classes.boxColumn} m={1}>
                  <Tooltip
                    title={formik?.values?.confirm_appointment ? 'Edit' : ''}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={formik?.values?.confirm_appointment}
                      title="Open Confirm Appointment activity is not present"
                      onClick={() =>
                        confirmAppointment(
                          formik?.values?.project_id,
                          historyProp,
                          setReloadFormProp,
                          setLoadingProp,
                          setConfirmLoading,
                          setAlertProp
                        )
                      }
                    >
                      Confirm Appointment
                    </Button>
                  </Tooltip>
                  {confirmLoading && <CircularProgress />}
                </Box>
              )}
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
            {formik?.values?.source_system?.source_system_id === 1 && (
              <Box className={classes.boxColumn} m={1}>
                <TextField
                  id="lowes_delivery_date"
                  label="Lowes Delivery Date"
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled={true}
                  value={
                    (formik?.values?.lowes_delivery_date &&
                      formatDate(formik?.values?.lowes_delivery_date)) ||
                    ''
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            )}
            {formik?.values?.source_system?.source_system_id === 1 && (
              <Box className={classes.boxColumn} m={1}>
                <TextField
                  id="lowes_delivery_sold"
                  label="Lowes Delivery Sold"
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled={true}
                  value={
                    (formik?.values?.lowes_delivery_sold &&
                    formik?.values?.lowes_delivery_sold === '1'
                      ? 'True'
                      : 'False') || ''
                  }
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            {formik?.values?.source_system?.source_system_id ===
              SOURCE_SYSTEMS.LOWES && (
              <>
                <Box className={classes.boxColumn} m={1}>
                  <Autocomplete
                    name="vendor_id"
                    options={vendorList || []}
                    onBlur={formik?.handleBlur}
                    getOptionLabel={option => (option && option?.name) || ''}
                    value={vendorList?.find(vendor => {
                      return vendor?.vendor_id === formik?.values?.vendor_id;
                    })}
                    onChange={(event, value) => {
                      formik.setFieldValue('vendor_id', value?.vendor_id);
                      formik.setFieldValue('vendor_phone', value?.phone);
                      formik.setFieldValue(
                        'vendor_tracking_link',
                        value?.tracking_link
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Vendor"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    disabled={(action === 'view' && true) || false}
                  />
                </Box>
                <Box className={classes.boxColumn} m={1}>
                  <TextField
                    id="sos_po_vendor"
                    label="SOS PO Vendor"
                    value={formik?.values?.sos_po_vendor || ''}
                    onChange={e => {
                      formik.setFieldValue('sos_po_vendor', e?.target?.value);
                    }}
                    disabled={(action === 'view' && true) || false}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 100 }}
                  />
                </Box>
                <Box className={classes.boxColumn} m={1}>
                  <TextField
                    id="vendor_phone"
                    label="Vendor Phone"
                    value={formik?.values?.vendor_phone || ''}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Box>

                <Box className={classes.boxColumn} m={1}>
                  <TextField
                    id="vendor_tracking_link"
                    label="Vendor Tracking Link"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: formik?.values?.vendor_tracking_link && (
                        <a
                          href={`${formik?.values?.vendor_tracking_link}`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary min-w-full no-underline overflow-hidden"
                        >
                          Click Here
                        </a>
                      ),
                    }}
                    disabled
                  />
                </Box>
              </>
            )}
          </Box>
          <Box className={classes.installationText} m={1}>
            <Typography variant="subtitle1">Installation Address</Typography>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <Box className={classes.boxColumn} m={1}>
              <TextField
                name={'installation_address_address1'}
                required={action === 'edit' || action === 'add'}
                label="Street"
                value={formik?.values?.installation_address?.address1 || ''}
                InputLabelProps={{ shrink: true }}
                onChange={event => {
                  const value = event.target.value;
                  formik.setFieldValue('installation_address', {
                    ...formik?.values?.installation_address,
                    address1: value,
                  });
                  formik.handleChange;
                }}
                disabled={(action === 'view' && true) || false}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.installation_address_address1 &&
                  formik.errors.installation_address?.address1
                }
                helperText={
                  formik.touched.installation_address_address1 &&
                  formik.errors.installation_address?.address1
                }
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                required={action === 'edit' || action === 'add'}
                name={'installation_address_city'}
                label="City"
                value={formik?.values?.installation_address?.city || ''}
                InputLabelProps={{ shrink: true }}
                onChange={event => {
                  const value = event.target.value;
                  formik.setFieldValue('installation_address', {
                    ...formik?.values?.installation_address,
                    city: value,
                  });
                  formik.handleChange;
                }}
                disabled={(action === 'view' && true) || false}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.installation_address_city &&
                  formik.errors.installation_address?.city
                }
                helperText={
                  formik.touched.installation_address_city &&
                  formik.errors.installation_address?.city
                }
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <Autocomplete
                name={'installation_address_state'}
                options={stateOptions || []}
                onChange={(event, value) => {
                  if (value) {
                    formik?.setFieldValue('installation_address', {
                      ...formik?.values?.installation_address,
                      state: value.state_code,
                    });
                  }
                }}
                onBlur={formik.handleBlur}
                getOptionLabel={option => option && option.state_code}
                renderInput={params => (
                  <TextField
                    required={action === 'edit' || action === 'add'}
                    {...params}
                    label="State"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disabled={(action === 'view' && true) || false}
                value={
                  stateOptions?.find(
                    state =>
                      state?.state_code ===
                      formik?.values?.installation_address?.state
                  ) || ''
                }
              />
            </Box>
            <Box className={classes.boxColumn} m={1}>
              <TextField
                required={action === 'edit' || action === 'add'}
                name={'installation_address_zipcode'}
                label="Zip"
                value={formik?.values?.installation_address?.zipcode || ''}
                InputLabelProps={{ shrink: true }}
                onChange={event => {
                  const value = event.target.value;
                  formik.setFieldValue('installation_address', {
                    ...formik?.values?.installation_address,
                    zipcode: value,
                  });
                  formik.handleChange;
                }}
                disabled={(action === 'view' && true) || false}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.installation_address_zipcode &&
                  formik.errors.installation_address?.zipcode
                }
                helperText={
                  formik.touched.installation_address_zipcode &&
                  formik.errors.installation_address?.zipcode
                }
              />
            </Box>
          </Box>
          {action === 'view' && (
            <div className="mt-4 mb-3">
              <a href={mapLink} target="_blank" rel="noreferrer">
                {formattedAddress}
              </a>
            </div>
          )}
          {showAddAddressPopUp ? (
            <Dialog
              header="Add New Address"
              visible={true}
              style={{ width: '50vw' }}
              onHide={() => setShowAddAddressPopUp(false)}
            >
              <AddCustomAddress
                setCustomAddressLocationPopup={setShowAddAddressPopUp}
                getAddressDetailsById={getAddressDetailsById}
                isLoaded={isLoaded}
              />
            </Dialog>
          ) : (
            formik?.values?.installation_address?.latitude && (
              <Box m={1}>
                <MapContainer
                  lat={Number(formik?.values?.installation_address?.latitude)}
                  long={Number(formik?.values?.installation_address?.longitude)}
                  isLoaded={isLoaded}
                />
              </Box>
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ScheduleInfo;
