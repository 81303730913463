import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isDailogOpen: false,
  isTechnicialFilterApplied: false,
};
const schedulerTechnicianViewSlice = createSlice({
  name: 'schedulerTechnicianView',
  initialState: initialState,
  reducers: {
    setFilterTechinicianView(state, { payload }) {
      state.isDailogOpen = payload.hasOwnProperty('isDailogOpen')
        ? payload.isDailogOpen
        : state.isDailogOpen;
      state.isTechnicialFilterApplied = payload.hasOwnProperty(
        'isTechnicialFilterApplied'
      )
        ? payload.isTechnicialFilterApplied
        : state.isTechnicialFilterApplied;
    },
  },
});
export const { setFilterTechinicianView } =
  schedulerTechnicianViewSlice.actions;
export const schedulerTechnicianViewReducer =
  schedulerTechnicianViewSlice.reducer;
