import moment from 'moment-timezone';
import { useState, useRef } from 'react';
import {
  DataTable,
  Column,
  Dropdown,
  Calendar,
  InputText,
  Button,
  Tooltip,
  Skeleton,
} from 'primereact';

import GenericDataTable from '../../../shared/GenericDataTable/GenericDataTable';
import {
  getDataByCube,
  storeDashboardAnalysis,
} from '../../Widget/service/widget.service';
import { formatDateTime, formatTimeHM } from '../../../../utils/Helpers';
import { TruncatedText } from '../../../shared/PFPrime/TruncatedText';

const FILTER_OPTIONS = [
  { label: 'Equals', value: 'equals', dateType: true, isMaster: true },
  { label: 'Not Equals', value: 'notEquals', isMaster: true },
  { label: 'Contains', value: 'contains', isMaster: true },
  { label: 'Not Contains', value: 'notContains', isMaster: true },
  { label: 'Starts With', value: 'startsWith' },
  {
    label: 'Ends With',
    value: 'endsWith',
  },
];
const DATE_FILTER_OPTIONS = [
  { label: 'In Between', value: 'equals', dateType: true, isMaster: true },
];

const getFilterOptions = (type, isMaster) => {
  if (type == 'time') return DATE_FILTER_OPTIONS;
  if (isMaster) return FILTER_OPTIONS.filter(item => item.isMaster);
  return FILTER_OPTIONS;
};

const WidgetTableRenderer = ({
  table_configuration,
  data,
  tablePagination,
  onTableChangeHandler,
  annotation,
  totalCount,
  rowsPerPageOptions = true,
  orderBy,
  setOrderBy,
  setPaginator,
  paginator,
  dashboardLogDetails,
  dashboardDetails,
}) => {
  const [masterData, setMasterData] = useState({});
  const [isMaterDataFetched, setIsMaterDataFetched] = useState(false);

  if (!table_configuration.length) return null;
  if (!Array.isArray(table_configuration) || !table_configuration.length)
    return null;

  const headingKeys = [];
  const tableConfiguration = [];
  table_configuration.forEach(record => {
    if (!headingKeys.includes(record.measureName)) {
      headingKeys.push(record.measureName);
      tableConfiguration.push(record);
    }
  });

  if (!isMaterDataFetched) {
    setIsMaterDataFetched(true);
    const annotationList =
      annotation && Object.keys(annotation)?.length
        ? Object.keys(annotation)
        : [];
    const masterPromissList = annotationList?.map(dimensions => {
      const [tableName, columnName] = dimensions.split('.');
      return getDataByCube(tableName, columnName);
    });
    Promise.all(masterPromissList).then(response => {
      const masterResponse = {};
      annotationList.forEach((record, index) => {
        if (response[index]?.length > 0) {
          masterResponse[record] = response[index];
        }
      });
      setMasterData(masterResponse);
    });
  }

  const tableData = data?.map(item => {
    const copiedObject = { ...item };
    Object.keys(copiedObject).forEach(key => {
      if (copiedObject?.[key] == 'Invalid date') {
        copiedObject[key] = '';
      } else if (copiedObject?.[key] && annotation[key]?.type == 'time') {
        if (annotation[key]?.meta?.isDateTimeField) {
          // add Z as timezone only when timezone is not present
          copiedObject[key] = formatDateTime(`${copiedObject[key]}Z`);
        } else if (annotation[key]?.meta?.isTimeField) {
          copiedObject[key] = formatTimeHM(`${copiedObject[key]}Z`);
        } else {
          copiedObject[key] = moment(`${copiedObject[key]}`).format(
            'MM-DD-YYYY'
          );
        }
      } else if (annotation?.[key]?.meta?.format) {
        const formatter = eval(annotation[key].meta.format);
        copiedObject[key] = formatter(copiedObject[key]);
      }
    });
    return copiedObject;
  });

  const getFilterTypeDetails = (type, options) => {
    switch (type) {
      case 'dropdown':
        return (
          <Dropdown
            value={options.value}
            optionLabel="label"
            options={masterData?.[options?.field].map(record => record)}
            onChange={e => options.filterCallback(e.value, options.index)}
            placeholder="Select One"
            className="p-column-filter"
            showClear
          />
        );
        break;

      case 'time':
        return (
          <Calendar
            value={options.value}
            onChange={e => {
              options.filterCallback(e.value, options.index);
            }}
            dateFormat="mm-dd-yy"
            placeholder="mm-dd-yyyy - mm-dd-yyyy"
            selectionMode="range"
            showIcon
          />
        );
        break;

      default:
        return (
          <InputText
            value={options.value || ''}
            onChange={e =>
              options.filterCallback(e.target.value, options.index)
            }
            placeholder="Search"
          />
        );
        break;
    }
  };
  //Helper function to render URL in report
  const renderUrl = (params, index) => {
    {
      return <a href={params?.[index?.field]}>{params?.[index?.field]}</a>;
    }
  };

  if (dashboardDetails && dashboardLogDetails) {
    storeDashboardAnalysis({
      ...dashboardLogDetails,
      ...dashboardDetails,
      executionTime: new Date().getTime() - dashboardDetails.start_time,
      end_time: new Date().getTime(),
    });
  }

  const truncatedString = ({ text, maxLength = 20 }) => {
    return <TruncatedText text={text || ''} maxLength={maxLength} />;
  };

  return (
    <div
      style={{
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
      }}
      className="oveflow-auto"
    >
      <DataTable
        responsive
        resizableColumns
        value={tableData}
        lazy
        dataKey="id"
        paginator
        first={paginator.first}
        rows={paginator.perPage}
        totalRecords={totalCount}
        onPage={event => {
          setPaginator({
            ...paginator,
            currentPage: event.page,
            perPage: event.rows,
            first: event.first,
          });
        }}
        onSort={event => {
          setOrderBy({
            ...orderBy,
            columns: event.sortField,
            direction: event.sortOrder,
          });
        }}
        sortField={orderBy?.columns}
        sortOrder={orderBy?.direction}
        onFilter={event => {
          onTableChangeHandler({
            ...tablePagination,
            filters: event.filters,
          });
          setPaginator({
            ...paginator,
            first: 0,
            currentPage: 0,
          });
        }}
        showClear={false}
        filters={tablePagination.filters}
        tableClassName="w-12"
        rowsPerPageOptions={
          rowsPerPageOptions && [10, 25, 50, 100, 500, 1000, 2000, 5000]
        }
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        {tableConfiguration.map((configuration, index) => {
          return (
            <Column
              key={index}
              showAddButton={false}
              pt={{
                headerCell: {
                  className: 'white-space-normal w-15rem',
                },
                bodyCell: {
                  className: 'white-space-normal',
                },
              }}
              showFilterOperator={false}
              field={configuration.measureName}
              sortable={configuration.measureName ? true : false}
              filter={
                configuration?.measureName && annotation
                  ? annotation[configuration.measureName]?.meta
                      ?.hideColumFilter ||
                    annotation[configuration.measureName]?.meta?.isTimeField
                    ? false
                    : true
                  : false
              }
              header={configuration.alice}
              body={
                configuration?.columnTypeAction
                  ? (params, index) =>
                      configuration.options.customBodyRenderLite(params, index)
                  : configuration?.measureName &&
                      annotation?.[configuration.measureName]?.meta?.isUrl
                    ? (params, index) => renderUrl(params, index)
                    : configuration?.measureName &&
                        annotation?.[configuration.measureName]?.meta
                          ?.isTruncate
                      ? (params, index) =>
                          truncatedString({
                            text: params[configuration.measureName],
                            maxLength:
                              annotation?.[configuration.measureName]?.meta
                                ?.isTruncate?.maxLength,
                          })
                      : undefined
              }
              filterMatchModeOptions={
                configuration?.measureName &&
                annotation &&
                getFilterOptions(
                  annotation?.[configuration?.measureName]?.type,
                  masterData?.[configuration?.measureName]
                )
              }
              filterElement={options => {
                return masterData?.[configuration?.measureName]
                  ? getFilterTypeDetails('dropdown', options)
                  : getFilterTypeDetails(
                      annotation?.[configuration?.measureName]?.type,
                      options
                    );
              }}
            />
          );
        })}
      </DataTable>
    </div>
  );
};
export default WidgetTableRenderer;
