import { useState, useCallback, useEffect, useRef } from 'react';
import {
  DataTable,
  Column,
  Tooltip,
  Card,
  Tag,
  Toast,
  Chip,
  Divider,
} from 'primereact';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import { SEND_RECEIVED } from '../constants/constants';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import apiService from '../../../../services/api.service';
import SkeletonLoader from '../../../shared/Loader/skeleton';
import TableLoader from '../../../shared/Loader/TableLoader';
import { generateUrlParams } from '../helper/helper';
import { isCrmEnabled, formatDateTime } from '../../../../utils/Helpers';
import { Colors } from '../../../../constants';

const EmailList = ({
  customerId,
  reload,
  communicationType,
  communicationId,
}) => {
  const [expandedRows, setExpandedRows] = useState(null);
  const [emailsList, setEmailsList] = useState([]);
  const [totalRecordsCount, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const toast = useRef(null);
  const [filters, setFilters] = useState({
    from: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    subject: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    sendOrReceived: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'createdAt',
    sortOrder: -1,
  });
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <PFInputText
            value={globalFilterValue}
            onChange={e => {
              setGlobalFilterValue(e.target.value);
            }}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const dataTableParams = { header: renderHeader() };

  const fetchEmailList = async () => {
    try {
      const urlParams = generateUrlParams({
        offset: lazyState.first,
        limit: lazyState.rows,
        sortField: lazyState.sortField,
        sortOrder: lazyState.sortOrder > 0 ? 'ASC' : 'DESC',
        globalSearch: globalFilterValue,
        communicationType,
        communicationId,
      });
      const response = await apiService.getWithDiffBaseUrl(
        `${URL_CONSTANTS.CRM.communication.email.getAll}/${customerId}?${urlParams}`,
        null,
        URL_CONSTANTS.CRM.baseUrl
      );
      if (response) {
        setEmailsList(
          response.data?.map((record, index) => ({ ...record, index }))
        );
        setTotalRecords(response.count);
      }
      setLoading(false);
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
      setLoading(false);
    }
  };

  const attachmentDownloadHandler = async payload => {
    try {
      toast?.current?.show({
        severity: 'info',
        summary: 'Downloading attachment',
        life: 2000,
      });
      const response = await apiService.postWithDiffBaseUrl(
        `${URL_CONSTANTS.CRM.communication.email.downloadAttachment}`,
        payload,
        URL_CONSTANTS.CRM.baseUrl
      );

      if (response.s3path) {
        window.open(response.s3path, '_blank');
      } else {
        throw new Error('Path not found');
      }
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Failed to download attachment',
        life: 2000,
      });
    }
  };
  useEffect(() => {
    fetchEmailList();
  }, [customerId, reload, lazyState, globalFilterValue]);
  const representativeBodyTemplate = rowData => {
    return (
      <div className="flex align-items-center gap-2">
        <div
          className="p-avatar p-component p-avatar-circle mr-2 custom-target-icon"
          data-pc-name="avatar"
          data-pc-section="root"
          style={{
            backgroundColor: 'rgb(33, 150, 243)',
            color: 'rgb(255, 255, 255)',
          }}
          data-pr-tooltip={rowData.from}
          data-pr-position="right"
          data-pr-at="right+5 top"
          data-pr-my="left center-2"
        >
          <span
            className="p-avatar-text"
            data-pc-section="label"
            tooltip={rowData.from}
          >
            {rowData.from?.charAt(0)?.toUpperCase()}
          </span>
        </div>
        <span>{rowData.subject}</span>
      </div>
    );
  };
  const allowExpansion = rowData => {
    return true;
  };
  const rowExpansionTemplate = data => {
    return (
      <>
        <div className=" align-items-start flex-column lg:justify-content-between lg:flex-row">
          <div className="grid">
            <div className="col-1">
              <div className="text-500 w-6 md:w-2 font-medium">Sender:</div>
            </div>
            <div className="col-6">
              <div className="font-bold">{`${data.sender_name?.replace(/"/g, '')}`}</div>
            </div>
          </div>
          <div className="grid">
            <div className="col-1">
              <div className="text-500 w-6 md:w-2 font-medium">To:</div>
            </div>
            <div className="col-6">
              {data?.toMail?.length ? (
                data?.toMail.map(mail => {
                  return (
                    <div className="mr-5 flex align-items-center">
                      <span>{mail.address || data.from_email}</span>
                    </div>
                  );
                })
              ) : (
                <div className="mr-5 flex align-items-center">
                  <span>{data.from_email}</span>
                </div>
              )}
            </div>
          </div>
          {data?.ccMail?.length ? (
            <div className="grid">
              <div className="col-1">
                <div className="text-500 w-6 md:w-2 font-medium">Cc:</div>
              </div>
              <div className="col-6">
                {data.ccMail.map(mail => {
                  return (
                    <div className="mr-5 flex align-items-center ">
                      <span>{mail?.address || ''}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          {data.attachment.length ? (
            <div className="grid">
              <div className="col-1">
                <p className="text-500 w-6 md:w-2 font-medium my-0">
                  Attachments:
                </p>
              </div>
              <div className="col-6">
                {data.attachment.map(attachment => (
                  <div
                    className="mr-5 mt-1 flex align-items-center cursor-pointer text-primary"
                    onClick={() => {
                      attachmentDownloadHandler({
                        messageId: data.message_id,
                        fileName: attachment.fileName,
                      });
                    }}
                  >
                    <i className="pi pi-download mr-2"></i>
                    <span className=" hover:underline">
                      {attachment.fileName?.split('/')?.[1]}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <Divider />
        <div dangerouslySetInnerHTML={{ __html: data.body.html }}></div>
      </>
    );
  };

  const tableColum = [
    {
      field: 'createdAt',
      header: 'Date',
      sortable: true,
      filter: false,
      style: { width: '17%' },
      body: rowData => {
        return (
          <>
            {rowData.sendOrReceived ? (
              <>
                <Tag
                  className="mr-2"
                  icon="pi pi-arrow-up"
                  severity="success"
                  pt={{
                    icon: { style: { marginLeft: '3px' } },
                  }}
                ></Tag>
                {formatDateTime(rowData.createdAt)}
              </>
            ) : (
              <>
                <Tag
                  className="mr-2"
                  icon="pi pi-arrow-down"
                  severity="info"
                  pt={{
                    icon: { style: { marginLeft: '3px' } },
                  }}
                ></Tag>
                {formatDateTime(rowData.createdAt)}
              </>
            )}
          </>
        );
      },
    },

    {
      expander: { allowExpansion },
      style: { width: '2%' },
    },
    {
      field: 'sender_name',
      header: 'Sender',
      sortable: true,
      filter: false,
      style: { width: '15%' },
      body: rowData => {
        return rowData?.sender_name?.replace(/"/g, '');
      },
    },
    {
      field: 'subject',
      header: 'Subject',
      sortable: true,
      filter: false,
      body: rowData => {
        return (
          <>
            <div>{rowData.subject}</div>
          </>
        );
      },
    },
  ];
  if (!communicationType && !communicationId) {
    tableColum.push({
      field: '',
      header: 'Reference',
      sortable: false,
      filter: false,
      hidden: !isCrmEnabled(),
      style: { width: '12%' },
      body: params => {
        if (params.parent_id && params.parent_type && params.parent_number) {
          let redirectUrl = '';
          if (params.parent_type == 'opportunity') {
            redirectUrl = `/crm/${customerId}/lead/view/${params.parent_id}`;
          }
          if (params.parent_type == 'quote') {
            redirectUrl = `/crm/${customerId}/${params.parent_opportunity_key}/quote/view/${params.parent_id}`;
          }
          if (params.parent_type == 'invoice') {
            redirectUrl = `/crm/invoice/view/${params.parent_invoice_key}`;
          }
          return (
            <Chip
              label={params.parent_number}
              icon="pi pi-external-link"
              onClick={() => window.open(redirectUrl)}
              style={{ cursor: 'pointer' }}
              className="text-sm"
            />
          );
        }
        return null;
      },
    });
  }

  return loading ? (
    <>
      <SkeletonLoader columnCount={1} columnWidth="100%" />
      <TableLoader columnCount={3} noOfRow={4} mTopBottom={0} />
    </>
  ) : (
    <>
      <Tooltip target=".custom-target-icon" />
      <PFDataTable
        columns={tableColum}
        data={emailsList}
        stripedRows
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows}
        onRowToggle={e => setExpandedRows(e.data)}
        dataTableParams={dataTableParams}
        lazy={true}
        loading={false}
        dataKey="index"
        paginator={true}
        rows={lazyState.rows}
        rowsPerPageOptions={[5, 10, 50]}
        tableStyle={{ minWidth: '50rem' }}
        onPage={event => {
          setlazyState({
            ...lazyState,
            first: event.first,
            pageCount: event.pageCount,
            rows: event.rows,
          });
        }}
        first={lazyState.first}
        totalRecords={totalRecordsCount}
        onSort={event => {
          setlazyState({
            ...lazyState,
            sortField: event.sortField,
            sortOrder: event.sortOrder,
          });
        }}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
      />

      <Toast ref={toast} />
    </>
  );
};

export default EmailList;
