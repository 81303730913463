import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

import { useStyles } from '../../ProjectManagement/Scheduling/Reports/Reports.styles';
import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import { Alert } from '../../shared/Alerts/Alert';
import Notes from '../../ProjectManagement/Notes/Notes';
import Installer from '../../ProjectManagement/ScheduleInfo/Installer';
import PFButton from '../../shared/PFPrime/PFButton';
import {
  getProjectDataById,
  getRescheduleReasonOptions,
  updateProjectScheduleInfo,
} from '../services/calendar';
import Loader from '../../shared/Loader/Loader';
import {
  ProjectScheduleValidationSchema,
  getProjectFormikInitialValues,
} from '../helpers/project-formik';

import ProjectDetailsViewEditForm from './ProjectDetailsViewEditForm';

const ProjectEditDialog = ({ calenderRefreshHandler }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isProjectEditDialogOpen,
    projectEditDialogSettings,
    selectedProject,
    projectData,
    rescheduleReasons,
    viewChangeFlag,
  } = useSelector(state => state.newScheduler);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadCalender, setReloadCalender] = useState(true);
  const toast = useRef(null);

  const { technicians } = useSelector(state => state.technicians);

  const projectDetailsFormik = useFormik({
    initialValues: getProjectFormikInitialValues(projectData),
    onSubmit: async values => {
      setIsLoading(true);
      let updateResponse;
      try {
        updateResponse = await updateProjectScheduleInfo(
          projectData.project_id,
          {
            reschedule_reason_id: values?.reschedule_reason?.reason_id,
            rts_follow_up_date: values?.rts_follow_up_date,
            date_scheduled_start: values?.date_scheduled_start,
            date_scheduled_end: values?.date_scheduled_end,
            project_type_id: values?.project_type_id,
          }
        );
        if (updateResponse.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Project Details updated successfully',
            life: 2000,
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            life: 2000,
          });
          return;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        dispatch(
          setNewSchedulerComponent({
            isProjectEditDialogOpen: false,
          })
        );
        calenderRefreshHandler();
      }
    },
    validationSchema: ProjectScheduleValidationSchema,
    enableReinitialize: true,
  });
  useEffect(() => {
    if (selectedProject && isProjectEditDialogOpen) {
      setIsLoading(true);
      Promise.all([
        getProjectDataById(selectedProject.project_id),
        getRescheduleReasonOptions(selectedProject.source_system_id),
      ])
        .then(([projectData, rescheduleReasons]) => {
          dispatch(
            setNewSchedulerComponent({
              projectData,
              rescheduleReasons,
            })
          );
        })
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }
  }, [selectedProject]);

  const handleClose = () => {
    dispatch(
      setNewSchedulerComponent({
        isProjectEditDialogOpen: false,
        viewChangeFlag: !viewChangeFlag,
      })
    );
    projectDetailsFormik?.handleReset();
  };

  const handleViewProject = () => {
    dispatch(
      setNewSchedulerComponent({
        isProjectEditDialogOpen: false,
      })
    );
    projectDetailsFormik?.handleReset();
    window.open(`/project/view/${projectData?.project_id}`, '_blank');
  };

  return (
    <GenericDialog
      fullwidth
      isOpen={isProjectEditDialogOpen}
      dialogWidth="lg"
      handleClose={handleClose}
      handleSave={projectDetailsFormik?.handleSubmit}
      dialogSettings={projectEditDialogSettings}
      disabledButton2={
        !projectDetailsFormik?.dirty || !projectDetailsFormik?.isValid
      }
      disabledButton1={false}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div>{alert.exists && <Alert />}</div>
          <div className="flex flex-column gap-2">
            <div className={classes.projectRedirectButton}>
              <PFButton
                label="View Project"
                size="small"
                onClick={handleViewProject}
                severity="primary"
                outlined
              />
            </div>
            <div>
              <form onSubmit={projectDetailsFormik?.handleSubmit}>
                <ProjectDetailsViewEditForm
                  projectDetailsFormik={projectDetailsFormik}
                  rescheduleReasonOptions={rescheduleReasons}
                />
              </form>
            </div>

            <div className={classes.tableWrapper}>
              <Installer
                formik={{
                  values: {
                    project_id: projectData?.project_id,
                    date_scheduled:
                      projectDetailsFormik?.values?.date_scheduled,
                    proj_end_date:
                      projectDetailsFormik?.values?.project_end_date,
                  },
                }}
                setReloadCalender={setReloadCalender}
                reloadCalender={reloadCalender}
                projectId={projectData?.project_id}
                installerOptions={technicians}
              />
            </div>
            <div className={classes.tableWrapper}>
              <Notes
                formik={{ values: { project_id: projectData?.project_id } }}
                action=""
              />
            </div>
          </div>
        </>
      )}
      <Toast ref={toast} />
    </GenericDialog>
  );
};

export default ProjectEditDialog;
