import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { FloatLabel } from 'primereact/floatlabel';
import { useSelector, useDispatch } from 'react-redux';

import PFDialog from '../shared/PFPrime/PFDialog';
import PFButton from '../shared/PFPrime/PFButton';
import PFMultiSelect from '../shared/PFPrime/PFMultiSelect';
import { setIsTechFilterApplied } from '../../redux/slices/scheduler-view-more-event.slice';

const TechnicianFilter = ({
  setShowTechnicianFilter,
  handleClosePopup,
  showTechnicianFilter,
  installerFormik,
  handleResetFilters,
  installerOptions,
  projectTypeOptions,
  storeOptions,
  categoryOptions,
  districtOptions,
  userTypesOptions,
  workRoomOptions,
  setSearchWorkroomText,
  setSearchStoreText,
  handleUserTypeSearch,
  storeLoader,
  workRoomLoader,
  userTypeLoader,
  calendarLoading,
  handleTechnicianSearch,
  handleCategorySearch,
  setSelectAllTech,
  selectAllTech,
}) => {
  const dispatch = useDispatch();

  const { technicians } = useSelector(state => state.technicians);
  const [selectedItems, setSelectedItems] = useState(null);

  const debouncedSearchStore = debounce(value => {
    setSearchStoreText(value);
  }, 300);

  const debouncedSearchUserType = debounce(value => {
    handleUserTypeSearch(value);
  }, 300);

  const debouncedSearchWorkroom = debounce(value => {
    setSearchWorkroomText(value);
  }, 300);

  const debouncedTechnicianSearch = debounce(value => {
    handleTechnicianSearch(value);
  }, 300);

  const debouncedTypeSearch = debounce(value => {
    handleCategorySearch(value);
  }, 300);

  useEffect(() => {
    setSelectedItems(
      selectAllTech ? technicians : installerFormik?.values?.filterTechnicial
    );
  }, [selectAllTech]);

  return (
    <PFDialog
      header="Filter Technician"
      show={showTechnicianFilter}
      className="w-11 lg:w-8"
      hide={() => {
        setShowTechnicianFilter(false);
        handleClosePopup();
      }}
      draggable={false}
      pt={{
        headertitle: {
          className: 'text-2xl',
        },
      }}
      BodyComponent={
        <>
          <form
            className="w-full"
            onSubmit={event => {
              event.preventDefault();
              installerFormik.handleSubmit();
            }}
          >
            <div className="grid overflow-y-auto">
              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    value={selectedItems || []}
                    onChange={e => {
                      const selectedItems = e?.value;
                      const selectedItemsLength = selectedItems?.length;

                      installerFormik.setFieldValue(
                        'filterTechnicial',
                        selectedItemsLength === technicians?.length
                          ? technicians
                          : selectedItems
                      );
                      setSelectedItems(selectedItems);
                      setSelectAllTech(
                        selectedItemsLength === technicians.length
                      );
                    }}
                    options={technicians || []}
                    optionLabel="full_name"
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={true}
                    selectedItemsLabel={
                      selectAllTech
                        ? `All Selected (${installerFormik?.values?.filterTechnicial.length})`
                        : `${installerFormik?.values?.filterTechnicial.length} Selected`
                    }
                    selectAll={selectAllTech}
                    onSelectAll={e => {
                      setSelectedItems(
                        e?.checked ? [] : technicians?.map(item => item)
                      );
                      installerFormik.setFieldValue(
                        'filterTechnicial',
                        !e?.checked ? technicians : []
                      );
                      setSelectAllTech(!e?.checked);
                    }}
                    virtualScrollerOptions={{ itemSize: 50 }}
                    disabled={
                      installerFormik?.values?.filterCategory?.length > 0 ||
                      installerFormik?.values?.filterStore?.length > 0 ||
                      installerFormik?.values?.filterProjectType?.length > 0 ||
                      installerFormik?.values?.filterDistrict?.length > 0 ||
                      installerFormik?.values?.filterUserType?.length > 0
                        ? true
                        : false
                    }
                    maxSelectedLabels={0}
                    onFilter={e => {
                      debouncedTechnicianSearch(e?.filter);
                    }}
                    placeholder="Select Technicians"
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="technician">Technician</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    value={installerFormik?.values?.filterProjectType || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterProjectType', value);
                    }}
                    options={projectTypeOptions}
                    optionLabel="project_type"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0
                        ? true
                        : false
                    }
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="projectType">Type</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="store"
                    className="w-full"
                    value={installerFormik.values?.filterStore || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterStore', value);
                    }}
                    options={
                      Array.isArray(storeOptions)
                        ? storeOptions?.map(store => ({
                            ...store,
                            label: `${store.store_number} - ${store.store_name}`,
                          }))
                        : []
                    }
                    optionLabel="label"
                    maxSelectedLabels={1}
                    filter
                    filterPlaceholder="Type to search..."
                    onFilter={e => {
                      debouncedSearchStore(e?.filter);
                    }}
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0 ||
                      installerFormik?.values?.filterDistrict?.length > 0
                        ? true
                        : false
                    }
                    appendTo={null}
                    loading={storeLoader}
                  />
                  <label htmlFor="store">Store</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="category"
                    className="w-full"
                    value={installerFormik?.values?.filterCategory || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterCategory', value);
                    }}
                    options={categoryOptions}
                    optionLabel="category"
                    maxSelectedLabels={2}
                    filter
                    onFilter={e => {
                      debouncedTypeSearch(e?.filter);
                    }}
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0
                        ? true
                        : false
                    }
                    pt={{
                      panel: {
                        className: 'max-w-26rem',
                      },
                    }}
                    appendTo={null}
                  />
                  <label htmlFor="category">Category</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="district"
                    className="w-full"
                    value={installerFormik?.values?.filterDistrict || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterDistrict', value);
                    }}
                    options={districtOptions}
                    optionLabel="district"
                    optionValue="district"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0 ||
                      installerFormik?.values?.filterStore?.length > 0
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="district">District</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="userType"
                    className="w-full"
                    value={installerFormik?.values?.filterUserType || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterUserType', value);
                    }}
                    options={userTypesOptions}
                    onFilter={e => {
                      debouncedSearchUserType(e?.filter);
                    }}
                    optionLabel="label"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0
                        ? true
                        : false
                    }
                    loading={userTypeLoader}
                  />
                  <label htmlFor="userType">User Type</label>
                </FloatLabel>
              </div>
              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="workroom"
                    className="w-full"
                    value={installerFormik?.values?.filterWorkroom || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterWorkroom', value);
                    }}
                    options={workRoomOptions}
                    optionLabel="label"
                    maxSelectedLabels={2}
                    onFilter={e => {
                      debouncedSearchWorkroom(e?.filter);
                    }}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0 ||
                      installerFormik?.values?.filterDistrict?.length > 0
                        ? true
                        : false
                    }
                    loading={workRoomLoader}
                  />
                  <label htmlFor="workroom">Workroom</label>
                </FloatLabel>
              </div>
            </div>
            <div className="w-12 flex justify-content-end mt-4">
              <PFButton
                outlined
                label="Filter Reset"
                icon={`${calendarLoading ? 'pi pi-spin pi-spinner' : null}`}
                onClick={() => {
                  handleResetFilters();
                  setSelectedItems([]);
                  setSelectAllTech(false);
                  installerFormik.setFieldValue('filterTechnicial', []);
                  dispatch(setIsTechFilterApplied(false));
                }}
                className="pr-4"
              />
              <PFButton
                primary
                type="submit"
                label="Apply Filters"
                className="ml-2"
              />
            </div>
          </form>
        </>
      }
    ></PFDialog>
  );
};
export default TechnicianFilter;
