import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { FilterMatchMode } from 'primereact/api';

import PageHeader from '../../../shared/PageHeader/PageHeader';
import SkeletonLoader from '../../../shared/Loader/skeleton';
import ApiService from '../../../../services/api.service';
import { formatDateTime } from '../../../../utils/Helpers';

const ResetPasswordLogsContainer = () => {
  const breadcrumb = [
    {
      text: 'Reset Password Logs',
    },
  ];
  const [count, setCount] = useState(0);
  const [logsData, setLogsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
    },
  });
  useEffect(() => {
    const fetchLoginLogs = async () => {
      try {
        const response = await ApiService.get(
          `/admin-config/get-reset-password-log?offset=${lazyState.first}&limit=${lazyState.rows}
          &email=${lazyState?.filters?.email?.value || ''}&status=${lazyState?.filters?.status?.value || ''}`
        );
        setCount(response.data.count);
        setLogsData(response.data.rows);
      } catch (error) {
        console.error('fetchResetPasswordLogs -> error', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchLoginLogs();
  }, [lazyState]);

  const tableColumn = [
    {
      name: 'email',
      label: 'Email',
      filter: true,
    },
    {
      name: 'ip_address',
      label: 'Ip Address',
    },
    {
      name: 'created_at',
      label: 'Date',
      body: row => formatDateTime(row.created_at),
      style: { width: '20%' },
    },
    {
      name: 'user_agent',
      label: 'Agent',
      body: row => row.user_agent,
    },
  ];
  const handleOnPage = event => {
    setlazyState(event);
  };
  return (
    <>
      <div className="w-full grid grid-nogutter">
        <div className="col-6">
          <PageHeader
            pageTitle="Reset Password Logs"
            breadCrumbArray={breadcrumb}
          />
        </div>
      </div>
      <Card className="w-full mt-4 mb-0 mx-auto">
        <div className="grid">
          {isLoading ? (
            <SkeletonLoader columnCount={4} columnWidth="24%" />
          ) : (
            <DataTable
              value={logsData}
              dataKey="user_reset_password_log_info_id"
              showGridlines={false}
              size="small"
              stripedRows
              lazy
              paginator
              page={lazyState.page}
              first={lazyState.first}
              rows={lazyState.rows}
              totalRecords={count}
              onPage={handleOnPage}
              sortField={lazyState?.sortField}
              sortOrder={lazyState?.sortOrder}
              filters={lazyState.filters}
              onFilter={e => {
                setlazyState({
                  first: 0,
                  rows: lazyState.rows,
                  page: 1,
                  sortField: null,
                  sortOrder: null,
                  filters: { ...e.filters },
                });
              }}
              rowsPerPageOptions={[10, 20, 50, 100]}
              className="w-full"
              filterDisplay="row"
              dataKey="id"
            >
              {tableColumn.map(col => (
                <Column
                  key={col.name}
                  field={col.name}
                  header={col.label}
                  sortable={col?.sortable}
                  filter={col?.filter}
                  showFilterMenu={false}
                  body={col.body}
                  filterElement={col?.filterElement || null}
                  style={col.style}
                />
              ))}
            </DataTable>
          )}
        </div>
      </Card>
    </>
  );
};
export default ResetPasswordLogsContainer;
