import axios from 'axios';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

export const binListService = async project_id => {
  try {
    const res = await axios.get(
      `${URL_CONSTANTS?.API?.BASE_URL}/receiving/bin-location/get-list`
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'receiving.service',
      'binListService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const createBinLocationService = async (body = {}) => {
  try {
    const res = await axios.post(
      `${URL_CONSTANTS?.API?.BASE_URL}/receiving/bin-location/create-bin-location`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'receiving.service',
      'createBinLocationService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const createProjectReceivingService = async (project_id, body = {}) => {
  try {
    const res = await axios.post(
      `${URL_CONSTANTS?.API?.BASE_URL}/receiving/project-receiving/create-receiving/${project_id}`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'receiving.service',
      'createProjectReceivingService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const getProjectReceivingService = async project_id => {
  try {
    const res = await axios.get(
      `${URL_CONSTANTS?.API?.BASE_URL}/receiving/project-receiving/get-receiving/${project_id}`
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'receiving.service',
      'getProjectReceivingService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const updateProjectReceivingService = async (
  project_id,
  project_receiving_id,
  body = {}
) => {
  try {
    const res = await axios.patch(
      `${URL_CONSTANTS?.API?.BASE_URL}/receiving/project-receiving/update-receiving/${project_id}/${project_receiving_id}`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'receiving.service',
      'updateProjectReceivingService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const getAllReceiptService = async (
  project_id,
  project_receiving_id,
  rows,
  page,
  sortBy,
  orderBy
) => {
  try {
    let queryParams = {};
    queryParams.limit = rows;
    queryParams.offset = page * rows;

    if (project_id && project_receiving_id) {
      const res = await axios.get(
        `${URL_CONSTANTS?.API?.BASE_URL}/receiving/project-receiving-receipt/get-all-receipt/${project_id}/${project_receiving_id}`,
        { params: queryParams }
      );
      return res?.data || [];
    }
  } catch (e) {
    console.error(
      'receiving.service',
      'getAllReceiptService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const getReceiptInfoService = async (
  project_id,
  project_receiving_id,
  project_receiving_receipt_id
) => {
  try {
    const res = await axios.get(
      `${URL_CONSTANTS?.API?.BASE_URL}/receiving/project-receiving-receipt/get-receipt-Info/${project_id}/${project_receiving_id}/${project_receiving_receipt_id}`
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'receiving.service',
      'getReceiptInfoService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const deleteReceiptInfoService = async (
  project_id,
  project_receiving_id,
  project_receiving_receipt_id
) => {
  try {
    const res = await axios.delete(
      `${URL_CONSTANTS?.API?.BASE_URL}/receiving/project-receiving-receipt/delete-receipt/${project_id}/${project_receiving_id}/${project_receiving_receipt_id}`
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'receiving.service',
      'deleteReceiptInfoService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const createReceiptService = async (
  project_id,
  project_receiving_id,
  payload
) => {
  try {
    const res = await axios.post(
      `${URL_CONSTANTS?.API?.BASE_URL}/receiving/project-receiving-receipt/create-receipt/${project_id}/${project_receiving_id}`,
      payload
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'receiving.service',
      'createReceiptService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const updateReceiptService = async (
  project_id,
  project_receiving_id,
  project_receiving_receipt_id,
  payload
) => {
  try {
    const res = await axios.patch(
      `${URL_CONSTANTS?.API?.BASE_URL}/receiving/project-receiving-receipt/update-receipt/${project_id}/${project_receiving_id}/${project_receiving_receipt_id}`,
      payload
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'receiving.service',
      'updateReceiptService',
      e && e.message ? e.message : e
    );
    return {};
  }
};
