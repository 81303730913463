export const RECEIPT_TYPE_RECEIVING = [
  { label: 'Delivery', value: 1 },
  { label: 'Pickup', value: 2 },
  { label: 'Return', value: 3 },
];

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
];
