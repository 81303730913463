import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';

import { setNewSchedulerComponent } from '../../../../redux/slices/new-scheduler.slice';

const InstallersListFilter = () => {
  const dispatch = useDispatch();
  const { selectedInstallers } = useSelector(state => state.newScheduler);
  const { technicians } = useSelector(state => state.technicians);
  const selectedInstallersList = Array.isArray(technicians)
    ? technicians?.map(item => {
        return {
          name: `${item?.first_name} ${item?.last_name}`,
          id: item?.installer_id,
          installerUserId: item?.user_id,
        };
      })
    : [];
  return (
    <div className="flex flex-column mt-3">
      <MultiSelect
        className="w-full"
        value={selectedInstallers}
        options={selectedInstallersList || []}
        onChange={event => {
          const selectedInstallersList = event?.target?.value;
          const isSelectAllInstallers =
            selectedInstallersList?.length === technicians?.length;
          dispatch(
            setNewSchedulerComponent({
              selectedInstallers: selectedInstallersList,
              isSelectAllInstallers: isSelectAllInstallers,
            })
          );
        }}
        optionLabel="name"
        dataKey="id"
        placeholder="Filter Technicians"
        display="chip"
        filter
        showSelectAll={false}
      />
    </div>
  );
};

export default InstallersListFilter;
