import apiService from '../../services/api.service';

export const getCategoryList = async () => {
  try {
    const response = await apiService.get(`/projects/master/project-category`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getSearchProjectWorkRoomList = async ({
  searchValue = '',
  setWorkRoomLoader = () => false,
} = {}) => {
  try {
    setWorkRoomLoader(true);
    const response = await apiService.get(`/system/workroom/list?searchString=${searchValue}`);
    return response;
  } catch (error) {
    console.error(`Error in fetching workroom list : ${error}`);
  } finally {
    setWorkRoomLoader(false);
  }
};

export const getSearchStoreList = async ({
  searchValue = '',
  setStoreLoader = () => false,
} = {}) => {
  try {
    setStoreLoader(true);
    const response = await apiService.get(`/stores?searchString=${searchValue}&offset=0&limit=10`);

    return response;
  } catch (error) {
    console.error(`Error in fetching store list : ${error}`);
  } finally {
    setStoreLoader(false);
  }
};

export const getUserTypesList = async ({ searchString = '' } = {}) => {
  try {
    const userTypeUrl = searchString
      ? `/system/user-types?searchString=${searchString}`
      : `/system/user-types`;
    const response = await apiService.get(userTypeUrl);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
