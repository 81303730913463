// **** React Imports ****
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import useToken from '../../../hooks/useToken';
import PdfViewerComponentNew from '../../PSPDF/PdfViewerComponentNew';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

// **** Services *****
import { URL_CONSTANTS } from '../../../constants/urlConstants';

import {
  getSourceFields,
  getTemplateById,
  saveTemplate,
  getDocumentAnnotations,
  updateTemplate,
  updateDocumentOpen,
} from './Templates.service';

// **** Styles/Images/Icons ****
import '../../../pdfviewerpage.css';
import ShowCheckbox from './ShowCheckbox';
import { openDocumentInfo } from '../../../constants';

//pdfviewer toolbar items
let toolbarItems = [];

const ViewEditPdfTemplate = () => {
  const { templateId } = useParams();
  const urlRef = useRef();
  const action = window.location.pathname.split('/')[3];
  const edit = action === 'view' ? false : true;
  if (edit) {
    toolbarItems = [
      { type: 'sidebar-annotations' },
      { type: 'pager' },
      { type: 'pan' },
      { type: 'zoom-out' },
      { type: 'zoom-in' },
      { type: 'zoom-mode' },
      { type: 'spacer' },
      { type: 'text' },
      { type: 'form-creator' },
      { type: 'image' },
      { type: 'search' },
      { type: 'debug' },
      { type: 'export-pdf' },
    ];
  } else {
    toolbarItems = [
      { type: 'sidebar-annotations' },
      { type: 'pager' },
      { type: 'pan' },
      { type: 'zoom-out' },
      { type: 'zoom-in' },
      { type: 'zoom-mode' },
      { type: 'spacer' },
      { type: 'debug' },
      { type: 'export-pdf' },
    ];
  }

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const refferer = queryParameters.get('refferer');
  const reffererId = queryParameters.get('reffererid') || null;

  const history = useHistory();
  const toastRef = useRef(null);
  function setAlert(severity, title, message) {
    toastRef?.current?.show({
      severity: severity,
      summary: title,
      detail: message,
      life: 3000,
    });
  }

  const [templateData, setTemplateData] = useState();
  const [sourceFieldOptions, setSourceFieldOptions] = useState(null);
  const [annotationData, setAnnotationData] = useState(null);

  const [tempItemsData, setTempItemsData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [, setOpenWidget] = useState(false);
  const { accessToken } = useToken();
  let pdfViewerRef = useRef(null);

  //Source Fields are Loaded Here
  useEffect(() => {
    getSourceFields(setLoading, setSourceFieldOptions);
  }, []);

  useEffect(() => {
    getTemplateById(
      templateId,
      setLoading,
      setAlert,
      setTemplateData,
      setTempItemsData,
      setAnnotationData
    );
  }, [templateId, action]);

  const updateOpenedDocumentCount = async () => {
    await updateDocumentOpen([
      { ...openDocumentInfo, template_id: templateId },
    ]);
  };

  useEffect(() => {
    updateOpenedDocumentCount();
  }, [templateId]);

  const locationRedirector = () => {
    if (refferer) {
      switch (refferer) {
        case 'project':
          history.push(`/project/view/${reffererId}/`);
          break;

        default:
          history.push('/admin/documentcenter/');
          break;
      }
    } else {
      history.push('/admin/documentcenter/');
    }
  };

  const getTemplateUrl = () => {
    if (templateData) {
      let urlArguments = 'toolbar=0';
      let url =
        action === 'view'
          ? `${
              URL_CONSTANTS.API.BASE_URL
            }/documents/templates/${templateId}/preview?token=${accessToken}&client_id=${localStorage.getItem(
              'client_id'
            )}#${urlArguments}`
          : templateData.template_aws_url + `#${urlArguments}`;
      urlRef.current = url;
      return url;
    }
  };

  const setPdfViewerRef = refObj => {
    if (refObj) {
      pdfViewerRef.current = refObj;
    }
  };

  const saveButtonClickHandler = async () => {
    const pdfData = await pdfViewerRef.current.exportPDFData();
    if (!pdfData) {
      setAlert('error', 'Operation Failed', 'Unable to get pdf data');
      return;
    }

    const duplicateAnnotations = await pdfViewerRef.current.findDuplicates();
    pdfData.duplicates = [...duplicateAnnotations];

    const buffer = await pdfViewerRef.current.exportPDFBuffer();
    const blob = new Blob([buffer], { type: 'application/pdf' });
    const updateTemplateObj = {
      design_config: null,
      design_config_new: pdfData,
      fileBlob: blob,
    };

    const updateTemplateApiStatus = await updateTemplate(
      templateId,
      updateTemplateObj,
      setLoading,
      setAlert,
      history,
      templateData.template_file
    );
    if (updateTemplateApiStatus) {
      setTimeout(() => {
        locationRedirector();
      }, 2200);
    }
  };

  const closeButtonClickHandler = () => {
    locationRedirector();
  };

  const editButtonClickHandler = () => {
    history.push(`/admin/documentcenter/edit/${templateId}`);
  };

  return (
    <>
      <Toast ref={toastRef} position="center" />
      <div className="document__editor__header">
        <div>
          <h2 className="document__editor__header__title">Document Template</h2>
          <h1 className="document__editor__header__name">
            {templateData ? templateData.template_name : ''}
          </h1>
        </div>

        <div className="document__editor__header__buttons">
          {edit && templateData && (
            <ShowCheckbox templateData={templateData} setAlert={setAlert} />
          )}
          <Button
            size="small"
            label="Back"
            icon="pi pi-chevron-left"
            severity="primary"
            outlined
            onClick={closeButtonClickHandler}
          />
          {edit && (
            <>
              <Button
                label="Save"
                size="small"
                icon="pi pi-check"
                severity="primary"
                disabled={loading}
                onClick={saveButtonClickHandler}
              />
            </>
          )}
          {!edit &&
            checkPermission(permissions?.documentCenter?.editDocTemplate) &&
            templateData &&
            parseInt(templateData.is_custom) === 1 && (
              <Button
                size="small"
                label="Edit"
                icon="pi pi-file-edit"
                disabled={loading}
                onClick={editButtonClickHandler}
              />
            )}
        </div>
      </div>
      <div className="PDF-viewer" style={{ width: '100%', height: '100vh' }}>
        {loading && (
          <div
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ProgressSpinner />
          </div>
        )}

        {!loading && templateData && annotationData && (
          <PdfViewerComponentNew
            toolbarItems={toolbarItems}
            setParentRef={setPdfViewerRef}
            fieldOptions={sourceFieldOptions}
            document={getTemplateUrl()}
            readOnly={!edit}
          />
        )}
      </div>
    </>
  );
};

export default ViewEditPdfTemplate;
