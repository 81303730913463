import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, Redirect, useParams } from 'react-router-dom';
import { Grid, CircularProgress, IconButton } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';

import PFDialog from '../../shared/PFPrime/PFDialog';
import PageHeader from '../../shared/PageHeader/PageHeader';
import WidgetTableRenderer from '../Widget/components/WidgetTableRenderer';
import {
  getTableQuery,
  prepareQueryWithFilters,
} from '../Widget/helpers/query';
import {
  getQueryResult,
  getFilterDetails,
  getProjectDetails,
} from '../Widget/service/widget.service';
import { addDefaultCondition } from '../Dashboards/Widgets/utils';
import {
  getLoginUserId,
  formatDateTime,
  formatTimeHM,
  removeLinkSession,
} from '../../../utils/Helpers';
import TaskManagement from '../../TaskManagement/TaskManagement';
import WidgetGroupedTableRenderer from '../Widget/components/WidgetGroupedTableRenderer';
import { setPageHeaderComponent } from '../../../redux/slices/page-header.slice';
import WidgetLoader from '../../shared/Loader/WidgetLoader';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import {
  INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID,
  INVENTORY_ADJUSTMENT_OBJECT_ID,
  INVENTORY_ADJUSTMENT_PROJECT_TABLE,
  INVENTORY_ADJUSTMENT_TABLE,
  INVENTORY_ADJUSTMENT_TYPES,
  INVENTORY_ADJUSTMENT_TYPE_PROJECT,
  INVENTORY_ADJUSTMENT_TYPE,
} from '../Widget/constant/constant';
import {
  adjustmentDispatch,
  adjustmentUndoDispatch,
  createAdjustment,
  updateAdjustment,
  getInventoryById,
} from '../../ProjectManagement/Inventory/services/adjustment.services';
import { removeSpaces } from '../../../utils/string.helper';

import { useStyles } from './DashboardList.styles';
import ExportWidgetTableData from './components/ExportWidgetTableData';
import { SESSION_LINKS } from './constants/constants';
import { getRedirectUrl } from './Widgets/WidgetRoutes';
import ViewEditReceiveInventory from './components/ViewEditReceiveInventory';

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  control?.style ? (control.style.display = 'block') : '';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const typeOfProject = 'projectType';

const ReportDetails = () => {
  const classes = useStyles();
  const { widgetDetails } = useSelector(state => state.pageHeader);
  const activeDashboard = widgetDetails.activeDashboard;
  const { widgetId } = useParams();
  const activeWidget = widgetDetails[widgetId]?.activeWidget;
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [inventoryLoading, setInventoryLoading] = useState(false);

  let widget = structuredClone(activeWidget?.widgetTemplate);
  const installerId = localStorage.getItem('installer_id');
  const state = structuredClone(activeWidget);

  const [region, setRegion] = React.useState(state?.region || []);
  const [storeNumber, setStoreNumber] = React.useState(
    state?.storeNumber || []
  );
  const [district, setDistrict] = React.useState(state?.district || []);
  const [category, setCategory] = React.useState(state?.category || []);
  const [source, setSource] = React.useState(state?.source || []);
  const [projectType, setProjectType] = React.useState(
    state?.projectType || []
  );
  const [installers, setInstallers] = React.useState(state?.installers || []);
  const [status, setStatus] = React.useState(state?.status || []);
  const [workroom, setWorkroom] = React.useState(state?.workroom || []);
  const [installer, setInstaller] = React.useState([]);
  const [scheduleDate, setScheduleDate] = React.useState(
    state?.scheduleDate || { start: null, end: null }
  );
  const [completionDate, setCompletionDate] = React.useState(
    state?.completionDate || { start: null, end: null }
  );
  const [checkDate, setCheckDate] = React.useState(
    state?.checkDate || { start: null, end: null }
  );
  const [dateSold, setDateSold] = React.useState(
    state?.dateSold || { start: null, end: null }
  );
  const [clearFilters, setClearFilters] = React.useState(false);
  const [homeFilters, setHomeFilters] = React.useState(
    state?.homeFilters || []
  );
  const [homeFilterDateDimension, setHomeFilterDateDimension] = React.useState(
    state?.homeFilterDateDimension || []
  );
  const [widgetFilterQuery, setWidgetFilterQuery] = React.useState([]);
  const [applyFilters, setApplyFilters] = React.useState(false);

  const [loading, setLoading] = useState(true);
  const [labelDataLoading, setLabelDataLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [tablePagination, setTablePagination] = useState({
    filters: null,
  });
  const [paginator, setPaginator] = useState({
    first: 0,
    currentPage: 0,
    perPage: widget?.report_group_by ? 10 : 25,
  });
  const [orderBy, setOrderBy] = useState({
    columns: '',
    direction: 1,
  });
  const [taskProjectId, setTaskProjectId] = useState('');
  const [tableName, setTableName] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [filterDetails, setFilterDetails] = useState(null);
  const [addViewEditDashboardKey, setAddViewEditDashboardKey] = useState(true);
  const history = useHistory();
  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const [refereshList, setRefereshList] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [projectDetails, setProjectDetails] = useState({});
  const [tabGrpDep, setTabGrpDep] = useState(false);
  const [dashboardLogDetails, setDashboardLogDetails] = useState({});
  let viewLinks = [];
  let editLinks = [];
  const [urlLinksView, setUrlLinksView] = useState([]);
  const [urlLinksEdit, setUrlLinksEdit] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedAdjustmentId, setSelectedAdjustmentId] = useState(null);

  useEffect(() => {
    setUrlLinksView([]);
    setUrlLinksEdit([]);
  }, [paginator?.currentPage]);

  const [widgetRetryCount, setWidgetRetryCount] = useState(0);
  const schedulingBreadcrumb = [
    {
      text: activeDashboard?.name,
      link: '/dashboard',
    },
    {
      text: widget?.widget_name,
    },
  ];
  const [searchQuery] = React.useState({ limit: 100, offset: 0 });
  const globalFilters = useSelector(state => state.globalFilters);
  const memoFilterState = useMemo(
    () => ({
      defaultFilter: globalFilters?.defaultFilter?.length,
      stores: globalFilters?.stores?.length,
      projectTypes: globalFilters?.projectTypes?.length,
      projectCategories: globalFilters?.projectCategories?.length,
      projectWorkrooms: globalFilters?.projectWorkrooms?.length,
      projectStatus: globalFilters?.projectStatus?.length,
      districtId: globalFilters?.districtId?.length,
      techniciansVal: globalFilters?.techniciansVal?.length,
      dateScheduledStart: globalFilters?.dateScheduledFilterStart,
      dateScheduledEnd: globalFilters?.dateScheduledFilterEnd,
      dateCompletionStart: globalFilters?.dateCompletionFilterStart,
      dateCompletionEnd: globalFilters?.dateCompletionFilterEnd,
      checkDateFrom: globalFilters?.checkDateFromFilter,
      checkDateTo: globalFilters?.checkDateToFilter,
      dateSoldFrom: globalFilters?.dateSoldFromFilter,
      dateSoldTo: globalFilters?.dateSoldToFilter,
    }),
    [
      globalFilters?.defaultFilter,
      globalFilters?.stores,
      globalFilters?.projectTypes,
      globalFilters?.projectCategories,
      globalFilters?.projectWorkrooms,
      globalFilters?.projectStatus,
      globalFilters?.districtId,
      globalFilters?.techniciansVal,
      globalFilters?.dateScheduledFilterStart,
      globalFilters?.dateScheduledFilterEnd,
      globalFilters?.dateCompletionFilterStart,
      globalFilters?.dateCompletionFilterEnd,
      globalFilters?.checkDateFromFilter,
      globalFilters?.checkDateToFilter,
      globalFilters?.dateSoldFromFilter,
      globalFilters?.dateSoldToFilter,
    ]
  );
  React.useEffect(() => {
    if (axios.defaults.headers.common['client_id']) {
      const appliedFilters = prepareDashboardFilters(globalFilters);
      setHomeFilters(appliedFilters?.[0]);
      setHomeFilterDateDimension(appliedFilters?.[1]);
      setApplyFilters(true);
      refreshDashboardList();
    }
  }, [memoFilterState]);

  useEffect(() => {
    if (isPageLoaded) {
      Promise.all([
        fetchTableData(tablePagination, filterDetails, orderBy, paginator),
      ]).then(() => setLoading(false));
    }
  }, [addViewEditDashboardKey]);

  useEffect(() => {
    const fetchData = async () => {
      const filterDetailsResponse = await getFilterDetails();
      setFilterDetails(filterDetailsResponse);
      Promise.all([
        fetchTableData(tablePagination, filterDetailsResponse),
      ]).then(() => {
        setIsPageLoaded(true);
        setLoading(false);
      });
    };
    fetchData();
  }, [tablePagination, applyFilters, clearFilters, activeWidget]);

  useEffect(() => {
    if (labelDataLoading) {
      removeLinkSession();
    }
  }, [labelDataLoading]);
  useEffect(() => {
    return () => {
      removeLinkSession();
    };
  }, []);

  useEffect(() => {
    if (isPageLoaded) {
      Promise.all([
        fetchTableData(tablePagination, filterDetails, orderBy, paginator),
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [orderBy, paginator]);

  const refreshDashboardList = () => {
    setAddViewEditDashboardKey(!addViewEditDashboardKey);
  };
  const getQuery = filterDetailsResponse => {
    const tableQuery = getTableQuery(widget);
    if ('user-filter' == widget.widget_filter_type && filterDetailsResponse) {
      tableQuery.filters.push(filterDetailsResponse.userFilterCondition);
      dashboardLogDetails.filter_type = 'user-filter';
      dashboardLogDetails.filter_condition = filterDetails?.userFilterCondition;
    } else if (
      'company-filter' == widget.widget_filter_type &&
      filterDetailsResponse
    ) {
      tableQuery.filters.push(filterDetailsResponse.companyFilterCondition);
      dashboardLogDetails.filter_type = 'company-filter';
      dashboardLogDetails.filter_condition =
        filterDetails?.companyFilterCondition;
    } else if (
      'assigned-to-self' == widget.widget_filter_type &&
      filterDetailsResponse
    ) {
      tableQuery.filters.push({
        member: 'UserMeta.user_id',
        values: [getLoginUserId()],
        operator: 'equals',
      });
      dashboardLogDetails.filter_type = 'assigned-to-self';
      dashboardLogDetails.filter_condition = [
        {
          member: 'UserMeta.user_id',
          values: [getLoginUserId()],
          operator: 'equals',
        },
      ];
    } else if (
      'user-and-assigned-to-self' == widget.widget_filter_type &&
      filterDetailsResponse
    ) {
      const userAndAssignToSelfFilter = {
        or: [
          {
            member: 'UserMeta.user_id',
            values: [getLoginUserId()],
            operator: 'equals',
          },
        ],
      };
      dashboardLogDetails.filter_type = 'user-and-assigned-to-self';
      if (filterDetailsResponse?.userFilterCondition) {
        userAndAssignToSelfFilter.or.push(
          filterDetailsResponse.userFilterCondition
        );
      }
      dashboardLogDetails.filter_condition = userAndAssignToSelfFilter;
      tableQuery.filters.push(userAndAssignToSelfFilter);
    }

    if (
      widget?.custom_properties?.reportTablePrimaryKey &&
      !widget?.report_group_by
    ) {
      tableQuery.dimensions.push(
        widget.custom_properties.reportTablePrimaryKey
      );
      if (widget?.custom_properties?.reportTableSecondaryKey) {
        tableQuery.dimensions.push(
          widget?.custom_properties?.reportTableSecondaryKey
        );
      }
      if (
        INVENTORY_ADJUSTMENT_PROJECT_TABLE ===
          widget?.custom_properties?.selectedReportTable &&
        tableQuery?.dimensions?.includes(INVENTORY_ADJUSTMENT_TYPE_PROJECT)
      ) {
        tableQuery.dimensions.push(INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID);
      }
      if (
        INVENTORY_ADJUSTMENT_TABLE ===
          widget?.custom_properties?.selectedReportTable?.replace(/\s/g, '') &&
        tableQuery?.dimensions?.includes(INVENTORY_ADJUSTMENT_TYPE)
      ) {
        tableQuery.dimensions.push(INVENTORY_ADJUSTMENT_OBJECT_ID);
      }
    }

    if (addDefaultCondition[widget?.custom_properties?.selectedReportTable]) {
      tableQuery.dimensions.push(
        ...addDefaultCondition[widget?.custom_properties?.selectedReportTable]
      );
    }
    dashboardLogDetails.globalFilters = [];
    if (homeFilterDateDimension && homeFilterDateDimension.length) {
      homeFilterDateDimension.forEach(item =>
        dashboardLogDetails.globalFilters.push(item.dimension)
      );
      homeFilterDateDimension.forEach(timeRecord => {
        tableQuery?.timeDimensions?.push({
          dimension: timeRecord.dimension,
          dateRange: timeRecord.dateRange,
        });
      });
    }

    if (homeFilters && homeFilters.length) {
      homeFilters.forEach(item =>
        dashboardLogDetails.globalFilters.push(item.member)
      );
      tableQuery.filters.push(...homeFilters);
    }
    setDashboardLogDetails({ ...dashboardLogDetails });
    return tableQuery;
  };

  const fetchTableData = async (
    params = tablePagination,
    filterDetailsResponse = filterDetails,
    orderByConfiguration = orderBy,
    paginatorConfiguration = paginator
  ) => {
    try {
      setTabGrpDep(!tabGrpDep);
      setLabelDataLoading(true);
      const tableQuery = getQuery(filterDetailsResponse);
      tableQuery.total = true;
      tableQuery.limit = paginatorConfiguration.perPage;
      tableQuery.offset =
        paginatorConfiguration.currentPage * paginatorConfiguration.perPage;
      if (orderByConfiguration?.columns && orderByConfiguration?.direction) {
        tableQuery.order = [
          [
            orderByConfiguration?.columns,
            orderByConfiguration?.direction > 0 ? 'asc' : 'desc',
          ],
        ];
      }

      if (params.filters && Object.keys(params.filters)?.length) {
        const tempFilters = [];
        const tempTimeDimension = [];
        Object.keys(params.filters).forEach(key => {
          if (chartData?.annotation?.dimensions?.[key]?.type == 'time') {
            if (params?.filters?.[key]) {
              params.filters?.[key].constraints.forEach(filterKey => {
                if (filterKey?.value && filterKey.value?.length) {
                  tempTimeDimension.push({
                    dimension: key,
                    dateRange: [
                      moment(filterKey.value[0]).format('Y-M-D'),
                      moment(filterKey.value[1]).format('Y-M-D'),
                    ],
                  });
                }
              });
            }
          } else if (params.filters?.[key]) {
            params.filters?.[key].constraints.forEach(filterKey => {
              if (filterKey.value) {
                tempFilters.push({
                  member: key,
                  values: [filterKey.value?.toString()],
                  operator: filterKey.matchMode,
                });
              }
            });
          }
        });
        if (tempFilters.length) {
          tableQuery.filters.push(...tempFilters);
        }
        if (tempTimeDimension) {
          tableQuery.timeDimensions.push(...tempTimeDimension);
        }
      }
      dashboardLogDetails.chartQuery = tableQuery;
      dashboardLogDetails.widget_name = widget.widget_name;
      dashboardLogDetails.widget_template_id = widget.widget_template_id;
      dashboardLogDetails.widget_type = 'table';
      dashboardLogDetails.widget_retry_count = widgetRetryCount;
      setDashboardLogDetails({ ...dashboardLogDetails });
      const response = await getQueryResult(tableQuery);
      if (response.error) {
        setWidgetRetryCount(widgetRetryCount + 1);
        fetchTableData(params, filterDetailsResponse, orderBy, paginator);
      } else {
        setChartData(response);
        setLabelDataLoading(false);
        setTotalCount(response?.total);
      }
    } catch (error) {
      setTimeout(() => {
        fetchTableData(params, filterDetailsResponse, orderBy, paginator);
      }, 1000);
      console.error('Failed to fetch report details :: ', error);
      setChartData([]);
      setLabelDataLoading(false);
      setTotalCount(0);
    }
  };
  const onTableChangeHandler = params => {
    setTablePagination(params);
  };
  const handleApplyFilterClick = () => {
    setApplyFilters(!applyFilters);
  };

  const handleInventoryDispatch = async id => {
    try {
      setInventoryLoading(true);
      const dispatchResponse = await adjustmentDispatch(id);
      if (dispatchResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Adjustment Updated successfully',
          life: 3000,
        });
        setTimeout(refreshDashboardList, 2000);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Something went wrong.',
          life: 2000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong.',
        life: 2000,
      });
    } finally {
      setInventoryLoading(false);
    }
  };

  const handleInventoryUndoDispatch = async id => {
    try {
      setInventoryLoading(true);
      const undoDispatchResponse = await adjustmentUndoDispatch(id);
      if (undoDispatchResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Adjustment Updated successfully',
          life: 3000,
        });
        setTimeout(refreshDashboardList, 2000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setInventoryLoading(false);
    }
  };

  const handleInventoryOnOrder = async id => {
    try {
      toast.current.show({
        severity: 'info',
        summary: '',
        detail: 'Updating adjustment.',
        life: 2000,
      });
      setInventoryLoading(true);
      const rowData = await getInventoryById(id);
      const createPayload = {
        productId: rowData.productId,
        date: new Date(rowData.date),
        adjustmentType: 'Add',
        warehouseId: rowData.warehouseId,
        quantityToAdjust: rowData.quantityToAdjust,
        unit: rowData.unit,
        noteText: rowData.noteText,
      };
      const updatePayload = {
        _id: rowData._id,
        productId: rowData.productId,
        date: new Date(rowData.date),
        adjustmentType: 'Allocated',
        warehouseId: rowData.warehouseId,
        quantityToAdjust: rowData.quantityToAdjust,
        unit: rowData.unit,
        noteText: rowData.noteText,
        convertedQuantityToAdjust: rowData.convertedQuantityToAdjust,
        purchasePricePerUnit: rowData.purchasePricePerUnit,
        salesPricePerUnit: rowData.salesPricePerUnit,
        projectId: rowData.projectId,
        projectNumber: rowData.projectNumber,
      };
      await createAdjustment(createPayload);
      const dispatchResponse = await updateAdjustment(updatePayload);
      if (dispatchResponse) {
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Adjustment Updated successfully',
          life: 3000,
        });
        setTimeout(refreshDashboardList, 2000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setInventoryLoading(false);
    }
  };

  if (!widget) {
    return <Redirect to="/dashboard" />;
  }
  const handleGroupByExport = () => {
    let widgetCopy = structuredClone(activeWidget?.widgetTemplate);
    if (widgetCopy.backToGroupBy) {
      widgetCopy.table_configuration =
        widgetCopy.backToGroupBy.table_configuration;
      widgetCopy.report_group_by = widgetCopy.backToGroupBy.report_group_by;
      delete widgetCopy.backToGroupBy;
    } else {
      widgetCopy.table_configuration = widgetCopy.table_configuration.filter(
        e => !e?.columnTypeAction
      );
      widgetCopy.backToGroupBy = {
        table_configuration: [...widgetCopy.table_configuration],
        report_group_by: widgetCopy.report_group_by,
      };
      if (
        !widgetCopy.table_configuration.some(
          e => e.measureName === widget?.report_group_by?.dimension
        )
      ) {
        widgetCopy.table_configuration.unshift({
          alice: widget?.report_group_by?.alice,
          measureName: widget?.report_group_by?.dimension,
        });
      }
      delete widgetCopy.report_group_by;
    }
    dispatch(
      setPageHeaderComponent({
        activeWidget: {
          ...activeWidget,
          widgetTemplate: widgetCopy,
        },
      })
    );
  };
  const excelData = [];
  const headingKeys = [];
  const headings = [];
  const tableConfiguration = [];
  widget.table_configuration.forEach(record => {
    if (!headingKeys.includes(record.measureName)) {
      headingKeys.push(record.measureName);
      tableConfiguration.push(record);
    }
    if (!headings.includes(record.alice)) headings.push(record.alice);
  });
  excelData.push(headings);

  chartData?.data?.forEach(record => {
    const excelRecord = [];
    headingKeys.forEach(key => {
      const annotation = chartData?.annotation?.dimensions;

      if (annotation[key]?.type === 'time' && record[key]) {
        if (annotation[key]?.meta?.isDateTimeField) {
          // add Z as timezone only when timezone is not present
          excelRecord.push(formatDateTime(`${record[key]}Z`));
        } else if (annotation[key]?.meta?.isTimeField) {
          excelRecord.push(formatTimeHM(`${record[key]}Z`));
        } else {
          excelRecord.push(moment(`${record[key]}`).format('MM-DD-YYYY'));
        }
      } else {
        const formatter = annotation[key]?.meta?.format
          ? eval(annotation[key]?.meta?.format)
          : undefined;

        if (formatter) {
          excelRecord.push(formatter(record[key]));
        } else {
          excelRecord.push(record[key]);
        }
      }
    });
    excelData.push(excelRecord);
  });

  const handleReceiveClick = adjustment_object_id => {
    setSelectedAdjustmentId(adjustment_object_id);
    setInventoryLoading(true);
    setVisible(true);
  };

  const actionButtons = {
    alice: '',
    measureName: '',
    columnTypeAction: true,
    options: {
      sort: false,
      filter: false,

      customBodyRenderLite: (dataIndex, rowIndex) => {
        let primaryKeyColumn = '';
        let secondaryKeyColumn = '';
        if (
          widget?.report_group_by &&
          widget?.custom_properties?.reportTablePrimaryKey
        ) {
          primaryKeyColumn = widget?.custom_properties?.reportTablePrimaryKey;
          secondaryKeyColumn =
            widget?.custom_properties?.reportTableSecondaryKey;
        } else {
          Object.keys(chartData?.annotation?.dimensions)?.forEach(key => {
            if (chartData?.annotation.dimensions[key]?.meta?.primaryKey) {
              primaryKeyColumn = key;
            }
            if (chartData?.annotation?.dimensions[key]?.meta?.secondaryKey) {
              secondaryKeyColumn = key;
            }
          });
        }
        let redirectDetails = null;
        let isInventoryAdjustmentOnProject;
        let isInventoryAdjustment;
        let adjustmentType;
        let adjustment_object_id;
        let secondaryObject = null;
        if (primaryKeyColumn) {
          const tableName = primaryKeyColumn?.split('.')[0];
          const id = dataIndex[primaryKeyColumn];
          if (secondaryKeyColumn) {
            const secondaryKeyParts = secondaryKeyColumn.split('.');
            if (
              Array.isArray(secondaryKeyParts) &&
              secondaryKeyParts?.length > 0 &&
              dataIndex[secondaryKeyColumn] !== undefined
            ) {
              secondaryObject = {
                secondaryTable: secondaryKeyParts[0],
                secondaryId: dataIndex[secondaryKeyColumn],
              };
            }
          }
          isInventoryAdjustmentOnProject =
            INVENTORY_ADJUSTMENT_PROJECT_TABLE === tableName;
          isInventoryAdjustment = INVENTORY_ADJUSTMENT_TABLE === tableName;
          adjustmentType = dataIndex?.[`${tableName}.adjustment_type`];
          adjustment_object_id =
            dataIndex?.[`${tableName}.adjustment_object_id`];
          if (
            isInventoryAdjustment &&
            adjustmentType === INVENTORY_ADJUSTMENT_TYPES.ALLOCATED
          ) {
            const adjustment_object_id =
              dataIndex?.[INVENTORY_ADJUSTMENT_OBJECT_ID];
            return (
              <div className="flex justify-content-center gap-2 m-2">
                <Button
                  className="border-none p-button-primary"
                  icon="pi pi-refresh"
                  text
                  outlined
                  severity="primary"
                  tooltip="Dispatch"
                  onClick={() => handleInventoryDispatch(adjustment_object_id)}
                  disabled={inventoryLoading}
                ></Button>
              </div>
            );
          }
          if (
            isInventoryAdjustment &&
            adjustmentType === INVENTORY_ADJUSTMENT_TYPES.DISPATCHED
          ) {
            const adjustment_object_id =
              dataIndex?.[INVENTORY_ADJUSTMENT_OBJECT_ID];
            return (
              <div className="flex justify-content-center gap-2 m-2">
                <Button
                  className="border-none p-button-primary"
                  icon="pi pi-undo"
                  text
                  outlined
                  severity="primary"
                  tooltip="Return to Inventory"
                  onClick={() =>
                    handleInventoryUndoDispatch(adjustment_object_id)
                  }
                  disabled={inventoryLoading}
                ></Button>
              </div>
            );
          }

          if (
            isInventoryAdjustment &&
            removeSpaces(adjustmentType) === INVENTORY_ADJUSTMENT_TYPES.ON_ORDER
          ) {
            const adjustment_object_id =
              dataIndex?.[INVENTORY_ADJUSTMENT_OBJECT_ID];
            return (
              <div className="flex justify-content-center gap-2 m-2">
                <Button
                  className="border-none p-button-primary"
                  icon="pi pi-undo"
                  text
                  outlined
                  severity="primary"
                  tooltip="Receive"
                  onClick={() => handleInventoryOnOrder(adjustment_object_id)}
                  disabled={inventoryLoading}
                ></Button>
              </div>
            );
          }

          redirectDetails = getRedirectUrl(tableName, id, {
            secondaryObject,
            customerId: (function () {
              if (addDefaultCondition?.[tableName]?.[0]) {
                return dataIndex[addDefaultCondition[tableName][0]];
              }
              return undefined;
            })(),
            opportunityId: (function () {
              if (addDefaultCondition?.[tableName]?.[1]) {
                return dataIndex[addDefaultCondition[tableName][1]];
              }
              return undefined;
            })(),
          });
        } else {
          return null;
        }
        if (!redirectDetails) return null;

        let viewJson = JSON.parse(sessionStorage.getItem('viewLinks')) || [];
        viewJson.push(redirectDetails?.viewUrl);
        viewJson = [...new Set(viewJson)];
        sessionStorage.setItem('viewLinks', JSON.stringify(viewJson));

        let editJson = JSON.parse(sessionStorage.getItem('editLinks')) || [];
        editJson.push(redirectDetails?.editUrl);
        editJson = [...new Set(editJson)];
        sessionStorage.setItem('editLinks', JSON.stringify(editJson));

        return (
          <div className="flex  justify-content-center gap-2 m-2">
            {redirectDetails?.viewUrl && (
              <Link to={redirectDetails.viewUrl} className="text-color-900">
                <i className="pi pi-eye"></i>
              </Link>
            )}
            {redirectDetails?.editUrl && (
              <Link to={redirectDetails.editUrl} className="text-color-900">
                <i className="pi pi-pencil"></i>
              </Link>
            )}

            {redirectDetails?.task?.openTaskPopup && (
              <>
                <i
                  className="pi pi-book cursor-pointer text-color-900"
                  onClick={() => {
                    setTaskProjectId(redirectDetails?.task?.taskProjectId);
                    setTableName(redirectDetails?.task?.tableName);
                    setVisibleSidebar(true);
                  }}
                ></i>
              </>
            )}
            {isInventoryAdjustmentOnProject &&
              adjustmentType === INVENTORY_ADJUSTMENT_TYPES.ALLOCATED && (
                <>
                  <Tooltip target=".dispatch-icon" />
                  <i
                    className="pi pi-refresh cursor-pointer text-color-900 dispatch-icon"
                    onClick={() =>
                      handleInventoryDispatch(adjustment_object_id)
                    }
                    data-pr-tooltip="Dispatch"
                    data-pr-position="top"
                  ></i>
                </>
              )}
            {isInventoryAdjustmentOnProject &&
              adjustmentType === INVENTORY_ADJUSTMENT_TYPES.DISPATCHED && (
                <>
                  <Tooltip target=".undo-dispatch-icon" />
                  <i
                    className="pi pi-undo cursor-pointer text-color-900 undo-dispatch-icon"
                    onClick={() =>
                      handleInventoryUndoDispatch(adjustment_object_id)
                    }
                    data-pr-tooltip="Return to Inventory"
                    data-pr-position="top"
                  ></i>
                </>
              )}
            {isInventoryAdjustmentOnProject &&
              removeSpaces(adjustmentType) ===
                INVENTORY_ADJUSTMENT_TYPES.ON_ORDER && (
                <>
                  <Tooltip target=".undo-dispatch-icon" />
                  <i
                    className="pi pi-undo cursor-pointer text-color-900 undo-dispatch-icon"
                    onClick={() => handleReceiveClick(adjustment_object_id)}
                    data-pr-tooltip="Receive"
                    data-pr-position="top"
                  ></i>
                </>
              )}
          </div>
        );
      },
    },
  };
  tableConfiguration.unshift(actionButtons);
  if (widget?.report_group_by)
    widget.table_configuration.unshift(actionButtons);
  const setRowProps = (row, dataIndex, rowIndex) => {
    return {
      onMouseEnter: e => handleRowHover(e, row, rowIndex),
      onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
      onDoubleClick: () => {
        if (widget?.custom_properties?.selectedChartTable) {
          const redirectDetails = getRedirectUrl(
            widget?.custom_properties?.selectedChartTable,
            chartData.data[rowIndex][
              widget?.custom_properties?.reportTablePrimaryKey
            ],
            chartData?.data[rowIndex][
              widget?.custom_properties?.reportTableSecondaryKey
            ]
          );
          history.push(redirectDetails.viewUrl);
        }
      },
    };
  };

  const widgetProjectDetails = async () => {
    const response = await getProjectDetails(taskProjectId);
    setProjectDetails(response);
  };

  useEffect(() => {
    if (viewLinks.length > 0) {
      viewLinks = [...new Set(viewLinks)];
      sessionStorage.setItem('viewLinks', JSON.stringify(viewLinks));
    }
  }, [viewLinks]);

  useEffect(() => {
    if (editLinks.length > 0) {
      editLinks = [...new Set(editLinks)];
      sessionStorage.setItem('editLinks', JSON.stringify(editLinks));
    }
  }, [editLinks]);

  useEffect(() => {
    if (tableName === 'Project') {
      widgetProjectDetails();
    }
  }, [tableName, taskProjectId]);
  const customHeader = (
    <React.Fragment>
      <div className="grid pr-5 w-12 text-center">
        <div className="grid w-12 text-white m-0 text-center justify-content-center">
          <h2 className="m-0">Task Planner</h2>
        </div>
        {tableName === 'Project' ? (
          <div className="grid w-12 text-white m-0">
            <div className="col-12 md:col-4 pb-0">
              {projectDetails?.project_number ? (
                <>
                  Project Number:
                  <strong className="text-white">
                    {' '}
                    {projectDetails?.project_number}
                  </strong>
                </>
              ) : (
                ''
              )}
            </div>

            <div className="col-12 md:col-4 pb-0">
              {projectDetails?.status?.status ? (
                <>
                  Internal Status:
                  <strong className="text-white">
                    {' '}
                    {projectDetails?.status?.status}
                  </strong>
                </>
              ) : (
                ''
              )}
            </div>
            <div className="col-12 md:col-4 pb-0">
              {projectDetails?.project_type?.project_type ? (
                <>
                  Type:
                  <strong className="text-white">
                    {' '}
                    {projectDetails?.project_type?.project_type}
                  </strong>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );

  useEffect(() => {
    document.title = widget?.widget_name || 'ProjectsForce';
  }, []);
  let dashboardDetails = null;
  if (widgetDetails?.[widgetId]) {
    dashboardDetails = {
      dashboard_id: widgetDetails[widgetId]?.activeDashboard?.id,
      dashboard_name: widgetDetails[widgetId]?.activeDashboard?.name,
      dashboard_client_id: localStorage.getItem('client_id'),
      user_id: localStorage.getItem('user_id'),
      start_time: new Date().getTime(),
    };
  }

  return loading ? (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <div className="card flex justify-content-center">
          <PFTableLoader />
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Toast ref={toast} />
      <Grid container direction="row" spacing={2}>
        <Grid
          container
          item
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Grid item xs={12} lg={5}>
            <PageHeader
              pageTitle={widget.widget_name}
              breadCrumbArray={schedulingBreadcrumb}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={7}
            className="flex-wrap flex md:justify-content-end"
          >
            <Grid
              item
              classes={{ root: classes.actionButtonWrapper }}
              className="mt-2 lg:mt-0"
            >
              <Button
                severity="primary"
                size="small"
                variant="contained"
                onClick={() => history.goBack()}
              >
                Go Back
              </Button>
              <ExportWidgetTableData
                widget={widget}
                groupByHandler={handleGroupByExport}
                exportData={excelData}
                filename={`${widget?.widget_name}_${paginator?.currentPage + 1}_${moment().format(
                  'Y_M_D'
                )}`}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="bg-white p-3">
          <div
            style={
              labelDataLoading ? { opacity: '0.4', pointerEvents: 'none' } : {}
            }
          >
            {widget?.report_group_by ? (
              <>
                {labelDataLoading ? (
                  <WidgetLoader chart_type="table-report" />
                ) : (
                  <WidgetGroupedTableRenderer
                    widgetDetails={widget}
                    data={chartData?.data}
                    tablePagination={tablePagination}
                    fetchTableData={fetchTableData}
                    annotation={chartData?.annotation?.dimensions}
                    totalCount={totalCount}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    paginator={paginator}
                    setPaginator={setPaginator}
                    filters={homeFilters}
                    homeFilterDateDimension={homeFilterDateDimension}
                    tabGrpDep={tabGrpDep}
                    filterDetails={filterDetails}
                  />
                )}
              </>
            ) : (
              <WidgetTableRenderer
                table_configuration={tableConfiguration}
                data={chartData?.data || []}
                tablePagination={tablePagination}
                onTableChangeHandler={onTableChangeHandler}
                setRowProps={setRowProps}
                annotation={chartData?.annotation?.dimensions}
                totalCount={totalCount}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                paginator={paginator}
                setPaginator={setPaginator}
                dashboardLogDetails={dashboardLogDetails}
                dashboardDetails={dashboardDetails}
              />
            )}
          </div>
        </Grid>
      </Grid>

      <Sidebar
        baseZIndex="1000"
        visible={visibleSidebar}
        onHide={() => {
          setRefereshList(!refereshList);
          setVisibleSidebar(false);
        }}
        fullScreen
        className="task-management p-0"
        icons={customHeader}
        pt={{
          header: {
            className: 'justify-content-between bg-primary',
          },
          icons: {
            className: 'w-12',
          },
        }}
      >
        <TaskManagement
          typeOfProject={typeOfProject}
          taskProjectId={taskProjectId}
          tableName={tableName}
        />
      </Sidebar>
      {visible && selectedAdjustmentId && (
        <ViewEditReceiveInventory
          setVisible={setVisible}
          visible={visible}
          selectedAdjustmentId={selectedAdjustmentId}
          setInventoryLoading={setInventoryLoading}
          toast={toast}
          refreshDashboardList={refreshDashboardList}
          inventoryLoading={inventoryLoading}
        />
      )}
    </>
  );
};

export default ReportDetails;
