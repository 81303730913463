import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projectTypes: [],
  mapPin: false,
};

const projectTypesMasterSlice = createSlice({
  name: 'stateMaster',
  initialState: initialState,
  reducers: {
    setProjectTypes(state, { payload }) {
      state.projectTypes = payload;
    },
    setMapPin(state, { payload }) {
      state.mapPin = payload;
    },
  },
});

export const { setProjectTypes, setMapPin } = projectTypesMasterSlice.actions;

export const projectTypesReducer = projectTypesMasterSlice.reducer;
