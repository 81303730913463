import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import { PFDynamicDropdown } from '../../../shared/PFPrime/PFDynamicDropdown';
import { cleanedPayload } from '../../Shipping/helpers/helper';
import {
  binListService,
  createBinLocationService,
  createProjectReceivingService,
  getProjectReceivingService,
  updateProjectReceivingService,
} from '../services/receiving.service';
import { fetchWarehouseList } from '../../ProjectItemsNewUI/ProjectItemsService';
import { ReceivingContext } from '../ReceivingContainer';
import { YES_NO_OPTIONS } from '../constants/constants';
import { convertString } from '../helpers/helper';

export const Receiving = () => {
  const { receivingContextValue, setReceivingContextValue } =
    useContext(ReceivingContext);
  const [warehouse, setWarehouse] = useState([]);
  const [selectedBin, setSelectedBin] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);

  const wareHouseList = async () => {
    const response = await fetchWarehouseList();
    receivingContextValue?.updateContextValue({ warehouse: response || [] });
    setWarehouse(response);
  };

  const binList = async (event = null, setItems) => {
    const query = event?.query || '';
    const res = await binListService(query);
    if (res?.status) {
      setItems(res?.data?.length > 0 ? res?.data : []);
    }
  };

  const binCreate = async (name, setValue, setItems) => {
    const res = await createBinLocationService({ bin_location: name });
    if (res?.status) {
      setValue(res?.data);
      binList(res?.data?.bin_location, setItems);
      receivingContextValue?.toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Package location added successfully',
      });
    }
  };

  useEffect(() => {
    setFieldValue(
      'package_bin_location_id',
      selectedBin?.bin_location_id || ''
    );
  }, [selectedBin]);

  const storeReceiving = async payload => {
    if (receivingContextValue?.visibleMode?.visible === 'add') {
      const store = await createProjectReceivingService(
        receivingContextValue?.project_id,
        payload
      );

      if (store?.status) {
        projectReceivingGetApi();
        receivingContextValue?.toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Receiving added successfully',
        });
      }
    } else {
      const store = await updateProjectReceivingService(
        receivingContextValue?.project_id,
        receivingContextValue?.projectReceivingData?.project_receiving_id,
        payload
      );
      if (store?.status) {
        projectReceivingGetApi();
        receivingContextValue?.toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Receiving update successfully',
        });
      }
    }
  };

  const { handleSubmit, values, setFieldValue, setValues, handleChange } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ship_to_warehouse_id: '',
        total_package_shipped: '',
        total_package_received: '',
        package_bin_location_id: '',
        package_warehouse_status: null,
        inventory_location_warehouse_id: '',
        inventory_note: '',
      },

      validationSchema: Yup.object().shape({}),
      onSubmit: values => {
        storeReceiving(values);
      },
    });

  useEffect(() => {
    const isEmpty = Object.keys(cleanedPayload(values)).length === 0;
    setSaveDisabled(true);
    if (!isEmpty) {
      setSaveDisabled(false);
    }
  }, [values]);

  const projectReceivingGetApi = async () => {
    const res = await getProjectReceivingService(
      receivingContextValue?.project_id
    );

    if (res?.status && res?.data) {
      const {
        binLocation,
        client_id,
        inventoryLocationWarehouse,
        project_id,
        project_receiving_id,
        shipToWarehouse,
        ...rest
      } = res?.data;
      setValues(rest);
      receivingContextValue?.updateContextValue({
        projectReceivingData: {
          project_receiving_id: project_receiving_id,
          binLocation: binLocation || {},
        },
        visibleMode: {
          data: rest,
          visible: 'edit',
        },
      });
    }
  };

  useEffect(() => {
    wareHouseList();
  }, []);

  useEffect(() => {
    receivingContextValue?.project_id && projectReceivingGetApi();
  }, [receivingContextValue?.project_id]);

  return (
    <div className="grid">
      <div className="col-12 md:col-6 lg:col-6">
        <h3 className="text-lg">Delivery</h3>
      </div>
      <div className="col-12 md:col-6 lg:col-6">
        <PFButton
          label="Add Delivery"
          icon="pi pi-plus"
          iconPos="left"
          onClick={() => {
            receivingContextValue?.updateContextValue({
              visibleMode: {
                visible: 'add',
                data: {},
                disabled: false,
              },
            });
            receivingContextValue?.hide();
          }}
          severity="primary"
          style={{ float: 'right' }}
          disabled={
            receivingContextValue?.projectReceivingData?.project_receiving_id
              ? false
              : true
          }
        />
      </div>

      <div className="col-12 md:col-6 lg:col-3">
        <span className="p-float-label">
          <PFDropdown
            name="ship_to_warehouse_id"
            value={values?.ship_to_warehouse_id || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            options={warehouse}
            optionLabel="name"
            optionValue="master_warehouse_id"
            filter={true}
            placeholder="Ship To"
            showClear={true}
          />
          <label htmlFor="ship_to_warehouse_id">Ship to</label>
        </span>
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <span className="p-float-label">
          <PFInputNumber
            name="total_package_shipped"
            value={values?.total_package_shipped || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
          />
          <label htmlFor="total_package_shipped">Ship to Count</label>
        </span>
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <span className="p-float-label">
          <PFInputNumber
            name="total_package_received"
            value={values?.total_package_received || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
          />
          <label htmlFor="total_package_received">
            Total # of packages Received
          </label>
        </span>
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <PFDynamicDropdown
          listCallback={binList}
          field="bin_location"
          storeCallback={binCreate}
          defaultValue={
            receivingContextValue?.projectReceivingData?.binLocation || ''
          }
          label="Package Location"
          selectedData={setSelectedBin}
        />
      </div>
      <div className="col-12 md:col-6 lg:col-3">
        <span className="p-float-label">
          <PFDropdown
            name="package_warehouse_status"
            value={convertString(values?.package_warehouse_status)}
            onChange={(name, value) => setFieldValue(name, value || null)}
            options={YES_NO_OPTIONS}
            optionLabel="label"
            optionValue="value"
            showClear={true}
          />
          <label htmlFor="package_warehouse_status">
            All Packages At Warehouse
          </label>
        </span>
      </div>
      <div className="col-12 md:col-6 lg:col-3 ">
        <span className="p-float-label">
          <PFDropdown
            name="inventory_location_warehouse_id"
            options={warehouse}
            optionLabel="name"
            optionValue="master_warehouse_id"
            value={values?.inventory_location_warehouse_id || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            filter={true}
            showClear={true}
          />
          <label htmlFor="inventory_location_warehouse_id">
            Current Inventory Location
          </label>
        </span>
      </div>
      <div className="col-12 md:col-6 lg:col-9">
        <span className="p-float-label">
          <InputTextarea
            className="w-full"
            rows={5}
            cols={30}
            name="inventory_note"
            onChange={e =>
              setFieldValue('inventory_note', e?.target?.value || '')
            }
            value={values?.inventory_note || ''}
          />
          <label htmlFor="inventory_note">Inventory Notes</label>
        </span>
      </div>
      <div className="col-12 md:col-6 lg:col-3 ">
        <div className="justify-content-end align-items-end flex mb-3">
          <PFButton
            label="Save"
            onClick={handleSubmit}
            severity="primary"
            disabled={saveDisabled}
          />
        </div>
      </div>
    </div>
  );
};
