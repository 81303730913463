import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

export const TruncateWithTooltip = ({ text, maxWidth, className }) => {
  const toast = useRef(null);
  const confirmation = event => {
    confirmPopup({
      target: event.currentTarget,
      message: text,
      footer: <></>,
      pt: {
        content: { className: 'text-xs p-1' },
      },
    });
  };
  return (
    <div
      className="p-d-inline-block"
      style={{ maxWidth: '100px', position: 'relative' }}
    >
      <span
        className={`${className} truncate-text cursor-pointer`}
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
        onClick={confirmation}
      >
        {text}
      </span>
    </div>
  );
};
