import { Tooltip } from 'primereact/tooltip';
import React from 'react';

const ProjectDetails = ({ selectedRow }) => {
  return (
    <>
      <div className="grid w-full surface-200 grid-nogutter mt-2 py-1 text-center flex align-items-center">
        <div className="col-12 my-2 md:col-6 xl:col">
          Client Name:
          <strong> {selectedRow?.client_name}</strong>
        </div>
        <div className="col-12 my-2 md:col-6 xl:col">
          Project No.:
          <strong> {selectedRow?.project_number}</strong>
        </div>
        <div className="col-12 my-2 md:col-6 xl:col">
          PO Number:
          <strong> {selectedRow?.po_number}</strong>
        </div>
        <div className="col-12 my-2 md:col-6 xl:col">
          Project Type:
          <strong> {selectedRow?.project_type}</strong>
        </div>
        <div className="col-12 my-2 md:col-6 xl:col">
          Category:
          <strong> {selectedRow?.category}</strong>
        </div>

        <div className="col-12 my-2 md:col-6 xl:col flex flex-wrap justify-content-center">
          <span
            className="store-name cursor-pointer"
            data-pr-tooltip={selectedRow?.store_name}
            data-pr-position="left"
            data-pr-at="left top+15"
            data-pr-my="right center-2"
          >
            Store:
            <strong> {selectedRow?.store_number}</strong>
          </span>
        </div>
        <Tooltip target=".store-name" className="text-sm" />
      </div>
    </>
  );
};

export default ProjectDetails;
