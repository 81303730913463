import React, { useContext, useState, useEffect } from 'react';

import PFCalendar from '../../../shared/PFPrime/PFCalendar';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import { InputTextarea } from 'primereact/inputtextarea';

import { YES_NO_OPTIONS } from '../constants/constants';

import { ReceivingContext } from '../ReceivingContainer';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import {
  binListService,
  createBinLocationService,
} from '../services/receiving.service';
import { PFDynamicDropdown } from '../../../shared/PFPrime/PFDynamicDropdown';
import { convertString } from '../helpers/helper';
import { dateFormatter } from '../../../../utils/Helpers';
export const ReceivingTypeReturn = ({ receiptForm }) => {
  const [selectedBin, setSelectedBin] = useState('');
  const { receivingContextValue } = useContext(ReceivingContext);
  const { values, setFieldValue, handleChange } = receiptForm;

  const binList = async (event = null, setItems) => {
    const query = event?.query || '';
    const res = await binListService(query);
    if (res?.status) {
      setItems(res?.data?.length > 0 ? res?.data : []);
    }
  };

  const binCreate = async (name, setValue, setItems) => {
    const res = await createBinLocationService({ bin_location: name });
    if (res?.status) {
      setValue(res?.data);
      binList(res?.data?.bin_location, setItems);
      receivingContextValue?.toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Package location added successfully',
      });
    }
  };

  useEffect(() => {
    setFieldValue('bin_location_id', selectedBin?.bin_location_id || '');
  }, [selectedBin]);

  return (
    <>
      <div className="col-12 md:col-6 lg:col-4">
        <span className="p-float-label">
          <PFCalendar
            name="receipt_date"
            appendTo={null}
            disabled={receivingContextValue?.visibleMode?.disabled || false}
            onChange={e =>
              setFieldValue(
                'receipt_date',
                (e?.target?.value &&
                  dateFormatter(e?.target?.value, 'YYYY-MM-DD')) ||
                  null
              )
            }
            value={
              values?.receipt_date
                ? new Date(dateFormatter(values?.receipt_date, 'MM/DD/YYYY'))
                : null
            }
          />
          <label htmlFor="receipt_date">Date</label>
        </span>
      </div>
      <div className="col-12 md:col-4 lg:col-4">
        <span className="p-float-label">
          <PFDropdown
            name="technician_id"
            optionLabel="full_name"
            optionValue="user_id"
            filter={true}
            value={values?.technician_id || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            options={receivingContextValue?.technicianList || []}
            disabled={receivingContextValue?.visibleMode?.disabled || false}
            showClear={true}
          />
          <label htmlFor="technician_id">Technician</label>
        </span>
      </div>
      <div className="col-12 md:col-4 lg:col-4">
        <span className="p-float-label">
          <PFDropdown
            name="return_warehouse_id"
            value={values?.return_warehouse_id || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            options={receivingContextValue?.warehouse || []}
            optionLabel="name"
            optionValue="master_warehouse_id"
            disabled={receivingContextValue?.visibleMode?.disabled || false}
            showClear={true}
            filter={true}
          />
          <label htmlFor="return_warehouse_id">Warehouse</label>
        </span>
      </div>
      <div className="col-12 md:col-4 lg:col-4">
        <PFDynamicDropdown
          listCallback={binList}
          field="bin_location"
          storeCallback={binCreate}
          defaultValue={
            receivingContextValue?.visibleMode?.data?.binLocation || ''
          }
          label="Location"
          selectedData={setSelectedBin}
          disabled={receivingContextValue?.visibleMode?.disabled || false}
        />
      </div>

      <div className="col-12 md:col-4 lg:col-4">
        <span className="p-float-label">
          <PFDropdown
            placeholder="Select Warehouse"
            name="is_still_in_warehouse"
            value={convertString(values?.is_still_in_warehouse)}
            optionLabel="label"
            optionValue="value"
            options={YES_NO_OPTIONS}
            onChange={(name, value) => setFieldValue(name, value || null)}
            disabled={receivingContextValue?.visibleMode?.disabled || false}
            showClear={true}
          />
          <label htmlFor="is_still_in_warehouse">Still in warehouse</label>
        </span>
      </div>
      <div className="col-12 md:col-4 lg:col-4">
        <span className="p-float-label">
          <PFInputNumber
            name="box_count"
            value={values?.box_count || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            disabled={receivingContextValue?.visibleMode?.disabled || false}
          />
          <label htmlFor="box_count">Box Count</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-12">
        <span className="p-float-label">
          <InputTextarea
            name="receipt_note"
            className="w-full"
            rows={5}
            cols={30}
            onChange={e =>
              setFieldValue('receipt_note', e?.target?.value || '')
            }
            value={values?.receipt_note || ''}
            disabled={receivingContextValue?.visibleMode?.disabled || false}
          />
          <label htmlFor="receipt_note">Notes</label>
        </span>
      </div>
    </>
  );
};
