import { useState, useRef } from 'react';
import axios from 'axios';
import 'primeicons/primeicons.css';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';

import useToken from '../../../../hooks/useToken';
import { REACT_APP_LOGIN_AUTH } from '../../../../constants/envConstants';

import { changePasswordValidation } from './LoginAuthValidation';
import { useStyles } from './Login.styles';

export const ChangePassword = ({ message }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { logout } = useToken();
  const toast = useRef(null);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      currentPassword: '',
    },
    validationSchema: changePasswordValidation,
    onSubmit: async values => {
      setLoading(true);
      try {
        const passwordChangeResponse = await axios.post(
          `${REACT_APP_LOGIN_AUTH}/change-password`,
          {
            currentPassword: values.currentPassword,
            password: values.password,
          }
        );
        if (
          passwordChangeResponse &&
          passwordChangeResponse.data &&
          passwordChangeResponse.data.status
        ) {
          toast.current.show({
            severity: 'success',
            summary: 'Password updated successfully, please login again.',
          });
          setTimeout(() => {
            localStorage.removeItem('isShowPasswordChangeConfirmation');
            logout();
            setLoading(false);
          }, 1000);
        } else {
          toast.current.show({
            severity: 'error',
            summary: passwordChangeResponse.data.message,
          });
        }
      } catch (error) {
        console.error(error);

        toast.current.show({
          severity: 'error',
          summary: error?.response?.data?.message || 'Something went wrong.',
        });
        setLoading(false);
      }
    },
  });

  const passwordFooter = (
    <>
      <small>
        Password length must be minimum 8 characters and at least one lowercase,
        one uppercase, one number, and one special character.
      </small>
    </>
  );

  return (
    <>
      <div
        className={`${classes.ChangePasswordContainer} w-12 flex justify-content-center`}
      >
        <div className="login-container-right">
          <div
            className="flex  align-center wrap p-6  w-100"
            style={{ width: '600px' }}
          >
            <div className="flex login-right-inner w-12">
              <div className=" w-12">
                <div className="text-center">
                  <img
                    src="/images/PFLogo.jpg"
                    alt="Project Force"
                    style={{ maxWidth: '180px' }}
                  />
                </div>
                <h1 className="text-center">Change Password</h1>
                {message && (
                  <div className="text-500 font-medium mb-4">{message}</div>
                )}

                <form
                  onSubmit={formik.handleSubmit}
                  className="p-fluid reset-password"
                >
                  <div name="confirmPassword">
                    <div
                      className={`${
                        formik.touched.currentPassword &&
                        formik.errors.currentPassword
                          ? 'validation-error'
                          : ''
                      } col-12 p-0 p-input-icon-right mb-3 `}
                    >
                      <span className="p-float-label">
                        <Password
                          autoComplete="off"
                          id="currentPassword"
                          name="currentPassword"
                          onChange={formik.handleChange}
                          value={formik.values.currentPassword}
                          onBlur={formik.handleBlur}
                          feedback={false}
                        />
                        <label htmlFor="currentPassword">
                          Current Password{' '}
                          <span className="text-red-500">*</span>
                        </label>
                      </span>
                      {formik.touched.currentPassword &&
                      formik.errors.currentPassword ? (
                        <div className="error">
                          {formik.errors.currentPassword}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div name="password">
                    <div
                      className={`${
                        formik.touched.password && formik.errors.password
                          ? 'validation-error'
                          : ''
                      } col-12 p-0 p-input-icon-right mb-3 `}
                    >
                      <span className="p-float-label">
                        <Password
                          id="password"
                          toggleMask
                          name="password"
                          strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!*_])[A-Za-z\d@#$%^&+=!*_]{8,}$"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          onBlur={formik.handleBlur}
                          footer={passwordFooter}
                          appendTo="self"
                        />
                        <label htmlFor="password">
                          New Password <span className="text-red-500">*</span>
                        </label>
                      </span>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="error">{formik.errors.password}</div>
                      ) : null}
                    </div>
                  </div>
                  <div name="confirmPassword">
                    <div
                      className={`${
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                          ? 'validation-error'
                          : ''
                      } col-12 p-0 p-input-icon-right mb-3 `}
                    >
                      <span className="p-float-label">
                        <Password
                          autoComplete="off"
                          id="confirmPassword"
                          name="confirmPassword"
                          onChange={formik.handleChange}
                          value={formik.values.confirmPassword}
                          onBlur={formik.handleBlur}
                          feedback={false}
                        />
                        <label htmlFor="confirmPassword">
                          Confirm New Password{' '}
                          <span className="text-red-500">*</span>
                        </label>
                      </span>
                      {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                        <div className="error">
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <Message
                    severity="warn"
                    text="Note: You will be automatically logged out after changing your password for security reasons."
                  />
                  <div className="mt-2">
                    By using ProjectsForce, you agree to our{' '}
                    <a
                      href="https://projectsforce.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                    .
                  </div>
                  <Button
                    type="submit"
                    severity="primary"
                    label="Submit"
                    icon={loading ? 'pi pi-spin pi-spinner' : ''}
                    pt={{
                      label: {
                        className: 'flex-none w-4rem',
                      },
                    }}
                    className="flex justify-content-center w-full mt-3 flex-row-reverse"
                    disabled={loading}
                  ></Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toast} />
    </>
  );
};

export default ChangePassword;
