import { ellipsisString } from '../../../utils/Helpers';

export const cardFields = optionKey => [
  {
    key: optionKey.basic_labor_quantity,
    label: 'Qty',
  },
  {
    key: optionKey.store,
    label: 'Store #',
  },
  {
    key: optionKey.project_type,
    label: 'Type',
  },
  {
    key: optionKey.category,
    label: 'Category',
  },
  {
    key: optionKey.status,
    label: 'Status',
  },
  {
    key: optionKey.project_number,
    label: 'Project No',
  },
  {
    key: optionKey.client_name,
    label: 'Client',
  },
  {
    key: optionKey.phone,
    label: 'Phone',
  },
  {
    key: optionKey.time,
    label: 'Arrival',
  },
  {
    key: optionKey.total_sale,
    label: 'Sale',
  },
  {
    key: optionKey.total_revenue,
    label: 'Revenue',
  },
  {
    key: optionKey.address,
    label: 'Address',
  },
  {
    key: optionKey.description,
    label: 'Description',
  },
];

export const getFieldValue = (identifier, optionKey, valueKey) => {
  if (identifier === optionKey.store) {
    return valueKey?.store_number;
  } else if (identifier === optionKey.project_type) {
    return valueKey?.project_type;
  } else if (identifier === optionKey.category) {
    return valueKey?.category;
  } else if (identifier === optionKey.status) {
    return valueKey?.project_status;
  } else if (identifier === optionKey.project_number) {
    return valueKey?.project_number;
  } else if (identifier === optionKey.client_name) {
    return valueKey?.client_name;
  } else if (identifier === optionKey.address) {
    return valueKey?.address;
  } else if (identifier === optionKey.phone) {
    return valueKey?.mobile_phone;
  } else if (identifier === optionKey.description) {
    return ellipsisString(valueKey?.project_desc, 45);
  } else if (identifier === optionKey.time) {
    return valueKey?.Time;
  } else if (identifier === optionKey.total_sale) {
    return valueKey?.total_sale_amount
      ? `$ ${valueKey.total_sale_amount}`
      : 'Not Available';
  } else if (identifier === optionKey.total_revenue) {
    return valueKey?.total_revenue
      ? `$ ${valueKey.total_revenue}`
      : 'Not Available';
  } else if (identifier === optionKey.basic_labor_quantity) {
    return valueKey?.basic_labor_sum;
  }
};
