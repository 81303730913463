import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import pLimit from 'p-limit';

import { URL_CONSTANTS } from '../../../constants/urlConstants';
import { store } from '../../../redux';
import {
  PFCalendar,
  PFDropdown,
  DomHandlerCall,
  PFNumberBox,
  PFTextBox,
} from '../pf-prime/Prime';
import apiService from '../../../services/api.service';
import { fetchWarehouseInventoryList } from '../../Locations/Warehouse.service';
import { INVENTORY_ADJUSTMENT_TYPES } from '../../Admin/Widget/constant/constant';
import { INVENTORY } from '../../../constants';

import { Bin } from './Bin';
import { addAdjustment } from './reducers/InventoryAdjustmentSlice';
import { getProduct } from './ProductDetails.model';
import { ADJUSTMENT_TYPE } from './constants';

const getErrorMessageByField = (validationMessages, field) => {
  const errorMessage = validationMessages.find(error => error.field === field);
  return errorMessage ? errorMessage.message : '';
};

const dateVisible = row => {
  const date = new Date(row.date);
  return <div className="w-8rem">{moment(date).format('MM-DD-YYYY')}</div>;
};

const adjustmentTypeOptions = INVENTORY.OPTIONS_FOR_ADJUSTMENT_TYPE;

const emptyObject = (index, productId) => ({
  productId: productId,
  index: index,
  date: new Date(),
  adjustmentType: null,
  warehouseId: null,
  quantityToAdjust: null,
  unit: null,
  costOfAdjustmentPurchase: null,
  costOfAdjustmentSale: null,
  newRow: true,
});

const addNewRow = (data, setData, setRowInserted, productInfo) => {
  const { productId, _id } = productInfo;
  const { listItems, ...rest } = data;
  const newObject = emptyObject(listItems?.length, _id);
  const newSet = { ...data, listItems: [newObject, ...listItems] };
  setData(newSet);
  setRowInserted(true);
};
let editRowCount = 0;
export const resetCount = () => {
  editRowCount = 0;
};
const onEdit = (e, options, setIsDisabled = null) => {
  editRowCount++;
  setIsDisabled && setIsDisabled(true);
  return options.rowEditor?.onInitClick && options.rowEditor?.onInitClick(e);
};

const onSave = (e, options, setIsDisabled = null) => {
  if (editRowCount >= 1) {
    editRowCount = editRowCount - 1;
  }
  if (editRowCount === 0) {
    setIsDisabled && setIsDisabled(false);
  }

  return options.rowEditor?.onSaveClick && options.rowEditor?.onSaveClick(e);
};

const onDelete = (
  e,
  rowData,
  options,
  setIsDisabled = null,
  setDeletedRow = null
) => {
  if (editRowCount >= 1) {
    editRowCount = editRowCount - 1;
  }
  if (editRowCount === 0) {
    setIsDisabled && setIsDisabled(false);
  }
  setDeletedRow(rowData);
  return (
    options.rowEditor?.onCancelClick && options.rowEditor?.onCancelClick(e)
  );
};

const onCancel = (e, rowData, options, setIsDisabled = null, setOnCancel) => {
  if (editRowCount >= 1) {
    editRowCount = editRowCount - 1;
  }
  if (editRowCount === 0) {
    setIsDisabled && setIsDisabled(false);
  }
  setOnCancel(true);
  return (
    options.rowEditor?.onCancelClick && options.rowEditor?.onCancelClick(e)
  );
};
let warehouseId = null;

const adjustmentData = (
  onRowEditComplete,
  setIsDisabled,
  setDeletedRow,
  setOnCancel = () => {},
  setSelectedWarehouse,
  selectedWarehouse
) => {
  return {
    listItems: [],
    dataTableConfig: {
      editMode: 'row',
      dataKey: 'id',
      onRowEditComplete: onRowEditComplete,
      setIsDisabled: setIsDisabled,
      tableStyle: { minWidth: '50rem' },
      onEdit: (e, options) => onEdit(e, options, setIsDisabled),
      onSave: (e, options) => onSave(e, options, setIsDisabled),
      onDelete: (e, rowData, options) =>
        onDelete(e, rowData, options, setIsDisabled, setDeletedRow),
      onCancel: (e, rowData, options) =>
        onCancel(e, rowData, options, setIsDisabled, setOnCancel),
    },
    columns: (() => {
      let res = [
        {
          field: 'date',
          header: 'Date',
          editor: options => {
            return (
              <>
                <PFCalendar
                  value={options?.value}
                  onChange={e =>
                    options.editorCallback(e ? e.toString() : new Date())
                  }
                  appendTo={null}
                  className="w-12rem"
                />
                <span className="text-red-500">
                  {!options?.value &&
                    getErrorMessageByField(
                      options?.rowData?.errorAdjustment || [],
                      'date'
                    )}
                </span>
              </>
            );
          },
          body: dateVisible,
        },
        {
          field: 'adjustmentType',
          header: 'Adjustment Type',
          editor: options => {
            const { projectId, projectNumber } =
              store.getState().inventoryManagment.ProductInfo?.projectInfo;

            const optionForItem = INVENTORY.OPTIONS_FOR_ITEM;
            const optionForProject = INVENTORY.OPTIONS_FOR_PROJECT;
            return (
              <>
                <PFDropdown
                  value={options?.value}
                  placeholder="Select Adjustment Type"
                  options={projectId ? optionForProject : optionForItem}
                  optionLabel="name"
                  selectedOption={adjustmentTypeOptions[0]?.value}
                  onChange={e => options.editorCallback(e)}
                  floatLabel={false}
                />
                <span className="text-red-500 relative">
                  {!options?.value &&
                    getErrorMessageByField(
                      options?.rowData?.errorAdjustment || [],
                      'adjustmentType'
                    )}
                </span>
              </>
            );
          },
          body: options => {
            return (
              <>
                <div>
                  {
                    adjustmentTypeOptions.find(
                      item => item.value === options?.adjustmentType
                    )?.name
                  }
                </div>
                <div>
                  {options?.projectNumber ? (
                    <Link
                      to={`/project/edit/${options?.projectId}`}
                      className="no-underline text-primary"
                      target="_blank"
                    >
                      {'Project No: ' + options?.projectNumber}
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </>
            );
          },

          style: { minWidth: '150px' },
        },
        {
          field: 'warehouseId',
          header: 'Warehouse',
          editor: options => {
            if (!options?.value) {
              warehouseId = null;
            }

            const locationOptions =
              store.getState().inventoryManagment?.Warehouse?.allWarehouses;
            return (
              <>
                <PFDropdown
                  value={options?.value}
                  placeholder="Select Warehouse"
                  options={locationOptions}
                  optionLabel="name"
                  optionValue="_id"
                  filter={true}
                  onChange={e => {
                    warehouseId = e;
                    setSelectedWarehouse(e);
                    return options.editorCallback(e);
                  }}
                  floatLabel={false}
                />
                <span className="text-red-500">
                  {!options?.value &&
                    getErrorMessageByField(
                      options?.rowData?.errorAdjustment || [],
                      'warehouseId'
                    )}
                </span>
              </>
            );
          },
          body: options => {
            const locationOptions =
              store.getState().inventoryManagment?.Warehouse?.allWarehouses;
            return locationOptions.find(
              item => item._id === options?.warehouseId
            )?.name;
          },
          style: { minWidth: '150px' },
        },
        {
          field: 'binId',
          header: 'Bin',
          editor: options => (
            <>
              <Bin
                field="binId"
                options={options}
                warehouseId={warehouseId}
                defaultSetting={{
                  placeholder: 'Select Bin',
                  optionLabel: 'binName',
                  optionValue: '_id',
                }}
              />
              <span className="text-red-500">
                {!options?.value &&
                  getErrorMessageByField(
                    options?.rowData?.errorAdjustment || [],
                    'binId'
                  )}
              </span>
            </>
          ),

          body: options => {
            return options?.binsCollection?.length > 0
              ? `${options?.binsCollection?.[0]?.binCode}-${options?.binsCollection?.[0]?.binName} `
              : '';
          },
          style: { minWidth: '150px' },
        },
        {
          field: 'quantityToAdjust',
          header: 'Qty to Adjust',
          editor: options => (
            <>
              <PFNumberBox
                value={options.value}
                placeholder="Qty to Adjustment"
                onChange={e => {
                  return options.editorCallback(e);
                }}
                className="text-primary"
                maxFractionDigits={2}
                minFractionDigits={2}
              />

              <span className="text-red-500">
                {!options?.value &&
                  getErrorMessageByField(
                    options?.rowData?.errorAdjustment || [],
                    'quantityToAdjust'
                  )}
              </span>
            </>
          ),
          style: { minWidth: '150px' },
        },
        {
          field: 'unit',
          header: 'Unit',
          editor: options => {
            let { unit } =
              store.getState().inventoryManagment.ProductInfo.itemInfo;
            unit = _.cloneDeep(unit);
            unit = unit.sort((a, b) => {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();

              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            });

            return (
              <>
                <PFDropdown
                  value={options?.value}
                  placeholder="Select Unit"
                  options={unit}
                  optionLabel="name"
                  optionValue="name"
                  selectedOption={unit[0]?.name}
                  onChange={e => options.editorCallback(e)}
                  floatLabel={false}
                />
                <span style={{ color: 'red', position: 'relative' }}>
                  {!options?.value &&
                    getErrorMessageByField(
                      options?.rowData?.errorAdjustment || [],
                      'unit'
                    )}
                </span>
              </>
            );
          },

          style: { minWidth: '150px' },
        },
        {
          field: 'costOfAdjustmentPurchase',
          header: 'Cost of Adjustment (Purchase)',
          editor: options => (
            <>
              <PFNumberBox
                value={options?.value || '0'}
                placeholder="Cost of Adjustment (Purchase)"
                onChange={e => options.editorCallback(e)}
                mode="currency"
                minFractionDigits={2}
                maxFractionDigits={2}
                readOnly={true}
              />
              <span className="text-red-500">
                {!options?.value &&
                  getErrorMessageByField(
                    options?.rowData?.errorAdjustment || [],
                    'costOfAdjustmentPurchase'
                  )}
              </span>
            </>
          ),
          body: options => {
            const value = Number(
              options?.costOfAdjustmentPurchase || 0
            ).toFixed(2);

            return `$ ${value || '0.00'}`;
          },

          style: { minWidth: '150px' },
        },
        {
          field: 'costOfAdjustmentSale',
          header: 'Cost of Adjustment (Sales)',
          editor: options => (
            <>
              <PFNumberBox
                value={options?.value || '0'}
                placeholder="Total Cost of Sales"
                onChange={e => options.editorCallback(e)}
                mode="currency"
                minFractionDigits={2}
                maxFractionDigits={2}
                readOnly={true}
              />
              <span className="text-red-500">
                {!options?.value &&
                  getErrorMessageByField(
                    options?.rowData?.errorAdjustment || [],
                    'costOfAdjustmentSale'
                  )}
              </span>
            </>
          ),
          body: options => {
            const value = Number(options?.costOfAdjustmentSale || 0).toFixed(2);
            return `$ ${value || '0.00'}`;
          },

          style: { minWidth: '150px' },
        },
      ];

      res = [
        ...res,
        {
          field: 'projectNote',
          header: 'Project Notes',
          editor: options => {
            return options?.projectNumber ? (
              <PFTextBox value={options?.projectNote} disabled={true} />
            ) : null;
          },
          body: data =>
            data?.projectNumber ? <span>{data?.projectNote}</span> : '',
          style: { minWidth: '150px' },
        },
      ];

      res = [
        ...res,
        {
          field: 'noteText',
          header: 'Adjustment Notes',
          editor: options => (
            <>
              <PFTextBox
                value={options?.value}
                placeholder="Note"
                onChange={e => options.editorCallback(e)}
                maxLength={500}
              />
              <span className="text-red-500">
                {!options?.value &&
                  getErrorMessageByField(
                    options?.rowData?.noteText || [],
                    'noteText'
                  )}
              </span>
            </>
          ),
          style: { minWidth: '150px' },
        },
      ];
      return res;
    })(),
  };
};

const updateRowData = (
  updateRow,
  data,
  setData,
  crud = null,
  dispatch = null,
  toast = null,
  setDataTableloading
) => {
  if (crud && crud === 'U' && updateRow) {
    const { index, newData } = updateRow;
    newData['validateAdjustment'] = true;
    newData['errorAdjustment'] = [];
    if (!newData?.date) {
      newData['validateAdjustment'] = false;
      newData['errorAdjustment'] = [
        ...newData['errorAdjustment'],
        { field: 'date', message: 'Required' },
      ];
    }
    if (!newData?.adjustmentType) {
      newData['validateAdjustment'] = false;
      newData['errorAdjustment'] = [
        ...newData['errorAdjustment'],
        { field: 'adjustmentType', message: 'Required' },
      ];
    }
    if (!newData?.warehouseId) {
      newData['validateAdjustment'] = false;
      newData['errorAdjustment'] = [
        ...newData['errorAdjustment'],
        { field: 'warehouseId', message: 'Required' },
      ];
    }
    if (!newData?.binId) {
      newData['validateAdjustment'] = false;
      newData['errorAdjustment'] = [
        ...newData['errorAdjustment'],
        { field: 'binId', message: 'Required' },
      ];
    }
    if (!newData?.quantityToAdjust) {
      newData['validateAdjustment'] = false;
      newData['errorAdjustment'] = [
        ...newData['errorAdjustment'],
        { field: 'quantityToAdjust', message: 'Required' },
      ];
    }

    if (!newData?.unit) {
      newData['validateAdjustment'] = false;
      newData['errorAdjustment'] = [
        ...newData['errorAdjustment'],
        { field: 'unit', message: 'Required' },
      ];
    }

    if (!newData?.validateAdjustment) {
      const errorId = DomHandlerCall('init-index-' + newData?.index);
      setDataTableloading(false);
      setTimeout(() => {
        errorId?.handler[0].click();
      }, 500);
    } else {
      delete newData.newRow;
      delete newData.validateAdjustment;
      delete newData.errorAdjustment;
      delete newData.index;
      delete newData.costOfAdjustmentPurchase;
      delete newData.costOfAdjustmentSale;
      const { projectId, projectNumber } =
        store.getState().inventoryManagment.ProductInfo?.projectInfo;
      if (
        newData?.adjustmentType === INVENTORY_ADJUSTMENT_TYPES.DISPATCHED ||
        newData?.adjustmentType === INVENTORY_ADJUSTMENT_TYPES.ALLOCATED ||
        newData?.adjustmentType === INVENTORY_ADJUSTMENT_TYPES.ON_ORDER
      ) {
        newData['projectId'] = projectId || newData?.projectId;
        newData['projectNumber'] = projectNumber || newData?.projectNumber;
      } else {
        delete newData?.projectId;
        delete newData?.projectNumber;
      }

      addUpdateAdjustment(newData, dispatch, toast, setDataTableloading);
    }

    const { listItems, ...rest } = data;
    if (newData?.validateAdjustment) {
      newData['newRow'] = false;
    }
    const updateList = listItems.map((item, i) =>
      i === index ? newData : item
    );

    setData(s => ({ ...s, listItems: updateList }));
  } else if (crud && crud === 'D' && updateRow) {
    if (updateRow?._id) {
      deleteAdjustment(
        updateRow?._id,
        updateRow?.productId,
        dispatch,
        toast,
        setDataTableloading
      );
    } else {
      data.listItems.shift();
      setData(s => ({ ...s, listItems: data.listItems }));
      setDataTableloading(false);
    }
  }
};

const displayDelayed = (rowInserted, setRowInserted) => {
  if (rowInserted) {
    const edit = DomHandlerCall('p-row-editor-init');
    setTimeout(() => {
      edit?.handler[0].click();
    }, 200);

    setRowInserted(false);
  }
};

const addUpdateAdjustment = async (data, dispatch, toast = null) => {
  try {
    let addUpdateAdjustment = null;
    if (data?._id) {
      addUpdateAdjustment = await apiService.patchWithDiffBaseUrl(
        `/stock-adjustment/update/${data?._id}`,
        { ...data },
        URL_CONSTANTS.INVENTORY.baseUrl
      );
    } else {
      addUpdateAdjustment = await apiService.postWithDiffBaseUrl(
        `/stock-adjustment/create`,
        { ...data },
        URL_CONSTANTS.INVENTORY.baseUrl
      );
    }

    if (addUpdateAdjustment?.status) {
      const { productId, _id } =
        store.getState().inventoryManagment.ProductInfo.itemInfo;
      getProduct(productId, dispatch);
      data?._id && toast
        ? toast.current.show({
            severity: 'success',
            summary: 'Success!',
            detail: 'Adjustment Updated successfully',
            life: 3000,
          })
        : toast?.current?.show({
            severity: 'success',
            summary: 'Success!',
            detail: 'Adjustment Added successfully',
            life: 3000,
          });
    }

    if (
      addUpdateAdjustment?.data?.adjustmentType === ADJUSTMENT_TYPE?.ALLOCATED
    ) {
      try {
        const addWarehouseInProjectItem = await axios.put(
          `${URL_CONSTANTS.API.BASE_URL}/projects/${addUpdateAdjustment?.data?.projectId}/v1/project-item/${addUpdateAdjustment?.data?._id}/update/inventory`,
          {}
        );
      } catch (e) {
        console.error(
          'InventoryAdjustment',
          'addUpdateAdjustment',
          e && e.message ? e.message : e
        );
      }
    }

    return addUpdateAdjustment;
  } catch (error) {
    console.error('error ', error);
    toast.current.show({
      severity: 'error',
      summary: 'Something went wrong.',
      life: 2000,
    });
    return [];
  }
};

const deleteAdjustment = async (id, productId, dispatch, toast = null) => {
  try {
    const deleteAdjustment = await apiService.deleteWithDiffBaseUrl(
      `/stock-adjustment/delete/${id}`,
      null,
      URL_CONSTANTS.INVENTORY.baseUrl
    );
    if (deleteAdjustment?.status) {
      const item = store.getState().inventoryManagment.ProductInfo.itemInfo;
      productId = item.productId;
      getProduct(productId, dispatch);
      toast &&
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Adjustment deleted successfully',
          life: 3000,
        });
    }
    return deleteAdjustment;
  } catch (error) {
    console.error('error ', error);
    toast.current.show({
      severity: 'error',
      summary: 'Something went wrong.',
      life: 2000,
    });
    return [];
  }
};

const cache = new Set();

const getNotes = async (item, itemId, projectId) => {
  let result = null;

  if (item?.projectNumber && !cache.has(projectId)) {
    if (item?.projectId == projectId) {
      cache.add(projectId);
      const res = await apiService.get(
        `/projects/${item?.projectId}/items/get-by-item-id/${itemId}`
      );
      if (res.status && res.data) {
        result = res.data?.note_text || null;
      }
    }
  }

  return null;
};

const adjustmentItes = async (item, i, itemId, projectId) => {
  const newKeys = { index: i, validateAdjustment: true, errorAdjustment: [] };
  return { ...item, ...newKeys };
};
const limit = pLimit(5);
const limitedAsyncFunction = (item, i, itemId, projectId) =>
  limit(() => adjustmentItes(item, i, itemId, projectId));

const getAdjustments = async (
  productId,
  dispatch,
  setDataTableloading,
  itemId,
  projectId
) => {
  try {
    const path = window?.location?.pathname?.split('/')?.[2] || null;

    let url = `/stock-adjustment/get-all/${productId}`;
    if (projectId && path !== 'items') {
      url = `/stock-adjustment/get-all/${productId}?projectId=${projectId}`;
    }

    let listOfAdjustments = await apiService.getWithDiffBaseUrl(
      url,
      null,
      URL_CONSTANTS.INVENTORY.baseUrl
    );
    listOfAdjustments = listOfAdjustments ? listOfAdjustments : [];

    // New API call to get project notes
    const projectIds = Array.from(
      new Set(listOfAdjustments.map(item => item.projectId))
    ).join(',');

    let list = [];
    if (projectIds?.length && listOfAdjustments?.length) {
      const projectNotesResponse = await apiService.get(
        `/projects/item/${itemId}/get-project-ids-notes?projectIds=${projectIds}`
      );

      // Create a map for quick access to project notes by project_id
      const projectNotesMap = projectNotesResponse.reduce((acc, note) => {
        acc[note.project_id] = note.note_text; // Assuming you want to store the note_text
        return acc;
      }, {});
      list = listOfAdjustments?.map((item, i) => {
        // Check if projectId exists in projectNotesMap and add projectNote property if it does
        if (projectNotesMap[item.projectId]) {
          item.projectNote = projectNotesMap[item.projectId]; // Add projectNote property
        }
        return limitedAsyncFunction(item, i, itemId, projectId);
      });
    } else {
      list = listOfAdjustments?.map((item, i) =>
        limitedAsyncFunction(item, i, itemId, projectId)
      );
    }
    const data = await Promise.all(list);
    cache.clear();
    dispatch(addAdjustment({ item: data.length > 0 ? data : [] }));
    setDataTableloading(false);
    return listOfAdjustments;
  } catch (error) {
    console.error('error ', error);
    toast.current.show({
      severity: 'error',
      summary: 'Something went wrong.',
      life: 2000,
    });
    return [];
  }
};

export {
  adjustmentData,
  getErrorMessageByField,
  dateVisible,
  emptyObject,
  addNewRow,
  updateRowData,
  displayDelayed,
  getAdjustments,
};
