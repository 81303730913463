// **** Utilities ****
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  actionIcons: {
    padding: '0px 6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  TableRow: {
    cursor: 'pointer',
  },
  buttonRoot: {
    marginLeft: '5px',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  gridPaper: {
    padding: '10px 0px 10px 0px',
    maxHeight: '350px',
  },
  sizeSmall: {
    padding: '0px',
    paddingLeft: '5px',
    whiteSpace: 'nowrap',
  },
  tableContainer: {
    minHeight: '170px',
    height: '250px',
    maxHeight: '300px',
    overflow: 'hidden',
    '&:hover': {
      overflow: 'auto',
    },
    boxShadow: 'unset',
    '&::-webkit-scrollbar': {
      // Width of vertical scroll bar
      width: '8px',
      // Height of horizontal scroll bar
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#c2c9d2',
    },
  },
  tableHeaderCell: {
    borderBottom: 'none',
    padding: '5px 5px',
    fontSize: '12px',
    color: theme.palette.text.primary,
    fontWeight: 600,
    backgroundColor: '#F6F6F6',
    whiteSpace: 'nowrap',
    zIndex: 999,
  },
  checkboxLabelPlacement: {
    flexDirection: 'row',
  },
  tableBody: props => ({
    height: !props.dataLength ? '150px' : '44px',
    '&:hover': {
      overflowY: 'scroll',
    },
    overflowY: 'scroll',
  }),
  tableCell: props => ({
    borderBottom: !props.dataLength && 'none',
    fontWeight: !props.dataLength && 600,
  }),
  viewAction: props => ({
    visibility:
      props.showActions?.view && props.showActionIcons ? 'visible' : 'hidden',
  }),
  editAction: props => ({
    visibility:
      props.showActions?.edit && props.showActionIcons ? 'visible' : 'hidden',
  }),
  deleteAction: props => ({
    visibility:
      props.showActions?.delete && props.showActionIcons ? 'visible' : 'hidden',
  }),
}));
