import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';

import useToken from '../../hooks/useToken';

const SessionExpiredPopup = ({ open, data }) => {
  const { logout } = useToken();
  const [isDisable, setIsDisable] = useState(false);

  const handleClose = async () => {
    setIsDisable(true);
    const response = await logout();
    if (response) {
      setIsDisable(false);
    }
  };

  return (
    <BlockUI blocked={true}>
      <Modal
        open={open}
        aria-labelledby="session-expired-title"
        aria-describedby="session-expired-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
            borderRadius: 1,
          }}
        >
          <h3>{data?.message?.title}</h3>
          <p>{data?.message?.message}</p>
          <Button
            label="Okay"
            disabled={isDisable}
            onClick={() => handleClose()}
            className="cursor-pointer"
          />
        </Box>
      </Modal>
    </BlockUI>
  );
};

export default SessionExpiredPopup;
