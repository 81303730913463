import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ApiService from '../../../services/api.service';
const initialState = {
  districts: [],
  status: false,
  error: null,
};
export const fetchDistricts = createAsyncThunk(
  'master/fetchDistricts',
  async (_, { getState, rejectWithValue }) => {
    const { districts } = getState().districts;
    if (districts && districts.length > 0) {
      return rejectWithValue('Districts already fetched');
    } else {
      const response = await ApiService.get('/stores/district');
      return response;
    }
  }
);
const districtsSlice = createSlice({
  name: 'districts',
  initialState,
  reducers: {
    clearDistricts: state => {
      state.districts = [];
      state.status = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDistricts.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchDistricts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.districts = action?.payload || [];
      })
      .addCase(fetchDistricts.rejected, (state, action) => {
        if (action.payload === 'Districts already fetched') {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.error = action.error.message;
        }
      });
  },
});

export const { clearDistricts } = districtsSlice.actions;

export const districtsReducer = districtsSlice.reducer;
