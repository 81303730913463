import React from 'react';
import { Skeleton } from 'primereact/skeleton';

export const firstRowData = [
  { width: '170%', height: '3rem', borderClass: 'border-1 border-gray-100' },
  { width: '12rem', height: '3rem', borderClass: 'border-y-1 border-x-0 border-gray-100 w-full' },
  { width: '12rem', height: '3rem', borderClass: 'border-1 border-gray-100 w-full' },
  { width: '12rem', height: '3rem', borderClass: 'border-y-1 border-x-0 border-gray-100 w-full' },
  { width: '12rem', height: '3rem', borderClass: 'border-1 border-gray-100 w-full' },
  { width: '12rem', height: '3rem', borderClass: 'border-y-1 border-x-0 border-gray-100 w-full' },
  { width: '12rem', height: '3rem', borderClass: 'border-1 border-gray-100 w-full' },
  {
    width: '4rem',
    height: '3rem',
    borderClass: 'border-y-1 border-right-1 border-gray-100 w-full',
  },
];

export const secondRowData = [
  { width: '170%', height: '8rem', borderClass: 'border-1 border-gray-100' },
  { width: '12rem', height: '8rem', borderClass: 'border-y-1 border-x-0 border-gray-100 w-full' },
  { width: '12rem', height: '8rem', borderClass: 'border-1 border-gray-100 w-full' },
  { width: '12rem', height: '8rem', borderClass: 'border-y-1 border-x-0 border-gray-100 w-full' },
  { width: '12rem', height: '8rem', borderClass: 'border-1 border-gray-100 w-full' },
  { width: '12rem', height: '8rem', borderClass: 'border-y-1 border-x-0 border-gray-100 w-full' },
  { width: '12rem', height: '8rem', borderClass: 'border-1 border-gray-100 w-full' },
  {
    width: '4rem',
    height: '8rem',
    borderClass: 'border-y-1 border-right-1 border-gray-100 w-full',
  },
];

export const SchedulerSkeleton = ({ rows }) => {
  return (
    <div className="w-full">
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className="flex">
          {row.map((item, itemIndex) => (
            <Skeleton
              key={itemIndex}
              width={item?.width}
              height={item?.height}
              borderRadius="0px"
              className={item?.borderClass}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
