import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
/*Prime Imports*/
import { Button } from 'primereact/button';

/*Custom Components*/
import { Toast } from 'primereact/toast';

import PageHeader from '../../../shared/PageHeader/PageHeader';
import {
  addEditChargeback,
  getChargeback,
  getProjects,
} from '../services/ChargebackService';
import { getUsersList } from '../../../Admin/UserManagement/UserManagement.service';
import { CLIENT_DETAILS } from '../../../../constants';
import { checkPermission } from '../../../../utils/Helpers';
import permissions from '../../../../config/permissions';

import ProjectSummary from './ProjectSummary';
import ChargebackTabs from './ChargebackTabs';

const ChargebackAction = () => {
  const { action, projectId, chargebackId } = useParams();
  let history = useHistory();
  let disabledFields = action === 'view';
  const toast = useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [usersListData, setUsersListData] = React.useState([]);
  const [installerData, setInstallerData] = React.useState([]);
  const [installerBasicData, setInstallerBasicData] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [reloadData, setReloadData] = React.useState(false);
  // Chargeback Formik
  const chargebackFormik = useFormik({
    initialValues: {
      project_id: null,
      project: null,
      repair_date: null,
      chargeback_reason: null,
      chargeback_status: null,
      payment_type_id: null,
      debit_user: null,
      credit_user: null,
      material_chargeback: null,
      labor_chargeback: null,
      total_retail: null,
      total_technician: null,
      frequency: null,
      payment_count: null,
      payment_type: null,
      payment_amount_in_emi: null,
      payment_amount_balance: null,
      description: null,
      decline_reason: null,
      created_at: null,
      payout_type: null,
      payout_type_value: null,
    },
    validationSchema: Yup.object().shape({
      debit_user: Yup.object().shape({
        user_id: Yup.string().trim().required('Required'),
      }),
    }),
    enableReinitialize: true,
  });

  const chargebackActionBreadcrumb = (customerId, mode) => {
    return [
      {
        text: projectId ? 'Project Details' : 'Admin',
        link: projectId ? `/project/view/${projectId}` : '',
      },

      {
        text: `${action[0].toUpperCase() + action.slice(1)} Chargeback`,
      },
    ];
  };

  const saveChargeback = async () => {
    let editedResponse;

    try {
      setIsLoading(true);
      let body = {
        summary: {
          project_id:
            projectId || chargebackFormik?.values?.project?.project_id,
          repair_date: chargebackFormik?.values?.repair_date
            ? chargebackFormik?.values?.repair_date
            : null,
          chargeback_reason_id:
            chargebackFormik?.values?.chargeback_reason?.chargeback_reason_id,
          chargeback_status_id:
            chargebackFormik?.values?.chargeback_status?.status_id,
          payment_type_id:
            chargebackFormik?.values?.payment_type?.payment_type_id,
          debit_user: chargebackFormik?.values?.debit_user?.user_id,
          credit_user: chargebackFormik?.values?.credit_user?.user_id,
          material_chargeback: parseFloat(
            chargebackFormik?.values?.material_chargeback || 0
          ),

          labor_chargeback: parseFloat(
            chargebackFormik?.values?.labor_chargeback || 0
          ),
          frequency: chargebackFormik?.values?.frequency,
          payment_count: chargebackFormik?.values?.payment_count,
          payment_amount_in_emi: parseFloat(
            chargebackFormik?.values?.payment_amount_in_emi || 0
          ),
          description: chargebackFormik?.values?.description,
          decline_reason: chargebackFormik?.values?.decline_reason,
          emi_date: chargebackFormik?.values?.emi_date,
          payout_type: chargebackFormik?.values?.payout_type,
          payout_type_value: parseFloat(
            chargebackFormik?.values?.payout_type_value || 0
          ),
        },
      };

      Object.keys(body.summary).forEach(key => {
        if (body.summary[key] === null || body.summary[key] === undefined) {
          delete body.summary[key];
        }
      });

      editedResponse = await addEditChargeback(
        body,
        chargebackFormik?.values?.chargeback_id
      );

      if (editedResponse?.statusCode === 200) {
        toast.current.show({
          severity: 'success',
          summary: editedResponse?.message,
          life: 2000,
        });
        setTimeout(() => {
          let redirectedUrl = '/admin/chargeback/edit';
          if (editedResponse?.data?.project_id) {
            redirectedUrl = `${redirectedUrl}/project/${editedResponse?.data?.project_id}/chargeback/${editedResponse?.data?.chargeback_id}`;
          } else {
            redirectedUrl = `${redirectedUrl}/chargeback/${editedResponse?.data?.chargeback_id}`;
          }
          setReloadData(true);
          history.push(redirectedUrl);
        }, 2000);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Something went wrong',
          life: 2000,
        });
      }
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setReloadData(false);
      setIsLoading(false);
    }
  };

  const fetchInitialValues = async () => {
    // Fetch Chargeback details
    if (chargebackId && activeIndex === 0) {
      setIsLoading(true);

      let response = await getChargeback(chargebackId);
      if (response?.statusCode === 200) {
        let projectRes;
        // Fetch project details
        if (projectId) {
          let searchString = `${projectId}&exact=true`;
          projectRes = await getProjects(searchString);
        }
        await chargebackFormik?.setValues({
          ...chargebackFormik?.values,
          ...response?.data,
          is_editable: response?.data?.is_editable,
          project_id: projectRes?.[0]?.project_id,
          project: projectRes?.[0],
          chargeback_reason: response?.data?.chargeback_reason
            ?.chargeback_reason_id
            ? {
                chargeback_reason_id:
                  response?.data?.chargeback_reason?.chargeback_reason_id,
                name: response?.data?.chargeback_reason?.name,
              }
            : null,
        });
        if (response?.data?.debit_user?.user_id) {
          const searchString = {
            limit: 10,
            offset: 0,
          };
          let user_id = [response?.data?.debit_user?.user_id];
          if (response?.data?.credit_user?.user_id) {
            user_id.push(response?.data?.credit_user?.user_id);
          }
          const payload = [
            {
              type: 'USER_ID',
              value: [user_id],
            },
          ];
          await getUsersList(
            searchString,
            {},
            () => null,
            setUsersListData,
            () => null,
            '',
            [
              {
                type: 'USER_ID',
                value: user_id,
              },
            ]
          );
          setIsLoading(false);
        }
      }
    }
    // Fetch project details
    if (action === 'add' && projectId) {
      let searchString = `${projectId}&exact=true`;
      let projectRes = await getProjects(searchString);

      await chargebackFormik?.setValues({
        ...chargebackFormik?.values,
        project_id: projectRes[0]?.project_id,
        project: projectRes[0],
      });
    }
  };

  React.useEffect(() => {
    fetchInitialValues();
  }, [action, activeIndex, chargebackId]);

  React.useEffect(() => {
    if (reloadData) fetchInitialValues();
  }, [reloadData]);

  React.useEffect(() => {
    if (usersListData?.length) {
      chargebackFormik?.setValues({
        ...chargebackFormik?.values,
        debit_user: usersListData?.find(
          user =>
            user?.user_id === chargebackFormik?.values?.debit_user?.user_id
        ),
        credit_user: usersListData?.find(
          user =>
            user?.user_id === chargebackFormik?.values?.credit_user?.user_id
        ),
      });
    }
  }, [usersListData]);

  return (
    <div className="grid w-full">
      <Toast ref={toast} />
      <div className="col-6 md:col-6 lg:col-6 p-0 pb-4">
        <PageHeader
          pageTitle={`${action[0].toUpperCase() + action.slice(1)} Chargeback`}
          breadCrumbArray={chargebackActionBreadcrumb()}
        />
      </div>

      <div className="col-6 md:col-6 lg:col-6 p-0  pb-4">
        <div className="align-items-end justify-content-end flex">
          <Button
            label="Cancel"
            outlined
            className="mr-2"
            onClick={() => history.goBack()}
            disabled={isLoading}
          />

          <Button
            label="Save"
            onClick={() => saveChargeback()}
            disabled={
              (activeIndex !== 0 && activeIndex !== 5) ||
              !chargebackFormik?.dirty ||
              !chargebackFormik?.isValid ||
              disabledFields
            }
          />
        </div>
      </div>

      <div className="col-12 md:col-12 lg:col-12 p-0 mt-1">
        <ProjectSummary
          formik={chargebackFormik}
          disabledFields={disabledFields}
        />
      </div>
      <div className="col-12 md:col-12 lg:col-12 p-0 mt-5">
        <ChargebackTabs
          toast={toast}
          isLoading={isLoading}
          chargebackFormik={chargebackFormik}
          disabledFields={disabledFields}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </div>
    </div>
  );
};

export default ChargebackAction;
