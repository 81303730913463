import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { useParams, useHistory } from 'react-router-dom';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

import {
  setUserDetailsForm,
  clearState,
} from '../../../redux/slices/user-form-details.slice';
import {
  userFormUpdateComponent,
  clearUserFormUpdateComponent,
} from '../../../redux/slices/user-form.slice';
import PageHeader from '../../shared/PageHeader/PageHeader';
import SkeletonLoader from '../../shared/Loader/skeleton';
import ApiService from '../../../services/api.service';
import { setUserManagementComponent } from '../../../redux/slices/user-management.slice';
import { getUserListWithFilters } from '../UserManagement/UserManagement.service';

import { getUserDetails } from './services/user-form-service';
import { userFormTabs } from './user-form-tabs';

const userFormBreadcrumb = [
  {
    text: 'Admin',
  },
  {
    link: '/admin/usermanagement',
    text: 'User Management',
  },
  {
    text: 'User Form',
  },
];

const PAGE_ACTION = {
  NEXT: 'NEXT',
  PREV: 'PREV',
};

const UserFormLayout = () => {
  const { user } = useParams();
  const {
    isLoading,
    activeTabIndex,
    isFormDataChanged,
    userId,
    mode,
    isInitDataLoaded,
  } = useSelector(state => state.userForm);
  const { userName, userDetails } = useSelector(state => state.userFormDetails);

  const {
    userList,
    nextUserId,
    prevUserId,
    userSearchQuery,
    userSelectedFilters,
    nextPageOffset,
    prevPageOffset,
  } = useSelector(state => state.userManagement);

  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useRef(null);

  useEffect(() => {
    const formAction = window.location.pathname.split('/')[4];
    dispatch(
      userFormUpdateComponent({
        mode: formAction,
      })
    );
    if (user) {
      dispatch(
        userFormUpdateComponent({
          userId: user,
        })
      );
    }
    return () => {
      dispatch(clearUserFormUpdateComponent());
      dispatch(clearState());
    };
  }, [user, mode]);

  const getNextAndPrevUser = async () => {
    if (Array.isArray(userList) && userList.length > 0) {
      const currentUserIndex = userList.findIndex(
        user => user?.user_id === userId
      );
      let nextUserId;
      let prevUserId;
      if (currentUserIndex !== -1) {
        nextUserId = userList.at(currentUserIndex + 1)?.user_id;
        prevUserId = userList.at(currentUserIndex - 1)?.user_id;

        if (currentUserIndex === 0) {
          if (userSearchQuery?.offset === 0 || prevPageOffset === 0) {
            prevUserId = null;
          } else {
            const newOffset = prevPageOffset - userSearchQuery?.limit;
            const prevPageQuery = {
              ...userSearchQuery,
              offset: newOffset,
            };
            const result = await getUserListWithFilters(
              prevPageQuery,
              userSelectedFilters
            );
            const filteredResults = result?.filter(
              user => !userList?.some(item => item?.user_id === user?.user_id)
            );
            const newUserList = [...filteredResults, ...userList];
            prevUserId = filteredResults?.at(-1)?.user_id;
            dispatch(
              setUserManagementComponent({
                userList: newUserList,
                prevPageOffset: newOffset,
              })
            );
          }
        } else {
          if (currentUserIndex === userList.length - 1) {
            const newOffset = nextPageOffset + userSearchQuery?.limit;
            const nextPageQuery = {
              ...userSearchQuery,
              offset: newOffset,
            };
            const result = await getUserListWithFilters(
              nextPageQuery,
              userSelectedFilters
            );
            const filteredResults = result?.filter(
              user => !userList.some(item => item?.user_id === user?.user_id)
            );
            const newUserList = [...userList, ...filteredResults];
            nextUserId = filteredResults.at(0)?.user_id;
            dispatch(
              setUserManagementComponent({
                userList: newUserList,
                nextPageOffset: newOffset,
              })
            );
          }
        }

        dispatch(
          setUserManagementComponent({
            nextUserId,
            prevUserId,
          })
        );
      }
    }
  };

  const fetchInitiationData = async () => {
    try {
      if (!!userId && (mode === 'view' || mode === 'edit')) {
        const userDetails = await getUserDetails(userId);
        dispatch(
          setUserDetailsForm({
            userName: `${userDetails?.first_name} ${userDetails?.last_name}`,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetTabs = () => {
    dispatch(userFormUpdateComponent({ activeTabIndex: 0 }));
  };

  useEffect(async () => {
    dispatch(userFormUpdateComponent({ isInitDataLoaded: false }));
    await getNextAndPrevUser();
    await fetchInitiationData();
    dispatch(userFormUpdateComponent({ isInitDataLoaded: true }));
  }, [userId]);

  useEffect(() => {
    return resetTabs();
  }, []);

  const handleLostChangeConfirmation = action => {
    confirmDialog({
      message: (
        <div>
          Leaving this page without saving will result in the loss of unsaved
          changes. <br />
          Are you sure you want to proceed?
        </div>
      ),
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (action === PAGE_ACTION.NEXT) {
          nextUserId &&
            history.push(`/admin/usermanagement/user-form/edit/${nextUserId}`);
        } else if (action === PAGE_ACTION.PREV) {
          prevUserId &&
            history.push(`/admin/usermanagement/user-form/edit/${prevUserId}`);
        }
      },
    });
  };
  const handleTabChange = tab => {
    dispatch(
      userFormUpdateComponent({
        activeTabIndex: tab?.index,
        isFormDataChanged: false,
      })
    );
  };

  const handlePreviousUser = async () => {
    if (isFormDataChanged) {
      handleLostChangeConfirmation(PAGE_ACTION.PREV);
    } else {
      prevUserId &&
        history.push(`/admin/usermanagement/user-form/edit/${prevUserId}`);
    }
  };

  const handleNextUser = async () => {
    if (isFormDataChanged) {
      handleLostChangeConfirmation(PAGE_ACTION.NEXT);
    } else {
      nextUserId &&
        history.push(`/admin/usermanagement/user-form/edit/${nextUserId}`);
    }
  };

  const handleDone = () => {
    history.push(`/admin/usermanagement`);
  };
  const handleSendInvite = async userId => {
    toast.current.show({
      severity: 'info',
      summary: 'Info',
      detail: 'Sending invitation',
      life: 2000,
    });
    const response = await ApiService.post(
      '/auth/user/manage/sendEmailInvite',
      {
        users: [{ userId: userId }],
      }
    );
    if (response) {
      toast.current.show({
        severity: 'success',
        summary: 'Confirmed',
        detail: 'Invitation has been sent to user',
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to send invite',
        life: 3000,
      });
    }
  };

  const handleLockUnlockUser = async event => {
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to ${userDetails?.is_blocked ? 'unlock' : 'lock'} user account?`,
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: async () => {
        const isBlocked = userDetails?.is_blocked;
        try {
          dispatch(
            setUserDetailsForm({
              userDetails: {
                ...userDetails,
                is_blocked: isBlocked ? 0 : 1,
              },
            })
          );
          const result = await ApiService.put(
            `/auth/user/manage/block-unblock/${userDetails.user_id}`,
            {
              is_blocked: isBlocked ? 0 : 1,
            }
          );
          if (!result.status) {
            dispatch(
              setUserDetailsForm({
                userDetails: {
                  ...userDetails,
                  is_blocked: isBlocked ? 1 : 0,
                },
              })
            );
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: `Failed to ${userDetails?.is_blocked ? 'unlock' : 'lock'} user`,
              life: 3000,
            });
          }
        } catch (error) {
          console.error(error);
          dispatch(
            setUserDetailsForm({
              userDetails: {
                ...userDetails,
                is_blocked: isBlocked ? 1 : 0,
              },
            })
          );
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to ${userDetails?.is_blocked ? 'unlock' : 'lock'} user`,
            life: 3000,
          });
        }
      },
      reject: () => {},
    });
  };
  return (
    <>
      <ConfirmPopup />
      <div className="grid w-12 m-0">
        <div className="col-12 lg:col pl-0">
          <PageHeader
            pageTitle={`User Form : ${userName ? userName : ''}`}
            breadCrumbArray={userFormBreadcrumb}
            icon={
              userDetails && (
                <i
                  className={`pi ${userDetails?.is_blocked ? 'pi-lock' : 'pi-lock-open'} ml-2 cursor-pointer`}
                  style={{ fontSize: '1rem' }}
                  onClick={handleLockUnlockUser}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleLockUnlockUser(e);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  aria-label={
                    userDetails?.is_blocked ? 'Unlock user' : 'Lock user'
                  }
                ></i>
              )
            }
          />
        </div>
        <div className="py-2 action-button-wrapper ">
          <div className="flex flex-row action-button-section justify-content-end gap-0">
            {activeTabIndex !== 0 && (
              <Button
                className="ml-2 mb-2"
                label="Cancel"
                onClick={handleDone}
                size="small"
                outlined
                severity="primary"
                disabled={!isInitDataLoaded}
              />
            )}
            {mode !== 'add' && (
              <Button
                className="ml-2 mb-2"
                label={'Send Invite'}
                onClick={() => handleSendInvite(userId)}
                size="small"
                outlined
                disabled={!isInitDataLoaded}
              />
            )}
            {mode !== 'add' && !!prevUserId && (
              <Button
                className="ml-2 mb-2"
                label="Previous User"
                onClick={handlePreviousUser}
                size="small"
                severity="info"
                disabled={!isInitDataLoaded}
              />
            )}
            {mode !== 'add' && !!nextUserId && (
              <Button
                className="ml-2 mb-2"
                label="Next user"
                onClick={handleNextUser}
                size="small"
                severity="primary"
                disabled={!isInitDataLoaded}
              />
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <div className="flex flex-column w-full user-detail-form p-3 bg-white">
          <TabView
            scrollable
            activeIndex={activeTabIndex}
            onTabChange={handleTabChange}
          >
            {userFormTabs.map(tab => {
              const Component = tab.component;
              return (
                <TabPanel
                  key={tab.header}
                  header={tab.header}
                  disabled={(mode === 'add') & (tab.header !== 'User Details')}
                >
                  <Component />
                </TabPanel>
              );
            })}
          </TabView>
          <ConfirmDialog />
        </div>
      )}
      <Toast ref={toast} />
    </>
  );
};

export default UserFormLayout;
