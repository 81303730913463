import {
  REACT_APP_YOUTUBE_CHANNEL_ID,
  REACT_APP_YOUTUBE_URL,
  REACT_APP_YOUTUBE_KEY,
  REACT_APP_YOUTUBE_MAX_RESULTS,
} from '../../constants/envConstants';

// Get Project Force Videos
export const getProjectForceVideos = async (
  setLoading,
  setVideos,
  videoId = null
) => {
  let type = 'search';
  let query = `channelId=${REACT_APP_YOUTUBE_CHANNEL_ID}`;
  if (videoId) {
    type = 'videos';
    query = `id=${videoId}`;
  }
  try {
    setLoading(true);
    const response = await fetch(
      `${REACT_APP_YOUTUBE_URL}/${type}?key=${REACT_APP_YOUTUBE_KEY}&part=snippet,id&order=date&maxResults=${REACT_APP_YOUTUBE_MAX_RESULTS}&${query}`
    );
    const responseBody = await response.json();
    if (responseBody && responseBody?.items) {
      const { items } = responseBody;
      if (items) {
        setVideos(items);
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};
