// **** React Imports ****
import React, { useState } from 'react';

// **** External Utilities ****
import { Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

// **** Custom Components ****

import { Dropdown } from 'primereact/dropdown';

import GenericTable from '../../shared/GenericTable/GenericTable';
import PFDialog from '../../shared/PFPrime/PFDialog';
import PFInputText from '../../shared/PFPrime/PFInputText';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import { getSearchLaborItemsList } from '../../ProjectManagement/Items/Items.service';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import PFButton from '../../shared/PFPrime/PFButton';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';

import {
  createNewLaborProfileItem,
  editLaborProfileItem,
  deleteLaborProfileItem,
  getItems,
  getlaborProfileStore,
  getAllStoresList,
} from './LaborProfiles.service';

// **** Styles *****
import { useStyles } from './LaborProfiles.styles';
import LaborProfileStoreDialog from './LaborProfileStoreDialog';

const labourProfileItemsColumns = [
  {
    id: 'itemNumber',
    value: 'Item#',
  },
  {
    id: 'description',
    value: 'Description',
  },
  {
    id: 'cost',
    value: 'Cost Each',
  },
  {
    id: 'percent',
    value: 'Percent',
  },
  {
    id: 'stores',
    value: 'Stores',
  },
];
const withValueLimit = inputObj => {
  const { value } = inputObj;
  if (value == '' || value > 0 || value == 0) return true;
  return false;
};

const LaborProfileItems = ({
  labourProfileItems,
  installerId,
  setReloadLaborProfileItemsList,
  profileItemSearch,
  setProfileItemSearch,
}) => {
  const classes = useStyles();
  const { setAlert } = useAlerts();
  const [loading, setLoading] = React.useState(false);
  const [itemsLoading, setItemsLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [formAction, setFormAction] = React.useState(null);
  const [getCurrentOffset, setCurrentOffset] = React.useState(0);
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Add Labor Profile Item',
    button1Text: '',
    button2Text: '',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [storesList, setStoresList] = React.useState([]);
  const [itemsList, setItemsList] = React.useState([]);
  const [searchItemQuery, setSearchItemQuery] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState({});
  const [laborItemDropDown, setLaborItemDropDown] = React.useState([]);
  const [itemsSearchLoading, setItemsSearchLoading] = React.useState(false);

  let labourProfileItemsData = [];
  let currentLabourProfileItemsId = [];

  const [allStores, setAllStores] = useState([
    {
      store_id: '',
      store_name: '',
      store_number: '',
    },
  ]);

  const [storesDialogVisible, setStoresDialogVisible] = useState(false);

  const storesIds = storesList?.map(item => ({
    store_id: item.store_id,
    store_name: item.store_name,
  }));

  const [dialogBody, setDialogBody] = useState([]);
  const renderStores = laborProfileItem => {
    return (
      <div className="cursor-pointer text-primary">
        <PFButton
          severity="primary"
          outlined
          label={`${laborProfileItem?.is_all_stores ? storesList?.length : laborProfileItem?.store_count} / ${storesList?.length}`}
          size="small"
          text
          onClick={() => {
            setDialogBody([]);
            setStoresDialogVisible(true);
            if (!laborProfileItem?.is_all_stores) {
              getlaborProfileStore(
                installerId,
                laborProfileItem?.installer_labor_profile_item_id
              ).then(result => {
                setDialogBody(result);
              });
            } else {
              setDialogBody(storesList);
            }
          }}
          className="pl-0 border-none"
        />
      </div>
    );
  };

  const getAlllaborStore = async (installerId, storeId) => {
    try {
      setSelectStoreIds([]);

      const response = await getlaborProfileStore(installerId, storeId);
      if (response?.length > 0) {
        const ids = [];

        response?.forEach(item => {
          if (!ids?.includes(item?.store_id)) {
            ids.push(item?.store_id);
          }
        });
        setSelectStoreIds(ids);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAllStoresList(setStoresList);
    getItems(
      setItemsList,
      setItemsLoading,
      setCurrentOffset,
      itemsList,
      getCurrentOffset
    );
  }, []);

  React.useEffect(() => {
    if (itemsList.length > 0) {
      const itemIds = itemsList?.map(item => item.item_id);
      const missingItems = labourProfileItems
        ?.filter(item => {
          if (!itemIds.includes(item.item_id)) {
            return item.items;
          }
        })
        .map(item => item.items);
      setLaborItemDropDown([...itemsList, ...missingItems]);
    }
    if (itemsList.length === 0) {
      getItems(
        setItemsList,
        setItemsLoading,
        setCurrentOffset,
        itemsList,
        getCurrentOffset
      );
    }
  }, [itemsList]);

  const fetchLaborDropdown = async (event, newInputValue) => {
    if (newInputValue?.length < 2) {
      return;
    }
    if (newInputValue && event && event.type !== 'click') {
      setSearchItemQuery(newInputValue);
      await getSearchLaborItemsList(
        { limit: 10, offset: 0 },
        setItemsSearchLoading,
        setItemsList,
        newInputValue
      );
    }
  };
  // **** Data Modification to Display *****
  if (labourProfileItems?.length) {
    labourProfileItemsData = labourProfileItems?.map(labourProfileItem => {
      return {
        item_id: { value: labourProfileItem?.item_id },
        itemNumber: { value: labourProfileItem?.items?.item_number },
        description: { value: labourProfileItem?.items?.item_desc },
        is_all_stores: { value: labourProfileItem?.is_all_stores },
        cost: {
          value:
            labourProfileItem?.cost || labourProfileItem?.cost === 0
              ? '$' + labourProfileItem?.cost
              : null,
        },
        percent: {
          value: labourProfileItem?.percent
            ? labourProfileItem?.percent + '%'
            : '',
        },
        installer_labor_profile_item_id: {
          value: labourProfileItem?.installer_labor_profile_item_id,
        },
        store_id: {
          value: !labourProfileItem?.is_all_stores
            ? allStores?.map(store => store.store_id)
            : storesIds?.map(store => store.store_id),
        },
        stores: { value: renderStores(labourProfileItem) },
      };
    });
    currentLabourProfileItemsId = labourProfileItems?.map(labourProfileItem => {
      return {
        value: labourProfileItem?.item_id,
      };
    });
  }

  //View Labor Profile Item
  const viewEditLaborProfileItem = async (action, index) => {
    setFormAction(action);
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: action === 'view' ? false : true,
      button2Text: action === 'edit' ? 'Save' : '',
      title:
        (action === 'view' && 'View Labor Profile Item') ||
        'Edit Labor Profile Item',
    }));
    setRowIndex(index);
    setFormAction(action);
    setIsOpen(true);
    let foundItem = labourProfileItemsData[index];
    if (foundItem) {
      setSelectedItem({
        image_url: null,
        item_id: foundItem.item_id.value,
        item_number: foundItem.item_id.value,
        item_desc: foundItem.description.value,
      });
    }
  };
  const confirmDeleteLaborProfileItem = index => {
    setRowIndex(index);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: 'Are you sure you want to delete Labor Profile Item ?',
      header: 'Delete Labor Profile Item',
    });
  };
  const onConfirmDialog = () => {
    deleteLaborProfileItem(
      installerId,
      labourProfileItemsData[rowIndex]?.installer_labor_profile_item_id?.value,
      setLoading,
      setAlert,
      setReloadLaborProfileItemsList,
      setConfirmDialog
    );
  };
  //Labor Profile Item ValidationSchema
  const laborProfileItemValidationSchema = Yup.object().shape(
    {
      item_id: Yup.number().required(),
      description: Yup.string().trim(),
      cost: Yup.string()
        .trim()
        .when('percent', (percent, schema) => {
          if (percent) {
            return schema;
          } else {
            return schema.required('Required');
          }
        }),
      percent: Yup.string()
        .trim()
        .when('cost', (cost, schema) => {
          if (cost) {
            return schema;
          } else {
            return schema.required('Required');
          }
        }),
    },
    [['cost', 'percent']]
  );
  const [selectStoreIds, setSelectStoreIds] = useState([]);
  const [selectReset, setSelectRest] = useState(false);

  // **** Formik Form Values ****
  const laborProfileItemFormik = useFormik({
    initialValues: {
      item_id:
        (formAction !== 'add' &&
          labourProfileItemsData[rowIndex]?.item_id.value) ||
        '',
      description:
        formAction !== 'add' &&
        labourProfileItemsData[rowIndex]?.description?.value
          ? labourProfileItemsData[rowIndex]?.description.value
          : '',
      cost:
        (formAction !== 'add' &&
          labourProfileItemsData[rowIndex]?.cost.value) ||
        '',
      percent:
        (formAction !== 'add' &&
          labourProfileItemsData[rowIndex]?.percent.value) ||
        '',
      isPercent:
        formAction !== 'add' && labourProfileItemsData[rowIndex]?.percent.value
          ? true
          : false,
      store_id: [],
      is_all_stores: true,
    },
    onSubmit: values => {
      if (formAction === 'add') {
        const payload = {
          ...values,
          store_id: selectStoreIds || [],
          is_all_stores: selectStoreIds?.length === storesList?.length,
        };

        createNewLaborProfileItem(
          installerId,
          payload,
          setLoading,
          setIsOpen,
          setReloadLaborProfileItemsList,
          setAlert,
          setSelectRest,
          selectReset
        );
      } else {
        const payload = {
          ...values,
          store_id: selectStoreIds || [],
          is_all_stores: selectStoreIds?.length === storesList?.length,
        };
        editLaborProfileItem(
          installerId,
          labourProfileItemsData[rowIndex]?.installer_labor_profile_item_id
            ?.value,
          payload,
          setLoading,
          setIsOpen,
          setReloadLaborProfileItemsList,
          setAlert
        );
      }
    },
    validationSchema: laborProfileItemValidationSchema,
    enableReinitialize: true,
  });

  React.useEffect(() => {
    const ids = [];
    if (storesList?.length > 0 && formAction === 'add') {
      storesList?.forEach(item => {
        if (!ids?.includes(item?.store_id)) {
          ids.push(item?.store_id);
        }
      });
      setSelectStoreIds(ids);
    }
  }, [storesList, formAction, labourProfileItems, selectReset]);

  const handleEditItemStoreDropdown = data => {
    if (data?.is_all_stores?.value) {
      const ids = [];
      if (storesList?.length > 0) {
        storesList?.forEach(item => {
          if (!ids?.includes(item?.store_id)) {
            ids.push(item?.store_id);
          }
        });
        setSelectStoreIds(ids);
      }
    } else {
      getAlllaborStore(
        installerId,
        data?.installer_labor_profile_item_id?.value
      );
    }
  };

  const getLaborprofileItemViewEditForm = () => {
    // Find the initial selected item based on item_id for edit/view mode

    const getInitialValue = () => {
      if (formAction === 'edit' || formAction === 'view') {
        let currentItem = null;
        laborItemDropDown?.map(item => {
          const option = {
            item: `${item?.item_number} - ${item?.item_desc}`,
            item_id: item?.item_id,
            item_desc: item?.item_desc,
          };

          if (option.item_id === selectedItem?.item_id) {
            currentItem = option;
          }

          return option;
        });

        return currentItem || null;
      }
      return null;
    };

    return (
      itemsList?.length > 0 && (
        <>
          <>
            <div className="grid my-2">
              <div className="col-12 md:col-6 lg:col-12 w-full">
                <span className="p-float-label">
                  <Dropdown
                    name="item"
                    options={laborItemDropDown.map(item => ({
                      item: `${item?.item_number} - ${item?.item_desc}`,
                      item_id: item?.item_id,
                      item_desc: item?.item_desc,
                    }))}
                    onChange={(event, value) => {
                      if (event.value) {
                        laborProfileItemFormik.setFieldValue(
                          'item_id',
                          event.value.item_id
                        );
                        laborProfileItemFormik.setFieldValue(
                          'description',
                          event.value.item_desc
                        );
                        setSelectedItem(event.value);
                      } else {
                        laborProfileItemFormik.setFieldValue('item_id', '');
                        laborProfileItemFormik.setFieldValue('description', '');
                      }
                    }}
                    filter={true}
                    onFilter={async e => {
                      if (e.filter && e.filter.length > 2) {
                        const updatedSearchItemQuery = e.filter;

                        await getSearchLaborItemsList(
                          { limit: 10, offset: 0 },
                          setItemsSearchLoading,
                          setItemsList,
                          updatedSearchItemQuery
                        );
                      }
                    }}
                    optionLabel="item"
                    placeholder="Select Items"
                    className="w-full"
                    pt={{
                      root: {
                        className: 'w-full ',
                      },
                      panel: {
                        className: 'w-full',
                      },
                    }}
                    appendTo={'self'}
                    disabled={formAction === 'view' || formAction === 'edit'}
                    value={
                      formAction === 'edit' || formAction === 'view'
                        ? getInitialValue()
                        : selectedItem
                    }
                  />
                  <label className="text-xs">Item#</label>
                </span>
              </div>

              <div className="col-12 md:col-6 lg:col-12 w-full">
                <label className="text-xs">Item Description</label>
                <PFInputText
                  value={laborProfileItemFormik?.values?.description || ''}
                  disabled
                  className="w-full"
                />
              </div>
              <div className="col-12 md:col-12 lg:col-12 ">
                <div className="flex  align-items-center gap-2">
                  <span className="text-sm">Cost Each</span>
                  <PFInputSwitch
                    checked={laborProfileItemFormik?.values?.isPercent}
                    onChange={event => {
                      laborProfileItemFormik.setFieldValue(
                        'isPercent',
                        event?.value
                      );
                      event?.value
                        ? laborProfileItemFormik.setFieldValue('cost', '')
                        : laborProfileItemFormik.setFieldValue('percent', '');
                    }}
                    pt={{
                      input: {
                        className: 'h-1',
                      },
                    }}
                    color="primary"
                    disabled={formAction === 'view'}
                    value={laborProfileItemFormik?.values?.isPercent}
                  />
                  <span className="text-sm">Percent</span>
                </div>
              </div>
              <div className="col-12 md:col-12 lg:col-12 ">
                {!laborProfileItemFormik?.values?.isPercent ? (
                  <NumberFormat
                    id="cost_each"
                    name="cost"
                    allowNegative={false}
                    onChange={laborProfileItemFormik.handleChange}
                    onBlur={laborProfileItemFormik.handleBlur}
                    label={`Cost Each`}
                    decimalScale={2}
                    customInput={TextField}
                    inputmode="numeric"
                    InputLabelProps={{ shrink: true }}
                    prefix={'$'}
                    value={laborProfileItemFormik?.values?.cost || ''}
                    error={
                      laborProfileItemFormik.touched.cost &&
                      laborProfileItemFormik.errors.cost
                    }
                    helperText={
                      laborProfileItemFormik.touched.cost &&
                      laborProfileItemFormik.errors.cost
                    }
                    disabled={formAction === 'view' ? true : false}
                    inputProps={{ maxLength: 10 }}
                  />
                ) : (
                  <NumberFormat
                    id="percent"
                    name="percent"
                    inputProps={{ maxLength: 6 }}
                    allowNegative={false}
                    onChange={laborProfileItemFormik.handleChange}
                    onBlur={laborProfileItemFormik.handleBlur}
                    label={`Percent`}
                    decimalScale={2}
                    customInput={TextField}
                    inputmode="numeric"
                    InputLabelProps={{ shrink: true }}
                    suffix={'%'}
                    value={laborProfileItemFormik?.values?.percent || ''}
                    error={
                      laborProfileItemFormik.touched.percent &&
                      laborProfileItemFormik.errors.percent
                    }
                    helperText={
                      laborProfileItemFormik.touched.percent &&
                      laborProfileItemFormik.errors.percent
                    }
                    disabled={formAction === 'view' ? true : false}
                  />
                )}
              </div>

              <div className="col-12 md:col-6 lg:col-12 w-full">
                <span className="p-float-label">
                  <PFMultiSelect
                    value={selectStoreIds || []}
                    optionLabel="store_name"
                    optionValue="store_id"
                    options={storesList.map(store => ({
                      store_name: `${store?.store_number} - ${store?.store_name}`,
                      store_id: store?.store_id,
                    }))}
                    onChange={e => setSelectStoreIds(e.value)}
                    placeholder="Stores"
                    className="w-full"
                    display="chip"
                    filter={true}
                    appendTo={null}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                    disabled={formAction === 'view'}
                  />
                  <label htmlFor="store_id">Stores</label>
                </span>
              </div>
            </div>
          </>
        </>
      )
    );
  };

  return (
    <>
      {(loading && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item className="w-12">
            <PFTableLoader />
          </Grid>
        </Grid>
      )) || (
        <Grid
          container
          spacing={2}
          direction="column"
          className="w-12 overflow-auto"
        >
          <Grid item classes={{ root: classes.tableWrapper }}>
            {itemsLoading ? (
              <GenericTable
                title="Labor Profile Items"
                columnData={labourProfileItemsColumns}
                rowData={labourProfileItemsData}
                showActions={{
                  view: true,
                  edit: true,
                  delete: true,
                  // clone: true,
                }}
                headerLinks={[
                  {
                    label: 'Add Item',
                    handler: () => {
                      setFormAction('add');
                      laborProfileItemFormik.resetForm();

                      setDialogSettings(prevState => ({
                        ...prevState,
                        showButton2: true,
                        title: 'Add Labor Profile Item',
                      }));
                      setIsOpen(true);
                      setSelectedItem({});
                    },
                  },
                ]}
                hearderSearch={
                  <Grid item>
                    <div className="flex gap-2">
                      <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <PFInputText
                          value={profileItemSearch}
                          onChange={e => {
                            setProfileItemSearch(e.target.value);
                          }}
                          placeholder="Keyword Search"
                        />
                      </span>
                    </div>
                  </Grid>
                }
                searchValue={profileItemSearch}
                setSearchvalue={setProfileItemSearch}
                handleView={index => {
                  viewEditLaborProfileItem('view', index),
                    handleEditItemStoreDropdown(labourProfileItemsData[index]);
                }}
                handleEdit={index => {
                  viewEditLaborProfileItem('edit', index),
                    handleEditItemStoreDropdown(labourProfileItemsData[index]);
                }}
                handleDelete={index => confirmDeleteLaborProfileItem(index)}
              />
            ) : (
              <PFTableLoader />
            )}
          </Grid>

          <PFDialog
            header={dialogSettings.title}
            show={isOpen}
            className="w-11 lg:w-6 xl:w-5"
            onHide={() => {
              setIsOpen(false);
              laborProfileItemFormik?.handleReset();
            }}
            draggable={false}
            pt={{
              headertitle: {
                className: 'headersize',
              },
            }}
            footer={
              <div>
                <PFButton
                  label="Cancel"
                  outlined
                  onClick={() => setIsOpen(false)}
                />

                {formAction !== 'view' && (
                  <PFButton
                    label="Save"
                    onClick={() => laborProfileItemFormik?.handleSubmit()}
                    disabled={
                      (formAction === 'add'
                        ? !laborProfileItemFormik?.dirty
                        : false) ||
                      !laborProfileItemFormik?.isValid ||
                      selectStoreIds?.length === 0
                    }
                  />
                )}
              </div>
            }
            BodyComponent={getLaborprofileItemViewEditForm()}
          ></PFDialog>

          {/* Confirmation dialog for delete */}
          <GenericConfirmationDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            onConfirmDialog={onConfirmDialog}
          />

          <LaborProfileStoreDialog
            storesDialogVisible={storesDialogVisible}
            setStoresDialogVisible={setStoresDialogVisible}
            selectedStoresValues={dialogBody}
          />
        </Grid>
      )}
    </>
  );
};

export default LaborProfileItems;
