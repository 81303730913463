import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { FloatLabel } from 'primereact/floatlabel';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import PFDialog from '../../shared/PFPrime/PFDialog';
import PFButton from '../../shared/PFPrime/PFButton';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import { setFilterTechinicianView } from '../../../redux/slices/scheduler-technician-view.slice';
import { hasAnyValue } from '../../../utils/Helpers';
import { mergeAndDuplicateValues } from '../helpers/scheduler-calendar.helper';
import {
  getSearchStoreList,
  getSearchProjectWorkRoomList,
} from '../services/schedulerMasterServices';

const TechnicianFilter = ({
  showTechnicianFilter,
  handleResetFilters,
  calendarLoading,
  handleSetLoader,
  handleSetFilter,
  filter,
}) => {
  const dispatch = useDispatch();
  const [selectAllTech, setSelectAllTech] = useState(false);
  const [selectedItems, setSelectedItems] = useState(null);

  const { technicians } = useSelector(state => state.technicians);
  const { types } = useSelector(state => state.types);
  const { projectStores } = useSelector(state => state.projectStores);
  const { categories } = useSelector(state => state.categories);
  const { districts } = useSelector(state => state.districts);
  const { userTypes } = useSelector(state => state.userTypes);
  const { workrooms } = useSelector(state => state.workrooms);
  const { isDailogOpen } = useSelector(state => state.schedulerTechnicianView);

  const [storeLoader, setStoreLoader] = useState(false);
  const [workRoomLoader, setWorkRoomLoader] = useState(false);
  const [storeOptions, setStoreOptions] = useState(projectStores || []);
  const [workRoomOptions, setWorkRoomOptions] = useState(workrooms || []);

  useEffect(() => {
    setSelectedItems(
      selectAllTech ? technicians : installerFormik?.values?.filterTechnicial
    );
  }, [selectAllTech]);

  const fetchStore = async value => {
    if (value?.length > 2) {
      const response = await getSearchStoreList({
        searchValue: value,
        setStoreLoader,
      });
      if (response && response?.length) {
        setStoreOptions(response);
      }
    } else {
      setStoreOptions(projectStores);
    }
  };
  const debouncedFetchStore = debounce(filter => {
    fetchStore(filter);
  }, 300);

  const debouncedFetchWorkroom = debounce(filter => {
    fetchWorkroom(filter);
  }, 300);

  const fetchWorkroom = async value => {
    if (value?.length > 2) {
      const response = await getSearchProjectWorkRoomList({
        searchValue: value,
        setWorkRoomLoader,
      });
      if (response && response.length) {
        setWorkRoomOptions(response);
      }
    } else {
      setWorkRoomOptions(workrooms);
    }
  };

  useEffect(() => {
    if (isDailogOpen) {
      setStoreOptions(projectStores || []);
      setWorkRoomOptions(workrooms || []);
    }
  }, [isDailogOpen]);

  const installerFormik = useFormik({
    initialValues: {
      filterTechnicial: '',
      filterProjectType: '',
      filterStore: '',
      filterCategory: '',
      filterDistrict: '',
      filterUserType: '',
      filterWorkroom: '',
    },
    onSubmit: values => {
      const checkValue = hasAnyValue(values);
      dispatch(
        setFilterTechinicianView({
          isTechnicialFilterApplied: checkValue ? true : false,
        })
      );
      const user_id =
        values?.filterTechnicial?.length > 0
          ? values?.filterTechnicial?.map(val => val?.user_id).join(',')
          : null;

      if (user_id?.length > 0) {
        const calendarFilter = { ...filter?.previousData };
        if (values?.filterTechnicial?.length !== 10) {
          calendarFilter.installerIds = user_id;
        }

        handleSetLoader({
          calendarLoader: true,
        });
        // handleSetFilter({
        //   calendarFilter,
        // });
        handleSetFilter({
          calendarFilter,
          filterTechnician: calendarFilter,
        });
        dispatch(
          setFilterTechinicianView({
            isDailogOpen: false,
          })
        );
      } else if (
        (Array.isArray(values?.filterCategory) &&
          values?.filterCategory?.length > 0) ||
        (Array.isArray(values?.filterStore) &&
          values?.filterStore?.length > 0) ||
        (Array.isArray(values?.filterProjectType) &&
          values?.filterProjectType?.length > 0) ||
        (Array.isArray(values?.filterDistrict) &&
          values?.filterDistrict?.length > 0) ||
        (Array.isArray(values?.filterUserType) &&
          values?.filterUserType?.length > 0) ||
        (Array.isArray(values?.filterWorkroom) &&
          values?.filterWorkroom?.length > 0)
      ) {
        const projectTypeId =
          values?.filterProjectType?.length > 0
            ? values?.filterProjectType
                ?.map(val => val?.project_type_id)
                .join(',')
            : '';

        const projectCategoryId =
          values?.filterCategory && values?.filterCategory?.length > 0
            ? values?.filterCategory
                ?.map(val => val?.project_category_id)
                ?.join(',')
            : '';

        const districts =
          values?.filterDistrict?.length > 0
            ? values?.filterDistrict?.filter(item => !!item)?.join(',')
            : '';

        const storeId =
          values?.filterStore?.length > 0
            ? values?.filterStore?.map(val => val?.store_id)?.join(',')
            : '';

        const userTypeId =
          values?.filterUserType.length > 0
            ? values?.filterUserType
                .filter(item => !!item)
                ?.map(item => item.user_type_id)
                ?.join(',')
            : [];

        const selectedWorkroomView =
          values?.filterWorkroom?.length > 0
            ? values?.filterWorkroom
                .filter(workRoom => workRoom?.type_id)
                .map(workRoom => workRoom?.type_id)
                .join(',')
            : '';

        const calendarFilter = {
          projectCategoryId: projectCategoryId
            ? filter?.previousData?.projectCategoryId
              ? mergeAndDuplicateValues(
                  projectCategoryId,
                  filter?.previousData?.projectCategoryId
                )
              : projectCategoryId
            : filter?.previousData?.projectCategoryId,
          projectStoreId: storeId
            ? filter?.previousData?.projectStoreId
              ? mergeAndDuplicateValues(
                  storeId,
                  filter?.previousData?.projectStoreId
                )
              : storeId
            : filter?.previousData?.projectStoreId,

          projectTypeIds: projectTypeId
            ? filter?.previousData?.projectTypeIds
              ? mergeAndDuplicateValues(
                  projectTypeId,
                  filter?.previousData?.projectTypeIds
                )
              : projectTypeId
            : filter?.previousData?.projectTypeIds,
          workRoomIds: selectedWorkroomView,
          districts: districts,
          userTypeIds: userTypeId,
        };
        const selectedFilterKey = {};
        if (projectCategoryId) {
          selectedFilterKey.projectCategoryId = projectCategoryId;
        }
        if (storeId) {
          selectedFilterKey.projectStoreId = storeId;
        }
        if (projectTypeId) {
          selectedFilterKey.projectTypeIds = projectTypeId;
        }
        if (selectedWorkroomView) {
          selectedFilterKey.workRoomIds = selectedWorkroomView;
        }
        if (districts) {
          selectedFilterKey.districts = districts;
        }
        if (userTypeId) {
          selectedFilterKey.userTypeIds = userTypeId;
        }
        handleSetLoader({
          calendarLoader: true,
        });
        handleSetFilter({
          calendarFilter,
          filterTechnician: selectedFilterKey,
        });
        dispatch(
          setFilterTechinicianView({
            isDailogOpen: false,
          })
        );
      }
    },
  });
  useEffect(() => {
    setSelectedItems(
      selectAllTech ? technicians : installerFormik?.values?.filterTechnicial
    );
  }, [selectAllTech]);

  const handleReset = () => {
    handleSetLoader({
      calendarLoader: true,
    });
    handleResetFilters();
    installerFormik.handleReset();
    setSelectedItems([]);
    setSelectAllTech(false);
    dispatch(
      setFilterTechinicianView({
        isDailogOpen: false,
        isTechnicialFilterApplied: false,
      })
    );
  };
  return (
    <PFDialog
      header="Filter Technician"
      show={isDailogOpen}
      className="w-11 lg:w-8"
      hide={() => {
        dispatch(
          setFilterTechinicianView({
            isDailogOpen: false,
          })
        );
      }}
      draggable={false}
      pt={{
        headertitle: {
          className: 'text-2xl',
        },
      }}
      BodyComponent={
        <>
          <form
            className="w-full"
            onSubmit={event => {
              event.preventDefault();
              installerFormik.handleSubmit();
            }}
          >
            <div className="grid overflow-y-auto">
              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    value={selectedItems || []}
                    onChange={e => {
                      const selectedItems = e?.value;
                      const selectedItemsLength = selectedItems?.length;
                      installerFormik.setFieldValue(
                        'filterTechnicial',
                        selectedItemsLength === technicians?.length
                          ? technicians
                          : selectedItems
                      );
                      setSelectedItems(selectedItems);
                      setSelectAllTech(
                        selectedItemsLength === technicians.length
                      );
                    }}
                    options={technicians || []}
                    optionLabel="full_name"
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={true}
                    selectedItemsLabel={
                      selectAllTech
                        ? `All Selected (${installerFormik?.values?.filterTechnicial.length})`
                        : `${installerFormik?.values?.filterTechnicial.length} Selected`
                    }
                    selectAll={selectAllTech}
                    onSelectAll={e => {
                      setSelectedItems(
                        e?.checked ? [] : technicians?.map(item => item)
                      );
                      installerFormik.setFieldValue(
                        'filterTechnicial',
                        !e?.checked ? technicians : []
                      );
                      setSelectAllTech(!e?.checked);
                    }}
                    virtualScrollerOptions={{ itemSize: 50 }}
                    disabled={
                      installerFormik?.values?.filterCategory?.length > 0 ||
                      installerFormik?.values?.filterStore?.length > 0 ||
                      installerFormik?.values?.filterProjectType?.length > 0 ||
                      installerFormik?.values?.filterDistrict?.length > 0 ||
                      installerFormik?.values?.filterUserType?.length > 0
                        ? true
                        : false
                    }
                    maxSelectedLabels={2}
                    placeholder="Select Technicians"
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="technician">Technician</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    value={installerFormik?.values?.filterProjectType || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterProjectType', value);
                    }}
                    options={types || []}
                    optionLabel="project_type"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0
                        ? true
                        : false
                    }
                    pt={{
                      root: {
                        className: 'w-full',
                      },
                    }}
                  />
                  <label htmlFor="projectType">Type</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="store"
                    className="w-full"
                    value={installerFormik.values?.filterStore || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterStore', value);
                    }}
                    options={
                      Array.isArray(storeOptions)
                        ? storeOptions?.map(store => ({
                            ...store,
                            label: `${store.store_number} - ${store.store_name}`,
                          }))
                        : []
                    }
                    optionLabel="label"
                    maxSelectedLabels={1}
                    filter
                    filterPlaceholder="Type to search..."
                    onFilter={e => {
                      debouncedFetchStore(e.filter);
                    }}
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0 ||
                      installerFormik?.values?.filterDistrict?.length > 0
                        ? true
                        : false
                    }
                    appendTo={null}
                    loading={storeLoader}
                  />
                  <label htmlFor="store">Store</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="category"
                    className="w-full"
                    value={installerFormik?.values?.filterCategory || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterCategory', value);
                    }}
                    options={categories || []}
                    optionLabel="category"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0
                        ? true
                        : false
                    }
                    pt={{
                      panel: {
                        className: 'max-w-26rem',
                      },
                    }}
                    appendTo={null}
                  />
                  <label htmlFor="category">Category</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="district"
                    className="w-full"
                    value={installerFormik?.values?.filterDistrict || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterDistrict', value);
                    }}
                    options={districts || []}
                    optionLabel="district"
                    optionValue="district"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0 ||
                      installerFormik?.values?.filterStore?.length > 0
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="district">District</label>
                </FloatLabel>
              </div>

              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="userType"
                    className="w-full"
                    value={installerFormik?.values?.filterUserType || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterUserType', value);
                    }}
                    options={userTypes || []}
                    optionLabel="label"
                    maxSelectedLabels={2}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="userType">User Type</label>
                </FloatLabel>
              </div>
              <div className="col-12 lg:col-6 mt-2">
                <FloatLabel>
                  <PFMultiSelect
                    id="workroom"
                    className="w-full"
                    value={installerFormik?.values?.filterWorkroom || []}
                    onChange={e => {
                      const value = e?.value;
                      installerFormik.setFieldValue('filterWorkroom', value);
                    }}
                    options={workRoomOptions || []}
                    optionLabel="label"
                    maxSelectedLabels={2}
                    onFilter={e => {
                      debouncedFetchWorkroom(e?.filter);
                    }}
                    filter
                    filterPlaceholder="Type to search..."
                    showSelectAll={false}
                    disabled={
                      installerFormik?.values?.filterTechnicial?.length > 0 ||
                      installerFormik?.values?.filterDistrict?.length > 0
                        ? true
                        : false
                    }
                    loading={workRoomLoader}
                  />
                  <label htmlFor="workroom">Workroom</label>
                </FloatLabel>
              </div>
            </div>
            <div className="w-12 flex justify-content-end mt-4">
              <PFButton
                outlined
                label="Filter Reset"
                icon={`${calendarLoading ? 'pi pi-spin pi-spinner' : null}`}
                onClick={() => handleReset()}
                className="pr-4"
              />
              <PFButton
                primary
                type="submit"
                label="Apply Filters"
                className="ml-2"
              />
            </div>
          </form>
        </>
      }
    ></PFDialog>
  );
};
export default TechnicianFilter;
