import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Dialog } from 'primereact/dialog';

import PFInputText from '../../shared/PFPrime/PFInputText';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFButton from '../../shared/PFPrime/PFButton';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import { getNotesTypeOptions } from '../Notes/Notes.service';
import PFFormikError from '../ProjectItemsNewUI/PFFormikError';
import PFCalendar from '../../shared/PFPrime/PFCalendar';
import { QUICK_ACTION_TYPE } from '../../../constants';
import PFDialog from '../../shared/PFPrime/PFDialog';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';

import { groupTypes } from './ProjectDetails.service';

const ActionNoteDialog = props => {
  const {
    bustedJobDialogVisible,
    setBustedJobDialogVisible,
    projectInstaller,
    projectId,
    notesFormik,
  } = props;
  const [reasonOptions, setReasonOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = notesFormik();
  const bustedReason = async () => {
    try {
      const response = await groupTypes(QUICK_ACTION_TYPE.BUSTED_JOB);
      if (response?.status && response?.data) {
        setReasonOptions(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    bustedReason();
    getNotesTypeOptions(setTypeOptions);
  }, []);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, []);

  const projectInstallerArray = useMemo(() => {
    return (
      Array.isArray(projectInstaller) &&
      projectInstaller?.map(installer => {
        const user_id = installer?.installer_detail?.user_id;
        return {
          installer_id: installer?.installer_id,
          project_installer_id: installer?.project_installer_id,
          installer_name: `${installer?.installer_detail?.user?.first_name} - ${installer?.installer_detail?.user?.last_name}`,
          user_id,
          worktypeid: installer?.worktypeid,
        };
      })
    );
  }, [projectInstaller]);
  const BodyComponent = () => (
    <>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="grid pt-2">
          {props?.quickActionReq === QUICK_ACTION_TYPE.BUSTED_JOB && (
            <>
              <div className="col-12">
                <span className="p-float-label">
                  <PFMultiSelect
                    value={values?.installers || []}
                    optionLabel="installer_name"
                    options={projectInstallerArray || []}
                    onChange={e => {
                      if (
                        props?.quickActionReq === QUICK_ACTION_TYPE.BUSTED_JOB
                      ) {
                        setFieldValue('installers', e.value || []);
                      }
                    }}
                    filter
                    className="w-full height-40"
                    showSelectAll={false}
                    name="installers"
                    pt={{
                      label: {
                        className: 'text-sm',
                      },
                      trigger: {
                        className: 'height-40',
                      },
                    }}
                  />
                  <label htmlFor="installers">
                    Busted Technician <span className="text-red-600">*</span>
                  </label>
                </span>
                <PFFormikError
                  touched={touched}
                  errors={errors}
                  field="installers"
                />
              </div>
              <div className="col-12">
                <span className="p-float-label">
                  <PFDropdown
                    optionLabel="label"
                    optionValue="type_id"
                    options={reasonOptions}
                    value={values?.busted_reason_id || ''}
                    name="busted_reason_id"
                    onChange={(name, value) => setFieldValue(name, value)}
                  />
                  <label htmlFor="busted-reason">Busted Reason</label>
                  <PFFormikError
                    touched={touched}
                    errors={errors}
                    field="busted_reason_id"
                  />
                </span>
              </div>
              <div className="col-12 flex">
                <span className="mr-3 flex align-items-center">
                  <label htmlFor="update-technician-work-type">
                    Update Technician Work Type
                  </label>
                </span>
                <span className="flex align-items-center">
                  <PFInputSwitch
                    name="update_technician_worktype"
                    checked={values?.update_technician_worktype || false}
                    onChange={e =>
                      setFieldValue('update_technician_worktype', e.value)
                    }
                  />
                </span>
              </div>
              <div className="col-12 flex">
                <span className="mr-3 flex align-items-center">
                  <label htmlFor="wipe_off_project_item">
                    Wipe Off Labor Amount & Assignments
                  </label>
                </span>
                <span className="flex align-items-center">
                  <PFInputSwitch
                    name="wipe_off_project_item"
                    checked={values?.wipe_off_project_item || false}
                    onChange={e =>
                      setFieldValue('wipe_off_project_item', e.value)
                    }
                  />
                </span>
              </div>
            </>
          )}

          <div className="col-12">
            <span className="p-float-label">
              <PFInputText
                name="note_text"
                onChange={handleChange}
                value={values?.note_text || ''}
              />
              <label htmlFor="note">
                Note <span className="text-red-600">*</span>
              </label>
            </span>
            <PFFormikError
              touched={touched}
              errors={errors}
              field="note_text"
            />
          </div>
          {props?.quickActionReq === QUICK_ACTION_TYPE.READY_TO_SCHEDULE && (
            <div className="col-12">
              <PFCalendar
                appendTo={null}
                minDate={new Date()}
                label="Follow Up Date"
                placeholder="Follow Up Date"
                name="follow_up_date"
                onChange={e => setFieldValue(e.target.name, e.target.value)}
                value={values?.follow_up_date || ''}
              />
            </div>
          )}

          <div className="col-12 flex justify-content-end flex-wrap">
            <PFButton label="Save" onClick={handleSubmit} />
          </div>
        </div>
      </form>
    </>
  );
  return (
    <PFDialog
      header={`${
        props?.quickActionReq === QUICK_ACTION_TYPE.BUSTED_JOB
          ? QUICK_ACTION_TYPE.BUSTED_TITLE
          : QUICK_ACTION_TYPE.NOTES_TITLE
      }`}
      visible={bustedJobDialogVisible}
      className="w-11 lg:w-6 xl:w-4"
      onHide={() => {
        if (!bustedJobDialogVisible) return;
        setBustedJobDialogVisible(false);
        resetForm();
      }}
      draggable={false}
      pt={{
        root: {
          className: 'border-noround',
        },
      }}
      BodyComponent={BodyComponent()}
    ></PFDialog>
  );
};

export default ActionNoteDialog;
