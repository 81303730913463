import React from 'react';
import { Link } from 'react-router-dom';

/**Custom Components */
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Paginator } from 'primereact/paginator';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';

import PFDataTable from '../../shared/PFPrime/PFDataTable';
import PFButton from '../../shared/PFPrime/PFButton';
import PFCheckbox from '../../shared/PFPrime/PFCheckbox';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import {
  checkPermission,
  dateFormatter,
  formatCurrency,
  formatDateTimeMDYHM,
} from '../../../utils/Helpers';

/**Service Import */
import permissions from '../../../config/permissions';
import {
  PayrollFooters,
  PayrollReviewType,
  PayrollType,
  PayrollRecordType,
  RETAINAGE_PAYOUT_TYPE_OPTION,
  CHARGEBACK_TYPE,
  CHARGEBACK_PAYOUT_TYPE_OPTION,
} from '../../../constants';

import { payrollAction } from './services/PayrollService';

/**Prime Imports */

const SearchResult = ({
  searchResult,
  isLoading,
  fetchSearchResult,
  toast,
  setIsLoading,
  setCurrentOffset,
  revAppExcSearchObj,
  currentOffset,
  searchString,
  setSearchString,
  setReportSearchString,
  reportSearchString,
  first,
  setFirst,
  paginationRows,
  setPaginationRows,
  paginationPage,
  setPaginationPage,
  previousTechnician,
}) => {
  const [showOptions, setShowOptions] = React.useState(PayrollType);
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const [removeAllColumns, setRemoveAllColumns] = React.useState(false);

  const onPageChange = event => {
    setPaginationPage(event?.page + 1);
    setPaginationRows(event?.rows);
    setFirst(event?.first);
    fetchSearchResult(
      searchResult?.offset,
      searchString,
      false,
      searchResult?.data[0]?.user_id,
      event?.page + 1,
      event?.rows,
      searchResult?.prevOffset
    );
  };
  //Helper to show/hide line items
  const isShowLineItems = () => {
    return showOptions.includes('showLineItems');
  };

  //Helper to review,approve and exclude report records
  const payrollReportAction = async (data, action, checked) => {
    try {
      setIsLoading(true);
      let response = await payrollAction(data);
      let message = checked
        ? response?.data?.action?.[0].toUpperCase() +
          response?.data?.action.slice(1) +
          ' action performed successfully.'
        : response?.data?.action?.[0].toUpperCase() +
          response?.data?.action.slice(1) +
          ' action rolled back successfully.';

      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: message,
      });
      fetchSearchResult(
        currentOffset,
        searchString,
        false,
        searchResult?.data[0]?.user_id,
        paginationPage,
        paginationRows,
        searchResult?.prevOffset
      );
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //Helper to set data for review,approve and exclude individual record
  const actionColumnEvent = (rowData, action, event) => {
    let searchObject = { ...revAppExcSearchObj, technician: rowData?.user_id };
    let actionObject = {
      action: action,
      actionValue: event?.checked === false ? 0 : 1,
      userId: rowData?.user_id,
      searchObject: searchObject,
    };
    if (!isShowLineItems() && rowData?.charge_type === 'Labor') {
      actionObject = {
        ...actionObject,
        laborItems: rowData?.lineItems?.map(item => item?.project_item_user_id),
      };
    } else {
      if (rowData?.project_item_user_id) {
        actionObject = {
          ...actionObject,
          laborItems: [rowData?.project_item_user_id],
        };
      } else if (rowData?.chargeback_payout_id) {
        actionObject = {
          ...actionObject,
          chargebackIds: [rowData?.chargeback_payout_id],
        };
      } else if (
        rowData?.retainage_payout_id ||
        rowData?.isPercentageOfPayroll
      ) {
        if (rowData?.retainage_payout_id === null && action === 'approve') {
          actionObject = {
            ...actionObject,
            retainages: [rowData?.approve_string],
          };
        } else {
          actionObject = {
            ...actionObject,
            retainageIds: [rowData?.retainage_payout_id],
          };
        }
      } else if (
        rowData?.isCollectFromRetainageChargeback ||
        rowData?.isPercentageOfPayrollChargeback
      ) {
        if (rowData?.chargeback_payout_id === null && action === 'approve') {
          actionObject = {
            ...actionObject,
            chargebacks: [rowData?.approve_string],
          };
        } else {
          actionObject = {
            ...actionObject,
            chargebackIds: [rowData?.chargeback_payout_id],
          };
        }
      }
    }
    payrollReportAction(actionObject, action, event?.checked);
  };

  //Helper to set data for review,approve and exclude for selected technician
  const headerActionEvent = (action, e, data) => {
    let searchObject = {
      ...revAppExcSearchObj,
      technician: data?.[0]?.user_id,
    };
    let actionObject = {
      action: action,
      actionValue: e?.checked === false ? 0 : 1,
      userId: data?.[0]?.user_id,
      searchObject: searchObject,
    };
    const laborItems = [];
    const retainageIds = [];
    const chargebackIds = [];
    const retainages = [];
    const chargebacks = [];
    if (!isShowLineItems()) {
      data?.map(rowData => {
        if (rowData?.lineItems?.length) {
          rowData?.lineItems?.map(line =>
            laborItems?.push(line?.project_item_user_id)
          );
        } else if (rowData?.chargeback_payout_id) {
          chargebackIds.push(rowData?.chargeback_payout_id);
        } else if (rowData.chargeback_payout_id === null) {
          chargebacks.push(rowData?.approve_string);
        } else if (rowData?.retainage_payout_id) {
          retainageIds.push(rowData?.retainage_payout_id);
        } else if (rowData.retainage_payout_id === null) {
          retainages.push(rowData?.approve_string);
        }
      });
    } else {
      data?.map(rowData => {
        if (rowData?.approve === 0 || rowData?.approve === null) {
          if (rowData?.project_item_user_id) {
            laborItems.push(rowData?.project_item_user_id);
          } else if (rowData?.chargeback_payout_id) {
            chargebackIds.push(rowData?.chargeback_payout_id);
          } else if (rowData.chargeback_payout_id === null) {
            chargebacks.push(rowData?.approve_string);
          } else if (rowData?.retainage_payout_id) {
            retainageIds.push(rowData?.retainage_payout_id);
          } else if (rowData.retainage_payout_id === null) {
            retainages.push(rowData?.approve_string);
          }
        }
      });
    }

    if (laborItems?.length) {
      actionObject = {
        ...actionObject,
        laborItems: laborItems,
      };
    }
    if (retainageIds?.length) {
      actionObject = {
        ...actionObject,
        retainageIds: retainageIds,
      };
    }
    if (retainages?.length) {
      actionObject = {
        ...actionObject,
        retainages: retainages,
      };
    }
    if (chargebackIds?.length) {
      actionObject = {
        ...actionObject,
        chargebackIds: chargebackIds,
      };
    }
    if (chargebacks?.length) {
      actionObject = {
        ...actionObject,
        chargebacks: chargebacks,
      };
    }
    payrollReportAction(actionObject, action, e?.checked);
  };

  //Helper to set data for review all,approve all and exclude all buttons
  const headerButtonsEvent = action => {
    let searchObject = {
      ...revAppExcSearchObj,
      technician: searchResult?.data?.[0]?.user_id,
    };
    let actionObject = {
      action: action,
      actionValue: 1,
      searchObject: searchObject,
      userId: searchResult?.data?.[0]?.user_id,
    };
    payrollReportAction(actionObject, action, true);
  };

  const disableReviewAndExcludeCheckbox = rowData => {
    return (
      (isShowLineItems() &&
        rowData?.charge_type === 'Labor' &&
        !rowData?.lineItems?.length &&
        !rowData?.item) ||
      (!isShowLineItems() && rowData?.charge_type === 'Labor'
        ? !rowData?.lineItems?.length ||
          rowData?.lineItems?.filter(item => item?.approve === 1)?.length ===
            rowData?.lineItems?.length
        : rowData?.approve) ||
      (rowData?.isPercentageOfPayroll &&
        rowData?.isPercentageOfPayroll === 1) ||
      (rowData?.isCollectFromRetainageChargeback &&
        rowData?.isCollectFromRetainageChargeback === 1) ||
      (rowData?.isPercentageOfPayrollChargeback &&
        rowData?.isPercentageOfPayrollChargeback === 1)
    );
  };

  // Show/hide selected columns
  const showColumns = (field, lineItemCol = '') => {
    let style = {};
    if (removeAllColumns) {
      return (style = { display: 'none' });
    }
    if (lineItemCol) {
      isShowLineItems() && selectedColumns?.length === 0
        ? (style = { display: 'table-cell' })
        : isShowLineItems() &&
            selectedColumns?.length &&
            selectedColumns?.filter(col => col?.field === field)?.length > 0
          ? (style = { display: 'table-cell' })
          : (style = { display: 'none' });
    } else {
      selectedColumns?.length === 0
        ? (style = { display: 'table-cell' })
        : selectedColumns?.length &&
            selectedColumns?.filter(col => col?.field === field)?.length > 0
          ? (style = { display: 'table-cell' })
          : (style = { display: 'none' });
    }

    style = {
      ...style,
      'text-transform': 'capitalize',
    };
    if (field === 'item_desc') {
      style = {
        ...style,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '180px',
      };
    }
    return style;
  };

  const addColspan = field => {
    if (selectedColumns?.slice(-1)?.[0]?.field === field) {
      return isShowLineItems()
        ? 10 + selectedColumns?.length
        : 6 + selectedColumns?.length;
    } else {
      return 0;
    }
  };

  const renderEllipsisWithTooltip = val => {
    return (
      <>
        <span
          className={'item_desc'}
          data-pr-tooltip={val}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {val}
        </span>
        <Tooltip target={'.item_desc'} position="bottom" />
      </>
    );
  };

  const tableColumns = [
    {
      field: 'review',
      header: 'Review',
      frozen: true,
      action: true,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : !PayrollFooters.filter(footer => footer?.key === rowData?.name)
            ?.length ? (
          <PFCheckbox
            checked={
              !isShowLineItems() && rowData?.charge_type === 'Labor'
                ? rowData?.lineItems?.length &&
                  !rowData?.lineItems?.find(
                    item => item?.review === 0 || item?.review === null
                  )
                : rowData?.review === 1
                  ? true
                  : false
            }
            disabled={
              !checkPermission(permissions?.payroll?.review) ||
              disableReviewAndExcludeCheckbox(rowData)
            }
            onChange={e => {
              {
                actionColumnEvent(rowData, 'review', e);
              }
            }}
          />
        ) : (
          ''
        );
      },
    },
    {
      field: 'approve',
      header: 'Approve',
      frozen: true,
      action: true,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : !PayrollFooters.filter(footer => footer?.key === rowData?.name)
            ?.length ? (
          <PFCheckbox
            disabled={
              !checkPermission(permissions?.payroll?.approve) ||
              (isShowLineItems() &&
                rowData?.charge_type === 'Labor' &&
                !rowData?.lineItems?.length &&
                !rowData?.item) ||
              (!isShowLineItems() && rowData?.charge_type === 'Labor'
                ? !rowData?.lineItems?.length ||
                  rowData?.lineItems?.filter(
                    item => item?.approve === 1 || item?.exclude === 1
                  )?.length === rowData?.lineItems?.length
                : rowData?.approve || rowData?.exclude)
            }
            checked={
              !isShowLineItems() && rowData?.charge_type === 'Labor'
                ? rowData?.lineItems?.length &&
                  !rowData?.lineItems?.find(
                    item => item?.approve === 0 || item?.approve === null
                  )
                : rowData?.approve === 1
                  ? true
                  : false
            }
            onChange={e => {
              actionColumnEvent(rowData, 'approve', e);
            }}
          />
        ) : (
          ''
        );
      },
    },
    {
      field: 'exclude',
      header: 'Exclude',
      frozen: true,
      action: true,
      pt: { bodycell: { colSpan: removeAllColumns ? 15 : 0 } },
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : !PayrollFooters.filter(footer => footer?.key === rowData?.name)
            ?.length ? (
          <PFCheckbox
            disabled={
              !checkPermission(permissions?.payroll?.exclude) ||
              disableReviewAndExcludeCheckbox(rowData)
            }
            checked={
              !isShowLineItems() && rowData?.charge_type === 'Labor'
                ? rowData?.lineItems?.length &&
                  !rowData?.lineItems?.find(
                    item => item?.exclude === 0 || item?.exclude === null
                  )
                : rowData?.exclude === 1
                  ? true
                  : false
            }
            onChange={e => {
              actionColumnEvent(rowData, 'exclude', e);
            }}
          />
        ) : (
          ''
        );
      },
    },
    {
      field: 'charge_type',
      header: 'Charge Type',
      sortable: false,
      filter: false,
      style: showColumns('charge_type'),
      colSpan: addColspan('charge_type'),
      pt: {
        bodycell: { colSpan: addColspan('charge_type') },
        headercell: { style: { minWidth: '160px' } },
      },
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : PayrollFooters.filter(footer => footer?.key === rowData?.name)
            ?.length ? (
          PayrollFooters.find(footer => footer?.key === rowData?.name)?.label
        ) : (
          rowData?.charge_type
        );
      },
    },

    {
      field: 'completion_date',
      header: 'Completion Date',
      style: showColumns('completion_date', true),
      colSpan: addColspan('completion_date'),
      pt: { bodycell: { colSpan: addColspan('completion_date') } },
      lineItemCol: true,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.completion_date;
      },
    },
    {
      field: 'completed_status',
      header: 'Completion Status',
      style: showColumns('completed_status', true),
      colSpan: addColspan('completed_status'),
      pt: { bodycell: { colSpan: addColspan('completed_status') } },
      lineItemCol: true,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.completed_status;
      },
    },
    {
      field: 'item',
      header: 'Item #',
      lineItemCol: true,
      style: showColumns('item', true),
      colSpan: addColspan('item'),
      pt: { bodycell: { colSpan: addColspan('item') } },
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.item;
      },
    },
    {
      field: 'item_desc',
      header: 'Item Description',
      lineItemCol: true,
      style: showColumns('item_desc', true),
      colSpan: addColspan('item_desc'),
      pt: { bodycell: { colSpan: addColspan('item_desc') } },
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : (
          renderEllipsisWithTooltip(rowData?.item_desc)
        );
      },
    },

    {
      field: 'total_retail',
      header: isShowLineItems() ? 'Labor Revenue' : 'Total Revenue',
      sortable: false,
      filter: false,
      style: { ...showColumns('total_retail'), textAlign: 'right' },
      colSpan: addColspan('total_retail'),
      pt: { bodycell: { colSpan: addColspan('total_retail') } },
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : rowData?.charge_type === 'Labor' &&
          rowData?.lineItems?.length === 0 ? (
          ''
        ) : (
          rowData?.total_retail
        );
      },
    },
    {
      field: 'total_cost',
      header: isShowLineItems() ? 'Labor Cost' : 'Total Cost',
      style: { ...showColumns('total_cost'), textAlign: 'right' },
      colSpan: addColspan('total_cost'),
      pt: { bodycell: { colSpan: addColspan('total_cost') } },
      sortable: false,
      filter: false,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : rowData?.charge_type === 'Labor' &&
          rowData?.lineItems?.length === 0 ? (
          ''
        ) : (
          rowData?.total_cost
        );
      },
    },
    {
      field: 'total_profit',
      header: isShowLineItems() ? 'Labor Profit' : 'Total Profit',
      style: { ...showColumns('total_profit'), textAlign: 'right' },
      colSpan: addColspan('total_profit'),
      pt: { bodycell: { colSpan: addColspan('total_profit') } },
      sortable: false,
      filter: false,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : rowData?.charge_type === 'Labor' &&
          rowData?.lineItems?.length === 0 ? (
          ''
        ) : (
          rowData?.total_profit
        );
      },
    },
    {
      field: 'total_margin',
      style: { ...showColumns('total_margin'), textAlign: 'right' },
      colSpan: addColspan('total_margin'),
      pt: { bodycell: { colSpan: addColspan('total_margin') } },
      header: isShowLineItems() ? 'Labor Margin' : 'Total Margin',
      sortable: false,
      filter: false,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : rowData?.charge_type === 'Labor' &&
          rowData?.lineItems?.length === 0 ? (
          ''
        ) : (
          rowData?.total_margin
        );
      },
    },
    {
      field: 'net_pay',
      header: 'Net Pay',
      colSpan: addColspan('net_pay'),
      pt: {
        bodycell: { colSpan: addColspan('net_pay') },
        headercontent: { style: { display: 'block' } },
      },
      sortable: false,
      style: {
        ...showColumns('net_pay'),
        textAlign: 'right',
      },
      filter: false,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : rowData?.charge_type === 'Labor' &&
          rowData?.lineItems?.length === 0 ? (
          ''
        ) : (
          rowData?.net_pay
        );
      },
    },
    {
      field: 'work_room',
      header: 'Workroom',
      sortable: false,
      style: showColumns('work_room'),
      colSpan: addColspan('work_room'),
      pt: { bodycell: { colSpan: addColspan('work_room') } },
      filter: false,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.workroom;
      },
    },
    {
      field: 'category',
      header: 'Category',
      sortable: false,
      style: showColumns('category'),
      colSpan: addColspan('category'),
      pt: { bodycell: { colSpan: addColspan('category') } },
      filter: false,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.category;
      },
    },
    {
      field: 'project',
      header: 'Project #',
      style: showColumns('project'),
      colSpan: addColspan('project'),
      pt: { bodycell: { colSpan: addColspan('project') } },
      sortable: false,
      filter: false,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : (
          <Link target="_blank" to={`/project/view/${rowData?.project_id}`}>
            {rowData?.project}
          </Link>
        );
      },
    },
    {
      field: 'po',
      header: 'PO #',
      sortable: false,
      style: showColumns('po'),
      colSpan: addColspan('po'),
      pt: { bodycell: { colSpan: addColspan('po') } },
      filter: false,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : (
          <Link target="_blank" to={`/project/view/${rowData?.project_id}`}>
            {rowData?.po}
          </Link>
        );
      },
    },

    {
      field: 'status',
      header: 'Status',
      style: showColumns('status'),
      colSpan: addColspan('status'),
      pt: { bodycell: { colSpan: addColspan('status') } },
      sortable: false,
      filter: false,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.status;
      },
    },
    {
      field: 'source_status',
      header: 'Source Status',
      style: showColumns('source_status'),
      colSpan: addColspan('source_status'),
      pt: { bodycell: { colSpan: addColspan('source_status') } },
      sortable: false,
      filter: false,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.source_status;
      },
    },
    {
      field: 'store',
      header: 'Store',
      sortable: false,
      filter: false,
      style: showColumns('store'),
      colSpan: addColspan('store'),
      pt: { bodycell: { colSpan: addColspan('store') } },
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : (
          <Link target="_blank" to={`/stores/view/${rowData?.store_id}`}>
            {rowData?.store}
          </Link>
        );
      },
    },
    {
      field: 'storeName',
      header: 'Store Name',
      style: showColumns('storeName'),
      colSpan: addColspan('storeName'),
      pt: { bodycell: { colSpan: addColspan('storeName') } },
      sortable: false,
      filter: false,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : (
          <Link target="_blank" to={`/stores/view/${rowData?.store_id}`}>
            {rowData?.storeName}
          </Link>
        );
      },
    },
    {
      field: 'customerName',
      header: 'Customer Name',
      style: showColumns('customerName'),
      colSpan: addColspan('customerName'),
      pt: { bodycell: { colSpan: addColspan('customerName') } },
      sortable: false,
      filter: false,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : (
          <Link
            target="_blank"
            to={`/crm/view/customer/${rowData?.customer_id}`}
          >
            {rowData?.customerName}
          </Link>
        );
      },
    },
    {
      field: 'project_type',
      header: 'Type',
      sortable: false,
      style: showColumns('project_type'),
      colSpan: addColspan('project_type'),
      pt: { bodycell: { colSpan: addColspan('type') } },
      filter: false,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.project_type;
      },
    },
    {
      field: 'technician_type',
      header: 'Technician Type',
      sortable: false,
      style: showColumns('technician_type'),
      colSpan: addColspan('technician_type'),
      pt: { bodycell: { colSpan: addColspan('technician_type') } },
      filter: false,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.technician_type;
      },
    },
    {
      field: 'key_rec_date',
      header: 'Key Rec Date',
      sortable: false,
      style: showColumns('key_rec_date'),
      colSpan: addColspan('key_rec_date'),
      pt: { bodycell: { colSpan: addColspan('key_rec_date') } },
      filter: false,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.key_rec_date;
      },
    },
    {
      field: 'key_rec_number',
      header: 'Key Rec Number',
      sortable: false,
      style: showColumns('key_rec_number'),
      colSpan: addColspan('key_rec_number'),
      pt: { bodycell: { colSpan: addColspan('key_rec_number') } },
      filter: false,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.key_rec_number;
      },
    },
    {
      field: 'key_rec_cost',
      header: 'Key Rec Amount',
      sortable: false,
      style: showColumns('key_rec_cost'),
      colSpan: addColspan('key_rec_cost'),
      pt: { bodycell: { colSpan: addColspan('key_rec_cost') } },
      filter: false,
      body: rowData => {
        return isLoading ? <Skeleton /> : rowData?.key_rec_cost;
      },
    },
    {
      field: 'reviewLogs',
      header: 'Review Logs',
      sortable: false,
      filter: false,
      style: showColumns('reviewLogs'),
      colSpan: addColspan('reviewLogs'),
      pt: { bodycell: { colSpan: addColspan('reviewLogs') } },
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : !isShowLineItems() &&
          rowData?.charge_type === 'Labor' &&
          rowData?.lineItems?.length ? (
          rowData?.lineItems?.[0]?.reviewLogs
        ) : (
          rowData?.reviewLogs
        );
      },
    },
    {
      field: 'approveLogs',
      header: 'Approve Logs',
      style: showColumns('approveLogs'),
      colSpan: addColspan('approveLogs'),
      pt: { bodycell: { colSpan: addColspan('approveLogs') } },
      sortable: false,
      filter: false,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : !isShowLineItems() &&
          rowData?.charge_type === 'Labor' &&
          rowData?.lineItems?.length ? (
          rowData?.lineItems?.[0]?.approveLogs
        ) : (
          rowData?.approveLogs
        );
      },
    },
    {
      field: 'excludeLogs',
      header: 'Exclude Logs',
      style: showColumns('excludeLogs'),
      colSpan: addColspan('excludeLogs'),
      pt: { bodycell: { colSpan: addColspan('excludeLogs') } },
      sortable: false,
      filter: false,
      body: rowData => {
        return isLoading ? (
          <Skeleton />
        ) : !isShowLineItems() &&
          rowData?.charge_type === 'Labor' &&
          rowData?.lineItems?.length ? (
          rowData?.lineItems?.[0]?.excludeLogs
        ) : (
          rowData?.excludeLogs
        );
      },
    },
  ];

  const renderHeader = () => {
    const [paidUnpaid, setPaidUnpaid] = React.useState(PayrollReviewType);
    const [excludedUnexcluded, setExcludedUnexcluded] =
      React.useState(PayrollRecordType);

    // Set search string for Paid/Unpaid checkbox buttons
    const paidUnpaidButtonEvent = e => {
      let _paidUnpaid = [...paidUnpaid];
      let searchObject = {};
      let reportSearchObject = {};

      if (e.checked) _paidUnpaid.push(e.value);
      else _paidUnpaid.splice(_paidUnpaid.indexOf(e.value), 1);

      setPaidUnpaid(_paidUnpaid);

      if (_paidUnpaid?.length !== 0 && _paidUnpaid?.length !== 2) {
        setSearchString(prevState => ({
          ...prevState,
          paymentStatus: _paidUnpaid.includes(PayrollReviewType[0]) ? 1 : 0,
        }));
        setReportSearchString(prevState => ({
          ...prevState,
          paymentStatus: _paidUnpaid.includes(PayrollReviewType[0]) ? 1 : 0,
          filter: {
            ...prevState?.filter,
            paymentStatus: _paidUnpaid.includes(PayrollReviewType[0]) ? 1 : 0,
          },
        }));
        searchObject = {
          ...searchString,
          paymentStatus: _paidUnpaid.includes(PayrollReviewType[0]) ? 1 : 0,
        };
      } else {
        searchObject = { ...searchString };
        reportSearchObject = { ...reportSearchString };

        if (searchObject.hasOwnProperty('paymentStatus')) {
          delete searchObject['paymentStatus'];
        }

        if (reportSearchObject.hasOwnProperty('paymentStatus')) {
          delete reportSearchObject['paymentStatus'];
          delete reportSearchObject?.filter['exclude'];
        }
        setSearchString(searchObject);
        setReportSearchString(reportSearchObject);
      }

      fetchSearchResult('', searchObject, false);
    };

    // Set search string for Excluded/Unexcluded checkbox buttons
    const excludedUnexcludedEvent = e => {
      let _excludedUnexcluded = [...excludedUnexcluded];
      let searchObject = {};
      let reportSearchObject = {};

      if (e.checked) _excludedUnexcluded.push(e.value);
      else _excludedUnexcluded.splice(_excludedUnexcluded?.indexOf(e.value), 1);

      setExcludedUnexcluded(_excludedUnexcluded);

      if (
        _excludedUnexcluded?.length !== 0 &&
        _excludedUnexcluded?.length !== 2
      ) {
        setSearchString(prevState => ({
          ...prevState,
          exclude: _excludedUnexcluded.includes(PayrollRecordType[0]) ? 1 : 0,
        }));
        setReportSearchString(prevState => ({
          ...prevState,
          exclude: _excludedUnexcluded.includes(PayrollRecordType[0]) ? 1 : 0,
          filter: {
            ...prevState?.filter,
            exclude: _excludedUnexcluded.includes(PayrollRecordType[0]) ? 1 : 0,
          },
        }));
        searchObject = {
          ...searchString,
          exclude: _excludedUnexcluded.includes(PayrollRecordType[0]) ? 1 : 0,
        };
      } else {
        searchObject = { ...searchString };
        reportSearchObject = { ...reportSearchString };
        if (searchObject.hasOwnProperty('exclude')) {
          delete searchObject['exclude'];
        }
        if (reportSearchObject.hasOwnProperty('exclude')) {
          delete reportSearchObject['exclude'];
          delete reportSearchObject?.filter['exclude'];
        }
        setSearchString(searchObject);
        setReportSearchString(reportSearchObject);
      }

      fetchSearchResult('', searchObject, false);
    };

    const onShowChange = e => {
      let _showOptions = [...showOptions];

      if (e.checked) _showOptions.push(e.value);
      else _showOptions.splice(_showOptions?.indexOf(e.value), 1);

      setShowOptions(_showOptions);

      if (e?.value !== 'showLineItems') {
        let searchObject = {};
        let reportSearchObject = {};
        let checkOptions = _showOptions.map(opt => {
          if (opt !== 'showLineItems') return { [opt]: 1 };
        });

        let newObj = {};

        if (checkOptions?.every(ele => ele === undefined)) {
          setShowOptions([e?.value]);
          toast.current.show({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Please select atleast one Charge Type',
          });
          searchObject = {
            ...searchObject,
            ...searchString,
            [e?.value]: 1,
          };
          reportSearchObject = {
            ...reportSearchObject,
            ...reportSearchString,
            [e?.value]: 1,
            filter: { ...reportSearchString?.filter, [e?.value]: 1 },
          };
        } else {
          newObj = Object.assign({}, ...checkOptions);
          searchObject = {
            ...searchObject,
            ...searchString,
            [e?.value]: e?.checked ? 1 : 0,
            ...newObj,
          };
          reportSearchObject = {
            ...reportSearchObject,
            ...reportSearchString,
            [e?.value]: e?.checked ? 1 : 0,
            ...newObj,
            filter: {
              ...reportSearchString?.filter,
              [e?.value]: e?.checked ? 1 : 0,
              ...newObj,
            },
          };
        }
        setSearchString(prevState => ({
          ...prevState,
          ...searchObject,
        }));
        setReportSearchString(prevState => ({
          ...prevState,
          ...reportSearchObject,
        }));
        fetchSearchResult('', searchObject, false);
      }
    };

    let selectColumnsOptions = [];

    if (isShowLineItems()) {
      selectColumnsOptions = tableColumns
        ?.filter(col => !col.action)
        ?.map(col => {
          return { header: col?.header, field: col?.field };
        });
    } else {
      selectColumnsOptions = tableColumns
        ?.filter(col => !col.action && !col?.lineItemCol)
        ?.map(col => {
          return { header: col?.header, field: col?.field };
        });
    }

    return (
      <div className="flex flex-wrap gap-4">
        <div className="flex justify-content-start gap-2 align-items-center">
          <PFButton
            label="Review All"
            severity="primary"
            disabled={!checkPermission(permissions?.payroll?.review)}
            onClick={() => headerButtonsEvent('reviewAll')}
          />
          <PFButton
            label="Approve All"
            severity="primary"
            onClick={() => headerButtonsEvent('approveAll')}
            disabled={!checkPermission(permissions?.payroll?.approve)}
          />
          <PFButton
            label="Exclude All"
            severity="primary"
            onClick={() => headerButtonsEvent('excludeAll')}
            disabled={!checkPermission(permissions?.payroll?.exclude)}
          />
        </div>
        <div className="flex align-items-center pl-3 gap-1 border-left-1 border-500">
          <PFCheckbox
            inputId="showLineItems"
            value={'showLineItems'}
            onChange={e => {
              setRemoveAllColumns(false);
              onShowChange(e);
            }}
            checked={showOptions.includes('showLineItems')}
          />
          <label htmlFor="showLineItems">Show Line Items</label>
        </div>
        <div className="flex align-items-center pl-3 gap-2 border-left-1 border-500">
          <PFCheckbox
            inputId="labor"
            value={'labor'}
            onChange={e => onShowChange(e)}
            checked={showOptions.includes('labor')}
          />
          <label htmlFor="labor">Labor</label>

          <PFCheckbox
            inputId="chargeback"
            value={'chargeback'}
            onChange={e => onShowChange(e)}
            checked={showOptions.includes('chargeback')}
          />
          <label htmlFor="chargeback">Chargeback</label>

          <PFCheckbox
            inputId="retainage"
            value={'retainage'}
            onChange={e => onShowChange(e)}
            checked={showOptions.includes('retainage')}
          />
          <label htmlFor="retainage">Retainage</label>
        </div>
        <div className="flex align-items-center pl-3 border-left-1 border-500 gap-1">
          <PFCheckbox
            inputId="excluded"
            name="excluded"
            value="excluded"
            onChange={e => excludedUnexcludedEvent(e)}
            checked={excludedUnexcluded?.includes(PayrollRecordType[0])}
          />
          <label htmlFor="excluded">Excluded</label>

          <PFCheckbox
            inputId="unexcluded"
            name="unexcluded"
            value="unexcluded"
            onChange={e => excludedUnexcludedEvent(e)}
            checked={excludedUnexcluded?.includes(PayrollRecordType[1])}
          />
          <label htmlFor="unpaid">Unexcluded</label>
        </div>
        <div className="flex align-items-center pl-3 border-left-1 border-500 gap-1">
          <PFCheckbox
            inputId="paid"
            name="paid"
            value="paid"
            onChange={e => paidUnpaidButtonEvent(e)}
            checked={paidUnpaid?.includes(PayrollReviewType[0])}
          />
          <label htmlFor="approved">Approved</label>

          <PFCheckbox
            inputId="unpaid"
            name="unpaid"
            value="unpaid"
            onChange={e => paidUnpaidButtonEvent(e)}
            checked={paidUnpaid?.includes(PayrollReviewType[1])}
          />
          <label htmlFor="unpaid">Unapproved</label>
        </div>
        <div className="flex align-items-center">
          <PFMultiSelect
            value={
              !removeAllColumns && !selectedColumns?.length
                ? selectColumnsOptions
                : selectedColumns
            }
            filter
            onChange={e => {
              if (e?.value?.length === 0) {
                setRemoveAllColumns(true);
              } else {
                setRemoveAllColumns(false);
              }
              setSelectedColumns(e.value);
            }}
            options={selectColumnsOptions}
            optionLabel="header"
            placeholder="Select Columns"
            maxSelectedLabels={3}
            className="w-full md:w-20rem"
          />
        </div>
      </div>
    );
  };

  const headerTemplate = data => {
    return !isLoading ? (
      <React.Fragment>
        <div className="flex">
          <PFCheckbox
            className="checkboxSpacing"
            checked={
              !isShowLineItems()
                ? data
                    ?.map(val => {
                      return val?.lineItems
                        ? val?.lineItems?.filter(
                            line => line?.review === 0 || line?.review === null
                          )
                        : [];
                    })
                    ?.filter(arr => arr?.length)?.length === 0 &&
                  data?.filter(val => val.review === 0 || val.review === null)
                    ?.length === 0
                : data?.filter(val => val.review === 0 || val.review === null)
                    ?.length === 0
            }
            onChange={e => headerActionEvent('review', e, data)}
            disabled={
              !checkPermission(permissions?.payroll?.review) ||
              (!isShowLineItems()
                ? data
                    ?.map(val => {
                      return val?.lineItems
                        ? val?.lineItems?.filter(
                            line =>
                              line?.approve === 0 || line?.approve === null
                          )
                        : [];
                    })
                    ?.filter(arr => arr?.length)?.length === 0 &&
                  data?.filter(val => val.approve === 0 || val.approve === null)
                    ?.length === 0
                : data?.filter(val => val.approve === 0 || val.approve === null)
                    ?.length === 0)
            }
          />
          <PFCheckbox
            className="mr-7"
            checked={
              !isShowLineItems()
                ? data
                    ?.map(val => {
                      return val?.lineItems
                        ? val?.lineItems?.filter(
                            line =>
                              line?.approve === 0 || line?.approve === null
                          )
                        : [];
                    })
                    ?.filter(arr => arr?.length)?.length === 0 &&
                  data?.filter(val => val.approve === 0 || val.approve === null)
                    ?.length === 0
                : data?.filter(val => val.approve === 0 || val.approve === null)
                    ?.length === 0
            }
            disabled={
              !checkPermission(permissions?.payroll?.approve) ||
              (!isShowLineItems()
                ? data
                    ?.map(val => {
                      return val?.lineItems
                        ? val?.lineItems?.filter(
                            line =>
                              line?.approve === 0 ||
                              line?.approve === null ||
                              line?.exclude === 0 ||
                              line?.exclude === null
                          )
                        : [];
                    })
                    ?.filter(arr => arr?.length)?.length === 0 &&
                  data?.filter(
                    val =>
                      val.approve === 0 ||
                      val.approve === null ||
                      val?.exclude === 0 ||
                      val?.exclude === null
                  )?.length === 0
                : data?.filter(
                    val =>
                      val.approve === 0 ||
                      val.approve === null ||
                      val?.exclude === 0 ||
                      val?.exclude === null
                  )?.length === 0)
            }
            onChange={e => headerActionEvent('approve', e, data)}
          />
          <PFCheckbox
            className="mr-7"
            checked={
              !isShowLineItems()
                ? data
                    ?.map(val => {
                      return val?.lineItems
                        ? val?.lineItems?.filter(
                            line =>
                              line?.exclude === 0 || line?.exclude === null
                          )
                        : [];
                    })
                    ?.filter(arr => arr?.length)?.length === 0 &&
                  data?.filter(val => val.exclude === 0 || val.exclude === null)
                    ?.length === 0
                : data?.filter(val => val.exclude === 0 || val.exclude === null)
                    ?.length === 0
            }
            onChange={e => headerActionEvent('exclude', e, data)}
            disabled={
              !checkPermission(permissions?.payroll?.exclude) ||
              (!isShowLineItems()
                ? data
                    ?.map(val => {
                      return val?.lineItems
                        ? val?.lineItems?.filter(
                            line =>
                              line?.approve === 0 || line?.approve === null
                          )
                        : [];
                    })
                    ?.filter(arr => arr?.length)?.length === 0 &&
                  data?.filter(val => val.approve === 0 || val.approve === null)
                    ?.length === 0
                : data?.filter(val => val.approve === 0 || val.approve === null)
                    ?.length === 0)
            }
          />
          <div className="capitalize font-bold boldFontFamily">
            {data?.[0]?.technician}
          </div>
        </div>
      </React.Fragment>
    ) : (
      ''
    );
  };

  const rawTableData = searchResult?.data?.map(result => {
    return {
      projects: result[result?.user_id]?.[0]?.projects?.map(proj => {
        return {
          chargebacks: proj?.chargebacks?.map(chargeback => {
            return {
              user_id: result?.user_id,
              technician: result?.user_name,
              charge_type: 'Chargeback',
              total_retail: `${chargeback?.frequency != null ? chargeback?.frequency : ''}  ${
                chargeback?.frequency != null ? '-' : ''
              } ${chargeback?.type} - ${formatCurrency(chargeback?.payment_amount || 0)}`,
              chargeback_payout_id: chargeback?.chargeback_payout_id,
              review: chargeback?.payroll_report_review,
              approve: chargeback?.payroll_report_approve,
              exclude: chargeback?.payroll_report_exclude,
              reviewLogs: `${chargeback?.payroll_report_review_by || ''} ${formatDateTimeMDYHM(
                chargeback?.payroll_report_review_datetime
              )}`,
              approveLogs: `${chargeback?.payroll_report_approve_by || ''} ${formatDateTimeMDYHM(
                chargeback?.payroll_report_approve_datetime
              )}`,
              excludeLogs: `${chargeback?.payroll_report_exclude_by || ''} ${formatDateTimeMDYHM(
                chargeback?.payroll_report_exclude_datetime
              )}`,

              net_pay:
                chargeback?.chargeback_type === 'credit'
                  ? formatCurrency(chargeback?.payment_amount || 0)
                  : `-${formatCurrency(chargeback?.payment_amount || 0)}`,
            };
          }),
          lineItems: proj?.lineItems?.map(item => {
            return {
              project_id: proj?.project_id,
              store_id: proj?.store_id,
              customer_id: proj?.customer_id,
              user_id: result?.user_id,
              technician: result?.user_name,
              charge_type: 'Labor',
              completion_date: item?.completion_date
                ? dateFormatter(item?.completion_date, 'MM-DD-YYYY')
                : '',
              completed_status: item?.completed_status,
              item_desc: item?.item_desc,
              item: item?.item_number,
              total_retail: formatCurrency(item?.laborRetail || 0),
              total_cost: formatCurrency(item?.laborCost || 0),
              total_profit: formatCurrency(item?.profit || 0),
              total_margin: `${parseFloat(item?.margin || 0).toFixed(2)}%`,
              net_pay: formatCurrency(item?.netPay || 0),
              project: proj?.project_number,
              po: proj?.po_number,
              category: proj?.category,
              workroom: proj?.workroom,
              status: proj?.status,
              source_status: proj?.source_status,
              project_type: proj?.project_type,
              technician_type: proj?.technician_type,
              key_rec_date: proj?.key_rec_date
                ? dateFormatter(proj?.key_rec_date, 'MM-DD-YYYY')
                : '',
              key_rec_number: proj?.key_rec_number,
              key_rec_cost: proj?.key_rec_cost,
              store: proj?.store_number,
              storeName: proj?.store_name,
              customerName: proj?.customer_name,
              review: item?.payroll_report_review,
              approve: item?.payroll_report_approve,
              exclude: item?.payroll_report_exclude,
              reviewLogs: `${item?.payroll_report_review_by || ''} ${formatDateTimeMDYHM(
                item?.payroll_report_review_datetime
              )}`,
              approveLogs: `${item?.payroll_report_approve_by || ''} ${formatDateTimeMDYHM(
                item?.payroll_report_approve_datetime
              )}`,
              excludeLogs: `${item?.payroll_report_exclude_by || ''} ${formatDateTimeMDYHM(
                item?.payroll_report_exclude_datetime
              )}`,

              project_item_user_id: item?.project_item_user_id,
              project_id: proj?.project_id,
            };
          }),
          projFooter: {
            name: 'projFooter',
            user_id: result?.user_id,
            technician: result?.user_name,
            total_retail: formatCurrency(
              proj?.actual_calculation?.total_labor_retail || 0
            ),
            total_cost: formatCurrency(
              proj?.actual_calculation?.total_labor_cost || 0
            ),
            total_profit: formatCurrency(
              proj?.actual_calculation?.total_profit || 0
            ),
            total_margin: `${parseFloat(proj?.actual_calculation?.total_margin || 0)?.toFixed(2)}%`,
            net_pay: formatCurrency(
              proj?.actual_calculation?.total_net_pay || 0
            ),
          },
          approvedFooter: {
            name: 'approvedFooter',
            user_id: result?.user_id,
            technician: result?.user_name,
            total_retail: formatCurrency(
              proj?.approved_calculation?.total_labor_retail || 0
            ),
            total_cost: formatCurrency(
              proj?.approved_calculation?.total_labor_cost || 0
            ),
            total_profit: formatCurrency(
              proj?.approved_calculation?.total_profit || 0
            ),
            total_margin: `${parseFloat(
              proj?.approved_calculation?.total_margin || 0
            )?.toFixed(2)}%`,
            net_pay: formatCurrency(
              proj?.approved_calculation?.total_net_pay || 0
            ),
          },
          excludeFooter: {
            name: 'excludeFooter',
            user_id: result?.user_id,
            technician: result?.user_name,
            total_retail: formatCurrency(
              proj?.excluded_calculation?.total_labor_retail || 0
            ),
            total_cost: formatCurrency(
              proj?.excluded_calculation?.total_labor_cost || 0
            ),
            total_profit: formatCurrency(
              proj?.excluded_calculation?.total_profit || 0
            ),
            total_margin: `${parseFloat(
              proj?.excluded_calculation?.total_margin || 0
            )?.toFixed(2)}%`,
            net_pay: formatCurrency(
              proj?.excluded_calculation?.total_net_pay || 0
            ),
          },
          balanceFooter: {
            name: 'balanceFooter',
            user_id: result?.user_id,
            technician: result?.user_name,
            total_retail: formatCurrency(
              proj?.balanced_calculation?.total_labor_retail || 0
            ),
            total_cost: formatCurrency(
              proj?.balanced_calculation?.total_labor_cost || 0
            ),
            total_profit: formatCurrency(
              proj?.balanced_calculation?.total_profit || 0
            ),
            total_margin: `${parseFloat(
              proj?.balanced_calculation?.total_margin || 0
            )?.toFixed(2)}%`,
            net_pay: formatCurrency(
              proj?.balanced_calculation?.total_net_pay || 0
            ),
          },
          user_id: result?.user_id,
          technician: result?.user_name,
          project: proj?.project_number,
          po: proj?.po_number,
          category: proj?.category,
          charge_type: 'Labor',
          workroom: proj?.workroom,
          status: proj?.status,
          source_status: proj?.source_status,
          project_type: proj?.project_type,
          technician_type: proj?.technician_type,
          key_rec_date: proj?.key_rec_date
            ? dateFormatter(proj?.key_rec_date, 'MM-DD-YYYY')
            : '',
          key_rec_number: proj?.key_rec_number,
          key_rec_cost: proj?.key_rec_cost,
          store: proj?.store_number,
          storeName: proj?.store_name,
          customerName: proj?.customer_name,
          total_retail: formatCurrency(proj?.total_labor_retail || 0),
          total_cost: formatCurrency(proj?.total_labor_cost || 0),
          total_profit: formatCurrency(proj?.total_profit || 0),
          total_margin: `${parseFloat(proj?.total_margin || 0)?.toFixed(2)}%`,
          net_pay: formatCurrency(proj?.total_net_pay || 0),
          project_id: proj?.project_id,
          store_id: proj?.store_id,
          customer_id: proj?.customer_id,
        };
      }),
      generalChargebacks: result[result?.user_id]?.[0]?.generalChargebacks?.map(
        chargeback => {
          return {
            user_id: result?.user_id,
            technician: result?.user_name,
            charge_type: 'General Chargeback',
            total_retail: `${chargeback?.frequency != null ? chargeback?.frequency : ''}  ${
              chargeback?.frequency != null ? '-' : ''
            } ${chargeback?.type}- ${
              chargeback?.type === CHARGEBACK_PAYOUT_TYPE_OPTION[1]?.value ||
              chargeback?.type === CHARGEBACK_TYPE?.PayrollOfPercentage
                ? chargeback.isPercentageOfPayroll
                  ? `${chargeback?.payout_type_value || 0}%`
                  : `${chargeback?.payment_amount || 0}%`
                : `$${chargeback?.payment_amount || 0}`
            }`,
            chargeback_payout_id: chargeback?.chargeback_payout_id,
            review: chargeback?.payroll_report_review,
            approve: chargeback?.payroll_report_approve,
            exclude: chargeback?.payroll_report_exclude,
            reviewLogs: `${chargeback?.payroll_report_review_by || ''} ${formatDateTimeMDYHM(
              chargeback?.payroll_report_review_datetime
            )}`,
            approveLogs: `${chargeback?.payroll_report_approve_by || ''} ${formatDateTimeMDYHM(
              chargeback?.payroll_report_approve_datetime
            )}`,
            excludeLogs: `${chargeback?.payroll_report_exclude_by || ''} ${formatDateTimeMDYHM(
              chargeback?.payroll_report_exclude_datetime
            )}`,
            net_pay:
              chargeback?.chargeback_type === 'credit'
                ? formatCurrency(chargeback?.payment_amount || 0)
                : `-${formatCurrency(chargeback?.payment_amount || 0)}`,
            isPercentageOfPayrollChargeback: chargeback?.isPercentageOfPayroll,
            isCollectFromRetainageChargeback:
              chargeback?.isCollectFromRetainage,
            approve_string: chargeback?.approve_string,
          };
        }
      ),
      retainages: result[result?.user_id]?.[0]?.generalRetainageData?.map(
        retainage => {
          return {
            user_id: result?.user_id,
            technician: result?.user_name,
            charge_type: 'Retainage',
            total_retail: `${retainage?.frequency != null ? retainage?.frequency : ''}  ${
              retainage?.frequency != null ? '-' : ''
            }  ${retainage?.type} - ${
              retainage?.payment_percentage
                ? retainage?.payment_percentage + '%'
                : '$' + retainage?.payment_amount
            }`,
            net_pay: `-${formatCurrency(retainage?.payment_amount || 0)}`,
            retainage_payout_id: retainage?.payout_id,
            review: retainage?.payroll_report_review,
            approve: retainage?.payroll_report_approve,
            exclude: retainage?.payroll_report_exclude,
            reviewLogs: `${retainage?.payroll_report_review_by || ''} ${formatDateTimeMDYHM(
              retainage?.payroll_report_review_datetime
            )}`,
            approveLogs: `${retainage?.payroll_report_approve_by || ''} ${formatDateTimeMDYHM(
              retainage?.payroll_report_approve_datetime
            )}`,
            excludeLogs: `${retainage?.payroll_report_exclude_by || ''} ${formatDateTimeMDYHM(
              retainage?.payroll_report_exclude_datetime
            )}`,
            isPercentageOfPayroll: retainage?.isPercentageOfPayroll,
            payment_percentage: retainage?.payment_percentage,
            approve_string: retainage?.approve_string,
          };
        }
      ),
      footerData: {
        masterCalculationActual:
          result[result?.user_id]?.[0]?.masterCalculationActual,
        masterCalculationApproved:
          result[result?.user_id]?.[0]?.masterCalculationApproved,
        masterCalculationExcluded:
          result[result?.user_id]?.[0]?.masterCalculationExcluded,
        masterCalculationBalanced:
          result[result?.user_id]?.[0]?.masterCalculationBalanced,
      },
    };
  });

  const tableData = [];
  // Convert raw data to populate in data table
  if (rawTableData?.length) {
    for (let rawData of rawTableData) {
      for (let project of rawData?.projects) {
        if (project?.lineItems?.length && isShowLineItems()) {
          for (let lineItem of project?.lineItems) {
            tableData.push(lineItem);
          }
        } else {
          tableData.push(project);
        }
        for (let chargeback of project?.chargebacks) {
          tableData.push(chargeback);
        }
        tableData.push(project?.projFooter);
        tableData.push(project?.approvedFooter);
        tableData.push(project?.excludeFooter);
        tableData.push(project?.balanceFooter);
      }
      for (let generalChargeback of rawData?.generalChargebacks) {
        tableData.push(generalChargeback);
      }
      for (let retainage of rawData?.retainages) {
        tableData.push(retainage);
      }
    }
  }
  const getColorForRow = rowData => {
    if (
      rowData?.approve ||
      (!isShowLineItems() &&
        rowData?.charge_type === 'Labor' &&
        rowData?.lineItems?.length &&
        rowData?.lineItems?.filter(item => item?.approve === 1)?.length ===
          rowData?.lineItems?.length)
    ) {
      return 'bg-green-100';
    }
    if (rowData?.exclude) {
      return 'bg-red-50';
    }

    if (rowData?.name === 'projFooter')
      return { 'bg-blue-100': true, footerstyle: true };
    if (rowData?.name === 'approvedFooter')
      return { 'bg-green-100': true, footerstyle: true };
    if (rowData?.name === 'excludeFooter')
      return { 'bg-red-50': true, footerstyle: true };
    if (rowData?.name === 'balanceFooter')
      return { 'surface-200': true, footerstyle: true };
  };
  const footerGroup = footerData => {
    return !isLoading && footerData ? (
      <ColumnGroup>
        <Row>
          <Column
            colSpan={'3'}
            className="bg-blue-600 text-white text-left footerstyle pr-0"
          />
          {isShowLineItems() && (
            <Column
              colSpan={'4'}
              className="bg-blue-600 text-white text-left footerstyle pr-0"
            />
          )}
          <Column
            footer="Technician: Total"
            className="bg-blue-600 text-white text-left footerstyle pr-0"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationActual?.total_labor_retail
            )}
            className={`${
              isShowLineItems()
                ? 'bg-blue-600 text-white footerstyle text-right footerSubHeaderStyle pr-3'
                : 'bg-blue-600 text-white footerstyle text-right pr-3 footerSubHeaderStyle'
            }`}
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationActual?.total_labor_cost
            )}
            className="bg-blue-600 text-white footerstyle text-right"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationActual?.total_profit
            )}
            className="bg-blue-600 text-white footerstyle text-right"
          />
          <Column
            footer={`${parseFloat(
              footerData?.masterCalculationActual?.total_margin || 0
            )?.toFixed(2)}%`}
            className="bg-blue-600 text-white footerstyle text-right"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationActual?.total_net_pay
            )}
            className="bg-blue-600 text-white footerstyle text-right"
          />
          <Column colSpan={18} className="bg-blue-600 text-white footerstyle" />
        </Row>
        <Row>
          <Column
            colSpan={'3'}
            className="bg-green-100 text-white text-left footerstyle pr-0"
          />
          {isShowLineItems() && (
            <Column
              colSpan={'4'}
              className="bg-green-100 text-white text-left footerstyle pr-0"
            />
          )}
          <Column
            footer="Technician: Approved"
            className="bg-green-100 text-left footerstyle pr-0"
          />

          <Column
            footer={formatCurrency(
              footerData?.masterCalculationApproved?.total_labor_retail
            )}
            className={`${
              isShowLineItems()
                ? 'bg-green-100 footerstyle text-right pr-3 footerSubHeaderStyle'
                : 'bg-green-100 footerstyle text-right pr-3 footerSubHeaderStyle'
            }`}
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationApproved?.total_labor_cost
            )}
            className="bg-green-100 footerstyle text-right"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationApproved?.total_profit
            )}
            className="bg-green-100 footerstyle text-right"
          />
          <Column
            footer={`${parseFloat(
              footerData?.masterCalculationApproved?.total_margin || 0
            )?.toFixed(2)}%`}
            className="bg-green-100 footerstyle text-right"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationApproved?.total_net_pay
            )}
            className="bg-green-100 footerstyle text-right"
          />
          <Column colSpan={18} className="bg-green-100 footerstyle" />
        </Row>
        <Row>
          <Column
            colSpan={'3'}
            className="bg-red-50 text-left footerstyle pr-0"
          />
          {isShowLineItems() && (
            <Column
              colSpan={'4'}
              className="bg-red-50 text-white text-left footerstyle pr-0"
            />
          )}
          <Column
            footer="Technician: Exclude"
            colSpan={'1'}
            className="bg-red-50 text-left footerstyle pr-0"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationExcluded?.total_labor_retail
            )}
            className={`${
              isShowLineItems()
                ? 'bg-red-50 footerstyle text-right pr-3 footerSubHeaderStyle'
                : 'bg-red-50 footerstyle text-right pr-3 footerSubHeaderStyle'
            }`}
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationExcluded?.total_labor_cost
            )}
            className="bg-red-50 footerstyle text-right"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationExcluded?.total_profit
            )}
            className="bg-red-50 footerstyle text-right"
          />
          <Column
            footer={`${parseFloat(
              footerData?.masterCalculationExcluded?.total_margin || 0
            )?.toFixed(2)}%`}
            className="bg-red-50 footerstyle text-right"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationExcluded?.total_net_pay
            )}
            className="bg-red-50 footerstyle text-right"
          />
          <Column colSpan={18} className="bg-red-50  footerstyle" />
        </Row>
        <Row>
          <Column
            colSpan={'3'}
            className="surface-200 text-white text-left footerstyle pr-0"
          />
          {isShowLineItems() && (
            <Column
              colSpan={'4'}
              className="surface-200 text-white text-left footerstyle pr-0"
            />
          )}
          <Column
            footer="Technician: Balance"
            className="surface-200 text-left footerstyle pr-5"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationBalanced?.total_labor_retail
            )}
            className={`${
              isShowLineItems()
                ? 'surface-200 footerstyle text-right pr-3 footerSubHeaderStyle'
                : 'surface-200 footerstyle footerSubHeaderStyle text-right pr-3'
            }`}
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationBalanced?.total_labor_cost
            )}
            className="surface-200 footerstyle text-right"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationBalanced?.total_profit
            )}
            className="surface-200 footerstyle text-right"
          />
          <Column
            footer={`${parseFloat(
              footerData?.masterCalculationBalanced?.total_margin || 0
            )?.toFixed(2)}%`}
            className="surface-200 footerstyle text-right"
          />
          <Column
            footer={formatCurrency(
              footerData?.masterCalculationBalanced?.total_net_pay
            )}
            className="surface-200 footerstyle text-right"
          />
          <Column colSpan={18} className="surface-200 footerstyle" />
        </Row>
      </ColumnGroup>
    ) : (
      ''
    );
  };

  const dataTableParams = {
    header: renderHeader(),
    scrollable: true,
    rowGroupMode: 'subheader',
    groupRowsBy: 'technician',
    rowGroupHeaderTemplate: headerTemplate(tableData),
    rowClassName: getColorForRow,
    footerColumnGroup: footerGroup(rawTableData?.[0]?.footerData),
  };

  return (
    <>
      <div className="flex mb-3 align-items-end col-12 justify-content-end gap-2">
        <PFButton
          label="Previous User"
          icon="pi pi-arrow-circle-left"
          severity="primary"
          outlined
          iconPos="left"
          disabled={searchResult?.offset === 1}
          onClick={() => {
            setPaginationPage(1);
            setFirst(1);
            setCurrentOffset(currentOffset - 1);
            fetchSearchResult(currentOffset - 1, '', false, '', 1);
          }}
        />
        <PFButton
          label="Next User"
          icon="pi pi-arrow-circle-right"
          severity="primary"
          iconPos="right"
          disabled={
            !searchResult?.data?.length ||
            !!searchString?.technician ||
            previousTechnician
          }
          onClick={() => {
            setPaginationPage(1);
            setFirst(1);
            setCurrentOffset(searchResult?.offset);
            fetchSearchResult(searchResult?.offset, '', false, '', 1);
          }}
        />
      </div>
      <PFDataTable
        columns={tableColumns}
        data={[...tableData] || []}
        dataKey="id"
        tableStyle={{ minWidth: '50rem' }}
        stripedRows={true}
        editMode={'row'}
        dataTableParams={dataTableParams}
        totalRecords={searchResult?.totalProjects}
      />
      <div className="flex justify-content-end align-items-center w-12 bg-white pr-3 text-base">
        <Paginator
          page={paginationPage}
          first={first}
          rows={paginationRows}
          totalRecords={searchResult?.totalProjects}
          onPageChange={onPageChange}
          rowsPerPageOptions={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        />
      </div>
    </>
  );
};

export default SearchResult;
