import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  buttonRoot: {
    marginLeft: '5px',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  gridPaper: {
    borderRadius: '0px',
    padding: '10px 0px 10px 0px',
    maxHeight: '350px',
  },
  listPaper: {
    borderRadius: '0px',
    padding: '10px 0px 10px 0px',
    maxHeight: 'fit-content',
    background: '#DDDDDD',
    border: '0.5px solid #B3B3B3',
    marginBottom: '10px',
    width: '80%',
    float: 'left',
  },
  listPaperChild: {
    borderRadius: '0px',
    padding: '10px 0px 10px 0px',
    // maxHeight: '350px',
    border: '0.5px solid #B3B3B3',
    marginBottom: '10px',
    width: '80%',
    float: 'right',
  },
  titleHeaderGrid: {
    padding: '10px 24px 10px 24px',
  },
  listHeaderGrid: {
    padding: '10px 10px',
  },
  tableContainer: {
    minHeight: '170px',
    padding: '10px 24px',
    height: '250px',
    maxHeight: '280px',
    overflowY: 'auto',
    boxShadow: 'unset',
    '&::-webkit-scrollbar': {
      // Width of vertical scroll bar
      width: '8px',
      // Height of horizontal scroll bar
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      background: '#c2c9d2',
    },
  },
  viewNotificationLink: {
    display: 'inline',
    cursor: 'pointer',
    marginRight: '20px',
  },
  editIcon: {
    cursor: 'pointer',
    marginRight: '5px',
  },
  viewSMSGridWraper: {
    padding: '12px',
  },
  scheduleStaticText: {
    fontWeight: 'bold',
    paddingLeft: '17px',
  },
  loggedInUser: {
    marginLeft: '0px',
  },
  statusText: {
    paddingTop: '2px',
  },
  statusResponseText: {
    paddingLeft: '2px',
  },
  smsReviewed: {
    color: 'rgb(44, 170, 66)',
  },
  reviewNotificationLink: {
    display: 'inline',
    cursor: 'pointer',
  },
  emptyResponse: {
    display: 'none',
  },
  reviewedByText: {
    color: '#B3B3B3',
    fontSize: '11px',
  },
  helperText: {
    color: '#B3B3B3',
    fontSize: '11px',
  },
  countIcon: {
    height: '30px',
    width: '30px',
  },
  countGrid: {
    marginTop: '0px',
    marginLeft: '15px',
    marginRight: '15px',
  },
  incomingIcon: {
    marginRight: '20px',
  },
}));
