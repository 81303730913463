import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserTracing } from '@sentry/tracing';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);
import AppWrapper from './AppWrapper';

ReactDOM.render(<AppWrapper />, document.getElementById('root'));
