import { Image } from 'primereact/image';
import React, { useState } from 'react';

import Placeholder from '../../assets/images/technician-avatar.png';

const TechnicianAvatar = ({ src, alt }) => {
  const [isImageBroken, setIsImageBroken] = useState(false);

  const handleImageError = () => {
    setIsImageBroken(true);
  };

  return (
    <Image
      src={isImageBroken || !src ? Placeholder : src}
      alt={alt}
      onError={handleImageError}
      pt={{
        image: {
          className: 'w-full h-6rem border-round-3xl',
        },
      }}
    />
  );
};

export default TechnicianAvatar;
