import { Chip } from 'primereact/chip';
import { useSelector } from 'react-redux';
import { OverlayPanel } from 'primereact/overlaypanel';
import React from 'react';
import { isValid } from 'date-fns';

import PFDataTable from '../shared/PFPrime/PFDataTable';
import {
  defaultFilterDisplayHeader,
  displayFilterHeader,
} from '../../constants';
import { dateFormatter } from '../../utils/Helpers';

const GlobalFiltersHeader = ({ globalFilterHeader }) => {
  const globalFilters = useSelector(state => state.globalFilters);
  const [dataTableValues, setDataTableValues] = React.useState([]);
  const [defaultDataTableValues, setDefaultDataTableValues] = React.useState(
    []
  );
  const displayFilter = displayFilterHeader;
  const defaultFilterHeader = defaultFilterDisplayHeader;

  const defaultFilterValueColumnDisplayFormat = rowData => {
    return rowData?.value?.map((item, index) => (
      <Chip
        key={index}
        label={item[defaultFilterHeader[rowData?.key]?.value]}
        className="m-1"
        pt={{ label: { className: 'm-0 text-sm' } }}
      ></Chip>
    ));
  };
  const renderDefaultFilterHeader = () => {
    return (
      <div className="flex align-items-center justify-content-center">
        <span className="flex justify-content-center font-bold">
          Default Filter:
        </span>
        <span>
          ({globalFilters?.defaultFilter?.selectedDefaultFiltersValues?.key})
        </span>
      </div>
    );
  };

  const defaultFilterTableColumn = [
    {
      field: 'key',
      header: renderDefaultFilterHeader(),
      style: { minWidth: '10px', maxWidth: '10px' },
      pt: { bodycell: { className: 'font-bold' } },
      body: rowData => {
        return defaultFilterHeader[rowData?.key]?.key;
      },
    },
    {
      field: 'value',
      style: { minWidth: '20px', maxWidth: '20px' },
      body: rowData => {
        return (
          <div className="flex flex-wrap flex-row">
            {defaultFilterValueColumnDisplayFormat(rowData)}
          </div>
        );
      },
    },
  ];
  const tableColumn = [
    {
      field: 'key',
      header: 'Custom Filters',
      style: { minWidth: '10px', maxWidth: '10px' },
      pt: { bodycell: { className: 'font-bold' } },
      body: rowData => {
        return displayFilter
          ?.filter(item => item?.key === rowData?.key)?.[0]
          ?.value?.[0]?.toString();
      },
    },
    {
      field: 'value',
      style: { minWidth: '20px', maxWidth: '20px' },
      body: rowData => {
        const objString = displayFilter?.filter(
          item => item?.key === rowData?.key
        )?.[0];
        return (
          <div className="flex flex-wrap flex-row">
            {rowData?.value?.length &&
              rowData?.value?.map((val, index) => {
                return (
                  <Chip
                    key={index}
                    label={
                      (Object.keys(val)?.length &&
                        val?.[objString?.value?.[1]]) ||
                      (isValid(val) ? dateFormatter(val, 'MM-DD-YYYY') : val)
                    }
                    className="m-1"
                    pt={{ label: { className: 'm-0 text-sm' } }}
                  ></Chip>
                );
              })}
          </div>
        );
      },
    },
  ];
  React.useEffect(() => {
    if (
      Object?.keys(globalFilters?.defaultFilter)?.length &&
      Object?.keys(
        globalFilters?.defaultFilter?.selectedDefaultFiltersValues?.value
      )?.length
    ) {
      const defaultFilterData = Object?.entries(
        globalFilters?.defaultFilter?.selectedDefaultFiltersValues?.value
      )?.map(([key, value]) => {
        return value?.length ? { key: key, value: value } : null;
      });
      setDefaultDataTableValues(defaultFilterData?.filter(val => val !== null));
    } else {
      setDefaultDataTableValues([]);
    }
    if (Object?.keys(globalFilters)?.length) {
      const data = Object?.entries(globalFilters)?.map(([key, value]) => {
        return Object.keys(value)?.length
          ? { key: key, value: value }
          : value?.length
            ? { key: key, value: value }
            : null;
      });

      setDataTableValues(
        data?.filter(val => val !== null && val?.key !== 'defaultFilter')
      );
    } else {
      setDataTableValues([]);
    }
  }, [globalFilters, globalFilterHeader]);

  const displayFiltersHeader = () => {
    return (
      <OverlayPanel
        ref={globalFilterHeader}
        className="w-4"
        pt={{ content: { className: 'p-1' } }}
      >
        <>
          {Object?.keys(globalFilters?.defaultFilter)?.length ? (
            <PFDataTable
              scrollable={false}
              columns={defaultFilterTableColumn}
              data={defaultDataTableValues}
              stripedRows
              dataKey="id"
              stripedRows={true}
            />
          ) : (
            <PFDataTable
              scrollable={false}
              columns={tableColumn}
              data={dataTableValues}
              stripedRows
              dataKey="id"
              stripedRows={true}
            />
          )}
        </>
      </OverlayPanel>
    );
  };
  return displayFiltersHeader();
};

export default GlobalFiltersHeader;
