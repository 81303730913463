import * as Yup from 'yup';

export const ProjectScheduleValidationSchema = Yup.object().shape({
  date_scheduled_start: Yup.string()
    .trim()
    .nullable()
    .when('date_scheduled', date_scheduled => {
      if (date_scheduled) {
        return Yup.string().trim().required('Required');
      }
    }),
  date_scheduled_end: Yup.string()
    .trim()
    .nullable()
    .when('date_scheduled', date_scheduled => {
      if (date_scheduled) {
        return Yup.string().trim().required('Required');
      }
    }),
});

export const getProjectFormikInitialValues = projectData => {
  return {
    store_number: projectData?.store?.store_number,
    type: projectData?.project_type?.project_type,
    category: projectData?.project_category?.category,
    status: projectData?.status?.status,
    project_number: projectData?.project_number,
    date_sold: projectData?.date_sold,
    client: projectData?.customer
      ? `${projectData?.customer?.first_name} ${projectData?.customer?.last_name}`
      : '',
    address: projectData?.installation_address?.address1,
    mobile_number: projectData?.customer?.customer_phones[0]?.phone_number,
    alternate_number: projectData?.customer?.alternate_phone,
    email: projectData?.customer?.customer_emails[0]?.email,
    ims_status: projectData?.source_status?.status,
    reschedule_reason: projectData?.reschedule_reason,
    rts_follow_up_date: projectData?.rts_follow_up_date,
    date_scheduled_start: projectData?.date_scheduled_start,
    date_scheduled_end: projectData?.date_scheduled_end,
    project_type_id: projectData?.project_type_id,
  };
};
