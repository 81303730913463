import React, { useMemo } from 'react';

import {
  checkPermission,
  doesKeyExistInArray,
  ellipsisString,
} from '../../../utils/Helpers';
import { SCHEDULER_CARD_VIEW_OPTIONS } from '../../Admin/NewConfiguration/company-config.constant';
import permissions from '../../../config/permissions';
import { CX_SCHEDULED_STATUS, PROJECT_REPORT } from '../../../constants';
import {
  checkScheduledDaysLength,
  checkScheduledHoursLength,
  MESSAGES,
} from '../helpers/helpers';
import { cardFields, getFieldValue } from '../helpers/scheduler-card.helper';
import { ProjectCardRowTemplate } from '../Templates/ProjectCardRow';

const ProjectScheduleCard = ({
  eventInfo,
  schedulerCardView,
  cxSchedulerIcon,
  period,
  view,
}) => {
  const keyExistence = useMemo(() => {
    const keysToCheck = [
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.store,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.project_type,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.category,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.status,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.project_number,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.client_name,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.address,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.phone,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.description,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.time,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.total_sale,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.total_revenue,
      SCHEDULER_CARD_VIEW_OPTIONS[1].type.basic_labor_quantity,
    ];

    return keysToCheck?.reduce((accumulator, key) => {
      accumulator[key] = doesKeyExistInArray(schedulerCardView, key);
      return accumulator;
    }, {});
  }, [schedulerCardView]);

  const horizontalView = (
    eventInfo,
    scheduledDaysLength,
    scheduledHoursLength
  ) => {
    return (
      <div className="schedule-job-card vertical-card px-0 pb-0 pt-3 pb-6">
        {view == PROJECT_REPORT.FULL_CALENDAR ? (
          <div className="flex flex-wrap">
            <span className="text-sm font-bold pl-1">
              {eventInfo?.installer_name}
            </span>
          </div>
        ) : null}
        {eventInfo?.project_status === CX_SCHEDULED_STATUS ||
        (!isNaN(eventInfo?.is_customer_requested) &&
          eventInfo?.is_customer_requested > 0) ? (
          <i
            className={`text-lg w-12 pl-2 absolute top-0 mt-1 ${cxSchedulerIcon}`}
          ></i>
        ) : null}
        <div className="grid w-full grid-nogutter mt-1">
          <div className="col-12 pr-0">
            <div className="grid flex-container schedule-job-row grid-nogutter">
              {cardFields(SCHEDULER_CARD_VIEW_OPTIONS[1].type).map(
                (field, i) => {
                  const value = getFieldValue(
                    field.key,
                    SCHEDULER_CARD_VIEW_OPTIONS[1].type,
                    eventInfo
                  );

                  if (!keyExistence[field.key]) return null;

                  const isSaleOrRevenue = [
                    SCHEDULER_CARD_VIEW_OPTIONS[1].type.total_sale,
                    SCHEDULER_CARD_VIEW_OPTIONS[1].type.total_revenue,
                  ].includes(field.key);

                  if (isSaleOrRevenue) {
                    const hasPermission = checkPermission(
                      permissions?.projectItems?.viewProjectLevelCostField
                    );

                    if (!hasPermission) return null; // Return null if the user doesn't have permission
                  }

                  return field.key ===
                    SCHEDULER_CARD_VIEW_OPTIONS[1].type.basic_labor_quantity &&
                    !value ? (
                    <></>
                  ) : (
                    <ProjectCardRowTemplate
                      key={`fieldKey_${i}`}
                      label={field.label}
                      value={value}
                    />
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const returnCard = eventInfo => {
    const extendedProps = eventInfo?.event?.extendedProps;
    const scheduledDaysLength = checkScheduledDaysLength(eventInfo);
    const scheduledHoursLength = checkScheduledHoursLength(eventInfo);

    return horizontalView(eventInfo, scheduledDaysLength, scheduledHoursLength);
  };

  return <>{returnCard(eventInfo)}</>;
};
export default ProjectScheduleCard;
