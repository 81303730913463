import React, { useState, useEffect, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { FilterMatchMode } from 'primereact/api';
import axios from 'axios';
import { debounce } from 'lodash';
import { Skeleton } from 'primereact/skeleton';

import PageHeader from '../../../shared/PageHeader/PageHeader';
import { formatDateTime } from '../../../../utils/Helpers';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

const CustomerResetPasswordLogs = () => {
  const breadcrumb = [
    {
      text: 'Customer Reset Password Logs',
    },
  ];
  const [count, setCount] = useState(0);
  const [logsData, setLogsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: '',
    sortOrder: -1,
    filters: {
      email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      identifier: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      client_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      client_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      customer_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
  });
  const [columns] = useState({
    count: 16,
    selected: {
      client_id: true,
      client_name: true,
      customer_name: true,
      identifier: true,
      email: true,
      ip_address: true,
      created_at: true,
      user_agent: true,
    },
  });

  const onSort = event => {
    const field = event?.sortField;
    const order = event.sortOrder;
    setLazyState(preProps => ({
      ...preProps,
      sortField: field,
      sortOrder: order,
    }));
  };

  const fetchResetPasswordLogs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${URL_CONSTANTS.CRM.baseUrl}/api/customer-log/reset-password-log?limit=${lazyState.rows}&offset=${lazyState.first}&email=${lazyState?.filters?.email?.value || ''}&client_id=${lazyState?.filters?.client_id?.value || ''}&client_name=${lazyState?.filters?.client_name?.value || ''}&customer_name=${lazyState?.filters?.customer_name?.value || ''}&identifier=${lazyState?.filters?.identifier?.value || ''}&sortBy=${lazyState?.sortField}&sortOrder=${lazyState?.sortOrder === 1 ? 'asc' : 'desc'}`
      );
      setCount(response?.data?.data?.count);
      setLogsData(response?.data?.data?.rows);
    } catch (error) {
      console.error('fetchResetPasswordLogs -> error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchLogs = debounce(fetchResetPasswordLogs, 1000);

  useEffect(() => {
    debouncedFetchLogs();
  }, [lazyState]);

  const tableColumn = [
    {
      name: 'client_id',
      label: 'Client Id',
      filter: true,
    },
    {
      name: 'client_name',
      label: 'Client Name',
      sortable: true,
      filter: true,
    },
    {
      name: 'customer_name',
      label: 'Customer Name',
      sortable: true,
      filter: true,
    },
    {
      name: 'identifier',
      label: 'Identifier',
      filter: true,
    },
    {
      name: 'email',
      label: 'Email',
      filter: true,
    },
    {
      name: 'ip_address',
      label: 'Ip Address',
      style: { minWidth: '200px' },
    },
    {
      name: 'created_at',
      label: 'Date',
      body: row => formatDateTime(row.created_at),
      style: { minWidth: '200px' },
    },
    {
      name: 'user_agent',
      label: 'Agent',
      style: { minWidth: '730px' },
      body: row => <div>{row.user_agent}</div>,
      style: { minWidth: '250px' },
    },
  ];

  const loaderTable = useMemo(() => {
    const loaderObj = {};
    for (const key in columns.selected) {
      loaderObj[key] = <Skeleton width="10rem" height="3rem" />;
    }
    return [1, 2, 3, 4, 5, 6].map(() => loaderObj);
  }, [columns]);

  const handleOnPage = event => {
    setLazyState(event);
  };
  return (
    <>
      <div className="w-full grid grid-nogutter">
        <div className="col-6">
          <PageHeader
            pageTitle="Customer Reset Password Logs"
            breadCrumbArray={breadcrumb}
          />
        </div>
      </div>

      <Card className="w-full mt-4 mb-0 mx-auto customer-login-logs p-0">
        <div>
          <DataTable
            value={isLoading ? loaderTable : logsData}
            dataKey="user_log_info_id"
            showGridlines={false}
            size="small"
            stripedRows
            lazy
            paginator={!isLoading}
            page={lazyState.page}
            first={lazyState.first}
            rows={lazyState.rows}
            totalRecords={count}
            onPage={handleOnPage}
            sortField={lazyState?.sortField}
            sortOrder={lazyState?.sortOrder}
            filters={lazyState.filters}
            onSort={onSort}
            onFilter={e => {
              setLazyState({
                first: 0,
                rows: lazyState.rows,
                page: 1,
                sortField: '',
                sortOrder: -1,
                filters: { ...e.filters },
              });
            }}
            rowsPerPageOptions={[10, 20, 50, 100]}
            className="w-full"
            filterDisplay="row"
          >
            {tableColumn.map(col => (
              <Column
                key={col.name}
                field={col.name}
                header={col.label}
                sortable={col?.sortable}
                filter={col?.filter}
                showFilterMenu={false}
                body={isLoading ? <Skeleton /> : col.body}
                filterElement={col?.filterElement || null}
                style={col.style}
              />
            ))}
          </DataTable>
        </div>
      </Card>
    </>
  );
};

export default CustomerResetPasswordLogs;
