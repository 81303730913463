import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';

import {
  REACT_APP_LOGIN_AUTH,
  REACT_APP_RECAPTCHA_KEY,
} from '../../../../constants/envConstants';

import { useStyles } from './Login.styles';
import Welcome from './Welcome';

const SHOW_RECAPTCHA_MAX_LIMIT = 1;

const ForgetPassword = () => {
  const classes = useStyles();
  const toast = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const resetPasswordSubmitCount = parseInt(
    localStorage.getItem('resetPasswordSubmitCount') || 0
  );

  const forgetPasswordValidation = {
    email: Yup.string()
      .required('Email field is required.')
      .email('Email must be a valid email.')
      .matches(/^(?!.*@[^,]*,)/),
    reCaptcha:
      resetPasswordSubmitCount >= SHOW_RECAPTCHA_MAX_LIMIT
        ? Yup.string().required('Please complete the reCAPTCHA.').nullable()
        : Yup.string().nullable(),
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape(forgetPasswordValidation),
    onSubmit: values => {
      setLoading(true);
      const email = values.email;
      const reCaptcha = values.reCaptcha;
      axios
        .post(`${REACT_APP_LOGIN_AUTH}/reset`, {
          email,
          reCaptcha,
          method: 'POST',
        })
        .then(response => {
          if (
            response?.data?.message ===
            'If your email is listed in our database, we will send you instructions on how to reset your password.'
          ) {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail:
                'Success! If your email is listed in our database, we will send you instructions on how to reset your password.',
            });
            setLoading(false);
            setTimeout(() => {
              localStorage.setItem(
                'resetPasswordSubmitCount',
                resetPasswordSubmitCount + 1
              );
              history.push('/');
            }, 2000);
          } else {
            toast.current.show({
              severity: 'error',
              summary: 'Something went wrong.',
              detail: 'Invalid Credentials.',
            });
            setLoading(false);
          }
        })
        .catch(error => {
          localStorage.setItem(
            'resetPasswordSubmitCount',
            resetPasswordSubmitCount + 1
          );
          toast.current.show({
            severity: 'error',
            summary: 'Something went wrong.',
            detail: error?.response?.data?.message || 'Invalid Credentials',
          });
          setLoading(false);
        });
      formik.resetForm();
    },
  });
  return (
    <>
      <Toast ref={toast} />
      <div
        className={`${classes.LoginContainer} flex align-items-center justify-content-center`}
      >
        <div>
          <img
            src="/images/circle-background.png"
            alt="Project Force"
            className="circle-bg mb-3"
            // style={{ maxWidth: '350px' }}
          />
          <div className="text-center pt-5">
            <img
              src="/images/PF-white-logo.png"
              alt="Project Force"
              className="mb-3"
              style={{ maxWidth: '180px' }}
            />
            <p className="px-3" style={{ maxWidth: '470px', margin: 'auto' }}>
              ProjectsForce puts you in control of your work, keeping both you
              and your clients on-track towards completing the job and getting
              paid.{' '}
            </p>
          </div>
          <div className="flex col-12">
            <Welcome />
            <div className="flex col-6 flex h-100 align-center wrap px-5 py-0 login-container-right">
              <div className="flex login-right-inner w-12">
                <div className="login-section w-12">
                  <h1 className="text-center">Forgot Password</h1>
                  <p>
                    Enter your email address and we will email you a link to
                    reset your password.
                  </p>
                  <form onSubmit={formik.handleSubmit}>
                    <div
                      className={`${
                        formik.touched.email && formik.errors.email
                          ? 'validation-error'
                          : ''
                      } col-12 p-0 p-input-icon-right mb-3`}
                    >
                      <span className="p-float-label">
                        <i className="pi pi-user"></i>
                        <InputText
                          autoComplete="off"
                          id="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="email">Email*</label>
                      </span>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error">{formik.errors.email}</div>
                      ) : null}
                    </div>

                    {resetPasswordSubmitCount >= SHOW_RECAPTCHA_MAX_LIMIT && (
                      <div className={` col-12 p-0 p-input-icon-right mb-3`}>
                        <ReCAPTCHA
                          sitekey={REACT_APP_RECAPTCHA_KEY}
                          onChange={captcha =>
                            formik.setFieldValue('reCaptcha', captcha)
                          }
                        />
                        {formik.errors.reCaptcha ? (
                          <div className="error">{formik.errors.reCaptcha}</div>
                        ) : null}
                      </div>
                    )}

                    <div className="col-12 mt-2">
                      By using ProjectsForce, you agree to our{' '}
                      <a href="https://projectsforce.com/privacy-policy">
                        Privacy Policy
                      </a>
                      .
                    </div>
                    <div className="flex flex-wrap">
                      <div className="col-12 p-0 text-right">
                        <Link to="/">&#8592; Go Back </Link>
                      </div>
                      <div className="col-12 p-0">
                        <Button
                          type="submit"
                          severity="primary"
                          label="Submit"
                          icon={loading ? 'pi pi-spin pi-spinner' : ''}
                          pt={{
                            label: {
                              className: 'flex-none w-4rem',
                            },
                          }}
                          className="flex justify-content-center w-full mt-3 flex-row-reverse"
                          disabled={loading}
                        ></Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
