import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  LoginContainer: {
    backgroundColor: '#f3f3f3',
    height: '100vh',
    backgroundImage: 'linear-gradient(to bottom, #0695ff 0%, #b9d8f5 100%)',
    color: '#fff',
    '& .circle-bg': {
      maxWidth: '140px',
      position: 'fixed',
      right: '-202px',
      top: '-202px',
      maxWidth: '410px',
    },
    '& .login-left-inner': {
      justifyContent: 'center',
    },
    '& .login-container-left, .login-right-inner': {
      flexWrap: 'wrap',
      alignContent: 'center',
      '& .login-left-inner': {
        flexWrap: 'wrap',
        height: 'auto',
        alignContent: 'center',
      },
    },
    '& .login-container-right': {
      padding: '20px',
      '& label, small': {
        display: 'block',
        marginBottom: '5px',
      },
      '& input': {
        width: '100%',
        padding: '10px 16px 10px 40px',
        '&:focus': {
          boxShadow: 'none',
        },
        '&:focus + label': {
          marginLeft: '30px',
        },
      },
      '& button.p-button-secondary': {
        background: '#1d25d5 !important',
      },
      '& a': {
        color: '#0094ff',
        fontSize: '14px',
        textDecoration: 'none',
      },
      '& .login-section': {
        color: '#000',
        background: '#ffffff',
        borderRadius: '15px',
        boxShadow: '0px 0px 5px #b1b1b1',
        padding: '34px',
        minHeight: '350px',
        '@media (max-width: 768px)': {
          minHeight: 'auto',
          paddingTop: '0',
        },
      },
      '& label ': {
        marginLeft: '28px!important',
      },
      '& i.pi.pi-user, i.pi.pi-lock': {
        top: '12px',
        left: '15px',
        width: '20px',
        position: 'absolute',
        zIndex: '1',
        color: '#a1a1a1',
      },
      '& i.pi.pi-eye, .pi-eye-slash': {
        top: '12px',
        right: '15px',
        width: '20px',
        color: 'rgba(0, 0, 0, 0.6)',
        position: 'absolute',
      },
      '& .error': {
        color: '#e70000',
        fontSize: '12px',
        marginLeft: '3px',
        marginTop: '3px',
      },
      '& .validation-error input': {
        border: '1px solid #e70000',
      },
    },
    '@media (max-width: 768px)': {
      '& .login-container-left': {
        display: 'none!important',
      },
      '& .login-container-right': {
        width: '100%!important',
        padding: '15px!important',
      },
    },
    '& .reset-password .pi-lock': {
      top: '20px!important',
    },
    '& .blue-text': {
      color: '#0094ff',
      fontSize: '14px',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '& .p-inputotp': {
      justifyContent: 'center',
      '& input': {
        width: '100%',
        maxWidth: '90px',
        padding: '20px !important',
        '&:focus': {
          boxShadow: 'none',
        },
        '&:focus + label': {
          marginLeft: '30px',
        },
      },
    },
  },
  ChangePasswordContainer: {
    '& .error': {
      color: '#e70000',
      fontSize: '12px',
      marginLeft: '3px',
      marginTop: '3px',
    },
  },
}));
