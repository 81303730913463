import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';
import { REACT_APP_DOCUMENTS_MS_API } from '../../../constants/envConstants';

export const getTemplateTypes = async (setLoading, setTemplateTypesOptions) => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates/types`
    );
    let responseData = response?.data;
    let anyType = [
      {
        project_type_id: null,
        project_type: 'All',
      },
    ];
    responseData = [...anyType, ...responseData];
    setTemplateTypesOptions(responseData);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

// Get Job source Options
export const getSourceOptions = async (setLoading, setSourceOptions) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/source-system`
    );
    setSourceOptions(response?.data);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

// Get Job types Options
export const getCategoriesOptions = async (
  setLoading,
  setCategoriesOptions
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates/categories`
    );
    let responseData = response?.data;
    let anyCategory = [
      {
        project_category_id: null,
        category: 'All',
      },
    ];
    responseData = [...anyCategory, ...responseData];
    setCategoriesOptions(responseData);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

// Get widgets
export const getWidgets = async (setLoading, setAlert, setWidgetsData) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates/widgets`
    );
    setWidgetsData(response?.data);
  } catch (error) {
    setAlert('error', 'Failed to fetch widgets');
  } finally {
    setLoading(false);
  }
};

// Get Job source fields Options
export const getSourceFields = async (
  setLoading,
  setSourceFieldOptions,
  setSourceGroupedOptions,
  setAlert
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/dashboard/widgets/cubes`
    );
    if (response.data) {
      setSourceFieldOptions(response.data);
    } else {
      setSourceFieldOptions([]);
    }
  } catch (error) {
    setAlert('error', 'Unable to load Annotation Options', '');
  }
};

// Get templates list
export const getTemplatesList = async (
  searchQuery,
  selectedFilters,
  setLoading,
  setTemplatesListData,
  setTemplatesCount
) => {
  try {
    setLoading(true);
    let queryString = `limit=${searchQuery.limit}&offset=${searchQuery.offset}`;
    if (searchQuery.search_query) {
      queryString = queryString + `&search_query=${searchQuery.search_query}`;
    }
    const order = JSON.stringify([[searchQuery.sortKey, searchQuery.order]]);
    if (searchQuery.order && searchQuery.sortKey) {
      queryString = queryString + `&order=${order}`;
    }

    // Logic to add selected filters in query string
    if (selectedFilters && Object.values(selectedFilters).length) {
      let searchQueryWithFilters = Object.entries(selectedFilters)
        .filter(([prop, value]) => value?.length)
        .map(
          ([key, value], index) => `${key}=${value.map(val => val.toString())}`
        )
        .join('&');

      queryString = queryString + '&' + searchQueryWithFilters;
    }

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates?${queryString}`
    );
    setTemplatesListData(response?.data?.items);
    setTemplatesCount(response?.data?.count);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

//update template
export const applyInstallerVisibilityTemplates = async (
  type,
  installerVisibleDocuments,
  setLoading,
  setAlert,
  setReloadList
) => {
  try {
    // setLoading(true);

    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates/update/?type=${type}`,
      installerVisibleDocuments
    );

    if (response?.data) {
      // setReloadList(true);
      setAlert('success', 'Template updated successfully');
    }
    return response;
  } catch (error) {
    return Promise.reject('Error');
  } finally {
    // setLoading(false);
  }
};

//Create template
export const createTemplate = async (
  templateObj,
  setLoading,
  setAlert,
  setReloadTemplates,
  setIsOpen
) => {
  try {
    setIsOpen(false);
    setLoading(true);
    const formData = new FormData();
    formData.append('template_name', templateObj.template_name.trim() || null);
    formData.append('source_system_id', templateObj.source_system_id);
    templateObj.project_type_id &&
      formData.append('project_type_id', templateObj.project_type_id);
    templateObj.project_category_id &&
      formData.append('project_category_id', templateObj.project_category_id);
    formData.append('file', templateObj.file);
    formData.append('is_custom', templateObj.is_custom ? 1 : 0);
    formData.append(
      'is_customer_visible',
      templateObj.is_customer_visible ? 1 : 0
    );
    formData.append('hd_doc_cd_number', templateObj.hd_doc_cd_number);
    formData.append('state_code', templateObj.state_code);
    formData.append('ll_document_type', templateObj.ll_document_type);
    formData.append(
      'is_signature_required',
      templateObj.is_signature_required ? 1 : 0
    );
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates`,
      formData
    );
    if (response.data) {
      setAlert('success', 'Template created successfully.');
      return response.data;
    }
  } catch (error) {
    setAlert('error', 'Failed to create template');
  }
  return null;
};

export const updateTemplateDetails = async (
  templateId,
  updateTemplateObj,
  setLoading,
  setAlert,
  setReloadForm,
  setIsOpen
) => {
  try {
    setIsOpen(false);
    setLoading(true);
    const formData = new FormData();
    updateTemplateObj['is_custom'] = updateTemplateObj['is_custom'] ? 1 : 0;
    updateTemplateObj['is_customer_visible'] = updateTemplateObj[
      'is_customer_visible'
    ]
      ? 1
      : 0;
    updateTemplateObj['is_signature_required'] = updateTemplateObj[
      'is_signature_required'
    ]
      ? 1
      : 0;
    updateTemplateObj['template_name'] =
      updateTemplateObj['template_name'].trim() || null;
    delete updateTemplateObj['template_id'];
    delete updateTemplateObj['project_category'];
    for (var key in updateTemplateObj) {
      formData.append(key, updateTemplateObj[key]);
    }
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates/${templateId}`,
      formData
    );

    if (response) {
      setAlert('success', 'Template updated successfully.');
      setReloadForm(true);
    }
  } catch (error) {
    setAlert('error', 'Failed to update template');
  } finally {
    setLoading(false);
  }
};

//update template
export const updateTemplate = async (
  templateId,
  updateTemplateObj,
  setLoading,
  setAlert,
  history,
  filename = ''
) => {
  try {
    setLoading(true);
    const formData = new FormData();
    if (updateTemplateObj.fileBlob) {
      var file = new File([updateTemplateObj.fileBlob], filename, {
        type: 'application/pdf',
        lastModified: Date.now(),
      });

      formData.append('file', file);
    }

    formData.append(
      'design_config',
      JSON.stringify(updateTemplateObj.design_config)
    );
    formData.append(
      'design_config_new',
      JSON.stringify(updateTemplateObj.design_config_new)
    );
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates/${templateId}`,
      formData
    );
    if (response) {
      setAlert('success', 'Template changes have been saved succesfully', '');
      return true;
    }
  } catch (error) {
    setAlert('error', 'Failed to update template', 'Please try again later');
  } finally {
    setLoading(false);
  }
};
export const copyTemplate = async (
  templateObj,
  setLoading,
  setAlert,
  setReloadTemplates,
  setIsOpen
) => {
  try {
    setIsOpen(false);
    setLoading(true);
    const templatedId = templateObj.template_id;
    delete templateObj.file;
    delete templateObj.template_id;
    if (templateObj.project_category) {
      delete templateObj.project_category;
    }
    templateObj.is_custom = templateObj.is_custom ? 1 : 0;
    templateObj.is_customer_visible = templateObj.is_customer_visible ? 1 : 0;
    templateObj.is_signature_required = templateObj.is_signature_required
      ? 1
      : 0;
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates/${templatedId}/copy`,
      templateObj
    );
    if (response?.status === 200) {
      setAlert('success', 'Template copied successfully.');
      setReloadTemplates(true);
    }
  } catch (error) {
    setAlert('error', 'Failed to copy template');
  } finally {
    setLoading(false);
  }
};

export const getTemplateById = async (
  templateId,
  setLoading,
  setAlert,
  setTemplateData,
  setTempItemsData,
  setAnnotationData
) => {
  setLoading(true);
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates/${templateId}`
    );
    if (response) {
      setTemplateData(response?.data);
      if (response?.data?.design_config_new) {
        setAnnotationData(response?.data.design_config_new);
      } else {
        setAnnotationData({});
      }
    }
  } catch (error) {
    setAlert('error', 'error', 'Failed to fetch template');
  } finally {
    setLoading(false);
  }
};
export const getTemplatePreview = async (
  templateId,
  setLoading,
  setAlert,
  setTemplatePreviewData,
  setLoadingError
) => {
  setLoading(true);
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates/${templateId}/preview`
    );
    setTemplatePreviewData(response?.data);
  } catch (error) {
    setLoadingError(true);
    setAlert('error', 'Failed to fetch template');
  } finally {
    setLoading(false);
  }
};
export const deleteDocument = async (
  templateId,
  setLoading,
  setAlert,
  setReloadForm,
  setConfirmDeleteDialog
) => {
  try {
    let query = 'document_template_id';
    setConfirmDeleteDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/documents/templates?${query}=${templateId}`
    );
    if (response) {
      setReloadForm(true);
      setAlert('success', 'Document Deleted successfully.', false, true);
    }
  } catch (error) {
    setAlert('error', 'Error in Deleting Document', false, true);
    setLoading(false);
  }
};

//save template new
export const saveTemplate = async (templateObj, setLoading, setAlert) => {
  try {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        client_id: `${localStorage.getItem('client_id')}`,
      },
    };
    const response = await axios.post(
      `${REACT_APP_DOCUMENTS_MS_API}/pdf/create`,
      templateObj,
      config
    );
    if (response && response.data.status == true) {
      setAlert(
        'success',
        'Template created successfully',
        'You will be redirected to Documents Center'
      );
    } else {
      setAlert('error', 'Failed to update template', 'Please try again later');
    }
    setLoading(false);
    return response.data;
  } catch (error) {
    setAlert(
      'error',
      'Error Ocurred while updating template',
      'Please contact the service provider to resolve this issue'
    );
  } finally {
    setLoading(false);
  }
  return false;
};

//get template data
export const getDocumentAnnotations = async (
  documentId,
  setAnnotationData,
  setLoading,
  setAlert
) => {
  setLoading(true);
  try {
    let response = null;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        client_id: `${localStorage.getItem('client_id')}`,
      },
    };
    response = await axios.get(
      `${REACT_APP_DOCUMENTS_MS_API}/pdf/${documentId}/get`,
      config
    );
    if (response?.data?.psPdfData) setAnnotationData(response.data.psPdfData);
    else {
      setAnnotationData({});
    }
  } catch (error) {
    setAlert('error', 'Unable to load pdf annotations', '');
  } finally {
    setLoading(false);
  }
};
export const updateDocumentOpen = async openDocumentInfo => {
  await axios.post(
    `${URL_CONSTANTS.API.BASE_URL}/documents/templates/update-document-open-count`,
    openDocumentInfo
  );
};
