import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { debounce } from 'lodash';
import { confirmDialog } from 'primereact/confirmdialog';

import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import Inventory from '../Inventory/Inventory.view';
import PFTableLoader from '../../shared/Loader/PFTableLoader.js';
import PFDataTable from '../../shared/PFPrime/PFDataTable.js';
import PFInputText from '../../shared/PFPrime/PFInputText.js';
import PFButton from '../../shared/PFPrime/PFButton.js';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import { ParentItemFilterOptions } from '../../../constants.js';

import { getMerchandiseItemsList } from './Items.service.js';
import { useStyles } from './Items.styles.js';
import AddEditMerchandiseItemDialog from './AddEditMerchandiseItemDialog.js';
import { unlinkGroupItems } from './GroupItems/group-items.service';

import './styles.css';

const ProductTableNew = ({ itemId }) => {
  const toast = useRef(null);

  const classes = useStyles();
  const [, setLoadingTable] = useState(false);
  const [merchandiseSearchQuery, setMerchandiseSearchQuery] = useState({
    limit: 10,
    offset: 0,
  });
  const [filters, setFilters] = useState({});
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [inventoryItem, setInventoryItem] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [
    isAddMerchandiseItemDialogVisible,
    setIsAddMerchandiseItemDialogVisible,
  ] = useState(false);
  const [action, setAction] = useState('add');
  const [rowData, setRowData] = useState('');
  const [itemReload, setItemReload] = useState(false);
  const [merchandiseItemsList, setMerchandiseItemsList] = useState([]);
  const [merchandiseLoading, setMerchandiseLoading] = useState(true);
  const [eventRow, setEventRow] = useState(null);
  const [eventFirst, setEventFirst] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getMerchandiseItemsList(
      merchandiseSearchQuery,
      setMerchandiseLoading,
      setMerchandiseItemsList
    );
  }, [eventRow, eventFirst, merchandiseSearchQuery, itemReload]);

  //*** Merchandise Items - Action Handlers ***/
  const viewEditMerchandiseItems = (action, index, rowData) => {
    if (merchandiseItemsList.items[index]?.inventory_managment) {
      setInventoryItem(merchandiseItemsList.items[index]);
      setInventoryOpen(true);
    } else {
      setInventoryItem({});
      setInventoryOpen(false);
      setRowData(rowData);
      setAction(action);
      setIsAddMerchandiseItemDialogVisible(true);
    }
  };
  const manufacturerTemplate = rowData => {
    return rowData?.manufacturer?.value || '';
  };

  const vendorTemplate = rowData => {
    return rowData?.vendor?.value || '';
  };

  const handleUnlinkItems = async rowData => {
    setMerchandiseLoading(true);
    try {
      const response = await unlinkGroupItems(rowData?.item);
      if (response) {
        toast.current.show({
          severity: 'success',
          summary: 'Item Unlinked',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setMerchandiseLoading(false);
      setItemReload(true);
    }
  };

  const getRowClassName = rowData => {
    if (rowData?.item_is_child) {
      return 'highlight-children';
    } else if (rowData?.item_is_parent) {
      return 'highlight-parent';
    }
    return '';
  };
  const handleUnlinkClick = rowData => {
    confirmDialog({
      message: `Are you sure you want to unlink the ${!rowData?.item_parent_id ? 'parent' : ''} item "${rowData?.item_number} - ${rowData?.item_desc}"? ${!rowData?.item_parent_id ? 'This action will also remove all its associated child items.' : ''}`,
      header: 'Unlink Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleUnlinkItems(rowData),
      rejectLabel: 'Cancel',
      acceptLabel: 'Unlink',
      pt: {
        root: {
          className: 'w-30rem',
        },
      },
    });
  };

  // Merchandise Generic Table
  const merchandiseDataTableColumns = [
    {
      field: '',
      sortable: false,
      style: { width: '75px' },
      body: rowData => {
        const dataIndex = rowData?.index;
        return (
          checkPermission(permissions?.items?.editMerchandiseItem) &&
          merchandiseItemsList.items[dataIndex]?.user?.username !==
            'system' && (
            <div
              id={`merchandise-icons-${dataIndex}`}
              className="flex justify-content-between align-items-center"
            >
              <Tooltip target=".editItem" mouseTrack mouseTrackLeft={10} />
              <span
                className="cursor-pointer ml-3 editItem"
                data-pr-tooltip="Edit"
                data-pr-position="top"
              >
                <i
                  className="pi pi-pencil text-color-900"
                  onClick={() =>
                    viewEditMerchandiseItems('edit', dataIndex, rowData)
                  }
                ></i>
              </span>
              {rowData?.item_is_parent ? (
                <span className="cursor-pointer ml-3">
                  <Tooltip target=".ejectItems" />
                  <i
                    className="pi pi-list text-color-900 ejectItems"
                    data-pr-tooltip="Show Linked Items"
                    data-pr-position="top"
                    onClick={() =>
                      history.push({
                        pathname: '/project/items/group-items/add',
                        state: {
                          type: 'Product',
                          is_child: true,
                          item_number: rowData?.item_number,
                          item_desc: rowData?.item_desc,
                          item_id: rowData?.item,
                        },
                      })
                    }
                  ></i>
                </span>
              ) : null}
              {rowData?.item_is_parent || rowData?.item_is_child ? (
                <span className="cursor-pointer ml-3">
                  <Tooltip target=".unlinkItem" />
                  <img
                    className="unlinkItem"
                    src="/images/UnlinkIcon.svg"
                    alt="Unlink Icon"
                    data-pr-tooltip="Unlink Item"
                    data-pr-position="top"
                    onClick={() => handleUnlinkClick(rowData)}
                  />
                </span>
              ) : (
                <span className="cursor-pointer ml-3">
                  <Tooltip target=".linkItem" />
                  <i
                    className="pi pi-link text-color-900 linkItem"
                    alt="Link Icon"
                    data-pr-tooltip="Link Item"
                    data-pr-position="top"
                    onClick={() => {
                      history.push({
                        pathname: '/project/items/group-items/add',
                        state: {
                          type: 'Product',
                          is_child: false,
                          item_number: rowData?.item_number,
                          item_desc: rowData?.item_desc,
                          item_id: rowData?.item,
                        },
                      });
                    }}
                  ></i>
                </span>
              )}
            </div>
          )
        );
      },
    },
    {
      field: 'image_url',
      header: 'Image',
      sortable: false,
      style: { width: '100px' },
    },
    {
      field: 'item_number',
      header: 'Item #',
      sortable: true,
      style: { width: '150px' },
    },
    {
      field: 'item_desc',
      header: 'Item Description',
      sortable: true,
      style: { width: '35%', whiteSpace: 'normal', wordBreak: 'break-word' },
    },
    {
      field: 'item_parent_id',
      header: 'Parent Item',
      filter: true,
      showFilterMatchModes: false,
      showFilterMenuOptions: false,
      showFilterOperator: false,
      showClearButton: true,
      showAddButton: false,
      filterClear: (
        <PFButton
          size="small"
          label="Clear"
          onClick={() => {
            setFilters({});
            setMerchandiseSearchQuery(prevState => ({
              ...prevState,
              is_parent: undefined,
              is_child: undefined,
            }));
          }}
          severity=" "
          outlined={true}
        />
      ),
      pt:
        Array.isArray(filters?.item_parent_id?.constraints) &&
        filters?.item_parent_id?.constraints.length
          ? {
              filtermenubutton: {
                style: { color: 'green' },
              },
            }
          : {},
      filterElement: options => {
        if (
          Array.isArray(filters?.item_parent_id?.constraints) &&
          filters?.item_parent_id?.constraints.length &&
          !options.value
        ) {
          options.value = filters?.item_parent_id?.constraints[0]?.value;
        }
        return (
          <PFDropdown
            value={options?.value}
            name="filter"
            optionLabel="label"
            optionValue="value"
            options={ParentItemFilterOptions}
            onChange={(name, value) => {
              options.filterCallback(value);
            }}
            placeholder="Select"
          />
        );
      },
      body: rowData => {
        if (rowData?.item_parent_id && rowData?.parent) {
          return (
            <span
              style={{ cursor: 'pointer', color: 'green' }}
              onClick={() => {
                history.push({
                  pathname: '/project/items/group-items/add',
                  state: {
                    type: 'Product',
                    is_child: true,
                    item_number: rowData?.parent?.item_number,
                    item_desc: rowData?.parent?.item_desc,
                    item_id: rowData?.parent?.item_id,
                  },
                });
              }}
            >
              {`${rowData?.parent?.item_number}`}
            </span>
          );
        } else {
          return '-';
        }
      },
      style: { width: '150px' },
    },
    {
      header: 'Manufacturer',
      body: manufacturerTemplate,
      field: 'manufacturer',
      sortable: true,
      style: { width: '150px' },
    },
    {
      header: 'Vendor',
      body: vendorTemplate,
      field: 'vendor',
      sortable: true,
      style: { width: '150px' },
    },
    {
      field: 'retail_price',
      header: 'Retail Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'revenue_price',
      header: 'Revenue Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'cost_price',
      header: 'Cost Ea $',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'inventory_managment',
      header: 'Has Inventory',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'express_ship',
      header: 'Has Express Ship',
      sortable: true,
      style: { width: '100px' },
    },
    {
      field: 'exclude_from_commission',
      header: 'Exclude From Commission',
      sortable: true,
      style: { width: '100px' },
    },
  ];

  let merchandiseDataArray = useMemo(() => {
    if (merchandiseItemsList?.items?.length) {
      return merchandiseItemsList?.items?.map((val, index) => {
        return {
          index: index,
          item: val?.item_id || '',
          parent: val?.parent || null,
          children: val?.children || [],
          item_is_parent: val?.parent_id === 0 || false,
          item_parent_id: val?.parent_id || '',
          item_is_child: !!val?.parent_id,
          item_number: val?.item_number || '',
          item_desc: val?.item_desc || '',
          retail_price: val.retail_price || 'N/A',
          revenue_price: val.revenue_price || 'N/A',
          cost_price: val.cost_price || 'N/A',
          image_url: (
            <Image
              width="40"
              height="30"
              alt="Item"
              src={
                val?.image_url ||
                'https://placehold.jp/14/d1d5db/666/80x40.png?text=No Image'
              }
              preview={val?.image_url}
              indicatorIcon={<i className="pi pi-search"></i>}
              pt={{
                image: {
                  className: 'border-round w-4rem h-2rem',
                  style: { objectFit: 'cover' },
                },
              }}
            />
          ),
          image_url_value: val?.image_url || '',
          inventory_managment: val.inventory_managment ? 'Yes' : 'No',
          express_ship: val?.express_ship ? 'Yes' : 'No',
          manufacturer: val?.meta_type_manufacturer,
          vendor: val?.meta_type_vendor,
          exclude_from_commission: val?.exclude_from_commission ? 'Yes' : 'No',
        };
      });
    }
    return [];
  }, [merchandiseItemsList?.items]);

  const onColumnSortChange = (changedColumn, direction) => {
    setLoadingTable(true);
    setMerchandiseSearchQuery(prevState => ({
      ...prevState,
      sortKey: changedColumn,
      sortDirection: direction === 1 ? 'asc' : 'desc',
    }));
  };

  const onChangeRowsPerPage = event => {
    setEventRow(event?.rows);
    setEventFirst(event?.first);
    setLoadingTable(true);
    setMerchandiseSearchQuery(prevState => ({
      ...prevState,
      limit: event?.rows,
      offset: event?.first,
    }));
  };

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    setEventRow(null);
    setEventFirst(null);
    const query = searchInputVal?.trim();
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    if (!query) {
      setMerchandiseSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        query: '',
      }));
      return;
    }
    if (query.length < 3) return;
    if (query.length >= 3) {
      setMerchandiseSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        query: query,
      }));
    }
  };

  const debounceKeywordSearch = useCallback(debounce(handleSearch, 500), []);
  // Merchandise Generic Table Ends Here.
  const showAlert = (severity, message, lifespan = 1500) => {
    toast.current.show({
      severity: severity,
      summary: message,
      lifespan: lifespan,
    });
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex justify-content-between">
          <h3 className="text-900 pt-2 text-lg">Product Items</h3>
          <div className="flex gap-2">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <PFInputText
                value={globalFilterValue}
                onChange={e => {
                  setGlobalFilterValue(e.target.value);
                  debounceKeywordSearch(e.target.value);
                }}
                placeholder="Keyword Search"
              />
            </span>
            {checkPermission(permissions?.items?.addLaborItem) && (
              <PFButton
                size="small"
                label="Add Product Item"
                classes={{ root: classes.buttonRoot }}
                onClick={() => {
                  setIsAddMerchandiseItemDialogVisible(true), setAction('add');
                }}
                disabled={
                  !checkPermission(permissions?.items?.addMerchandiseItem)
                }
              />
            )}
          </div>
        </div>
      </>
    );
  };
  const onFilter = e => {
    setFilters(e.filters);
    if (
      Array.isArray(e.filters?.item_parent_id?.constraints) &&
      e.filters?.item_parent_id?.constraints.length
    ) {
      const value = e.filters?.item_parent_id?.constraints[0]?.value;

      if (value === ParentItemFilterOptions[0].value) {
        setMerchandiseSearchQuery(prevState => ({
          ...prevState,
          is_parent: 1,
          is_child: undefined,
        }));
      } else if (value === ParentItemFilterOptions[1].value) {
        setMerchandiseSearchQuery(prevState => ({
          ...prevState,
          is_parent: undefined,
          is_child: 1,
        }));
      }
    }
  };

  const dataTableParams = { header: renderHeader() };

  return (
    <>
      <Toast ref={toast} />
      {merchandiseLoading ? (
        <div className="justify-content-center">
          {merchandiseLoading && <PFTableLoader />}
        </div>
      ) : (
        <>
          {inventoryOpen && (
            <Inventory
              className="ml-1"
              inventoryOpen={inventoryOpen}
              setInventoryOpen={setInventoryOpen}
              inventoryItem={inventoryItem}
              itemsReload={setItemReload}
              showAlert={showAlert}
            />
          )}

          <div>
            <PFDataTable
              columns={merchandiseDataTableColumns}
              data={merchandiseDataArray}
              paginator={true}
              dataKey="item_id"
              rows={merchandiseSearchQuery?.limit}
              rowsPerPageOptions={[10, 20, 50, 100]}
              tableStyle={{ minWidth: '80rem' }}
              dataTableParams={dataTableParams}
              rowClassName={getRowClassName}
              onSort={event => {
                onColumnSortChange(event?.sortField, event?.sortOrder);
              }}
              filter={filters}
              onFilter={event => onFilter(event)}
              sortField={merchandiseSearchQuery?.sortKey}
              sortOrder={
                merchandiseSearchQuery?.sortDirection === 'asc' ? 1 : -1
              }
              totalRecords={merchandiseItemsList?.count || 0}
              lazy={true}
              first={merchandiseSearchQuery?.offset}
              onPage={onChangeRowsPerPage}
              pt={{
                wrapper: {
                  style: { height: '500px' },
                },
              }}
            />
          </div>
        </>
      )}
      {isAddMerchandiseItemDialogVisible && (
        <AddEditMerchandiseItemDialog
          visible={isAddMerchandiseItemDialogVisible}
          setIsAddMerchandiseItemDialogVisible={
            setIsAddMerchandiseItemDialogVisible
          }
          itemId={itemId}
          itemsReload={setItemReload}
          setMerchandiseLoading={setMerchandiseLoading}
          action={action}
          rowData={rowData}
          showAlert={showAlert}
        />
      )}
    </>
  );
};

export default ProductTableNew;
