import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { REACT_APP_LOGIN_AUTH } from '../../../../constants/envConstants';

import { useStyles } from './Login.styles';
import Welcome from './Welcome';
import { resetPasswordValidation } from './LoginAuthValidation';

const SetPassword = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const toast = useRef(null);
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const hashPath = urlParams.get('hash');
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordValidation,
    onSubmit: values => {
      setFormData(values);
      axios
        .post(`${REACT_APP_LOGIN_AUTH}/password?hash=${hashPath}`, {
          password: values.password,
        })
        .then(response => {
          if (response?.data?.message === 'User password set successfully') {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'User password reset successfully',
            });
            formik.resetForm();
            setTimeout(() => {
              history.push('/');
            }, 2000);
          } else {
            toast.current.show({
              severity: 'error',
              summary: 'Something went wrong.',
              detail: 'Something went wrong.',
            });
          }
        })
        .catch(error => {
          toast.current.show({
            severity: 'error',
            summary: 'Something went wrong.',
            detail: error?.response?.data?.message || 'Set Password Failed.',
          });
        });
    },
  });

  const passwordFooter = (
    <React.Fragment>
      <small>
        Password length must be minimum 8 characters and at least one lowercase,
        one uppercase, one number, and one special character.
      </small>
    </React.Fragment>
  );

  return (
    <>
      <Toast ref={toast} />

      <div
        className={`${classes.LoginContainer} flex align-items-center justify-content-center`}
      >
        <div>
          <div className="text-center md:pt-5 pt-0">
            <img
              src="/images/PF-white-logo.png"
              alt="Project Force"
              className="mb-3"
              style={{ maxWidth: '180px' }}
            />
            <p className="px-3" style={{ maxWidth: '470px', margin: 'auto' }}>
              ProjectsForce puts you in control of your work, keeping both you
              and your clients on-track towards completing the job and getting
              paid.{' '}
            </p>
          </div>
          <div className="flex col-12">
            <Welcome />
            <div className="flex col-6 flex h-100 align-center wrap p-6 login-container-right w-100">
              <div className="flex login-right-inner w-12">
                <div className="login-section w-12">
                  <h1 className="text-center">Set Password</h1>

                  <form
                    onSubmit={formik.handleSubmit}
                    className="p-fluid reset-password"
                  >
                    <div name="password">
                      <div
                        className={`${
                          formik.touched.password && formik.errors.password
                            ? 'validation-error'
                            : ''
                        } col-12 p-0 p-input-icon-right mb-3 `}
                      >
                        <i className="pi pi-lock"></i>
                        <span className="p-float-label">
                          <Password
                            id="password"
                            toggleMask
                            name="password"
                            strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!*_])[A-Za-z\d@#$%^&+=!*_]{8,}$"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            footer={passwordFooter}
                          />
                          <label htmlFor="password">Password*</label>
                        </span>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="error">{formik.errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                    <div name="confirmPassword">
                      <div
                        className={`${
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                            ? 'validation-error'
                            : ''
                        } col-12 p-0 p-input-icon-right mb-3 `}
                      >
                        <i className="pi pi-lock"></i>

                        <span className="p-float-label">
                          <Password
                            autoComplete="off"
                            id="confirmPassword"
                            name="confirmPassword"
                            onChange={formik.handleChange}
                            value={formik.values.confirmPassword}
                            onBlur={formik.handleBlur}
                            feedback={false}
                          />
                          <label htmlFor="confirmPassword">
                            Confirm Password*
                          </label>
                        </span>
                        {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword ? (
                          <div className="error">
                            {formik.errors.confirmPassword}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-2">
                      By using ProjectsForce, you agree to our{' '}
                      <a href="https://projectsforce.com/privacy-policy">
                        Privacy Policy
                      </a>
                      .
                    </div>
                    <Button
                      type="submit"
                      label="Submit"
                      disabled={formik.dirty && formik.isValid ? false : true}
                      className="mt-2"
                    ></Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetPassword;
