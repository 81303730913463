// **** External Utilities ****
import axios from 'axios';

import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Get Stores List
export const getStoresList = async (
  searchQuery,
  selectedFilters,
  setLoading,
  setStoreListData
) => {
  try {
    setLoading(true);
    let newQueryString;
    newQueryString = Object.entries(searchQuery)
      .filter(([prop, value]) => Boolean(value) || value === 0)
      .map(([key, value], index) => `${key}=${value}`)
      .join('&');

    // Logic to add selected filters in query string
    if (selectedFilters && Object.values(selectedFilters).length) {
      let searchQueryWithFilters = Object.entries(selectedFilters)
        .filter(([prop, value]) => value?.length)
        .map(
          ([key, value], index) => `${key}=${value.map(val => val.toString())}`
        )
        .join('&');

      newQueryString = newQueryString + '&' + searchQueryWithFilters;
    }

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/reports?${newQueryString}`
    );
    setStoreListData(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

//Delete Store from Store List page
export const deleteStore = async (
  storeId,
  setLoading,
  setAlert,
  setReloadList,

  setConfirmDialog
) => {
  try {
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));
    setLoading(true);
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/stores/${storeId}`
    );
    if (response) {
      setReloadList(true);
      setAlert('success', 'Store deleted successfully.', true, true);
    }
  } catch (error) {
    setAlert('error', 'Error in Deleting Store', false, true);
    setLoading(false);
  }
};

// Get Client Details by Id
export const getStoreById = async (
  storeId,
  setLoading,
  setAlert,
  setStoreDetailsData,
  setReloadForm
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/${storeId}`
    );
    setStoreDetailsData(response?.data);
  } catch (error) {
    setAlert('error', 'Error in Fetching Store Details.');
  } finally {
    setReloadForm(false);
    setLoading(false);
  }
};

// Create a new client
export const addStore = async (
  createObj,
  history,
  setLoading,
  setAlert,
  setReloadForm,
  clearMasterData,
  fetchMasterData
) => {
  try {
    setLoading(true);
    const response = await axios.post(`${URL_CONSTANTS.API.BASE_URL}/stores`, {
      source_system_id: createObj?.source?.source_system_id,
      division: createObj?.division || null,
      region: createObj?.region || null,
      district: createObj?.district || null,
      store_name: createObj?.store_name.trim() || null,
      store_number: createObj?.store_number,
      is_deleted: createObj?.is_store_active ? 0 : 1,
      address1: createObj?.address || null,
      city: createObj?.city || null,
      state: createObj?.state || null,
      zipcode: createObj?.zip || null,
      phone_number: createObj?.phone_number || null,
      phonearea_citycode: createObj?.phone_area_city_code || null,
      pse_name: createObj?.pse_name.trim() || null,
      pse_email: createObj?.pse_email || null,
      latitude: createObj?.latitude || null,
      longitude: createObj?.longitude || null,
      type_id: createObj?.type_id?.type_id || null,
      installer_id: createObj?.installer_id || null,
      master_warehouse_id: createObj?.master_warehouse_id || null,
    });

    if (response.status === 200) {
      setReloadForm(true);
      history.push(`/stores/view/${response?.data?.store_id}`);
      setAlert('success', 'Store added successfully.', true, true);
      clearMasterData();
      fetchMasterData();
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
  } finally {
    setLoading(false);
  }
};

// Get Project Type, Category, Source, Customers dropdown options
export const getStoreAssociatesRoles = async (
  setLoading,
  setAssociateRoles
) => {
  try {
    // setLoading(true);

    const sourceResponse = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/manage/roles`
    );

    setAssociateRoles(sourceResponse?.data?.rows);
  } catch (error) {
    console.log(error);
  } finally {
    //setLoading(false);
  }
};

// Update Store Associate
export const updateStore = async (
  storeId,
  updateObj,
  history,
  setReloadForm,
  setLoading,
  setAlert,
  clearMasterData,
  fetchMasterData
) => {
  try {
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/stores/${storeId}`,
      {
        source_system_id: updateObj?.source?.source_system_id,
        division: updateObj?.division || null,
        region: updateObj?.region || null,
        district: updateObj?.district || null,
        store_name: updateObj?.store_name.trim() || null,
        store_number: updateObj?.store_number,
        is_deleted: updateObj?.is_store_active ? 0 : 1,
        address1: updateObj?.address || null,
        city: updateObj?.city || null,
        state: updateObj?.state || null,
        zipcode: updateObj?.zip || null,
        phone_number: updateObj?.phone_number || null,
        phonearea_citycode: updateObj?.phone_area_city_code || null,
        pse_name: updateObj?.pse_name || null,
        pse_email: updateObj?.pse_email || null,
        latitude: updateObj?.latitude || null,
        longitude: updateObj?.longitude || null,
        type_id: updateObj?.type_id?.type_id || null,
        installer_id: updateObj?.installer_id || null,
        master_warehouse_id: updateObj?.master_warehouse_id || null,
      }
    );
    if (response) {
      setReloadForm(true);
      history.push(`/stores/view/${storeId}`);

      setAlert('success', 'Store Details updated successfully.', true, true);
      clearMasterData(), fetchMasterData();
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
    setLoading(false);
  }
};

// Get Project Type, Category, Source, Customers dropdown options
export const getDropdownValues = async (setLoading, setSourceOptions) => {
  try {
    setLoading(true);

    const sourceResponse = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/source-system`
    );

    setSourceOptions(sourceResponse?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const getStatusOptions = async (
  statusType,
  setLoading,
  setStatusOptions
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/status?statusType=${statusType}`
    );
    setStatusOptions(response?.data);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

// Create Store Associate
export const createStoreAssociate = async (
  storeId,
  values,
  setLoading,
  setAlert,
  setReloadForm,
  setOpen
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/stores/${storeId}/associates`,
      values
    );
    if (response) {
      setReloadForm(true);
      setAlert('success', 'Store Associate added successfully.', true, true);
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
    setLoading(false);
  }
};

// Add New Store Associate Role
export const addNewAssociateRole = async (
  values,
  setAlert,
  setAssociateRoles
) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/stores/manage/roles`,
      values
    );

    if (response) {
      setAssociateRoles(response?.data?.rows);
      return response?.data?.rows;
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
  }
};

// Update Store Associate
export const updateStoreAssociate = async (
  storeId,
  associateId,
  values,
  setLoading,
  setAlert,
  setReloadForm,
  setOpen
) => {
  try {
    setOpen(false);
    setLoading(true);
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/stores/${storeId}/associates/${associateId}`,
      values
    );
    if (response) {
      setReloadForm(true);
      setAlert(
        'success',
        'Associate Details updated successfully.',
        true,
        true
      );
    }
  } catch (error) {
    setAlert('error', error?.response?.data?.error);
    setLoading(false);
  }
};

//Delete Associate
export const deleteAssociate = async (
  storeId,
  associateId,
  setLoading,
  setAlert,
  setReloadForm,

  setConfirmDialog
) => {
  try {
    setLoading(true);
    setConfirmDialog(prevState => ({
      ...prevState,
      isOpen: false,
    }));

    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/stores/${storeId}/associates/${associateId}`
    );
    if (response) {
      setReloadForm(true);
      setAlert('success', 'Store Associate Deleted successfully.', true, true);
    }
  } catch (error) {
    setAlert('error', 'Error in Deleting Store Associate', false, true);
    setLoading(false);
  }
};

// Get filter options
export const getStoreTableFiltersOptions = async (
  setDistrictOptions,
  setRegionOptions
) => {
  try {
    const regionRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/region`
    );
    const districtRequest = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/stores/district`
    );

    const responses = await Promise.all([regionRequest, districtRequest]);

    setRegionOptions(responses[0]?.data);
    setDistrictOptions(responses[1]?.data);
  } catch (error) {
    console.log(error);
  }
};

export const getWorkRoomValues = async (setWorkRoomOptions, value) => {
  try {
    const sourceResponse = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/workroom/list?searchString=${value}`
    );

    setWorkRoomOptions(sourceResponse?.data);
    return sourceResponse;
  } catch (error) {
    console.log(error);
  }
};

export const addNewWorkRoom = async values => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/system/workroom`,
      values
    );

    return response;
  } catch (error) {
    console.log('error', error);
  }
};

export const searchWorkrooms = async (searchString = '') => {
  try {
    const searchResponse = await apiService.get(
      `/system/workroom/list?searchString=${searchString}`
    );
    return searchResponse;
  } catch (error) {
    console.log(error);
  }
};

export const getActiveInstallers = async () => {
  try {
    const response = await apiService.get('/auth/user/report/get-installers');
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getItemData = async (store_id, type) => {
  try {
    const response = await apiService.get(
      `${URL_CONSTANTS.ITEM_SOURCE_COST_EACH.getItem}/${store_id}?type=${type}`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const AddItemData = async (storeId, payload) => {
  try {
    const response = await apiService.post(
      `${URL_CONSTANTS.ITEM_SOURCE_COST_EACH.addItem}/${storeId}`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getLaborItemDropdownListData = async searchString => {
  try {
    const response = await apiService.get(
      `${URL_CONSTANTS.ITEM_SOURCE_COST_EACH.getLaborList}`,
      {
        params: {
          query: searchString,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateItemData = async (store_item_id, payload) => {
  try {
    const response = await apiService.patch(
      `${URL_CONSTANTS.ITEM_SOURCE_COST_EACH.updateItem}/${store_item_id}`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteRowItemData = async store_item_id => {
  try {
    const response = await apiService.delete(
      `${URL_CONSTANTS.ITEM_SOURCE_COST_EACH.deleteItem}/${store_item_id}`
    );

    console.log('Service', response);

    return response;
  } catch (error) {
    throw error;
  }
};

export const searchListItemData = async (store_id, searchString) => {
  try {
    const response = await apiService.get(
      `${URL_CONSTANTS.ITEM_SOURCE_COST_EACH.getItem}/${store_id}?searchString=${searchString}`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchWarehouseList = async payload => {
  try {
    const data = await apiService.get(`/system/master-warehouses/list`);
    return data;
  } catch (error) {
    console.error('error ', error);
    return [];
  }
};
