import React, { useContext } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';

import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import { YES_NO_OPTIONS } from '../constants/constants';
import PFCalendar from '../../../shared/PFPrime/PFCalendar';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import { ReceivingContext } from '../ReceivingContainer';
import { convertString } from '../helpers/helper';
import { dateFormatter } from '../../../../utils/Helpers';
export const ReceivingTypeDelivery = ({ receiptForm }) => {
  const { receivingContextValue } = useContext(ReceivingContext);
  const { values, setFieldValue, handleChange } = receiptForm;
  return (
    <>
      <div className="col-12 md:col-4 lg:col-4">
        <span className="p-float-label">
          <PFDropdown
            name="needed"
            optionLabel="label"
            optionValue="value"
            value={convertString(values?.needed)}
            options={YES_NO_OPTIONS}
            onChange={(name, value) => setFieldValue(name, value || null)}
            disabled={receivingContextValue?.visibleMode?.disabled || false}
            showClear={true}
          />
          <label htmlFor="needed">Needed</label>
        </span>
      </div>
      <div className="col-12 md:col-6 lg:col-4">
        <span className="p-float-label">
          <PFCalendar
            name="receipt_date"
            appendTo={null}
            disabled={receivingContextValue?.visibleMode?.disabled || false}
            onChange={e =>
              setFieldValue(
                'receipt_date',
                (e?.target?.value &&
                  dateFormatter(e?.target?.value, 'YYYY-MM-DD')) ||
                  null
              )
            }
            value={
              values?.receipt_date
                ? new Date(dateFormatter(values?.receipt_date, 'MM/DD/YYYY'))
                : null
            }
          />
          <label htmlFor="payPercent">Date</label>
        </span>
      </div>
      <div className="col-12 md:col-4 lg:col-4">
        <span className="p-float-label">
          <PFDropdown
            name="technician_id"
            optionLabel="full_name"
            optionValue="user_id"
            filter={true}
            value={values?.technician_id || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            options={receivingContextValue?.technicianList || []}
            disabled={receivingContextValue?.visibleMode?.disabled || false}
            showClear={true}
          />
          <label htmlFor="technician_id">Technician</label>
        </span>
      </div>
      <div className="col-12 md:col-4 lg:col-4">
        <span className="p-float-label">
          <PFDropdown
            name="from_warehouse_id"
            value={values?.from_warehouse_id || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            options={receivingContextValue?.warehouse || []}
            optionLabel="name"
            optionValue="master_warehouse_id"
            disabled={receivingContextValue?.visibleMode?.disabled || false}
            showClear={true}
            filter={true}
          />
          <label htmlFor="from_warehouse_id">Transfer From</label>
        </span>
      </div>
      <div className="col-12 md:col-4 lg:col-4">
        <span className="p-float-label">
          <PFDropdown
            name="to_warehouse_id"
            value={values?.to_warehouse_id || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            options={receivingContextValue?.warehouse || []}
            optionLabel="name"
            optionValue="master_warehouse_id"
            disabled={receivingContextValue?.visibleMode?.disabled || false}
            showClear={true}
            filter={true}
          />
          <label htmlFor="to_warehouse_id">Transfer To</label>
        </span>
      </div>
      <div className="col-12 md:col-4 lg:col-4">
        <span className="p-float-label">
          <PFInputNumber
            name="box_count"
            value={values?.box_count || ''}
            onChange={(name, value) => setFieldValue(name, value || '')}
            disabled={receivingContextValue?.visibleMode?.disabled || false}
          />
          <label htmlFor="box_count">Box Count</label>
        </span>
      </div>
      <div className="col-12 md:col-12 lg:col-12">
        <span className="p-float-label">
          <InputTextarea
            name="receipt_note"
            className="w-full"
            rows={5}
            cols={30}
            onChange={e =>
              setFieldValue('receipt_note', e?.target?.value || '')
            }
            value={values?.receipt_note || ''}
            disabled={receivingContextValue?.visibleMode?.disabled || false}
          />
          <label htmlFor="receipt_note">Notes</label>
        </span>
      </div>
    </>
  );
};
