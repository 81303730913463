import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { useFormik } from 'formik';
import * as Yup from 'yup';

/* Components */

/* Services */
import { debounce } from 'lodash';
import { Checkbox } from 'primereact/checkbox';
import { BlockUI } from 'primereact/blockui';
import { Message } from 'primereact/message';

import { getMerchandiseItemsList } from '../Items/Items.service';
import { discountTypes } from '../../CRM/Quotes/constants/constants';

import { createMerchandiseItem } from './ProjectItemsService';
import PFFormikError from './PFFormikError';

const AddProjectMerchandiseItemDialog = ({
  visible,
  projectId,
  onHide,
  itemsList,
  addAllMerchandiseCost,
  quoteNumber,
  CRMEnabled,
  selectedProjectItem,
  isFromClone,
  setIsFromClone,
}) => {
  const toast = useRef(null);
  const [projectMerchandiseItems, setProjectMerchandiseItems] =
    useState(itemsList);
  const [selectedItem, setSelectedItem] = useState({});
  const [reloadMasterItems, setReloadMasterItems] = useState(false);
  const [isConsent, setIsConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //mount
  useEffect(() => {
    if (selectedProjectItem?.project_item_id > 0) {
      let itemObj;
      if (isFromClone && selectedProjectItem) {
        itemObj = selectedProjectItem?.item || [];
      } else {
        itemObj = itemsList?.find(
          item => item?.item_id === selectedProjectItem?.item?.item_id
        );
      }

      if (itemObj) {
        setProjectMerchandiseItems(itemsList);
        setSelectedItem(itemObj);
      } else {
        setProjectMerchandiseItems([...itemsList, selectedProjectItem?.item]);
        setSelectedItem(selectedProjectItem?.item);
      }

      formik.setValues(prevValues => ({
        ...prevValues,
        item_id: selectedProjectItem?.item?.item_id
          ? selectedProjectItem?.item?.item_id
          : null,
        quantityValue: !isNaN(parseFloat(selectedProjectItem?.item_quantity))
          ? parseFloat(selectedProjectItem?.item_quantity)
          : 0,
        revenue_price: !isNaN(parseFloat(selectedProjectItem?.cost_ea))
          ? parseFloat(selectedProjectItem?.cost_ea)
          : 0,
        retail_price: !isNaN(parseFloat(selectedProjectItem?.sell_price))
          ? parseFloat(selectedProjectItem?.sell_price)
          : 0,
        cost_price: !isNaN(
          parseFloat(selectedProjectItem?.edited_labor_cost_ea)
        )
          ? parseFloat(selectedProjectItem?.edited_labor_cost_ea)
          : 0,
        delivery_type: selectedProjectItem?.delivery_type
          ? selectedProjectItem?.delivery_type
          : null,
      }));
    }
  }, [selectedProjectItem]);

  const selectedLabotIdTemplate = option => {
    if (option) {
      return (
        <div>
          {option?.item_number} {option?.item_desc}
        </div>
      );
    }
  };

  const laborOptionTemplate = option => {
    return (
      <div className="flex align-items-center">
        <div>
          {option?.item_number}
          {option?.item_desc}
        </div>
      </div>
    );
  };

  const handleClose = (projectItem = null, timeout = 0) => {
    setIsFromClone(false);
    setSelectedItem(null);
    formik.resetForm();
    if (timeout > 0) {
      setTimeout(() => {
        onHide(projectItem);
      }, timeout);
    } else onHide(projectItem);
  };
  const validationSchema = Yup.object().shape({
    item_id: Yup.number()
      .typeError('Item Number must be a valid number')
      .integer('Item must be an integer')
      .min(1, 'Item must be greater than 0')
      .required('Item is required'),
    quantityValue: Yup.number()
      .typeError('Quantity must be a valid number')
      .min(0, 'Quantity must be a positive number')
      .required('Quantity is required'),
    cost_price: Yup.number()
      .typeError('Cost must be a valid number')
      .min(0, 'Cost must be greater than or equal to 0'),
    retail_price: Yup.number()
      .typeError('Retail Price must be a valid number')
      .min(0, 'Retail Price must be greater than or equal to 0'),
    revenue_price: Yup.number().typeError(
      'Revenue Price must be a valid number'
    ),
  });

  const formik = useFormik({
    initialValues: {
      item_id: 0,
      quantityValue: 0,
      cost_price: 0,
      retail_price: 0,
      revenue_price: 0,
      delivery_type: null,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      if (!isConsent) return null;
      setIsLoading(true);
      const response = await createMerchandiseItem(projectId, {
        item_id: values.item_id,
        item_quantity: values.quantityValue,
        cost_ea: values.revenue_price,
        sell_price: values.retail_price,
        edited_labor_cost_ea: values.cost_price,
        delivery_type: values?.delivery_type ?? undefined,
        project_item_desc: selectedItem?.item_desc || '',
      });
      setIsLoading(false);

      if (response?.data?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Product item assigned successfully.',
        });
        handleClose(response?.data?.status, 1500);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Unable to assign product item',
        });
      }
    },
  });
  const filterOptions = debounce(async event => {
    if (event?.filter?.length > 2) {
      getMerchandiseItemsList(
        { query: event.filter },
        setReloadMasterItems,
        result => result?.count && setProjectMerchandiseItems(result?.items)
      );
    } else if (!event?.filter?.length) setProjectMerchandiseItems(itemsList);
  }, 300);
  const totalCost = formik.values.cost_price * formik.values.quantityValue;
  const totalRetail = formik.values.retail_price * formik.values.quantityValue;
  const totalRevenue =
    formik.values.revenue_price * formik.values.quantityValue;

  return (
    <Dialog
      header="Assign Product Item"
      className="w-11 xl:w-6"
      visible={visible}
      onHide={handleClose}
      pt={{
        header: { className: 'mb-0 pb-0' },
        content: { className: 'pt-1' },
      }}
      draggable={false}
    >
      <BlockUI
        blocked={isLoading}
        className="opacity-30"
        pt={{ mask: { className: 'gray-bg-300' } }}
      >
        <div className="card w-12">
          <Toast ref={toast} />
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-column gap-2"
          >
            <div className="grid mt-2">
              {isFromClone ? (
                <div className="col-12 md:col-6 pb-1">
                  <span className="p-float-label w-full w-12">
                    <InputText
                      id="item_id"
                      name="item_id"
                      type="text"
                      value={`${selectedItem?.item_number} ${selectedItem?.item_desc}`}
                      readOnly
                      className="w-12"
                    />
                    <label
                      htmlFor="description"
                      style={{ top: '-11px!important', background: '#fff' }}
                    >
                      Item #
                    </label>
                  </span>
                </div>
              ) : (
                <>
                  <div className="col-12 md:col-6 pb-1">
                    <span className="p-float-label h-full">
                      <Dropdown
                        loading={reloadMasterItems}
                        name="item_id"
                        value={selectedItem}
                        filterBy="item_number,item_desc"
                        onChange={e => {
                          setSelectedItem(e.value);
                          formik.setValues(prevValues => ({
                            ...prevValues,
                            item_id: e.value?.item_id,
                            cost_price: !isNaN(parseFloat(e.value?.cost_price))
                              ? parseFloat(e.value?.cost_price)
                              : 0,
                            retail_price: !isNaN(
                              parseFloat(e.value?.retail_price)
                            )
                              ? parseFloat(e.value?.retail_price)
                              : 0,
                            revenue_price: !isNaN(
                              parseFloat(e.value?.revenue_price)
                            )
                              ? parseFloat(e.value?.revenue_price)
                              : 0,
                          }));
                        }}
                        options={projectMerchandiseItems}
                        optionLabel="item_id"
                        filter
                        onFilter={filterOptions}
                        valueTemplate={selectedLabotIdTemplate}
                        itemTemplate={laborOptionTemplate}
                        className="labor-item w-12 border h-full"
                        appendTo="self"
                        pt={{
                          panel: { className: 'max-w-2rem' },
                        }}
                      />
                      <label htmlFor="item_id">Item #</label>
                    </span>
                    <PFFormikError
                      touched={formik.touched}
                      errors={formik.errors}
                      field="item_id"
                    ></PFFormikError>
                  </div>
                  <div className="col-12 text-xs pt-0 md:hidden">
                    Type at least 3 chars. to search.
                  </div>
                </>
              )}
              <div className="col-12 md:col-6 pb-1">
                <span className="p-float-label w-full w-12">
                  <InputText
                    id="description"
                    inputId="description"
                    name="description"
                    type="text"
                    value={selectedItem?.item_desc}
                    readOnly
                    className="w-12"
                  />
                  <label
                    htmlFor="description"
                    style={{ top: '-11px!important', background: '#fff' }}
                  >
                    Description
                  </label>
                </span>
              </div>
              <div className="col-12 text-xs pt-0 hidden md:block">
                Type at least 3 chars. to search.
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label w-full w-12">
                  <InputText
                    id="manufacturer"
                    name="manufacturer"
                    type="text"
                    value={selectedItem?.meta_type_manufacturer?.value}
                    readOnly
                    className="w-12"
                    disabled
                  />
                  <label
                    htmlFor="manufacturer"
                    style={{ top: '-11px!important', background: '#fff' }}
                  >
                    Manufacturer
                  </label>
                </span>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label w-full w-12">
                  <InputText
                    id="vendor"
                    name="vendor"
                    type="text"
                    value={selectedItem?.meta_type_vendor?.value}
                    readOnly
                    className="w-12"
                    disabled
                  />
                  <label
                    htmlFor="vendor"
                    style={{ top: '-11px!important', background: '#fff' }}
                  >
                    Vendor
                  </label>
                </span>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="item_quantity"
                    value={formik.values.quantityValue}
                    onChange={e => {
                      formik.setFieldValue(
                        'quantityValue',
                        !isNaN(parseFloat(e.value)) ? parseFloat(e.value) : null
                      );
                    }}
                    min={0}
                    useGrouping={false}
                    className="w-12"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                  />
                  <label htmlFor="item_quantity">Quantity</label>
                </span>
                <PFFormikError
                  touched={formik.touched}
                  errors={formik.errors}
                  field="quantityValue"
                ></PFFormikError>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label w-full w-12">
                  <InputText
                    id="delivery_type"
                    name="delivery_type"
                    type="text"
                    value={formik?.delivery_type}
                    onChange={e => {
                      formik.setFieldValue(
                        'delivery_type',
                        e?.target?.value ? e?.target?.value : null
                      );
                    }}
                    className="w-12"
                    maxLength={45}
                  />
                  <label
                    htmlFor="description"
                    style={{ top: '-11px!important', background: '#fff' }}
                  >
                    Delivery Type
                  </label>
                </span>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="retail_price"
                    value={formik.values.retail_price}
                    onChange={e => {
                      formik.setFieldValue(
                        'retail_price',
                        !isNaN(parseFloat(e.value)) ? parseFloat(e.value) : null
                      );
                    }}
                    min={0}
                    useGrouping={false}
                    className="w-12"
                    disabled={!addAllMerchandiseCost}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  />
                  <label htmlFor="retail_price">Retail Ea $</label>
                </span>
                <PFFormikError
                  touched={formik.touched}
                  errors={formik.errors}
                  field="retail_price"
                ></PFFormikError>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="total_retail"
                    value={totalRetail}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    className="w-12"
                    min={0}
                    readOnly
                  />
                  <label htmlFor="total_retail">Retail $</label>
                </span>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="revenue_price"
                    value={formik.values.revenue_price}
                    onChange={e => {
                      const revenueValue = !isNaN(e.value) ? e.value : null;
                      formik.setFieldValue(
                        'revenue_price',
                        !isNaN(parseFloat(revenueValue))
                          ? parseFloat(revenueValue)
                          : null
                      );
                    }}
                    useGrouping={false}
                    className="w-12"
                    disabled={!addAllMerchandiseCost}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  />
                  <label htmlFor="revenue_price">Revenue Ea $</label>
                </span>
                <PFFormikError
                  touched={formik.touched}
                  errors={formik.errors}
                  field="revenue_price"
                ></PFFormikError>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="total_revenue"
                    value={totalRevenue}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    className="w-12"
                    readOnly
                  />
                  <label htmlFor="total_revenue">Revenue $</label>
                </span>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="cost_price"
                    value={formik.values.cost_price}
                    onChange={e => {
                      formik.setFieldValue(
                        'cost_price',
                        !isNaN(parseFloat(e.value)) ? parseFloat(e.value) : null
                      );
                    }}
                    min={0}
                    useGrouping={false}
                    className="w-12"
                    disabled={!addAllMerchandiseCost}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  />
                  <label htmlFor="cost_price">Cost Ea $</label>
                </span>
                <PFFormikError
                  touched={formik.touched}
                  errors={formik.errors}
                  field="cost_price"
                ></PFFormikError>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="total"
                    value={totalCost}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    className="w-12"
                    min={0}
                    readOnly
                  />
                  <label htmlFor="total">Cost $</label>
                </span>
              </div>
              {CRMEnabled && quoteNumber && (
                <>
                  <div className="col-12 md:col-6">
                    <span className="p-float-label">
                      <Dropdown
                        options={discountTypes}
                        optionLabel="name"
                        optionValue="value"
                        name="discount_type"
                        value={formik?.values?.discount_type || ''}
                        className="w-full"
                        onChange={e =>
                          formik.setFieldValue('discount_type', e.value || '')
                        }
                        appendTo={'self'}
                      />
                      <label htmlFor="discount_type">Discount Type</label>
                    </span>
                  </div>
                  <div className="col-12 md:col-6">
                    <span className="p-float-label">
                      <InputNumber
                        inputId="tax"
                        name="tax"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        max={100}
                        value={formik?.values?.tax || 0}
                        onChange={e =>
                          formik.setFieldValue('tax', e.value || '')
                        }
                        className="w-full"
                      />
                      <label htmlFor="tax">Tax</label>
                    </span>
                  </div>
                  <div className="col-12 md:col-6">
                    <span className="p-float-label">
                      {formik?.values?.discount_type === 'Amount' ? (
                        <InputNumber
                          inputId="discount"
                          name="discount"
                          mode={'currency'}
                          currency="USD"
                          minFractionDigits={2}
                          value={formik?.values?.discount || 0}
                          onChange={e =>
                            formik.setFieldValue('discount', e.value || '')
                          }
                          className="w-full"
                        />
                      ) : (
                        <InputNumber
                          inputId="discount"
                          name="discount"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          max={100}
                          value={formik?.values?.discount || 0}
                          onChange={e =>
                            formik.setFieldValue('discount', e.value || '')
                          }
                          className="w-full"
                        />
                      )}
                      <label htmlFor="discount">Discount</label>
                    </span>
                  </div>
                </>
              )}

              {selectedItem?.image_url ? (
                <div className="col-12">
                  <h6 className="my-2">Image</h6>
                  <img
                    width="40"
                    height="30"
                    alt="Item"
                    src={selectedItem?.image_url}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="col-12 md:col-12 px-0 flex gap-2">
              <Checkbox
                inputId="consent"
                name="consent"
                value={isConsent}
                onChange={e => setIsConsent(e.checked)}
                checked={isConsent}
              />
              Yes, I confirm to make changes to the cost fields and provide the
              consent to update the Project level initially derived values or
              taken from source.
            </div>
            <div className="col-12 md:col-12 px-0 text-right">
              <Button
                type="button"
                label="Cancel"
                className="ml-2"
                size="small"
                severity="info"
                outlined
                onClick={handleClose}
              />
              <Button
                label="Save"
                className="ml-2"
                size="small"
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !isConsent ||
                  !formik.values?.item_id
                }
                type="submit"
              />
            </div>
          </form>
        </div>
      </BlockUI>
    </Dialog>
  );
};

export default AddProjectMerchandiseItemDialog;
