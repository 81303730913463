import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  h1Style: {
    position: 'absolute',
    top: '5rem',
    left: '5rem',
    color: '#281414',
    zIndex: 10,
    margin: 0,
    padding: 20,
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    '@media (max-width: 768px)': {
      left: '5%',
      top: '1rem',
      width: '90%',
      '& .MuiGrid-item': {
        width: '100%',
        padding: '5px 0 0',
      },
      '& .MuiAutocomplete-root': {
        minWidth: '100%!important',
        maxWidth: '100%!important',
        width: '100%',
      },
    },
  },
  marker: {
    position: 'relative',
    textAlign: 'center',
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  mapDatePickerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: '10px',
  },
  datePicker: {
    maxWidth: '240px',

    '& .MuiInput-underline:before': {
      borderBottom: 'none !important',
    },

    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  gridPaper: {
    borderRadius: '20px',
    padding: '10px 0px 10px 0px',
    maxHeight: '400px',
  },
  avatar: {
    backgroundColor: '#ffffff',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
  },
  dateIcons: {
    width: '20px',
    height: '20px',
  },
  filterAvatar: {
    backgroundColor: '#ffffff',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    border: '1px solid #B3B3B3',
    display: 'inline-flex',
  },
  filterButton: {
    fontSize: '13px',
    '& .MuiSvgIcon-root': {
      height: '18px',
    },
  },
  avatarWrapper: {
    position: 'absolute',
    top: '5rem',
    left: '5rem',
    color: '#281414',
    zIndex: 10,
    margin: 0,
    borderRadius: '50px',
    padding: 10,
    backgroundColor: '#ffffff',
    border: '1px solid grey',
  },
  iconRoot: {
    width: '25px',
    height: '25px',
  },
  tabRoot: {
    minHeight: 'auto',
  },
  tabTextColor: {
    color: theme.palette.text.primary,
  },
}));
