import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ApiService from '../../../services/api.service';
const initialState = {
  types: [],
  status: false,
  error: null,
};
export const fetchTypes = createAsyncThunk(
  'master/fetchTypes',
  async (_, { getState, rejectWithValue }) => {
    const { types } = getState().types;
    if (types.length > 0) {
      return rejectWithValue('Types already fetched');
    } else {
      const response = await ApiService.get('/projects/master/project-type');
      return response;
    }
  }
);
const typesSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {
    clearTypes: state => {
      state.types = [];
      state.status = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTypes.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.types = action?.payload || [];
      })
      .addCase(fetchTypes.rejected, (state, action) => {
        if (action.payload === 'Types already fetched') {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.error = action.error.message;
        }
      });
  },
});

export const { clearTypes } = typesSlice.actions;

export const typeReducer = typesSlice.reducer;
