import { useState } from 'react';
import axios from 'axios';

import { authURL } from '../config/variables';
import clearMasterDataHook from '../redux/slices/master/clearMasterDataHook';
import {
  REACT_APP_AUTH_CLIENT_SECRET,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REFRESH_GRANT_TYPE,
} from '../constants/envConstants';

export default function useToken() {
  const getAccessToken = () => {
    return localStorage.getItem('access_token');
  };

  const getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
  };
  const clearMasterData = clearMasterDataHook();
  const [accessToken, setAccessToken] = useState(getAccessToken());
  const [refreshToken, setRefreshToken] = useState(getRefreshToken());

  const saveToken = userToken => {
    localStorage.setItem('access_token', userToken.access_token);
    localStorage.setItem('refresh_token', userToken.refresh_token);
    setAccessToken(userToken.access_token);
    setRefreshToken(userToken.refresh_token);
  };

  const saveRereshToken = () => {
    let errorResponse = false;
    if (refreshToken) {
      axios
        .post(`${authURL}/token`, {
          refresh_token: refreshToken,
          client_secret: REACT_APP_AUTH_CLIENT_SECRET,
          client_id: REACT_APP_AUTH_CLIENT_ID,
          grant_type: REACT_APP_AUTH_REFRESH_GRANT_TYPE,
        })
        .then(res => {
          if (
            res?.status === 200 &&
            res.data.refresh_token &&
            res.data.access_token
          ) {
            saveToken(res.data);
          } else {
            errorResponse = true;
          }
        })
        .catch(function (error) {
          errorResponse = true;
          console.log(error);
        });
    } else {
      errorResponse = true;
    }
    if (errorResponse === true) {
      localStorage.clear();
      document.location = document.location.origin;
    }
  };

  const removeToken = () => {
    return axios
      .post(`${authURL}/logout`, {
        refresh_token: refreshToken,
      })
      .then(res => {
        if (res?.status === 200) {
          clearMasterData();
        }
        return res;
      })
      .catch(error => {
        console.error(error);
        throw error;
      })
      .finally(() => {
        localStorage.clear();
        document.location = document.location.origin + '/';
      });
  };

  const redirectUser = () => {
    localStorage.clear();
    setAccessToken('');
    setRefreshToken('');
    document.location = `${document.location.origin}/`;
  };

  return {
    setToken: saveToken,
    logout: removeToken,
    expireToken: redirectUser,
    updateToken: saveRereshToken,
    accessToken,
    refreshToken,
  };
}
