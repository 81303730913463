import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedViewMore: '1',
  isTechFilterApplied: false,
};

const viewMoreEventSlice = createSlice({
  name: 'viewMoreEventDropdown',
  initialState,
  reducers: {
    setSelectedViewMore: (state, action) => {
      state.selectedViewMore = action.payload;
    },
    setIsTechFilterApplied: (state, action) => {
      state.isTechFilterApplied = action.payload;
    },
  },
});

export const { setSelectedViewMore, setIsTechFilterApplied } =
  viewMoreEventSlice.actions;

export const viewMoreEventReducer = viewMoreEventSlice.reducer;
