import { getLocalClientDetails } from '../../../utils/Helpers';

export const getPeriod = type => {
  let period;
  switch (type) {
    case 'Day':
    case 'TimelineDay':
      period = 'D';
      break;
    case 'Week':
    case 'TimelineWeek':
      period = 'W';
      break;
    case 'Month':
    case 'TimelineMonth':
      period = 'M';
      break;
  }
  return period;
};
export const getDefaultView = () => {
  let defaultView = 'TimelineDay';
  const client_details = getLocalClientDetails();
  if (client_details?.default_calender_view) {
    switch (client_details.default_calender_view.toLowerCase()) {
      case 'day':
        defaultView = 'TimelineDay';
        break;
      case 'week':
        defaultView = 'TimelineWeek';
        break;
      case 'month':
        defaultView = 'TimelineMonth';
        break;
      default:
        defaultView = 'TimelineDay';
    }
  }
  return defaultView;
};
export const getCalendarDefaultView = () => {
  let defaultView = 'Day';
  const client_details = getLocalClientDetails();
  if (client_details?.default_calender_view) {
    switch (client_details.default_calender_view.toLowerCase()) {
      case 'day':
        defaultView = 'Day';
        break;
      case 'week':
        defaultView = 'Week';
        break;
      case 'month':
        defaultView = 'Month';
        break;
      default:
        defaultView = 'Day';
    }
  }
  return defaultView;
};

export const selectedRapidSlotSyncFusion = selectInfo => {
  const startSlotTime = new Date(selectInfo?.data?.StartTime);
  const endSlotTime = new Date(selectInfo?.data?.EndTime);
  startSlotTime?.setMinutes(startSlotTime?.getMinutes() + 60);

  const timeDifference = endSlotTime?.getTime() - startSlotTime?.getTime();
  const minutesDifference = timeDifference / (1000 * 60);
  return { minutesDifference, startSlotTime };
};
export const mergeAndDuplicateValues = (primary, secondary) => {
  const toArray = value => {
    if (Array.isArray(value)) return value.map(String);
    if (typeof value === 'string') return value.split(',');
    if (typeof value === 'number') return [String(value)];
    return [];
  };

  const uniqueValues = Array.from(
    new Set([...toArray(primary), ...toArray(secondary)])
  );

  return uniqueValues.join(',');
};
