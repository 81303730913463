//import
import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { useFormik } from 'formik';
import * as Yup from 'yup';

//Services

/* Components */
import { debounce } from 'lodash';
import { Checkbox } from 'primereact/checkbox';
import { BlockUI } from 'primereact/blockui';
import { Message } from 'primereact/message';

import { getLaborItemsList } from '../Items/Items.service';
import { getLocalClientDetails } from '../../../utils/Helpers';
import { discountTypes } from '../../CRM/Quotes/constants/constants';
import { INPUT_NUMBER_LOCALE } from '../../../constants';

import PFFormikError from './PFFormikError';
import { createLaborItem, getSystemItems } from './ProjectItemsService';

const AddProjectLaborItemDialog = ({
  visible,
  onHide,
  projectId,
  itemsList,
  addAllCostFields,
  quoteNumber,
  CRMEnabled,
  selectedProjectItem,
  isFromClone,
  setIsFromClone,
}) => {
  const toast = useRef(null);
  const [projectLaborItems, setProjectLaborItems] = useState(itemsList);
  const [selectedItem, setSelectedItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [reloadMasterItems, setReloadMasterItems] = useState(false);
  const [isConsent, setIsConsent] = useState(false);
  const [isSkipTechnicianAssignment, setIsSkipTechnicianAssignment] = useState(
    getLocalClientDetails()?.skip_technician_assignment_cloning ? true : false
  );
  const selectedLabotIdTemplate = option => {
    if (option) {
      return (
        <div>
          {option?.item_number} {option?.item_desc}
        </div>
      );
    }
  };

  const laborOptionTemplate = option => {
    return (
      <div className="flex align-items-center">
        <div>
          {option?.item_number}
          {option?.item_desc}
        </div>
      </div>
    );
  };
  const validationSchema = Yup.object().shape({
    item_id: Yup.number()
      .typeError('Item Number must be a valid number')
      .integer('Item must be an integer')
      .min(1, 'Item must be greater than 0')
      .required('Item is required'),
    quantityValue: Yup.number()
      .typeError('Quantity must be a valid number')
      .min(0, 'Quantity must be a positive number')
      .required('Quantity is required'),
    revenue_price: Yup.number().typeError('Cost must be a valid number'),
    laborCost: Yup.number()
      .typeError('Labor Cost must be a valid number')
      .min(0, 'Labor Cost must be greater than or equal to 0'),
    retail_price: Yup.number()
      .typeError('Retail Price must be a valid number')
      .min(0, 'Retail Price must be greater than or equal to 0'),
  });
  useEffect(() => {
    if (selectedProjectItem?.project_item_id > 0) {
      let itemObj;
      if (isFromClone && selectedProjectItem) {
        itemObj = selectedProjectItem?.item || [];
      } else {
        itemObj = projectLaborItems?.find(
          item => item.item_id === selectedProjectItem?.item?.item_id
        );
      }

      if (itemObj) {
        setProjectLaborItems(itemsList);
        setSelectedItem(itemObj);
      } else {
        setProjectLaborItems([...itemsList, selectedProjectItem?.item]);
        setSelectedItem(selectedProjectItem?.item);
      }

      formik.setValues(prevValues => ({
        ...prevValues,
        item_id: selectedProjectItem?.item?.item_id
          ? selectedProjectItem?.item?.item_id
          : null,
        laborCost:
          (selectedProjectItem?.edited_labor_cost_ea
            ? selectedProjectItem?.edited_labor_cost_ea
            : 0) || selectedProjectItem?.labor_cost_ea
            ? selectedProjectItem?.labor_cost_ea
            : 0,
        revenue_price: selectedProjectItem?.cost_ea
          ? selectedProjectItem?.cost_ea
          : 0,
        quantityValue: selectedProjectItem?.item_quantity
          ? selectedProjectItem?.item_quantity
          : 0,
        retail_price: selectedProjectItem?.sell_price
          ? selectedProjectItem?.sell_price
          : 0,
        delivery_type: selectedProjectItem?.delivery_type
          ? selectedProjectItem?.delivery_type
          : null,
      }));
    }
  }, [selectedProjectItem]);

  const formik = useFormik({
    initialValues: {
      item_id: null,
      quantityValue: 0,
      revenue_price: 0,
      laborCost: 0,
      retail_price: 0,
      delivery_type: null,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      if (!isConsent) return null;
      setIsLoading(true);
      const {
        item_id,
        quantityValue,
        revenue_price,
        laborCost,
        retail_price,
        delivery_type,
      } = values;
      const response = await createLaborItem(projectId, {
        item_id,
        item_quantity: quantityValue,
        cost_ea: revenue_price,
        edited_labor_cost_ea: laborCost,
        sell_price: retail_price,
        delivery_type: delivery_type ?? undefined,
        parent_item_id: isSkipTechnicianAssignment
          ? selectedProjectItem.project_item_id
          : undefined,
        project_item_desc: selectedItem?.item_desc || '',
      });
      setIsLoading(false);
      if (response?.data?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Labor item assigned successfully.',
        });
        handleClose(response?.data?.status, 1500);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Unable to assign labor item',
        });
      }
    },
    enableReinitialize: true,
  });

  const totalRevenue =
    formik.values.revenue_price * formik.values.quantityValue;
  const totalLaborCost = formik.values.laborCost * formik.values.quantityValue;
  const totalRetail = formik.values.retail_price * formik.values.quantityValue;

  const handleClose = (projectItem = null, timeout = 0) => {
    setIsFromClone(false);
    formik.resetForm();
    setSelectedItem(null);
    if (timeout > 0) {
      setTimeout(() => {
        onHide(projectItem);
      }, timeout);
    } else onHide(projectItem);
  };

  const filterOptions = debounce(async event => {
    if (event?.filter?.length > 2) {
      getLaborItemsList(
        { query: event.filter },
        setReloadMasterItems,
        result => result?.count && setProjectLaborItems(result?.items)
      );
    } else if (!event?.filter?.length) setProjectLaborItems(itemsList);
  }, 300);
  return (
    <Dialog
      header="Assign Labor Item"
      visible={visible}
      onHide={handleClose}
      pt={{
        header: { className: 'mb-0 pb-0' },
        content: { className: 'pt-1' },
      }}
      className="w-11 xl:w-6"
      draggable={false}
    >
      <BlockUI
        blocked={isLoading}
        className="opacity-30"
        pt={{ mask: { className: 'gray-bg-300' } }}
      >
        <div className="card w-12">
          <Toast ref={toast} />
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-column gap-2"
          >
            <div className="grid mt-2">
              {!isFromClone ? (
                <>
                  <div className="col-12 md:col-6 pb-1">
                    <span className="p-float-label h-full">
                      <Dropdown
                        loading={reloadMasterItems}
                        name="item_id"
                        filterBy="item_number,item_desc"
                        value={selectedItem}
                        onChange={e => {
                          formik.setValues(prevValues => ({
                            ...prevValues,
                            item_id: e.value?.item_id,
                            revenue_price: e.value?.revenue_price || 0,
                            retail_price: e.value?.retail_price || 0,
                          }));

                          setSelectedItem(e.value);
                        }}
                        options={projectLaborItems}
                        optionLabel="item_id"
                        showFilterClear
                        filter
                        onFilter={filterOptions}
                        valueTemplate={selectedLabotIdTemplate}
                        itemTemplate={laborOptionTemplate}
                        className="labor-item w-12 border h-full"
                        appendTo="self"
                        pt={{
                          panel: { className: 'max-w-2rem' },
                        }}
                        disabled={isFromClone}
                      />
                      <label htmlFor="item_id">Item #</label>
                    </span>
                    <PFFormikError
                      touched={formik.touched}
                      errors={formik.errors}
                      field="item_id"
                    ></PFFormikError>
                  </div>
                  <div className="col-12 text-xs pt-0 md:hidden">
                    Type at least 3 chars. to search.
                  </div>
                </>
              ) : (
                <div className="col-12 md:col-6 pb-1">
                  <span className="p-float-label">
                    <InputText
                      className="w-12"
                      id="item_id"
                      name="item_id"
                      type="text"
                      value={`${selectedItem?.item_number} ${selectedItem?.item_desc}`}
                      readOnly
                    />
                    <label
                      htmlFor="item_id"
                      style={{ top: '-11px!important', background: '#fff' }}
                    >
                      Item #
                    </label>
                  </span>
                </div>
              )}

              <div className="col-12 md:col-6 pb-1">
                <span className="p-float-label">
                  <InputText
                    className="w-12"
                    id="description"
                    name="description"
                    type="text"
                    value={selectedItem?.item_desc}
                    readOnly
                  />
                  <label htmlFor="description">Description</label>
                </span>
              </div>
              <div className="col-12 text-xs pt-0 hidden md:block">
                Type at least 3 chars. to search.
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="item_quantity"
                    value={formik.values.quantityValue}
                    onChange={e => {
                      formik.setFieldValue('quantityValue', e.value);
                    }}
                    useGrouping={false}
                    min={0}
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    locale={INPUT_NUMBER_LOCALE}
                    className="w-12"
                  />

                  <label htmlFor="item_quantity">Quantity</label>
                </span>
                <PFFormikError
                  touched={formik.touched}
                  errors={formik.errors}
                  field="quantityValue"
                ></PFFormikError>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    className="w-12"
                    id="delivery_type"
                    name="delivery_type"
                    type="text"
                    value={selectedItem?.delivery_type}
                    onChange={e => {
                      formik.setFieldValue(
                        'delivery_type',
                        e?.target?.value ? e?.target?.value : null
                      );
                    }}
                    maxLength={45}
                  />
                  <label htmlFor="delivery_type">Delivery Type</label>
                </span>
              </div>

              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="retail_price"
                    name="retailPrice"
                    value={formik.values.retail_price || 0}
                    onChange={e => {
                      formik?.setFieldValue(
                        'retail_price',
                        e.value !== null ? e.value : 0
                      );
                    }}
                    mode="currency"
                    currency="USD"
                    locale={INPUT_NUMBER_LOCALE}
                    className="w-12"
                    min={0}
                  />
                  <label htmlFor="retail_price">Retail Ea $</label>
                </span>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="total_retail"
                    value={totalRetail}
                    mode="currency"
                    currency="USD"
                    locale={INPUT_NUMBER_LOCALE}
                    className="w-12"
                    min={0}
                    readOnly
                  />
                  <label htmlFor="total_retail">Retail $</label>
                </span>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="revenue_price"
                    value={formik.values.revenue_price}
                    onChange={e => {
                      const revenueValue = !isNaN(e.value) ? e.value : null;
                      formik.setFieldValue(
                        'revenue_price',
                        !isNaN(parseFloat(revenueValue))
                          ? parseFloat(revenueValue)
                          : null
                      );
                    }}
                    mode="currency"
                    currency="USD"
                    locale={INPUT_NUMBER_LOCALE}
                    className="w-12"
                    disabled={!addAllCostFields}
                  />
                  <label htmlFor="revenue_price">Revenue Ea $</label>
                </span>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="revenue_price"
                    value={totalRevenue}
                    mode="currency"
                    currency="USD"
                    locale={INPUT_NUMBER_LOCALE}
                    className="w-12"
                    readOnly
                  />
                  <label htmlFor="revenue_price">Revenue $</label>
                </span>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="labor_cost_ea"
                    value={formik.values.laborCost}
                    onChange={e => {
                      formik.setFieldValue('laborCost', e.value);
                    }}
                    mode="currency"
                    currency="USD"
                    locale={INPUT_NUMBER_LOCALE}
                    min={0}
                    className="w-12"
                    disabled={!addAllCostFields}
                  />
                  <label htmlFor="labor_cost_ea">Labor Cost Ea $</label>
                </span>
              </div>
              <div className="col-12 md:col-6">
                <span className="p-float-label">
                  <InputNumber
                    inputId="total_labor"
                    value={totalLaborCost}
                    mode="currency"
                    currency="USD"
                    locale={INPUT_NUMBER_LOCALE}
                    min={0}
                    className="w-12"
                    readOnly
                  />
                  <label htmlFor="total_labor">Labor Cost $</label>
                </span>
              </div>

              {CRMEnabled && quoteNumber && (
                <>
                  <div className="col-12 md:col-6">
                    <span className="p-float-label">
                      <Dropdown
                        options={discountTypes}
                        optionLabel="name"
                        optionValue="value"
                        name="discount_type"
                        value={formik?.values?.discount_type || ''}
                        className="w-full"
                        onChange={e =>
                          formik.setFieldValue('discount_type', e.value || '')
                        }
                        appendTo={'self'}
                      />
                      <label htmlFor="discount_type">Discount Type</label>
                    </span>
                  </div>
                  <div className="col-12 md:col-6">
                    <span className="p-float-label">
                      <InputNumber
                        inputId="tax"
                        name="tax"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        max={100}
                        value={formik?.values?.tax || 0}
                        onChange={e =>
                          formik.setFieldValue('tax', e.value || '')
                        }
                        className="w-full"
                      />
                      <label htmlFor="tax">Tax</label>
                    </span>
                  </div>
                  <div className="col-12 md:col-6">
                    <span className="p-float-label">
                      {formik?.values?.discount_type === 'Amount' ? (
                        <InputNumber
                          inputId="discount"
                          name="discount"
                          mode={'currency'}
                          currency="USD"
                          minFractionDigits={2}
                          value={formik?.values?.discount || 0}
                          onChange={e =>
                            formik.setFieldValue('discount', e.value || '')
                          }
                          className="w-full"
                        />
                      ) : (
                        <InputNumber
                          inputId="discount"
                          name="discount"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          max={100}
                          value={formik?.values?.discount || 0}
                          onChange={e =>
                            formik.setFieldValue('discount', e.value || '')
                          }
                          className="w-full"
                        />
                      )}
                      <label htmlFor="discount">Discount</label>
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="col-12 md:col-12 px-0 flex gap-2">
              <Checkbox
                inputId="consent"
                name="consent"
                value={isConsent}
                onChange={e => setIsConsent(e.checked)}
                checked={isConsent}
              />
              Yes, I confirm to make changes to the cost fields and provide the
              consent to update the Project level initially derived values or
              taken from source.
            </div>
            {selectedProjectItem &&
            getLocalClientDetails()?.skip_technician_assignment_cloning ? (
              <div className="col-12 md:col-12 px-0 flex gap-2">
                <Checkbox
                  inputId="consent"
                  name="consent"
                  value={isSkipTechnicianAssignment}
                  onChange={e => setIsSkipTechnicianAssignment(e.checked)}
                  checked={isSkipTechnicianAssignment}
                />
                Skip Technician Assignment.
              </div>
            ) : null}
            {selectedProjectItem &&
            selectedProjectItem.project_item_user &&
            getLocalClientDetails()?.skip_technician_assignment_cloning &&
            isSkipTechnicianAssignment ? (
              <Message
                severity="warn"
                text="Cloning with Skip Technician flag enabled will copy all the assignments and cost fields from parent to cloned item and delete the technician from parent item. Any change on cloned item would be discarded, so please ensure to reverify the same."
              />
            ) : null}
            <div className="col-12 md:col-12 px-0 text-right">
              <Button
                type="button"
                label="Cancel"
                className="ml-2"
                size="small"
                severity="info"
                outlined
                onClick={handleClose}
              />
              <Button
                type="submit"
                label="Save"
                className="ml-2"
                size="small"
                disabled={
                  !formik.isValid ||
                  !formik.dirty ||
                  formik.isSubmitting ||
                  !isConsent ||
                  !formik.values?.item_id
                }
              />
            </div>
          </form>
        </div>
      </BlockUI>
    </Dialog>
  );
};
export default AddProjectLaborItemDialog;
